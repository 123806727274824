export const editPolygonEventName = 'map.editOnePolygonEventName';

export const editOnePolygon = (geometry: any, id: number) => {
  const event = new CustomEvent(editPolygonEventName, {
    detail: {
      geometry,
      id,
    },
  });
  window.dispatchEvent(event);
};
