import { IGetChecklist } from '../../models/checklist/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

export type TGetChecklistByKeysPayload = {
  organizationId: string;
  operationTypeId?: string;
  cultureId?: string;
  intensityId?: string;
  machinery?: boolean;
};

type TRequest = {} & TGetChecklistByKeysPayload;

type TResponse = {} & IGetChecklist;

export const getChecklistByKeys: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/checklist/byKeys`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
