import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { OrganizationalRoutes } from './OrganizationalRoutes';
import { MyOrganizationsWidget } from './components/MyOrganizations';
import { OrganizationSearchWidget } from './components/OrganizationSearch';
import { IncomeOrganizationsWidget } from './components/IncomeOrganizations';
import { OutgoingOrganizationsWidget } from './components/OutgoingOrganizations';

export const OrganizationalRouting: FC = () => (
  <Switch>
    <Route path={OrganizationalRoutes.search} exact component={OrganizationSearchWidget} />
    <Route path={OrganizationalRoutes.incoming} exact component={IncomeOrganizationsWidget} />
    <Route path={OrganizationalRoutes.outgoing} exact component={OutgoingOrganizationsWidget} />
    <Route path={OrganizationalRoutes.list} exact component={MyOrganizationsWidget} />
    <Redirect to={OrganizationalRoutes.list} />
  </Switch>
);
