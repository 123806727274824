import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../shared/constans/sizes';

export const TimeLineWrapper = styled.div`
  background: ${Colors.white};
  border-radius: ${BorderRadius.default};
  height: 48px;
  width: 100%;
`;

export const TimelineInfoMessage = styled.div`
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: ${Colors.darkGray};

  & span {
    margin: 0 8px;
  }
`;

export const TimelineItemsWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 32px;
  max-width: 100%;
`;

export const TimelineItem = styled.div<{ active: boolean }>`
  ${FontSizes.medium};
  color: ${({ active }) => (active ? Colors.green : Colors.darkGray)};
  cursor: pointer;
  padding: 0 16px;
  border-right: 1px solid ${Colors.grayBorder};
  white-space: nowrap;

  &:first-of-type {
    border-right: 1px solid transparent;
  }
`;

export const TimelineIconItem = styled.div`
  padding: 0 16px;
  border-right: 1px solid ${Colors.grayBorder};

  &:first-of-type {
    border-right: 1px solid transparent;
  }
`;
