import styled, { css } from 'styled-components';

import { Colors } from '../../../constans/colors';

import { ReactComponent as InfoIconSvg } from './assets/images/info-default-16x16.svg';
import StyledDropdownBody from './components/DropdownBody/DropdownBody.styles';
import StyledDropdownIcons from './components/DropdownIcons/DropdownIcons.styles';

type TDropdownAutocompleteProps = {
  $width: number;
};

const Autocomplete = styled.span<TDropdownAutocompleteProps>`
  width: ${({ $width }) => `${$width}px` || '100%'};
  color: ${Colors.secondaryDarkGray};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  padding-left: 2px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 98;
`;

const AutocompleteMatchedValue = styled.span`
  color: ${Colors.generalBlack};
`;

const LabelInfoIcon = styled(InfoIconSvg)`
  width: 14px;
  height: 14px;
  margin: 0 4px 4px 4px;
  cursor: pointer;
`;

type TStyledDropdownLabelProps = {
  $isRequired: boolean;
};

const Label = styled.p<TStyledDropdownLabelProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.secondaryDarkGray};

  &:after {
    color: red;
    visibility: ${({ $isRequired }) => ($isRequired ? 'visible' : 'hidden')};
    content: '*';
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchFieldWrapper = styled.div`
  flex: 1 0 auto;
  position: relative;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1 1 50%;
`;

type TStyledDropdownFieldWrapperProps = {
  $isRemovePaddingBottom: boolean;
  $isCentered: boolean;
};

const removePaddingBottom = (condition: boolean) => {
  if (condition) {
    return css`
      padding-bottom: 0px;
    `;
  }
};

const centerValueContainer = (condition: boolean) => {
  if (condition) {
    return css`
      & ${StyledDropdownIcons.Wrapper} {
        align-self: center;
      }
    `;
  }
};

const FieldWrapper = styled.div<TStyledDropdownFieldWrapperProps>`
  background: ${Colors.secondaryGray};
  border-radius: 12px;
  border: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  /* height: 40px; */
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  cursor: pointer;

  ${({ $isRemovePaddingBottom }) => removePaddingBottom($isRemovePaddingBottom)}

  ${({ $isCentered }) => centerValueContainer($isCentered)}


  &:hover {
    border-color: ${Colors.secondaryDarkGray};
  }
`;

type StyledDropdownWrapperProps = {
  $isDropped: boolean;
  $isError: boolean;
  $isBlocked: boolean;
  $isDisabled: boolean;
};

const Wrapper = styled.div<StyledDropdownWrapperProps>`
  position: relative;
  outline: none;
  user-select: none;

  ${({ $isDisabled, $isBlocked }) =>
    ($isDisabled || $isBlocked) &&
    css`
      & ${FieldWrapper} {
        pointer-events: none;
      }
    `};

  & * {
    box-sizing: border-box;
  }

  ${({ $isBlocked }) =>
    $isBlocked &&
    css`
      ${FieldWrapper} {
        background: ${Colors.generalWhite};
      }
    `}

  ${({ $isDropped }) =>
    $isDropped &&
    css`
      & ${FieldWrapper} {
        border-radius: 12px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: ${Colors.secondaryDarkGray};
        border-bottom-color: transparent;
      }

      & ${StyledDropdownBody.Wrapper} {
        border-color: ${Colors.secondaryDarkGray};
      }
    `}

  ${({ $isError, $isDropped }) =>
    $isError &&
    css`
      & ${FieldWrapper} {
        border-color: ${Colors.red};

        ${$isDropped &&
        css`
          border-bottom-color: transparent;
        `}
      }

      & ${StyledDropdownBody.Wrapper} {
        border-color: ${Colors.red};
      }
    `}
`;

const StyledDropdown = {
  Autocomplete,
  AutocompleteMatchedValue,
  LabelInfoIcon,
  Label,
  LabelWrapper,
  SearchFieldWrapper,
  ValueContainer,
  FieldWrapper,
  Wrapper,
};

export default StyledDropdown;
