import { Select } from '@farmlink/farmik-ui';
import { FC, memo, useCallback, useMemo } from 'react';

import { Operation } from '../../../../../../../api/models/operations/operation.model';
import { useStore } from '../../../../../../shared/utils/IoC';
import { TaskEditController } from '../../../../operations/controllers/task.edit.controller';
import { OperationsStore } from '../../../../operations/stores/operations.store';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';
import { TasksStore } from '../../../../operations/stores/tasks.store';
import { PointsMapStore } from '../../../stores/pointsMap.store';
import { ChecklistInstancesController } from '../../../../operationsAndTasks/controllers/checklist.instances.controller';

interface IProps {
  cultureList: TasksEditStore['cultureList'];
  fieldsWithoutCulturesCount: OperationsStore['fieldsWithoutCulturesCount'];
  fromTasksPage: TasksStore['fromTasksPage'];
  cultureId: TasksEditStore['cultureId'];
  task: TasksEditStore['task'];
  presetDateFromTaskList: TasksEditStore['presetDateFromTaskList'];
  disabled: boolean;
  blocked?: boolean;
  setOperation: (operation: Operation | null) => void;
  setSelectedFormCultureId: TaskEditController['setSelectedFormCultureId'];
  setTask: TaskEditController['setTask'];
  getOperationsByCultureId: TaskEditController['getOperationsByCultureId'];
}

const CultureSelect: FC<IProps> = props => {
  const {
    task,
    cultureList,
    fieldsWithoutCulturesCount,
    cultureId,
    presetDateFromTaskList,
    disabled,
    setOperation,
    setSelectedFormCultureId,
    setTask,
    getOperationsByCultureId,
    blocked,
  } = props;

  const checklistInstancesController = useStore(ChecklistInstancesController);
  const pointsMapStore = useStore(PointsMapStore);

  const getSeasonCulturesSelectOptions = useMemo(() => {
    const result = cultureList?.cultureAndVarietyList?.length
      ? cultureList?.cultureAndVarietyList.map((item: any) => ({
          label: item.culture.name,
          value: item.culture.id,
        }))
      : [];
    return fieldsWithoutCulturesCount
      ? [{ label: 'Культура не указана', value: 'emptyCulture' }, ...result]
      : result;
  }, [cultureList]);

  const getSeasonCultureSelectDefaultValue = useMemo(() => {
    // const res =
    //   (!fromTasksPage &&
    //     getSeasonCulturesSelectOptions.find(cultureItem => cultureItem.value === cultureId)) ||
    //   null;
    const res =
      getSeasonCulturesSelectOptions.find(cultureItem => cultureItem.value === cultureId) || null;
    return res;
  }, [cultureId, cultureList]);

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (cultureId: string): Promise<void> => {
      setSelectedFormCultureId(cultureId);
      setOperation(null);
      setTask({
        ...task,
        operationId: '',
        planStartDate: presetDateFromTaskList ? presetDateFromTaskList : null,
        planEndDate: undefined,
        fieldId: '',
        field: { id: null },
        cultureName: '',
        operationTypeName: '',
      });
      checklistInstancesController.clearInstances();
      pointsMapStore.clearAllMarkers();
      pointsMapStore.deletePolygon();

      await getOperationsByCultureId(cultureId === 'emptyCulture' ? '' : cultureId);
    },
    [task, presetDateFromTaskList]
  );

  return (
    <Select
      label="Культура"
      options={getSeasonCulturesSelectOptions}
      defaultValue={getSeasonCultureSelectDefaultValue}
      onChange={handleChange}
      disabled={disabled && !blocked}
      blocked={blocked}
      dataTestId={'culture-select'}
    />
  );
};

export default memo(CultureSelect);
