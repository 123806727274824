const TOKEN_KEY = 'importedFieldsToken';

const getToken = (): string => {
  return sessionStorage.getItem(TOKEN_KEY);
};

const setToken = (token: string): void => {
  const savedToken = getToken();

  if (savedToken) {
    const isExactlyTisToken: boolean = savedToken === token;

    if (isExactlyTisToken) {
      return;
    }
  }

  sessionStorage.setItem(TOKEN_KEY, token);
};

const deleteToken = (): void => {
  sessionStorage.removeItem(TOKEN_KEY);
};

const importedFieldsSessionStorageHelpers = {
  getToken,
  setToken,
  deleteToken,
};

export default importedFieldsSessionStorageHelpers;
