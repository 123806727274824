import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { TypeOrganization } from '../../../../../api/models/organization.model';
import { Axios } from '../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationStore {
  @lazyInject(Axios)
  protected axios2: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  organization: TypeOrganization | undefined = undefined;
  organizationUpdateInProgress = true;

  getOrganizationInfo = (organizationId: string) => {
    this.organizationUpdateInProgress = true;

    this.axios2.api
      .getOrganization({ organizationId }, { omit: ['organizationId'] })
      .then(response => {
        this.organization = response;
        this.organizationUpdateInProgress = false;
      });
  };
}
