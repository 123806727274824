import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../shared/utils/IoC';
import Widget, {
  AddPointLabel,
  NoPointsPlaceHolder,
  NoPointsPlaceholderImg,
  NoPointsTextBlock,
} from '../style';
import { FieldPointsMap } from '../../../../../components/FieldPointsMap/FieldPointsMap';
import { Field } from '../../../../../../../api/models/field.model';
import { ChecklistInstancesStore } from '../../../../operationsAndTasks/stores/checklist.instances.store';
import { CheckAccessStore } from '../../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../shared/constants/access-rules-action';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { ProfileStore } from '../../../../profile/stores/ProfileStore';
import { TasksStore } from '../../../../operations/stores/tasks.store';

import noPointsSvg from './assets/mapIcon.svg';
import InstanceList from './InstanceList/InstanceList';

export enum EFieldPointsMapMode {
  LISTING_ONLY = 'listing-only',
  READ_ONLY = 'read-only',
  READ_ONLY_WITH_SELECTED = 'read-only-with-selected',
  STANDART = 'standart',
}

export interface PointsOnTheMapWidgetProps {
  mode: EFieldPointsMapMode;
  field: Field;
  taskId?: string;
  onAddFieldClick?: () => void;
  title?: string;
  noPointsTextBlock?: any;
  checklistsAvailable?: boolean;
  allowAddPoints?: boolean;
}

const PointsOnTheMapWidget: FC<PointsOnTheMapWidgetProps> = observer(
  (props: PointsOnTheMapWidgetProps) => {
    const checklistInstancesStore = useStore(ChecklistInstancesStore);
    const { getAccessRuleValue } = useStore(CheckAccessStore);

    const { selectedOrganizationId } = useStore(OrganizationsStore);

    const profileStore = useStore(ProfileStore);

    const taskStore = useStore(TasksStore);

    useEffect(() => {
      checklistInstancesStore.taskId = props.taskId;
    }, [props.taskId]);

    const allowAddPoints =
      selectedOrganizationId === 'my' ||
      getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS) ||
      (getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS) &&
        taskStore.tasksMap.get(props.taskId)?.assignee?.id === profileStore.user.id);

    return (
      <Widget
        style={{ padding: 0 }}
        className={'points-on-the-map-widget'}
        title={props.title ? props.title : 'Точки осмотра'}
        displayTitle={
          props.mode === EFieldPointsMapMode.LISTING_ONLY ||
          props.mode === EFieldPointsMapMode.READ_ONLY
        }
        $smallTitle={props.mode === EFieldPointsMapMode.LISTING_ONLY}
      >
        {props.mode !== EFieldPointsMapMode.LISTING_ONLY ? (
          <FieldPointsMap {...{ ...props, allowAddPoints }} />
        ) : null}
        {props.mode !== EFieldPointsMapMode.READ_ONLY_WITH_SELECTED &&
        Boolean(checklistInstancesStore.positionToInstance.size) ? (
          <InstanceList
            instanceList={checklistInstancesStore.positionToInstance}
            checklistsAvailable={props.checklistsAvailable}
          />
        ) : (
          props.noPointsTextBlock && (
            <NoPointsPlaceHolder>
              <NoPointsTextBlock>{props.noPointsTextBlock}</NoPointsTextBlock>
              <NoPointsPlaceholderImg className={'no-points-img'} src={noPointsSvg} />
            </NoPointsPlaceHolder>
          )
        )}

        {props.mode === EFieldPointsMapMode.LISTING_ONLY && allowAddPoints && (
          <AddPointLabel data-test-id={'add-point-label'} onClick={props.onAddFieldClick}>
            Добавить точку
          </AddPointLabel>
        )}
      </Widget>
    );
  }
);

export default PointsOnTheMapWidget;
