import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

export const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const Wrapper = styled.div<{ $error?: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${({ $error }) =>
    $error &&
    css`
      & ${Label} {
        color: ${Colors.red};
      }
    `}
`;
