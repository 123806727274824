import { FC } from 'react';

import { isImgUrl } from '../../../../../../../../../../shared/utils/helpers';

import {
  ActionClose,
  ActionDelete,
  ActionDownload,
  ActionsBlock,
  ArrowLeft,
  ArrowRight,
  ImageBlock,
  ImageCarouselBlock,
  LargeImage,
  Overlay,
  Wrapper,
  ActionsBlockWrapper,
} from './style';
import ArrowIcon from './assets/arrowIcon.svg';
import CloseIcon from './assets/Close.svg';
import DeleteIcon from './assets/Delete.svg';
import DownloadIcon from './assets/Download.svg';

export interface ImageCarouselProps {
  onSwitchLeft: () => void;
  onSwitchRight: () => void;
  imgUrl: string;
  isBlocked?: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const ImageCarousel: FC<ImageCarouselProps> = ({
  onSwitchLeft,
  onSwitchRight,
  imgUrl,
  isBlocked,
  onClose,
  onDelete,
}) => {
  const getBlob = (value: string) => {
    const BASE64_MARKER = ';base64,';
    const parts = value.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };

  const onDownloadClick = () => {
    const blob = getBlob(imgUrl);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file';

    a.style.display = 'none';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();
  };

  return (
    <Wrapper>
      <Overlay onClick={onClose} />
      <ImageCarouselBlock>
        <ArrowLeft src={ArrowIcon} onClick={onSwitchLeft} />
        <ImageBlock>
          <ActionsBlock>
            {!isBlocked && <ActionDelete src={DeleteIcon} onClick={onDelete} />}
            {isImgUrl(imgUrl) ? (
              <a href={imgUrl}>
                <ActionDownload src={DownloadIcon} />
              </a>
            ) : (
              <ActionDownload src={DownloadIcon} onClick={onDownloadClick} />
            )}
            <ActionClose src={CloseIcon} onClick={onClose} />
          </ActionsBlock>
          <LargeImage src={imgUrl} />
        </ImageBlock>
        <ArrowRight src={ArrowIcon} onClick={onSwitchRight} />
      </ImageCarouselBlock>
    </Wrapper>
  );
};
