import queryString from 'querystring';

import { FC, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ApplicationRoutes } from '../routes';
import { useStore } from '../shared/utils/IoC';

import { AdminViewStore } from './AdminViewStore';

export const AdminView: FC = observer(() => {
  const history = useHistory();
  const store = useStore(AdminViewStore);

  useEffect(() => {
    const params: any = queryString.parse(location.search.substr(1));
    if (!params.id) {
      history.push(ApplicationRoutes.Dashboard);
      return;
    }
    store.authAsUser(params.id, history, params.expireAt);
  }, []);

  return <div>ADMIN VIEW</div>;
});
