import {
  TChangeImportedFieldReq,
  TGetImportedFieldListReq,
  TGetImportedFieldListRes,
  TImportFieldsFromGeojsonReq,
  TImportFieldsFromGeojsonRes,
  TImportFieldsFromKmlReq,
  TImportFieldsFromKmlRes,
  TImportFieldsFromShapeReq,
  TImportFieldsFromShapeRes,
} from '../../../../../../api/api';
import { Field } from '../../../../../../api/models/field.model';
import { Axios } from '../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../shared/utils/IoC';

@provide.singleton()
class FieldsImportService {
  @lazyInject(Axios)
  protected axios: Axios;

  importFieldsFromKml = async (
    payload: TImportFieldsFromKmlReq
  ): Promise<TImportFieldsFromKmlRes> => {
    const { seasonYear, file, organizationId } = payload;

    try {
      const response = await this.axios.api.importFieldsFromKml(payload, {
        omit: ['organizationId', 'seasonYear'],
        query: {
          organizationId,
          seasonYear,
        },
        formData: file,
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  importFieldsFromGeojson = async (
    payload: TImportFieldsFromGeojsonReq
  ): Promise<TImportFieldsFromGeojsonRes> => {
    const { seasonYear, file, organizationId } = payload;

    try {
      const response = await this.axios.api.importFieldsFromGeojson(payload, {
        omit: ['organizationId', 'seasonYear'],
        query: {
          organizationId,
          seasonYear,
        },
        formData: file,
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  importFieldsFromShape = async (
    payload: TImportFieldsFromShapeReq
  ): Promise<TImportFieldsFromShapeRes> => {
    const { seasonYear, files, organizationId } = payload;

    try {
      const response = await this.axios.api.importFieldsFromShape(payload, {
        omit: ['organizationId', 'seasonYear'],
        query: {
          organizationId,
          seasonYear,
        },
        formData: files,
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getImportedFieldList = async (
    payload: TGetImportedFieldListReq
  ): Promise<TGetImportedFieldListRes> => {
    try {
      const response = await this.axios.api.getImportedFieldList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  deleteImportedFieldById = async (id: string): Promise<boolean> => {
    try {
      await this.axios.api.deleteImportedFieldById({ id }, { omit: ['id'] });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  saveImportedFieldList = async (token: string): Promise<boolean> => {
    try {
      await this.axios.api.saveImportedFieldList({ token });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  changeImportedField = async (id: string, props: TChangeImportedFieldReq): Promise<boolean> => {
    if (!id) {
      return;
    }

    try {
      await this.axios.api.changeImportedField(props, { omit: ['id'] });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default FieldsImportService;
