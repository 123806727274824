import { FC, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import { CheckAccessStore } from '../../../authorization/stores/checkAccess.store';
import { useStore } from '../../../shared/utils/IoC';
import { SCOUTING_ACCESS_ACTIONS } from '../../../shared/constants/access-rules-action';
import { DashboardRoutes } from '../../dashboard.routes';

import { TasksListing } from './containers/listing/tasks.listing';
import { TasksRoute } from './tasks.route';
// import { TasksEdit } from './components/TaskEdit/TaskEdit';
// import { FieldsAdd } from './containers/add/fields.add';
// import { FieldsEdit } from './containers/edit/fields.edit';

export const TasksRouter: FC = observer(() => {
  const { accessRules, getAccessRuleValue } = useStore(CheckAccessStore);

  return (
    <Switch>
      {/* <Route path={OperationsRoute.Add} component={() => <FieldsAdd />} />
      <Route path={`${OperationsRoute.Edit}/:id`} component={() => <FieldsEdit />} /> 
      <Route path={`${OperationsRoute.ViewEditTask}/:taskId?`} component={() => <TasksEdit />} /> */}
      <Route path={TasksRoute.Listing} component={() => <TasksListing />} />
      <Redirect to={DashboardRoutes.Fields} />
    </Switch>
  );
});
