import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

export const Fullscreen = styled.div`
  padding: 24px;
  /* height: 200%; */
  max-width: 912px;
  box-sizing: content-box;
  margin: 0 auto;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const BackButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  margin-top: 7px;
`;

export const BackButtonTitle = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-left: 15px;
`;

export const ActionButton = styled.div`
  width: 160px;
  height: 40px;
  border: 1px solid #3fb65f;
  border-radius: 12px;
  background: #ffffff;
  text-align: center;
  size: 14px;
  line-height: 40px;
  color: #3fb65f;
`;
