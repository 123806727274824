import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  padding: 0 16px;
`;

export const SearchIcon = styled.img`
  width: 80px;
  height: 80px;
  margin-top: 172px;
  margin-bottom: 32px;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.black};
  margin: 0 0 27px;
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.black};
  margin: 0;
`;
