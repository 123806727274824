import styled, { css } from 'styled-components';

import { Colors } from '../../constans/colors';

import { ReactComponent as InfoIcon } from './info.svg';

type TWrapperProps = {
  $topPlacement?: boolean;
};

export const Wrapper = styled.div<TWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .rdtDays {
    background: ${Colors.white};
  }
  .rdtPicker {
    position: absolute;
    z-index: 999;
    ${({ $topPlacement }) =>
      $topPlacement
        ? css`
            bottom: 40px;
          `
        : css`
            top: 40px;
          `}
    & .rdtMonths,
    & .rdtYears {
      background-color: #fff;
    }
  }
`;

type StyledProps = {
  verticalGap?: boolean;
  $isError?: boolean;
};

type TLabelProps = {
  $isRequired?: boolean;
};

export const LabelWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p<TLabelProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.darkGray};
  display: flex;

  ${({ $isRequired }) =>
    $isRequired &&
    css`
      &:after {
        color: red;
        visibility: visible;
        content: '*';
      }
    `}
`;

export const Icon = styled.div<StyledProps>`
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 16px;
  svg path {
    fill: ${({ $isError }) => ($isError ? Colors.red : Colors.black)};
  }
`;

export const InformationIcon = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  margin: 0 4px 4px 4px;
  cursor: pointer;
`;

export const Placeholder = styled.p<StyledProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${Colors.darkGray};
  line-height: 16px;
`;

export const DateInput = styled.div<{ blocked?: boolean; $isError?: boolean }>`
  border: 1px solid ${Colors.grayBorder};
  box-sizing: border-box;
  border-radius: 12px;
  background: ${({ blocked }) => (blocked ? '#ffffff' : Colors.gray)};
  width: 100%;
  height: 40px;
  outline: none;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: relative;

  ${({ $isError }) =>
    $isError &&
    css`
      border-color: ${Colors.red};
      color: ${Colors.red};
    `};
`;
