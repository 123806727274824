import { Select } from '@farmlink/farmik-ui';
import moment from 'moment';
import { FC, memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Operation } from '../../../../../../../api/models/operations/operation.model';
import { useStore } from '../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { TaskEditController } from '../../../../operations/controllers/task.edit.controller';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';
import { PointsMapStore } from '../../../stores/pointsMap.store';
import { ChecklistInstancesController } from '../../../../operationsAndTasks/controllers/checklist.instances.controller';

interface IProps {
  selectedOrganizationId: OrganizationsStore['selectedOrganizationId'];
  presetDateFromTaskList: TasksEditStore['presetDateFromTaskList'];
  operations: TasksEditStore['operations'];
  task: TasksEditStore['task'];
  disabled: boolean;
  blocked?: boolean;
  setSelectedFormOperationId: TaskEditController['setSelectedFormOperationId'];
  setTask: TaskEditController['setTask'];
  getFieldsOfOperation: TaskEditController['getFieldsOfOperation'];
  setOperation: (operation: Operation | null) => void;
  getStartDate: TaskEditController['getStartDate'];
}

const OperationSelect: FC<IProps> = props => {
  const {
    selectedOrganizationId,
    presetDateFromTaskList,
    operations,
    task,
    disabled,
    setSelectedFormOperationId,
    setTask,
    getFieldsOfOperation,
    setOperation,
    getStartDate,
    blocked,
  } = props;

  const pointsMapStore = useStore(PointsMapStore);
  const checklistInstancesController = useStore(ChecklistInstancesController);

  const getOperationSelectOptions = useMemo(() => {
    const filteredOperations = presetDateFromTaskList
      ? operations.filter(
          (operationItem: Operation) =>
            presetDateFromTaskList >= moment(operationItem.startDate) &&
            presetDateFromTaskList <= moment(operationItem.endDate)
        )
      : operations;

    const res = filteredOperations.map(opertationItem => ({
      label: opertationItem?.name,
      value: opertationItem?.id,
    }));

    return res;
  }, [operations]);

  const getOperationsSelectDefaultValue = useMemo(() => {
    const { operationId } = task;
    if (operationId) {
      return (
        getOperationSelectOptions.find(operationItem => operationItem.value === operationId) || {
          value: null,
          label: null,
        }
      );
    } else {
      return { value: null, label: null };
    }
  }, [task.operationId, operations]);

  const handleChange = useCallback(
    async (operationId: string): Promise<void> => {
      setSelectedFormOperationId(operationId);

      checklistInstancesController.clearInstances();
      pointsMapStore.clearAllMarkers();
      pointsMapStore.deletePolygon();

      const selectedOperation = operations.find(operationItem => operationItem.id === operationId);

      setOperation(selectedOperation);

      setTask({
        ...task,
        operationId,
        planStartDate: presetDateFromTaskList
          ? presetDateFromTaskList
          : getStartDate(selectedOperation, true),
        planEndDate: presetDateFromTaskList
          ? presetDateFromTaskList
          : getStartDate(selectedOperation, true),
        fieldId: '',
        field: { id: '' },
        operationTypeName: '',
      });

      const organizationId = selectedOrganizationId === 'my' ? '' : selectedOrganizationId;

      await getFieldsOfOperation({
        operationId,
        organizationId,
      });
    },
    [operations, task, presetDateFromTaskList]
  );

  const OperationSelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & > div {
      width: 100%;
    }
  `;

  const OperationSelectLabel = styled.span`
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px 4px;
    color: rgb(154, 160, 173);
    & > b {
      color: #000;
    }
  `;

  return (
    <OperationSelectWrapper>
      <OperationSelectLabel>
        Операция
        <b>
          &nbsp;{presetDateFromTaskList ? `(для ${presetDateFromTaskList.format('D MMMM')})` : null}
        </b>
      </OperationSelectLabel>
      <Select
        options={getOperationSelectOptions}
        onChange={handleChange}
        defaultValue={getOperationsSelectDefaultValue}
        disabled={disabled && !blocked}
        blocked={blocked}
        dataTestId={'operation-select'}
      />
    </OperationSelectWrapper>
  );
};

export default memo(OperationSelect);
