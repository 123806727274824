import { Field } from '../../../../api/models/field.model';
import { toFixedWithCeil } from '../../../shared/utils/toFixedWithCeil';

export const getUniqueCulturesNameList = (cultureZones: Field['cultureZones']): string[] =>
  cultureZones ? Array.from(new Set<string>(cultureZones.map(zone => zone?.culture.name))) : [];

export const makeCultureTooltip = (cultureZones: Field['cultureZones']): string =>
  cultureZones?.length
    ? `<span><center>${getUniqueCulturesNameList(cultureZones).join(',<br>')}</center></span>`
    : 'Нет культуры';

export const makeAreaTooltip = (area: Field['area']): string => `${toFixedWithCeil(area)} га`;

export const makeEmptyTooltip = (): string => '<span></span>';

export interface ITooltipVariants {
  area: string;
  culture: string;
  none: string;
  name: string;
}

export const makeTooltipVariants = (field: Field): ITooltipVariants => ({
  area: makeAreaTooltip(field.area),
  culture: makeCultureTooltip(field.cultureZones),
  none: makeEmptyTooltip(),
  name: field.name,
});
