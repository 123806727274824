import styled from 'styled-components';

import { Colors } from '../../constans/colors';

 export const Wrapper = styled.div<{ isOpen: boolean; disabled: boolean }>`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 12px;
  background: ${Colors.white};
  border: 0.5px solid ${Colors.grayBorder};
  transition: 300ms;
  &:hover {
    ${({ isOpen }) => !isOpen && `border-color: ${Colors.darkGray};`}
  }

  * {
    box-sizing: border-box;
    user-select: none;
    transition: all 0.3s ease-in-out;
  }
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
`;

export const Body = styled.div<{ isOpen: boolean }>`
  width: 100%;
  position: relative;
  padding-left: 4px;
  padding-right: 4px;
  transition: all 0.3s ease-in-out;
  max-height: 0;
  overflow: hidden;
  background: ${Colors.white};
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;

  ${({ isOpen }) => isOpen && 'max-height: 1000px; padding-bottom: 32px;'};
`;

export const InnerBody = styled.div`
  position: relative;
  padding-top: 8px;
  width: 100%;
  padding-bottom: 8px;
  max-height: 144px;
  overflow-y: scroll;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  //overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const PlusIcon = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 8px;
    height: 8px;
    fill: ${Colors.green};
  }
`;

export const AddSeasonLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.green};
  margin: 0;
`;

export const AddSeason = styled.div`
  position: absolute;
  width: 100%;
  height: 32px;
  color: ${Colors.green};
  border-top: 1px solid ${Colors.grayBorder};
  bottom: 0px;
  left: 0px;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${AddSeasonLabel} {
      color: ${Colors.greenHover};
    }
    svg {
      fill: ${Colors.greenHover};
    }
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: ${Colors.black};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }

  span {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: ${Colors.darkGray};
  }
`;

export const EditIcon = styled.div`
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  display: none;

  :hover {
    svg {
      fill: ${Colors.green} !important;
    }
  }
  svg {
    fill: ${Colors.darkGray};
  }
`;

export const ListItem = styled.div<{ isActive?: boolean }>`
  width: 100%;
  padding: 6px 12px;
  height: 42px;
  background: ${Colors.white};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    ${EditIcon} {
      display: flex;
    }
    background: ${Colors.backgroundSecondaryGray};
  }

  cursor: pointer;
  display: flex;
  border-radius: 6px;
  align-items: center;

  ${ContentColumn} p {
    ${({ isActive }) => isActive && `color: ${Colors.green};`}
  }

  & .control-access-rules-wrapper-el {
    height: auto;
    width: auto;
  }
`;

export const Line = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  transition: none;
  border-top: 0.5px solid ${Colors.backgroundSecondaryGray};
`;

export const Header = styled.div`
  width: 100%;
  height: 30px;

  background: ${Colors.white};
  border-radius: 12px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
`;

export const Arrow = styled.img<{ isOpen: boolean }>`
  height: 6px;
  width: 10px;
  margin-top: 5px;
  ${({ isOpen }) => (isOpen ? `transform: scale(1, -1)` : '')};
`;

export const Label = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.black};
`;

export const Placeholder = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
`;
export const PopupWrapper = styled.div`
  position: absolute;
  height: 40px;
  padding: 12px;
  right: -30px;
`;

export const Popup = styled.div`
  position: relative;
  div {
    position: absolute;
    left: 0;
    transform: rotate(45deg);
  }
`;

export const TipWrapperContainer = styled.div`
  position: absolute;
  z-index: 900;
`;

export const TipTail = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: -5px;
  top: 15px;
  border-right: 5px solid ${Colors.white};
`;

export const TipWrapper = styled.div`
  position: relative;
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: ${Colors.white};
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
  }
`;
