import { useMemo } from 'react';
import _ from 'lodash';

import { IChecklistAttributeWithValue } from '../../../../../api/models/checklist/attribute/checklist.attribute.model';

export const useGetOrderedAttributeWithValueList = (
  attributeWithValueList: IChecklistAttributeWithValue[] = []
): IChecklistAttributeWithValue[] => {
  return useMemo(() => {
    return _.sortBy(attributeWithValueList, 'checklistAttribute.order');
  }, [attributeWithValueList]);
};
