import { PopupPages } from '../../dashboard/constants/popup.pages';
import { UiStore } from '../../dashboard/stores/ui.store';
import { lazyInject, provide } from '../../shared/utils/IoC';
import MapStore from '../stores/map.store';

@provide.singleton()
export class MapController {
  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(UiStore)
  protected uiStore: UiStore;

  removeLayout = (id: number) => {
    if (this.uiStore.popupPageState === PopupPages.Seasons) return;

    try {
      const layout = this.mapStore.getLayout(id);

      if (layout) {
        layout.remove();
        this.mapStore.deleteLayout(id);
      }
    } catch (err) {
      throw new Error(`не полуается удалить layout с ${id}`);
    }
  };
}
