import styled from 'styled-components';

import { Colors } from '../../constans/colors';
import { Breakpoints } from '../../constans/sizes';

export const ListWrapper = styled.div`
  font-size: 12px;
`;

export const ListItem = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const ListKey = styled.div`
  width: 45%;
  color: ${Colors.darkGray};

  @media (max-width: ${Breakpoints.extraSmall}) {
    width: 55%;
  }
`;
export const ListValue = styled.div<{ status: string }>`
  width: 55%;
  display: flex;
  position: relative;
  color: ${({ status }) => {
    switch (status) {
      case 'error':
        return Colors.red;
      case 'success':
        return Colors.green;
      default:
        return Colors.black;
    }
  }};

  & > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
  }
`;

export const HelperWrapper = styled.div`
  position: relative;
  margin-left: 8px;
`;

export const HelperPopup = styled.div`
  width: 215px;
  position: absolute;
  left: 100%;
  top: 0;
  //margin-left: 10px;
  margin-top: -10px;
  background: #ffffff;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  color: ${Colors.black};

  & span {
    margin-top: 15px;
    display: block;
  }
`;

export const HelperAction = styled.div`
  color: ${Colors.green};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
`;
