import {
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../models/checklist/instance/checklist.instance.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = IPutChecklistInstance[] | { data: IPutChecklistInstance[]; taskId };

type TypeResponse = IGetChecklistInstanceByTaskId[];

export const putChecklistInstances: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/checklist/instance`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
