import { observer } from 'mobx-react';
import { FC, memo, useRef, useState } from 'react';

import { useStore } from '../../../../../shared/utils/IoC';
import { UiStore } from '../../../../stores/ui.store';
import {
  Footer,
  FullScreenHeader,
  StyledFullscreenFooter,
  StyledFullscreenWrapper,
  Wrapper,
} from '../../style';

import FullscreenBackButton, { IFullscreenBackButtonProps } from './FullscreenBackButton';
import * as Styled from './styles';
import { ActionButton } from './styles';

export type IFullscreenProps = {
  className?: string;
  actionButton?: {
    label?: string;
    onClick?: () => void;
  };
  onClose?: () => void;
} & IFullscreenBackButtonProps;

const Fullscreen: FC<IFullscreenProps> = observer(props => {
  const { children, backButtonHandler, backButtonText, actionButton } = props;
  const uiStore = useStore(UiStore);

  const [hideFullScreenHeader, setHideFullScreenHeader] = useState(false);

  let prevScroll = 0;
  let currentScroll = 0;

  const ref = useRef<HTMLDivElement>();

  const handleScroll = event => {
    prevScroll = currentScroll;
    currentScroll = ref.current.scrollTop;
    if (currentScroll < prevScroll) {
      console.log('show');
      setHideFullScreenHeader(false);
    } else if (currentScroll > prevScroll && currentScroll > 72) {
      console.log('hide');
      setHideFullScreenHeader(true);
    }
  };

  return (
    <StyledFullscreenWrapper
      $isShort={uiStore.isAdaptive || uiStore.isSidebarShort}
      className={`${props.className} fullscreen-header ${
        hideFullScreenHeader ? 'header_hidden' : ''
      }`}
    >
      <FullScreenHeader
        className={`fullscreen-header ${hideFullScreenHeader ? 'header_hidden' : ''}`}
      >
        <FullscreenBackButton
          backButtonText={backButtonText}
          backButtonHandler={backButtonHandler}
        />
        {actionButton && (
          <ActionButton onClick={actionButton.onClick}>{actionButton.label}</ActionButton>
        )}
      </FullScreenHeader>
      <StyledFullscreenFooter
      // onScroll={handleScroll}
      // ref={ref}
      >
        <Styled.Fullscreen>{children}</Styled.Fullscreen>
      </StyledFullscreenFooter>
    </StyledFullscreenWrapper>
  );
});

export default memo(Fullscreen);
