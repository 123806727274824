import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { usePortals } from 'react-portal-hook';
import { Chip, Tooltip } from '@farmlink/farmik-ui';

import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import {
  Arrow,
  Content as ToolContent,
  IconWrapper,
  Item,
  Label as ToolLabel,
  Wrapper as ToolWrapper,
} from '../../../../../shared/components/ToolTip/style';
import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { UiStore } from '../../../../stores/ui.store';
import { FieldsStore } from '../../../fields/stores/fields.store';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import PointsOnTheMapWidget, {
  EFieldPointsMapMode,
} from '../../../tasks/components/Widgets/PointsOnTheMap';
import ChecklistFullscreen from '../../../tasks/containers/fullscreen/checklist';
import { PointsMapStore } from '../../../tasks/stores/pointsMap.store';
import { TasksFilterStore } from '../../../tasks/stores/tasks.filter.store';
import { TaskViewController } from '../../controllers/task.view.controller';
import { TasksController } from '../../controllers/tasks.controller';
import { PreparedTask } from '../../controllers/tasks.listing.controller';
import { OperationsStore } from '../../stores/operations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { ETaskFormViewMode, TaskViewStore } from '../../stores/task.view.store';
import {
  FullScreenMode,
  Task,
  TaskPrioretees,
  TasksStore,
  TaskStatuses,
} from '../../stores/tasks.store';
import priorityHighSvg from '../TasksList/priorityHigh.svg';
import priorityLowSvg from '../TasksList/priorityLow.svg';
// @ts-ignore
import priorityMediumSvg from '../TasksList/priorityMedium.svg';
import { TaskStatusesButtonsBlock } from '../TaskStatusesButtonsBlock/TaskStatusesButtonsBlock';
import { ChecklistInstancesController } from '../../../operationsAndTasks/controllers/checklist.instances.controller';
import { ChecklistFileUploaderController } from '../../../operationsAndTasks/controllers/checklist.fileUploader.controller';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import {
  TechniqueListContainer,
  ListOfChecklistInstance,
} from '../../../operationsAndTasks/components/instance';

import calendarSvg from './assets/calendarSvg.svg';
import { ReactComponent as CloseSvg } from './assets/close.svg';
import cultureSvg from './assets/cultureSvg.svg';
import { ReactComponent as DeleteSvg } from './assets/delete.svg';
import { ReactComponent as EditSvg } from './assets/edit.svg';
import fieldSvg from './assets/fieldSvg.svg';
import { ReactComponent as FullscreenSvg } from './assets/fullscreen.svg';
import { ReactComponent as OptionsSvg } from './assets/options.svg';
import userSvg from './assets/userSvg.svg';
import {
  Comment,
  CommentBlock,
  CommentLabel,
  Content,
  CreateNewTaskButton,
  Footer,
  Header,
  Info,
  InfoBlock,
  Label,
  NoPointsHeader,
  OperationTypeName,
  Overlay,
  PointsOnTheMapWidgetWrapper,
  SkeletonWrapper,
  SkHeader,
  SkInfoRow,
  SkLeftcolumn,
  SkOperationTypeName,
  SkRightColumn,
  SkTextarea,
  Spinner,
  SpinnerWrapper,
  StyledWrapper,
  SvgImg,
  TaskViewForm,
  TopBlock,
} from './style';

const prioritySvgMatrix = {
  [TaskPrioretees.Low]: priorityLowSvg,
  [TaskPrioretees.Medium]: priorityMediumSvg,
  [TaskPrioretees.High]: priorityHighSvg,
};

export const TasksView = observer(
  ({
    onCloseDialog,
    onGoToEdit,
    onGoToCreate,
    taskId,
    fromTaskListPage,
  }: {
    onCloseDialog?: any;
    onGoToEdit: any;
    onGoToCreate: any;
    taskId: string;
    fromTaskListPage?: boolean;
  }) => {
    const portalManager = usePortals();

    const operationsStore = useStore(OperationsStore);
    const organizationsStore = useStore(OrganizationsStore);
    const seasonsStore = useStore(SeasonsStore);
    const tasksStore = useStore(TasksStore);
    const tasksController = useStore(TasksController);
    const taskViewStore = useStore(TaskViewStore);
    const taskViewController = useStore(TaskViewController);
    const task = taskViewStore.taskForView;
    const seasonCulture = useStore(SeasonCultureStore);
    const tasksFilterStore = useStore(TasksFilterStore);
    const fieldsStore = useStore(FieldsStore);
    const checklistInstancesStore = useStore(ChecklistInstancesStore);
    const checklistInstancesController = useStore(ChecklistInstancesController);
    const taskStore = useStore(TasksStore);
    const pointsMapStore = useStore(PointsMapStore);
    const ui = useStore(UiStore);
    const profileStore = useStore(ProfileStore);
    const { accessRules, getAccessRuleValue } = useStore(CheckAccessStore);

    const { clearFileUploader } = useStore(ChecklistFileUploaderController);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [showFullScreen, setShowFullScreen] = useState(false);
    const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

    const [loading, setLoading] = useState(true);

    const taskStatus = useMemo(
      () => taskViewController.getTaskStatus(taskViewStore.taskForView.status),
      [taskViewStore.taskForView.status]
    );

    useEffect(() => {
      checklistInstancesController.setDeleteImmediately(true);
      checklistInstancesStore.positionToInstance = new Map();
      checklistInstancesStore.setHasPositionToInstanceChanged(false);
      pointsMapStore.idToMarker = new Map();
      (async () => {
        if (taskId) {
          setLoading(true);
          await taskViewController.getTaskById(taskId);
          await taskViewController.getSeasonCultures(
            Number(seasonsStore.selectedSeason),
            organizationsStore.selectedOrganizationId
          );
          await taskViewController.getOperationsByCultureId();
          await checklistInstancesController.fetchInstanceList(taskId);
          checklistInstancesStore.setField(
            fieldsStore.idToFields.get(
              taskViewStore.taskForView?.fieldId || taskViewStore.taskForView?.field?.id
            )
          );
          const cultureId = taskViewStore.taskForView.operationInfo?.cultureId;
          const cultureName = cultureId
            ? operationsStore.OperationCulturesInFields.find(
                cultureItem => cultureItem.culture.id === cultureId
              )?.culture?.name || 'Нет данных'
            : 'Культура не указана';

          await operationsStore.getOperationsType();
          await taskViewController.getOperationsByCultureId(cultureId);
          taskViewController.setSelectedFormCultureId(cultureId || 'emptyCulture');
          // await taskViewController.getOrganizationUsersList(organizationsStore.selectedOrganizationId);
          taskViewController.setTask({
            ...taskViewStore.taskForView,
            cultureName,
          });
          tasksController.setTemporaryTask({
            ...taskViewStore.taskForView,
            cultureName,
          });
          setLoading(false);
          if (
            !taskStore.isViewMode &&
            taskViewStore.taskFormLastViewMode === ETaskFormViewMode.FullscreenForm
          ) {
            tasksController.enableViewMode();
            tasksStore.setFullScreenMode(FullScreenMode.TaskFullScreen);
            onGoToEdit();
          } else {
            tasksStore.setFullScreenMode(null);
          }
        }
      })();
      return () => {
        taskViewController.clearStore();
        tasksController.clearTemporaryTask();
      };
    }, []);

    const isUserIsAssignee = Boolean(
      (task.assigneeId || task.assignee?.id) === profileStore.user.id
    );

    const isOrgMy = organizationsStore.selectedOrganizationId === 'my';

    const allowGoToEditAndDelete =
      isOrgMy ||
      (getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS) && isUserIsAssignee) ||
      getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);

    const handleCloseDialog = (event?) => {
      if (event) {
        event.stopPropagation();
      }
      tasksController.disableViewMode();
      onCloseDialog();
    };

    const handleFormClick = event => {
      event.stopPropagation();
    };

    const deleteTask = async () => {
      await taskViewController.deleteTask(taskId);
      tasksController.resetPageSettings();
      if (fromTaskListPage) {
        await tasksController.fetchTasks({
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId:
            tasksFilterStore.culture === 'emptyCulture' ? '' : tasksFilterStore.culture,
          fieldId: tasksFilterStore.field,
          assigneeId: tasksFilterStore.asignee,
          statusIn: tasksFilterStore.status.map(item => item.value),
          planDateFrom: tasksFilterStore.startDate,
          planDateTo: tasksFilterStore.endDate,
          operationTypeId: tasksFilterStore.operationType,
          priorityIn: tasksFilterStore.priority.map(item => item.value),
          noCulture: tasksFilterStore.culture === 'emptyCulture' ? true : undefined,
        });
      } else {
        tasksController.fetchTasks({
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId:
            seasonCulture.selectedCultureId === 'emptyCulture'
              ? ''
              : seasonCulture.selectedCultureId,
          operationId: operationsStore.selectedOperationId,
          fieldId: operationsStore.selectedOperationFieldId,
          noCulture: seasonCulture.selectedCultureId === 'emptyCulture' ? true : undefined,
        });
      }
      handleCloseDialog();
    };

    const handleUpdateStatus = async (newStatus: TaskStatuses) => {
      console.log(task);
      await tasksController.setStatus(task.id, newStatus);
      /* await tasksController.fetchTasks({
            organizationId: organizationsStore.selectedOrganizationId,
            seassonCultureId: seasonCulture.selectedCultureId,
            operationId: operationsStore.selectedOperationId,
            fieldId: operationsStore.selectedOperationFieldId,
        }); */
      handleCloseDialog();
    };

    const getSkeleton = () => (
      <SkeletonWrapper onClick={event => event.stopPropagation()}>
        <SkHeader>
          <Skeleton />
        </SkHeader>
        <SkOperationTypeName>
          <Skeleton />
        </SkOperationTypeName>
        <SkInfoRow>
          <SkLeftcolumn>
            <Skeleton />
          </SkLeftcolumn>
          <SkRightColumn>
            <Skeleton />
          </SkRightColumn>
        </SkInfoRow>
        <SkInfoRow>
          <SkLeftcolumn>
            <Skeleton />
          </SkLeftcolumn>
          <SkRightColumn>
            <Skeleton />
          </SkRightColumn>
        </SkInfoRow>
        <SkInfoRow>
          <SkLeftcolumn>
            <Skeleton />
          </SkLeftcolumn>
          <SkRightColumn>
            <Skeleton />
          </SkRightColumn>
        </SkInfoRow>
        <SkInfoRow>
          <SkLeftcolumn>
            <Skeleton />
          </SkLeftcolumn>
          <SkRightColumn>
            <Skeleton />
          </SkRightColumn>
        </SkInfoRow>
        <SkInfoRow>
          <SkLeftcolumn>
            <Skeleton />
          </SkLeftcolumn>
          <SkRightColumn>
            <Skeleton />
          </SkRightColumn>
        </SkInfoRow>
        <SkTextarea>
          <Skeleton />
        </SkTextarea>
      </SkeletonWrapper>
    );

    if (taskStore.fullScreenMode === FullScreenMode.ChecklistFullScreen) {
      return (
        <ChecklistFullscreen
          className={'checklist-fullscreen'}
          backButtonHandler={() => {
            if (checklistInstancesStore.idOfUnsavedAttr) {
              checklistInstancesController.warnBeforeLeavingThePage(() => {
                if (taskStore.fullScreenMode === FullScreenMode.ChecklistFullScreen) {
                  onCloseDialog();
                }
              });
            } else {
              taskStore.setFullScreenMode(null);
              if (taskStore.isViewMode || checklistInstancesStore.isChecklistViewMode) {
                onCloseDialog();
              }
            }
          }}
          backButtonText={taskViewStore.taskForView.operationInfo?.name}
          actionButton={
            checklistInstancesStore.isChecklistViewMode &&
            allowGoToEditAndDelete && {
              label: 'Редактировать',
              onClick: () => checklistInstancesController.enableChecklistEditMode(),
            }
          }
        />
      );
    }

    const handleMenuClick = event => {
      event.stopPropagation();
      if (!isOpenContextMenu) {
        portalManager.open(portal => renderContextMenu(portal.close), {
          appendTo: ui.tipContainerRef,
        });

        const bounds = event.target.getBoundingClientRect();
        ui.openTip(bounds.x - 145, bounds.y + 32);
        setIsOpenContextMenu(true);
      } else {
        setIsOpenContextMenu(false);
      }
    };

    const renderContextMenu = closeMenu => {
      const handleRef = el => {
        if (!el) {
          return;
        }
        el.focus();
      };

      ui.setContextMenuCloseFunc(() => {
        closeMenu();
        ui.closeTip();
        setIsOpenContextMenu(false);
      });

      const closeContextMenu = () => {
        closeMenu();
        ui.closeTip();
        setIsOpenContextMenu(false);
      };

      return (
        <ToolWrapper
          ref={handleRef}
          onBlur={event => {
            setTimeout(() => {
              event.stopPropagation();
              closeContextMenu();
            }, 100);
          }}
          tabIndex={-1}
        >
          <ToolContent>
            <Item
              onClick={e => {
                e.stopPropagation();
                tasksController.showFullScreenCreationPage();
                tasksController.disableViewMode();
                taskViewController.setTaskFormLastViewMode(ETaskFormViewMode.SideForm);
                closeContextMenu();
                onGoToEdit();
              }}
              data-test-id={'task-view-menu-item-edit'}
            >
              <IconWrapper>
                <EditSvg />
              </IconWrapper>
              <ToolLabel>Редактировать</ToolLabel>
            </Item>
            <Item
              onClick={e => {
                e.stopPropagation();
                setShowDeleteConfirmation(true);
                closeContextMenu();
              }}
              disabled={!task?.deletable}
              data-test-id={'task-view-menu-item-delete'}
            >
              <IconWrapper>
                <DeleteSvg />
              </IconWrapper>
              <ToolLabel>Удалить</ToolLabel>
            </Item>
          </ToolContent>
          <Arrow
            style={{ top: '-12px', left: 'unset', right: '10px', transform: 'rotate(90deg)' }}
          />
        </ToolWrapper>
      );
    };

    if (taskViewStore.taskFormLastViewMode === ETaskFormViewMode.FullscreenForm && loading) {
      return (
        <Overlay>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        </Overlay>
      );
    }

    return (
      taskViewStore.taskFormLastViewMode === ETaskFormViewMode.SideForm && (
        <Overlay onClick={handleCloseDialog}>
          {loading ? getSkeleton() : null}
          <TaskViewForm onClick={handleFormClick}>
            <TopBlock>
              <Header>
                {taskViewStore.taskForView.status === TaskStatuses.Canceled &&
                taskViewStore.taskForView.cancellationReason ? (
                  <Tooltip
                    id={`task-${taskViewStore.taskForView.id}-status`}
                    place="bottom"
                    className="task-cancel-tooltip"
                    multiline
                    getContent={() => (
                      <span>{`Причина: ${taskViewStore.taskForView.cancellationReason}`}</span>
                    )}
                  >
                    <Chip
                      color={taskStatus.color}
                      size="medium"
                      icon="info"
                      dataTestId={`task-${taskViewStore.taskForView.id}-status-title`}
                    >
                      {taskStatus.title}
                    </Chip>
                  </Tooltip>
                ) : (
                  <Chip
                    color={taskStatus.color}
                    size="medium"
                    dataTestId={`task-${taskViewStore.taskForView.id}-status-title`}
                  >
                    {taskStatus.title}
                  </Chip>
                )}

                {taskStore.fullScreenMode !== FullScreenMode.TaskFullScreen && (
                  <StyledWrapper>
                    {allowGoToEditAndDelete && (
                      <OptionsSvg
                        onClick={handleMenuClick}
                        data-test-id={'task-view-toggle-top-menu'}
                      />
                    )}
                    <FullscreenSvg
                      onClick={() => {
                        tasksController.showFullScreenCreationPage();
                        tasksController.enableViewMode();
                        taskViewController.setTaskFormLastViewMode(ETaskFormViewMode.SideForm);
                        onGoToEdit();
                      }}
                      data-test-id={'go-to-fullscreen-task-view'}
                    />
                    <CloseSvg data-test-id={'close-task-view-form'} onClick={handleCloseDialog} />
                  </StyledWrapper>
                )}
              </Header>
              <Content>
                <OperationTypeName data-test-id={'operation-type-name'}>
                  {taskViewStore.taskForView.operationInfo?.name}
                </OperationTypeName>
                <InfoBlock>
                  <Label>Культура</Label>
                  <SvgImg src={cultureSvg} />
                  <Info data-test-id={'culture-name'}>
                    {(taskViewStore.taskForView as PreparedTask).cultureName}
                  </Info>
                </InfoBlock>
                <InfoBlock>
                  <Label>Поле</Label>
                  <SvgImg src={fieldSvg} />
                  <Info data-test-id={'field-name'}>{taskViewStore.taskForView.field?.name}</Info>
                </InfoBlock>
                <InfoBlock>
                  <Label>Старт задачи</Label>
                  <SvgImg src={calendarSvg} />
                  <Info data-test-id={'task-plan-start-date'}>
                    {moment(taskViewStore.taskForView.planStartDate).format('DD MMMM YYYY')}
                  </Info>
                </InfoBlock>
                <InfoBlock>
                  <Label>Дата завершения</Label>
                  <SvgImg src={calendarSvg} />
                  <Info data-test-id={'task-plan-end-date'}>
                    {moment(taskViewStore.taskForView.planEndDate).format('DD MMMM YYYY')}
                  </Info>
                </InfoBlock>
                {taskViewStore.taskForView.executedDate ? (
                  <InfoBlock>
                    <Label>Фактическая дата</Label>
                    <SvgImg src={calendarSvg} />
                    <Info data-test-id={'task-fact-date'}>
                      {taskViewStore.taskForView.executedDate
                        ? moment(taskViewStore.taskForView.executedDate).format('DD MMMM YYYY')
                        : ''}
                    </Info>
                  </InfoBlock>
                ) : null}
                <InfoBlock>
                  <Label>Исполнитель</Label>
                  <SvgImg src={userSvg} />
                  <Info data-test-id={'task-assignee'}>
                    {taskViewStore.taskForView.assignee?.fullName ? task.assignee?.fullName : `Я`}
                  </Info>
                </InfoBlock>
                <CommentBlock>
                  <CommentLabel>Комментарий к задаче</CommentLabel>
                  <Comment data-test-id={'task-comment'}>
                    {taskViewStore.taskForView.comment || 'Отсутствует'}
                  </Comment>
                </CommentBlock>
                <PointsOnTheMapWidgetWrapper>
                  <PointsOnTheMapWidget
                    mode={EFieldPointsMapMode.LISTING_ONLY}
                    field={(() => {
                      return checklistInstancesStore.field;
                    })()}
                    taskId={task.id}
                    noPointsTextBlock={
                      <NoPointsHeader>
                        Перейдите в полноэкранный шаблон создания задачи, чтобы построить удобный
                        маршрут по полю
                      </NoPointsHeader>
                    }
                    onAddFieldClick={() => {
                      onGoToEdit();
                      tasksController.disableViewMode();
                      tasksStore.setFullScreenMode(FullScreenMode.TaskFullScreen);
                    }}
                    checklistsAvailable={task.checklistsAvailable}
                  />

                  {task?.checklistsMachineryAvailable ? <TechniqueListContainer /> : null}
                </PointsOnTheMapWidgetWrapper>
              </Content>
            </TopBlock>
            <Footer>
              {tasksController.isTaskAvailableForEdit(taskViewStore.taskForView) && (
                <TaskStatusesButtonsBlock
                  task={taskViewStore.taskForView}
                  handleCloseDialog={handleCloseDialog}
                />
              )}
            </Footer>
            {showDeleteConfirmation ? (
              <DialogModal
                status={'warning'}
                title={'Вы уверены, что хотите удалить задачу?'}
                accept={{
                  name: 'Продолжить',
                  handler: deleteTask,
                  color: 'secondary',
                }}
                cancel={{
                  name: 'Отменить',
                  handler: () => setShowDeleteConfirmation(false),
                  color: 'default',
                }}
              />
            ) : null}
          </TaskViewForm>
        </Overlay>
      )
    );
  }
);
