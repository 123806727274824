import { FC, useEffect, useState } from 'react';
import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment from 'moment';

import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { useStore } from '../../../../../shared/utils/IoC';
import { Calendar } from '../../../../../shared/components/Calendar/Calendar';
import { EditSeasonStore } from '../../stores/edit.season.store';
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import { UiStore } from '../../../../stores/ui.store';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import { EditSeasonController } from '../../controllers/edit.season.controller';

import {
  Title,
  Line,
  CalendarWrapper,
  CalendarRow,
  ButtonsWrapper,
  MarkOkImg,
  LabelTitle,
  ButtonActionWrapper,
  DeleteText,
} from './style';
import MarkSvg from './../../static/label.svg';

type EditSeasonModalProps = {
  closeModal: () => void;
};

export const EditSeasonModal: FC<EditSeasonModalProps> = observer(({ closeModal }) => {
  const store = useStore(EditSeasonStore);
  const ui = useStore(UiStore);
  const editSeasonController = useStore(EditSeasonController);
  const { deleteCurrentSeason } = editSeasonController;

  const { setEditSeason } = ui;
  const {
    seasonLabel,
    setEndDate,
    currentSeason,
    clearStore,
    fetchSeasonByYear,
    setStartDate,
    dateBorders,
    saveSeasonChanges,
    isSaveAvailable,
    isSowingAndHarvestBordersOut,
    fetchSeasons,
  } = store;

  useEffect(() => {
    fetchSeasonByYear();

    return () => {
      clearStore();
    };
  }, []);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDateBordersIncorrectOpen, setIsDateBordersIncorrectOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteVerification = async () => {
    try {
      await deleteCurrentSeason();
    } catch (e) {
      return;
    }

    setEditSeason(false);
  };

  const handleSave = async () => {
    if (isSowingAndHarvestBordersOut) {
      setIsDateBordersIncorrectOpen(true);
      return;
    }

    try {
      await saveSeasonChanges();
    } catch (e) {
      console.log(e);
      return;
    }

    setIsSuccess(true);
  };

  const handleSaveVerification = async () => {
    try {
      await saveSeasonChanges();
    } catch (e) {
      console.log(e);
      return;
    }
    setIsSuccess(true);
  };

  const handleExit = () => {
    setEditSeason(false);
    fetchSeasons();
  };

  if (isSuccess) {
    if (isDateBordersIncorrectOpen) {
      return (
        <ModalComponent
          paddings={'default'}
          isVerticalCentered={true}
          data-test-id={'season-editing-sowing-harvesting-success-modal'}
        >
          <MarkOkImg src={MarkSvg} />
          <LabelTitle data-test-id="date-sowing-harvesting-refrashed">
            Даты сева и уборки успешно обновлены
          </LabelTitle>
          <ButtonActionWrapper>
            <Button
              color={'primary'}
              type={'button'}
              onClick={handleExit}
              dataTestId={'season-editing-sowing-harvesting-success-continue'}
            >
              Продолжить
            </Button>
          </ButtonActionWrapper>
        </ModalComponent>
      );
    }
    return (
      <ModalComponent
        paddings={'default'}
        isVerticalCentered={true}
        data-test-id={'season-editing-success-modal'}
      >
        <MarkOkImg src={MarkSvg} />
        <LabelTitle data-test-id={'season-editing-label-success'}>
          Сезон «{seasonLabel} гг.» <br /> успешно изменен
        </LabelTitle>
        <ButtonActionWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={handleExit}
            dataTestId={'season-editing-success-continue'}
          >
            Продолжить
          </Button>
        </ButtonActionWrapper>
      </ModalComponent>
    );
  }

  return (
    <ModalComponent paddings={'seasons'} data-test-id={'season-editing-modal'}>
      <Title data-test-id={'season-editing-label'}>Редактирование сезона {seasonLabel}</Title>
      <Line />
      <CalendarRow>
        <CalendarWrapper>
          <Calendar
            label={'Начало сезона'}
            value={currentSeason.startDate}
            onChange={setStartDate}
            placeholder={'Укажите дату'}
            minDate={dateBorders.start.min}
            maxDate={
              currentSeason.endDate
                ? moment(currentSeason.endDate).subtract(1, 'd')
                : dateBorders.start.max
            }
            dataTestId={'season-editing-start-date'}
          />
        </CalendarWrapper>
        <CalendarWrapper>
          <Calendar
            label={'Конец сезона'}
            value={currentSeason.endDate}
            onChange={setEndDate}
            placeholder={'Укажите дату'}
            // minDate={currentSeason.startDate ? moment(currentSeason.startDate).add(1, 'd').add(1, 'y') : moment(dateBorders.end.min).add(1, 'y')}
            minDate={
              currentSeason.startDate && currentSeason.startDate.year() < dateBorders.end.max.year()
                ? moment(dateBorders.end.min).add(1, 'y')
                : moment(currentSeason.startDate).add(1, 'd')
            }
            maxDate={dateBorders.end.max}
            dataTestId={'season-editing-end-date'}
          />
        </CalendarWrapper>
      </CalendarRow>
      <ButtonsWrapper>
        <Button
          color={'default'}
          type={'button'}
          onClick={() => closeModal()}
          dataTestId={'season-editing-cancel'}
        >
          Отменить
        </Button>
        <Button
          color={'primary'}
          type={'button'}
          disabled={!isSaveAvailable}
          onClick={handleSave}
          dataTestId={'season-editing-save'}
        >
          Сохранить
        </Button>
      </ButtonsWrapper>
      <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.deleteSeason">
        <DeleteText onClick={handleDelete} data-test-id={'season-delete-confirm'}>
          Хотите <span>удалить сезон</span>?
        </DeleteText>
      </ControlAccessRulesWrapper>
      {isDateBordersIncorrectOpen && (
        <DialogModal
          accept={{
            handler: handleSaveVerification,
            name: 'Подтвердить',
          }}
          title={`Даты сева/уборки выходят за пределы сезона и будут перенесены в соответствии с новыми ограничениями`}
          status={'warning'}
          cancel={{
            handler: () => setIsDateBordersIncorrectOpen(false),
            name: 'Отменить',
          }}
          onClose={() => setIsDateBordersIncorrectOpen(false)}
          data-test-id={'season-editing-modal-sowing-harvesting-out-of-range'}
        />
      )}

      {isDeleteModalOpen && (
        <DialogModal
          accept={{
            handler: handleDeleteVerification,
            name: 'Да, удалить',
          }}
          title={`Вы уверены, что хотите удалить Сезон ${seasonLabel}?`}
          status={'warning'}
          cancel={{
            handler: () => setIsDeleteModalOpen(false),
            name: 'Отменить',
          }}
          onClose={() => setIsDeleteModalOpen(false)}
          dataTestId={'season-editing-modal-delete'}
        />
      )}
    </ModalComponent>
  );
});
