import { FC, useState, useEffect } from 'react';
import { Icon } from '@farmlink/farmik-ui';

import { Colors } from '../../constans/colors';

import {
  ListWrapper,
  ListItem,
  ListKey,
  ListValue,
  HelperWrapper,
  HelperPopup,
  HelperAction,
} from './style';

export type ListItemStatus = 'default' | 'error' | 'success';

export type ListItem = {
  key: string;
  value: string;
  status?: ListItemStatus;
  helper?: string;
  helperAction?: () => void;
  helperActionText?: string;
};

type Props = {
  items: ListItem[];
};

export const List: FC<Props> = ({ items }) => {
  const [showHelper, setShowHelper] = useState(false);
  const [actionEnable, setActionEnable] = useState(true);
  const [seconds, setSeconds] = useState(60);

  const handleHelperAction = (item: ListItem) => () => {
    if (item.helperAction) {
      item.helperAction();
      setActionEnable(false);
    }
  };

  useEffect(() => {
    if (!actionEnable) {
      setSeconds(seconds - 1);
    }
  }, [actionEnable]);

  useEffect(() => {
    if (!actionEnable) {
      if (seconds < 1) {
        setActionEnable(true);
        setSeconds(60);
      } else {
        setTimeout(() => {
          setSeconds(seconds - 1);
        }, 1000);
      }
    }
  }, [seconds]);

  return (
    <ListWrapper>
      {items.map((item, index) => (
        <ListItem key={item.key} data-test-id={`profile-item-${index}`}>
          <ListKey>{item.key}</ListKey>
          <ListValue status={item.status || 'default'}>
            <span>{item.value}</span>{' '}
            {item.helper ? (
              <HelperWrapper
                onMouseEnter={() => {
                  setShowHelper(true);
                }}
                onMouseLeave={() => {
                  setShowHelper(false);
                }}
              >
                <Icon
                  icon="suggestion"
                  size="small"
                  fill={item.status === 'error' ? Colors.red : ''}
                />
                {showHelper && (
                  <HelperPopup>
                    {item.helper}
                    {item.helperAction && item.helperActionText ? (
                      <>
                        {actionEnable ? (
                          <HelperAction onClick={handleHelperAction(item)}>
                            {item.helperActionText}
                          </HelperAction>
                        ) : (
                          <span>Повторить отправку через {seconds} секунд</span>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </HelperPopup>
                )}
              </HelperWrapper>
            ) : (
              ''
            )}
          </ListValue>
        </ListItem>
      ))}
    </ListWrapper>
  );
};
