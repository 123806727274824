import { ModalFooter } from '@farmlink/farmik-ui';
import { TModalButton } from '@farmlink/farmik-ui/dist/feature/Modal/components/Modal/Modal';
import { FC, memo, useMemo } from 'react';

import Styled from './FieldsImportWrongFormatModal.styles';
import { TFieldsImportWrongFormatModalProps } from './FieldsImportFileImportModal.types';

const FieldsImportWrongFormatModal: FC<TFieldsImportWrongFormatModalProps> = ({
  onFileUploaderClick,
  closeModal,
}) => {
  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Продолжить',
      handler: onFileUploaderClick,
    };
  }, []);

  const denyButton = useMemo<TModalButton>(() => {
    return {
      title: 'Отменить',
      handler: () => closeModal(),
    };
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.IconWrapper>
        <Styled.Icon />
      </Styled.IconWrapper>

      <Styled.Title>
        Неверный формат. Загрузите файлы в форматах KML, geoJSON или shape
      </Styled.Title>

      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </Styled.Wrapper>
  );
};

FieldsImportWrongFormatModal.displayName = 'FieldsImportWrongFormatModal';

export default memo(FieldsImportWrongFormatModal);
