import styled, { css } from 'styled-components';

import StyledAttributeContainer from '../ChecklistAttributeContainer/styles';

const Wrapper = styled.div<{ $isAttributesAdjusted: boolean; $isActive?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 70%;

  ${({ $isActive }) =>
    !$isActive &&
    css`
      margin-bottom: 20px;
    `}

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ $isAttributesAdjusted }) =>
    $isAttributesAdjusted &&
    css`
      & ${StyledAttributeContainer.Wrapper}:nth-last-child(2) {
        margin-bottom: 0 !important;
      }
    `}
`;

const StyledContainerForAttributeWithChildren = {
  Wrapper,
};

export default StyledContainerForAttributeWithChildren;
