import { FC } from 'react';

import { StyledLabel, StyledLabelByCoorWrap, StyledLabelMark } from './styles';

interface IProps {
  isActual: boolean;
}

const LabelByCoordinates: FC<IProps> = ({ isActual }) => (
  <StyledLabelByCoorWrap>
    <StyledLabelMark $isActual={isActual} />
    <StyledLabel data-test-id={'instance-point-type'}>
      {isActual ? 'Фактическая' : 'Плановая'}
    </StyledLabel>
  </StyledLabelByCoorWrap>
);

export default LabelByCoordinates;
