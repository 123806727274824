import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useRef } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button } from '@farmlink/farmik-ui';
import _ from 'lodash';

import { useStore } from '../../../../../shared/utils/IoC';
import { Burger } from '../../../../components/Burger/Burger';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { FieldsController } from '../../controllers/fields.controller';
import { FieldsRoute } from '../../fields.route';
import { FieldsStore } from '../../stores/fields.store';
import {
  Footer,
  Header,
  Label,
  StyledBurgerWrapper,
  StyledLabelWrapper,
  Wrapper,
} from '../../style';
import { ButtonWrapper } from '../add/style';
import { FieldsImportController } from '../../controllers/import';
import { FieldsImportStore } from '../../stores/import';
import { EditableFieldItem, FieldErrorNotification } from '../../components';
import { Field } from '../../../../../../api/models/field.model';
import MapStore from '../../../../../map/stores/map.store';
import { TChangeImportedFieldReq } from '../../../../../../api/api';

import Styled from './FieldsImportContainer.styles';

const FieldsImportContainer: FC = observer(() => {
  const organizationsStore = useStore(OrganizationsStore);
  const { selectedOrganizationId } = organizationsStore;

  const fieldsStore = useStore(FieldsStore);
  const { editableField, fields } = fieldsStore;

  const fieldsImportStore = useStore(FieldsImportStore);
  const { listOfImportedField } = fieldsImportStore;

  const mapStore = useStore(MapStore);
  const { getEditableLayerGeometry } = mapStore;

  const fieldsImportController = useStore(FieldsImportController);
  const {
    changeFieldName,
    deleteImportedField,
    displayImportedFields,
    clearStoreAfterLeavePage,
    saveImportedFieldList,
    changeImportedField,
  } = fieldsImportController;

  const fieldsController = useStore(FieldsController);
  const { enableImportedFieldEditing } = fieldsController;

  const history = useHistory();

  const throttledRequestToChangeField = useRef(
    _.throttle((changedData: TChangeImportedFieldReq) => changeImportedField(changedData), 3000)
  );

  const fieldsListingRoute = useMemo<string>(() => {
    return generatePath(FieldsRoute.Listing, {
      orgId: selectedOrganizationId,
    });
  }, [selectedOrganizationId]);

  useEffect(() => {
    if (editableField) {
      const { id, name } = editableField;

      const changedData: TChangeImportedFieldReq = { id, name };

      throttledRequestToChangeField.current(changedData);
    }
  }, [editableField]);

  useEffect(() => {
    if (editableField && getEditableLayerGeometry(editableField?.polyId)) {
      const { id } = editableField;

      const geometry = getEditableLayerGeometry(editableField?.polyId);

      const changedData: TChangeImportedFieldReq = {
        id,
        geoJson: {
          type: 'Feature',
          geometry,
        },
      };

      throttledRequestToChangeField.current(changedData);
    }
  }, [getEditableLayerGeometry(editableField?.polyId)]);

  useEffect(() => {
    (async () => {
      await displayImportedFields();
    })();

    return () => {
      clearStoreAfterLeavePage();
    };
  }, []);

  const handleSave = async (): Promise<void> => {
    const isSuccess = await saveImportedFieldList();

    if (isSuccess) {
      history.push(fieldsListingRoute);
    }
  };

  const handleClose = async (): Promise<void> => {
    await clearStoreAfterLeavePage();
    history.push(fieldsListingRoute);
  };

  const totalCountOfIncorrectFields = useMemo<number>(() => {
    return fields.reduce<Field[]>((incorrectFieldList, field) => {
      if (field?.error) {
        incorrectFieldList.push(field);
      }

      return incorrectFieldList;
    }, [])?.length;
  }, [fields]);

  const displayedImportedFiledList = useMemo<Field[]>(() => {
    const filteredFields = fields.filter(field => field?.isImported);

    return _.sortBy(filteredFields, 'errorType');
  }, [fields]);

  return (
    <Wrapper data-test-id={'fields-import-section'}>
      <Header>
        <StyledBurgerWrapper>
          <Burger />
        </StyledBurgerWrapper>
        <StyledLabelWrapper>
          <Label data-test-id={'fields-import-section-label'}>Добавление полей</Label>
        </StyledLabelWrapper>
      </Header>

      <Styled.ListWrapper>
        {totalCountOfIncorrectFields ? (
          <Styled.ErrorNotificationWrapper>
            <FieldErrorNotification totalErrorCount={totalCountOfIncorrectFields} />
          </Styled.ErrorNotificationWrapper>
        ) : null}

        {displayedImportedFiledList.map(field => (
          <EditableFieldItem
            key={field?.id}
            field={field}
            onDelete={deleteImportedField}
            onNameChange={changeFieldName}
            onCardClick={enableImportedFieldEditing}
          />
        ))}
      </Styled.ListWrapper>

      <Footer>
        <ButtonWrapper>
          <Button
            type={'button'}
            color={'default'}
            onClick={handleClose}
            dataTestId={'fields-import-cancel'}
          >
            Отменить
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            type={'button'}
            color={'primary'}
            onClick={handleSave}
            dataTestId={'fields-import-cancel'}
          >
            Сохранить
          </Button>
        </ButtonWrapper>
      </Footer>
    </Wrapper>
  );
});

export default FieldsImportContainer;
