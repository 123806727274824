import { FC } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  title?: string;
  style?: any;
  className?: string;
  displayTitle?: boolean;
  $displayCentered?: boolean;
  $smallTitle?: boolean;
}

const Widget: FC<IProps> = ({ title, children, displayTitle, $smallTitle, ...otherProps }) => (
  <StyledWrapper {...otherProps}>
    {title && displayTitle && (
      <StyledHeader>
        <StyledTitle $smallTitle={$smallTitle}>{title}</StyledTitle>
      </StyledHeader>
    )}
    {children}
  </StyledWrapper>
);

const StyledWrapper = styled.section<IProps>`
  background-color: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 912px;
  max-height: 100%;
  margin: 24px 0;
  padding: 24px;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }

  ${({ $displayCentered }) =>
    $displayCentered &&
    css`
      margin: 0 auto;
    `}
`;

const StyledHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e6f0;
`;

const StyledTitle = styled.span<{ $smallTitle: boolean }>`
  font-weight: 600;
  font-size: ${({ $smallTitle }) => ($smallTitle ? '16px' : '24px')};
  line-height: 32px;
`;

export const AddPointLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3fb65f;
  cursor: pointer;
`;

export const NoPointsPlaceHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: -24px;
`;

export const NoPointsTextBlock = styled.div`
  flex-grow: 1;
`;

export const NoPointsPlaceholderImg = styled.img`
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
`;

export default Widget;
