import styled from 'styled-components';

export const StyledHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e6f0;
`;

export const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const InstancesList = styled.div<{ length: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-height: calc(100% - 30px);
  overflow-y: auto;
  min-height: ${({ length }) => (length >= 3 ? 183 : length * 61)}px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100%;
  width: 100%;
  margin-top: -24px;
`;
