import { forwardRef, MouseEvent } from 'react';

import Styled from './DropdownSearchField.styles';
import { TDropdownSearchFieldProps } from './DropdownSearchField.types';

const DropdownSearchField = forwardRef<HTMLInputElement, TDropdownSearchFieldProps>(
  (
    {
      handlers: { onChange, onClick, onBlur, onFocus, onKeyUp },
      values: { searchValue, selectValue },
      configFieldProps: { id, placeholder, type },
      configVisualProps: { isBlocked },
    },
    inputRef
  ) => {
    const handleInputClick = (event: MouseEvent<HTMLInputElement>): void => {
      event.stopPropagation();

      onClick();
    };

    return (
      <Styled.Input
        readOnly={isBlocked || !type?.search}
        isDisabled={isBlocked || !type?.search}
        placeholder={placeholder}
        value={searchValue ?? (selectValue?.label || '')}
        id={id}
        ref={inputRef}
        maxLength={100}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onClick={handleInputClick}
        autoComplete={'off'}
      />
    );
  }
);

export default DropdownSearchField;
