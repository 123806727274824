import { Button } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { TasksEdit } from '../TaskEdit/TaskEdit';

import fileSvg from './fileSvg.svg';
import { CreateNewTaskButtonWrapper, CreateTasksSVG, CreateTasksText, CreateTasksTitle, NoTasksPlaceHolderWrapper } from './style';

export const NoTasksPlaceHolder = () => {

    const [showCreateNewTaskForm, setShowCreateNewTaskForm] = useState(false);

    return (
        <NoTasksPlaceHolderWrapper>
            <CreateTasksSVG src={fileSvg}/>
            <CreateTasksTitle>
                Создайте новую задачу
            </CreateTasksTitle>
            <CreateTasksText>
                Вся история будет сохранена и доступна для просмотра
            </CreateTasksText>
            <CreateNewTaskButtonWrapper>
                <Button
                    color={'primary'}
                    type={'button'}
                    onClick={() => setShowCreateNewTaskForm(true)}
                >
                    Создать задачу
                </Button>
            </CreateNewTaskButtonWrapper>
            {showCreateNewTaskForm ? (
                <TasksEdit 
                    onCloseDialog={() => setShowCreateNewTaskForm(false)}
                    onCancel={() => setShowCreateNewTaskForm(false)}
                />): null}
        </NoTasksPlaceHolderWrapper>
    );
};