import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { EChecklistAttrPlaceholder } from '../../constants';

const getChecklistAttrPlaceholderByAttrType = (attrType: EChecklistAttributeType): string => {
  switch (attrType) {
    case EChecklistAttributeType.Int:
      return EChecklistAttrPlaceholder.Int;
    case EChecklistAttributeType.Double:
      return EChecklistAttrPlaceholder.Double;
    case EChecklistAttributeType.String:
      return EChecklistAttrPlaceholder.String;
    case EChecklistAttributeType.LongString:
      return EChecklistAttrPlaceholder.LongString;
    case EChecklistAttributeType.Enum:
      return EChecklistAttrPlaceholder.Enum;
    case EChecklistAttributeType.UserDictionaryLink:
      return EChecklistAttrPlaceholder.UserDictionaryLink;
    case EChecklistAttributeType.DictionaryLink:
      return EChecklistAttrPlaceholder.DictionaryLink;
    case EChecklistAttributeType.Date:
      return EChecklistAttrPlaceholder.Date;
    default:
      return '';
  }
};

export default getChecklistAttrPlaceholderByAttrType;
