import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { IndiceModel } from '../../models/indices.model';
import { CultureFillEnum } from '../../../modules/dashboard/constants/culture.fill.enum';

type TypeRequest = {
  fieldId?: string;
  indexName?: CultureFillEnum;
  year?: string;
};

type TypeResponse = {
  content: Array<IndiceModel>;
} & TypeResponsePageable;

export const getIndicesList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/agro/indices/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
