import { IGetDictionary } from '../../models/dictionary/dictionary.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TGetDictListByRemoteNameReq = {
  idIn?: string[];
  parentId?: string;
  nameFilter?: string;
  codeFilter?: string;
  pathEquals?: string;
  pathPrefix?: string;
  originalOnly?: boolean;
  latestVersion?: boolean;
  level?: number;
  attrs?: {
    additionalProp1: any;
    additionalProp2: any;
    additionalProp3: any;
  };
  status?: string;
  fetchAttributes?: boolean;
  remoteName?: string;
  dependencyName?: string;
  dependencyRecordId?: string;
};

type TGetDictListByRemoteNameRes = { content: IGetDictionary[] };

const getDictionaryListByRemoteName: TypeApiRoute & {
  request: TGetDictListByRemoteNameReq;
  response: TGetDictListByRemoteNameRes;
} = {
  url: ({ remoteName }) => `/api/da-dictionary/dictionary/${remoteName}/findAll`,
  method: 'POST',
  headers: {},
  request: {} as TGetDictListByRemoteNameReq,
  response: {} as TGetDictListByRemoteNameRes,
};

export type { TGetDictListByRemoteNameReq, TGetDictListByRemoteNameRes };

export { getDictionaryListByRemoteName };
