import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../shared/constans/sizes';

import defaultImg from './assets/default.svg';

export const CultureItemBody = styled.div<{ active: boolean }>`
  width: 176px;
  min-height: 58px;
  padding: 10px 12px;
  background: ${Colors.white};
  border: 1px solid ${({ active }) => (active ? `${Colors.green}` : `${Colors.grayBorder}`)};
  box-sizing: border-box;
  border-radius: ${BorderRadius.small};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &#emptyCulture {
    width: 197px;
    &>div {
      overflow: visible;
    }
  }
`;

export const CultureItemName = styled.div`
  ${FontSizes.mediumBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
`;

export const CultureItemSort = styled.div`
  color: ${Colors.darkGray};
  ${FontSizes.default};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
`;

export const CultureItemImg = styled.img.attrs(props => ({
  src: props.src || defaultImg,
  className: props.src ? '' : 'defaultImg',
}))`
  height: 100%;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotateY(180deg);
  &.defaultImg {
    height: 50%;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
