import { lazyInject, provide } from '../../../shared/utils/IoC';
import { UiStore } from '../../stores/ui.store';
import { PopupPages } from '../../constants/popup.pages';
import { MapMode } from '../../../map/stores/map.store';
import { FieldsStore } from '../../modules/fields/stores/fields.store';

@provide.singleton()
export class SidebarController {
  @lazyInject(UiStore)
  uiStore: UiStore;

  @lazyInject(FieldsStore)
  fieldsStore: FieldsStore;

  clearMainDashboardState = () => {
    this.uiStore.setPopupPageState(PopupPages.None);
    this.fieldsStore.setMapModeValue(MapMode.Listing);
  };
}
