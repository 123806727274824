import { FC, useEffect, useState, useCallback } from 'react';
import { Button, Select } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';

import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { useStore } from '../../../../../shared/utils/IoC';
import { Calendar } from '../../../../../shared/components/Calendar/Calendar';
import ErrorImage from '../../../../../shared/assets/images/cancel.png';
import { OperationsStore } from '../../stores/operations.store';
import { CreateOperationFormController } from '../../controllers/create.operation.form.controller';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { Operation } from '../../../../../../api/models/operations/operation.model';

import MarkSvg from './static/label.svg';
import {
  Title,
  Line,
  SeasonStubSelectorWrapper,
  CalendarWrapper,
  CalendarRow,
  BottomLine,
  ButtonsWrapper,
  MarkOkImg,
  LabelTitle,
  ButtonActionWrapper,
  ErrorImg,
  CalendarSplitter,
} from './style';

type CreateSeasonModalProps = {
  closeModal: () => void;
  operationId: string;
};

export const EditOperationModal: FC<CreateSeasonModalProps> = observer(({ closeModal, operationId }) => {
  const seassonStore = useStore(SeasonsStore);
  const operationsStore = useStore(OperationsStore);
  const createOperationFormController = useStore(CreateOperationFormController);
  const seasonCultureStore = useStore(SeasonCultureStore);

  const operationToEdit = createOperationFormController.getOperationById(operationId);

  const [isSecondStep, setIsSecondStep] = useState(false);
  const [isSeasonCreateError, setIsSeasonCreateError] = useState(false);
  const [operationTypeId, setOperationTypeId] = useState(operationToEdit.operationTypeId);
  const [isFormSending, setIsFormSending] = useState(false);

  const [startDate, setStartDate] = useState<Moment>(moment(operationToEdit.startDate));
  const [endDate, setEndDate] = useState<Moment>(moment(operationToEdit.endDate));

  const [operationTitle, setOperationTitle] = useState(null)

  useEffect(() => {
    const culture = operationsStore.OperationCulturesInFields.filter(
        item => item?.culture.id === seasonCultureStore.selectedCultureId
      )[0]?.culture.name
 
    setOperationTitle(culture ? culture : 'поля без культуры');
    operationsStore.getOperationsType(seasonCultureStore.selectedCultureId);
  }, []);

  const isSaveButtonAvailable =
    endDate && startDate.isSameOrBefore(endDate) && Boolean(operationTypeId) && !isFormSending;

  const handleEditOperation = async () => {
    setIsFormSending(true);
    const { label } = operationsStore.getOperationsTypeSelectAdapter.find(
      option => option.value === operationTypeId
    );

    try {
      await createOperationFormController.handleEditOperation(
        {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          name: label,
          operationTypeId,
        },
        operationId
      );

      setIsFormSending(false);
      setIsSecondStep(true);
    } catch (e) {
      setIsFormSending(false);
      setIsSeasonCreateError(true);
    }
  };

  const handleStartDateChange = useCallback((date: Moment) => {
    setStartDate(date)
    if(date.isAfter(endDate)) setEndDate(date)
  }, [endDate])

  const handleEndDateChange = useCallback((date: Moment) => {
    setEndDate(date)
    if(date.isBefore(endDate)) setStartDate(date)
  }, [startDate])

  if (isSecondStep) {
    return (
      <ModalComponent
        paddings={'default'}
        isVerticalCentered={true}
        data-test-id={'operation-add-modal-success'}
      >
        <MarkOkImg src={MarkSvg} />
        <LabelTitle data-test-id={'operation-add-modal-label'}>
          Операция успешно сохранена
        </LabelTitle>
        <ButtonActionWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={async () => {
              closeModal();
              operationsStore.resetPageNumberSettings();
              await operationsStore.getOperations();
            }}
            data-test-id={'operation-add-modal-success-continue'}
          >
            Продолжить
          </Button>
        </ButtonActionWrapper>
      </ModalComponent>
    );
  }

  if (isSeasonCreateError) {
    return (
      <ModalComponent
        paddings={'default'}
        isVerticalCentered={true}
        data-test-id={'operation-add-modal-error'}
      >
        <ErrorImg src={ErrorImage} data-test-id={'operation-add-modal-already-created-img'} />
        <LabelTitle>Не удалось сохранить операцию</LabelTitle>
        <ButtonActionWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={() => setIsSeasonCreateError(false)}
            data-test-id={'operation-add-modal-error-continue'}
          >
            Продолжить
          </Button>
        </ButtonActionWrapper>
      </ModalComponent>
    );
  }

  return (
    <ModalComponent size={'medium'} paddings={'seasons'} data-test-id={'operation-add-modal-new'}>
      <Title data-test-id={'operation-add-modal-new-title'}>Операция для {operationTitle}</Title>
      <Line />
      <SeasonStubSelectorWrapper>
        <Select
          onChange={setOperationTypeId}
          options={operationsStore.getOperationsTypeSelectAdapter}
          placeholder={'Не выбрано'}
          label={'Тип операции'}
          dataTestId={'operation-stub-select'}
          defaultValue={{
            value: operationToEdit.id,
            label: operationToEdit.name,
          }}
          disabled={true}
        />
      </SeasonStubSelectorWrapper>
      <CalendarRow>
        <CalendarWrapper>
          <Calendar
            label={'Начало выполнения'}
            value={startDate as Moment}
            onChange={handleStartDateChange}
            placeholder={'Укажите дату'}
            minDate={moment(seassonStore.selectedSeassonData.startDate)}
            maxDate={moment(seassonStore.selectedSeassonData.endDate)}
            dataTestId={'operation-start-date'}
            dateFormat={'DD MMMM'}
          />
        </CalendarWrapper>
        <CalendarSplitter />
        <CalendarWrapper>
          <Calendar
            label={'Окончание выполнения'}
            value={endDate as Moment}
            onChange={handleEndDateChange}
            placeholder={'Укажите дату'}
            minDate={moment(seassonStore.selectedSeassonData.startDate)}
            maxDate={moment(seassonStore.selectedSeassonData.endDate)}
            dataTestId={'operation-end-date'}
            dateFormat={'DD MMMM'}
          />
        </CalendarWrapper>
      </CalendarRow>
      <BottomLine />
      <ButtonsWrapper>
        <Button
          color={'default'}
          type={'button'}
          onClick={() => closeModal()}
          dataTestId={'operation-cancel-btn'}
        >
          Отменить
        </Button>
        <Button
          color={'primary'}
          type={'button'}
          disabled={!isSaveButtonAvailable}
          onClick={handleEditOperation}
          dataTestId={'operation-save-btn'}
        >
          Сохранить
        </Button>
      </ButtonsWrapper>
    </ModalComponent>
  );
});
