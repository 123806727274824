import styled, { css } from 'styled-components';

enum EAttributeContainerMode {
  Half = 'HALF',
  Full = 'FULL',
  Mixed = 'MIXED',
}

type TStyledAttributeContainerInnerContainerProps = {
  $isFullWidth?: boolean;
};

const InnerContainer = styled.div<TStyledAttributeContainerInnerContainerProps>`
  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      flex: 1 1 100%;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

type TStyledAttributeContainerWrapperProps = {
  $isHidden?: boolean;
  $reduceMarginBottom?: boolean;
  $mode?: EAttributeContainerMode;
};

const Wrapper = styled.div<TStyledAttributeContainerWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 8px;

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      display: none;
    `}

  ${({ $mode }) => {
    if ($mode === EAttributeContainerMode.Mixed) {
      return css`
        flex: 1 0 40%;
        max-width: calc(50% - 16px);
        margin-right: calc(50% - 16px);
      `;
    }

    if ($mode === EAttributeContainerMode.Half) {
      return css`
        flex: 1 0 40%;
        max-width: calc(50% - 16px);
      `;
    }
    return css`
      flex: 1 0 70%;
    `;
  }}

  ${({ $reduceMarginBottom }) =>
    $reduceMarginBottom &&
    css`
      margin-bottom: 16px !important;
    `}
`;

const StyledAttributeContainer = {
  InnerContainer,
  Container,
  Wrapper,
};

export { EAttributeContainerMode };

export default StyledAttributeContainer;
