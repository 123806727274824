import { useCallback } from 'react';

import { TUseDropdownHandlers } from './useDropdownHandlers.types';

const useDropdownHandlers: TUseDropdownHandlers = ({ state, setState, props }) => {
  const handleClickOutside = useCallback((): void => {
    setState({
      values: { ...state.values, searchValue: null },
      settings: { ...state.settings, isDropped: false, isExactCompareOfSearchValue: false },
    });
  }, [state]);

  const handleFieldWrapperClick = useCallback((): void => {
    const isBlocked = props.config?.visual?.isBlocked;

    const { isSearchFieldFocused } = state.settings;

    if (isBlocked || isSearchFieldFocused) {
      return;
    }

    setState({ settings: { ...state.settings, isDropped: !state.settings.isDropped } });
  }, [state, props.config?.visual?.isBlocked]);

  const handlers: ReturnType<TUseDropdownHandlers> = {
    onClick: handleFieldWrapperClick,
    onClickOutside: handleClickOutside,
  };

  return handlers;
};

export default useDropdownHandlers;
