import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import {
  ECheckListInstanceType,
  IInstance,
} from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { CheckAccessStore } from '../../../../../../../authorization/stores/checkAccess.store';
import {
  CUSTOM_COLORS_LIST,
  ICustomColor,
} from '../../../../../../../shared/constans/customColorsPrests';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../shared/constants/access-rules-action';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import Instance from '../Instance';

import { InstancesList, StyledHeader, StyledTitle, Wrapper } from './styles';

interface IProps {
  instanceList: Map<number, Partial<IInstance>>;
  checklistsAvailable?: boolean;
}

interface IInstanceToColors {
  num: number;
  instance: Partial<IInstance>;
  colors: ICustomColor;
  displayChecklist?: boolean;
}

const InstanceList: FC<IProps> = observer(({ instanceList, checklistsAvailable }) => {
  const { getAccessRuleValue } = useStore(CheckAccessStore);
  const { selectedOrganizationId } = useStore(OrganizationsStore);

  const workWithTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS);

  const isOrgMy = selectedOrganizationId === 'my';

  const manageTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);

  const instanceToColors: IInstanceToColors[] = useMemo(() => {
    const totalColors = CUSTOM_COLORS_LIST.length - 1;
    let selectedColorPosition = -1;

    return Array.from(instanceList.keys()).map<IInstanceToColors>(instanceNumber => {
      const instance = instanceList.get(instanceNumber);
      ++selectedColorPosition;

      if (selectedColorPosition > totalColors) selectedColorPosition = 0;

      return {
        num: instanceNumber,
        instance,
        colors: CUSTOM_COLORS_LIST[selectedColorPosition],
        displayChecklist: checklistsAvailable,
      };
    });
  }, [instanceList, instanceList.size]);

  return (
    <Wrapper className={'instances-list-wrapper'}>
      <InstancesList length={instanceToColors.length} className="instances-list">
        {instanceToColors.map(({ num, instance, colors, displayChecklist }, index) => {
          if (instance.type === ECheckListInstanceType.ActualPoint) {
            return (
              <Instance
                key={instance.id}
                instance={instance}
                colors={CUSTOM_COLORS_LIST[5]}
                position={num}
                checklistsAvailable={displayChecklist}
                isOrgMy={isOrgMy}
                workWithTasks={workWithTasks}
                manageTasks={manageTasks}
              />
            );
          }

          return (
            <Instance
              key={instance.id}
              instance={instance}
              colors={colors}
              position={num}
              checklistsAvailable={displayChecklist}
              isOrgMy={isOrgMy}
              workWithTasks={workWithTasks}
              manageTasks={manageTasks}
            />
          );
        })}
      </InstancesList>
    </Wrapper>
  );
});

export default InstanceList;
