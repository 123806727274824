import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { FieldsImportModalContainer } from '../containers';

const FIELDS_IMPORT_MODALS_UNIQUE_KEY = 'fieldsImport';

enum EFieldsImportModalName {
  FileImport = 'fileImport',
}

const fileImportModalConfig: TModalConfig = {
  name: EFieldsImportModalName.FileImport,
  title: '',
  type: EModalType.Custom,
  id: EFieldsImportModalName.FileImport,
  children: FieldsImportModalContainer,
  dataTestId: EFieldsImportModalName.FileImport,
};

const fieldsImportModalConfigList = [fileImportModalConfig];

export { EFieldsImportModalName, FIELDS_IMPORT_MODALS_UNIQUE_KEY, fieldsImportModalConfigList };
