import styled from 'styled-components';

export const StyledFullscreen = styled.div`
  padding: 24px;
  /* height: 200%; */
  max-width: 912px;
  margin: 0 auto;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  margin-top: 7px;
`;

const StyledHeaderTitle = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-left: 15px;
`;

export const PointsOnTheMapWidgetWrapper = styled.div`
  width: 100%;
  & .points-on-the-map-widget {
    min-height: 600px;
    padding: 24px !important;
  }
  & .instances-list-wrapper {
    max-height: 400px;
  }
  & #pointsMapId {
    border: none;
  }
  & .no-points-img {
    display: none;
  }
`;

export const TaskStatusesButtonsBlockWrapper = styled.div`
  & button {
    width: calc((100% - 50px) / 3);
  }
`;

export const NoPointsHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 24px;
`;

export const NoPointsText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9AA0AD;
  margin-top: 4px;
`;
