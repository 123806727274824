import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  flex: 0 0 300px;

  & > button {
    justify-content: center;
  }
`;
