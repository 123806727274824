import React, { FC } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { PortalProvider } from 'react-portal-hook';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { ModalContainer } from '@farmlink/farmik-ui';

import { EAppSettingsType, lsAppSettingsKey } from '../index';
import { Agreement } from '../pages/agreement/Agreement';

import { ApplicationRoutes } from './routes';
import { DashboardRouter } from './dashboard/dashboard.router';
import { AdminView } from './admin-view/AdminView';
import { useStore } from './shared/utils/IoC';
import { Axios } from './shared/utils/axios2';
import { ExternalRedirect } from './authorization/containters/AuthorizationWrapper';

export const App: FC = observer(() => {
  const axios = useStore(Axios);

  const { search } = useLocation();
  const organizationQuery = new URLSearchParams(search).get('organizationId');
  const appSettingsStr = localStorage.getItem(lsAppSettingsKey);
  const appSettings = JSON.parse(appSettingsStr);

  const orgId =
    organizationQuery || (appSettings && appSettings[EAppSettingsType.Organization]) || 'my';

  if (organizationQuery && appSettings) {
    appSettings[EAppSettingsType.Organization] = orgId;
    localStorage.setItem(lsAppSettingsKey, JSON.stringify(appSettings));
  }

  let registrationStep2Url =
    process.env?.NODE_ENV === 'development'
      ? ApplicationRoutes.ExternalRegistrationStep2Dev
      : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalRegistrationStep2}`;
  const systemId = 'assistant';
  registrationStep2Url = `${registrationStep2Url}&systemId=${systemId}`;
  return (
    <>
      <Wrapper>
        <PortalProvider>
          <Switch>
            <Route path={ApplicationRoutes.Agreement} component={Agreement} />
            {axios.needAdditionalInfo ? (
              <ExternalRedirect redirectTo={registrationStep2Url} />
            ) : null}
            <Route path={ApplicationRoutes.AdminView} component={AdminView} />
            <Route path={ApplicationRoutes.Dashboard} component={DashboardRouter} />
            <Redirect to={ApplicationRoutes.Dashboard} />
          </Switch>
          <ModalContainer />
        </PortalProvider>
      </Wrapper>
    </>
  );
});

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
