import { makeAutoObservable } from 'mobx';

import { provide } from '../../shared/utils/IoC';
import { TypeOrganization } from '../../../api/models/organization.model';

@provide.singleton()
export class OrganizationsStore {
  idToOrganization: Map<string, TypeOrganization> = new Map();

  loading = false;

  organization: TypeOrganization | undefined = undefined;

  organizationUpdateInProgress = true;

  selectedOrganizationId = 'my';

  constructor() {
    makeAutoObservable(this);
  }

  get currentOrganization() {
    return this.idToOrganization.get(this.selectedOrganizationId);
  }

  get organizationList() {
    return Array.from(this.idToOrganization.values()).map(i => {
      return {
        label: i.name,
        value: i.organizationId,
      };
    });
  }

  setSelectedOrganizationId = (id: string): void => {
    this.selectedOrganizationId = id;
  };
}
