import { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';

import {
  PersonalDataWidget,
  // CompetentionWidget,
  // InteresWidget
} from './components';
import { ProfileInnerGrid } from './style';
import { ProfileStore } from './stores/ProfileStore';
import { ProfileController } from './controllers/profile.controller';

export const Profile: FC = observer(() => {
  const profileStore = useStore(ProfileStore);
  const { getUserInfo } = useStore(ProfileController);
  const {
    loading,
    fullName,
    user,
    personalDataItems,
    // competentionsList,
    // toggleWelcomeWidget,
    // collapsedWelcomeWidget,
    // expertises,
    
    // interests,
  } = profileStore;

  useEffect(() => {
      getUserInfo();
  }, []);
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        'Loading...'
      ) : user ? (
        <ProfileInnerGrid>
          <PersonalDataWidget
            fullName={fullName}
            items={personalDataItems}
            avatar={user?.avatar ? user?.avatar.downloadUrl : ''}
          />
          {/* <CompetentionWidget items={expertises} />*/}
          {/* <InteresWidget items={interests} />*/}
        </ProfileInnerGrid>
      ) : (
        'Нет удалось загрузить данные профиля'
      )}
    </>
  );
});
