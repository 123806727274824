import { forwardRef, memo, useCallback, useEffect, useMemo } from 'react';

import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import { ImagesUploader } from '../../../../../../../tasks/containers/fullscreen/checklist/components/Phenophase/components/ImagesUploader/ImagesUploader';
import { useHandleChecklistAttrChange } from '../../../../../../hooks/useHandleChecklistAttrChange';
import { getChecklistAttributeId } from '../../../../../../utils/checklist.instances';
import { TChecklistAttributeProps } from '../../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';
import Styled, {
  EAttributeContainerMode,
} from '../../../containers/ChecklistAttributeContainer/styles';
import {
  useGetChecklistAttrPlaceholder,
  useGetErrorListOfChecklistAttr,
} from '../../../hooks/attribute';
import { Input } from '../../shared/Input';

type TDoubleAttributeProps = {} & TChecklistAttributeProps;

const DoubleAttribute = forwardRef<HTMLInputElement, TDoubleAttributeProps>(
  ({ attributeWithValue, onChange, isBlocked, focusTarget }, ref) => {
    const { isRequired, attribute, id } = attributeWithValue.checklistAttribute;

    const {
      attributeValue,
      schema,
      isActive,
      attributeWithFileValue,
      checklistAttribute,
    } = attributeWithValue;

    const { partialValue, setPartialValue } = useHandleChecklistAttrChange(
      attributeWithValue,
      { doubleValue: attributeValue.doubleValue ?? '' },
      onChange
    );

    const placeholder = useGetChecklistAttrPlaceholder(
      checklistAttribute.attribute.type,
      checklistAttribute.attribute?.placeholder
    );

    const attributeId: string = useMemo(() => getChecklistAttributeId(id), [id]);

    const errorList = useGetErrorListOfChecklistAttr(schema, attributeWithFileValue?.schema);

    const handleChange = useCallback((value: string): void => {
      const trimmedValue = value.trim();
      const numberValue = Number(trimmedValue);
      const isMinus: boolean = trimmedValue === '-';
      const isEmpty: boolean = trimmedValue === '';

      const isMoreThanOneLeadingZero: boolean =
        trimmedValue?.[0] === '0' && trimmedValue?.[1] === '0';

      const isMinusAndIsMoreThanOneLeadingZero: boolean =
        trimmedValue?.[0] === '-' && trimmedValue?.[1] === '0' && trimmedValue?.[2] !== '.';

      if (isMinus || !isNaN(numberValue) || isEmpty) {
        setPartialValue({
          doubleValue: isMoreThanOneLeadingZero
            ? 0
            : isMinusAndIsMoreThanOneLeadingZero
            ? '-0'
            : trimmedValue,
        });
      }
    }, []);

    // Костыль. Нужен для того, чтобы избавлять от минуса после валидации
    useEffect(() => {
      if (schema?.isShownError && partialValue?.doubleValue === '-') {
        setPartialValue({ doubleValue: '' });
      }
    }, [schema]);

    return (
      <>
        {isActive && (
          <Styled.Wrapper
            $mode={EAttributeContainerMode.Half}
            $reduceMarginBottom={attributeWithFileValue ? false : Boolean(errorList?.length)}
          >
            <Input
              isRequired={isRequired}
              label={attribute.name}
              value={partialValue.doubleValue ?? ''}
              onChange={handleChange}
              isBlocked={isBlocked}
              placeholder={placeholder}
              error={errorList?.[0] || ''}
              isWithoutErrorText={true}
              maxLength={16}
              id={getChecklistAttributeId(id)}
              tooltip={checklistAttribute?.toolTip}
              ref={id === focusTarget ? ref : null}
            />

            <Styled.Container>
              <Styled.InnerContainer>
                <InputFieldError error={{ errorList }} />
              </Styled.InnerContainer>
              <Styled.InnerContainer
                $isFullWidth={Boolean(attributeWithFileValue?.attributeValue?.fileValue?.length)}
              >
                {attributeWithFileValue && (
                  <ImagesUploader
                    onImgUrlsChange={onChange}
                    attributeWithValue={attributeWithFileValue}
                    isBlocked={isBlocked}
                    id={attributeId}
                  />
                )}
              </Styled.InnerContainer>
            </Styled.Container>
          </Styled.Wrapper>
        )}
      </>
    );
  }
);

DoubleAttribute.displayName = 'DoubleAttribute';

export default memo(DoubleAttribute);
