import styled from 'styled-components';

export const StyledMapContainer = styled.div`
  width: 100%;
  height: 415px;
  & .plan-coord-general {
    box-sizing: content-box;
    height: auto !important;
    width: auto !important;
    min-width: 14px;
    min-height: 14px;
    line-height: 16px;
    background-color: #f29993;
    border-radius: 50%;
    border: 1px solid #ffffff;
    padding: 1px;
    text-align: center;
  }
  & .fact-coord {
    background-color: #ffd765;
  }
  & .multi-coord {
    background-color: #ffffff;
  }
  & .not-selected {
    box-sizing: content-box;
    height: auto !important;
    width: auto !important;
    min-width: 14px;
    min-height: 14px;
    line-height: 16px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    padding: 1px;
    text-align: center;
    background-color: #ffffff;
  }
  & .selected {
    box-sizing: content-box;
    height: auto !important;
    width: auto !important;
    min-width: 14px;
    min-height: 14px;
    line-height: 16px;
    border-radius: 50%;
    padding: 1px;
    text-align: center;
    background-color: #ffd765;
  }
  & .completed {
    background-color: #3fb65f;
  }
`;
