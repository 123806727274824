import styled from 'styled-components';

export const Wrapper = styled.div<{ selected: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 148px;
  height: 56px;
  background-color: #ffffff;
  padding: 8px 12px 8px 12px;
  border: 1px solid ${({ selected }) => (selected ? `#3FB65F` : `#E9E6F0`)};
  border-radius: 12px;
  margin-right: 12px;
  margin-bottom: 5px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardTitle = styled.div`
  max-width: 82px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #151f32;
`;

export const InstanceCoordType = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
`;

export const CardsListBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 10px;
  margin-top: 24px;
`;

export const PointImg = styled.img`
  height: 32px;
  width: 32px;
  & > svg > path {
    fill: orange;
  }

  & > svg > g > path {
    fill: grey;
  }
`;
