import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { OrganizationCard } from '../types/OrganizationCard';
import { Axios } from '../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationIncomeStore {
  @lazyInject(Axios)
  protected axios2: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  searchQuery = '';

  setSearchQuery = (v: string) => {
    this.searchQuery = v;
    this.fetchApplicationsAndInvites();
  };

  idToInvitation: Map<string, OrganizationCard> = new Map();

  get invitations() {
    return Array.from(this.idToInvitation.values());
  }

  fetchApplicationsAndInvites = () => {
    this.axios2.api
      .getOrganizationApplicationsMine({
        nameOrInn: this.searchQuery,
      })
      .then(response => {
        runInAction(() => {
          this.idToInvitation.clear();
          response.content.forEach((item: any) => {
            if (item.type !== 'INVITATION') {
              return;
            }

            this.idToInvitation.set(item.applicationId, {
              avatar: '',
              organizationId: item.organization.id,
              title: item.organization.name,
              roleName: '',
              type: 'invitation',
              applicationId: item.applicationId,
              organization: item.organization,
              createdWhen: item.createdWhen,
              comment: item.comment,
              state: item.status,
            });
          });
        });
      })
      .catch(error => {
        console.error(error);
      });
  };
}
