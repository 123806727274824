import { FC } from 'react';
import { observer } from 'mobx-react';

import { EditInterests } from '../../components/EditInterest/EditInterests';

import { Wrapper } from './style';

export const EditInterestsPage: FC = observer(() => {
  return (
    <Wrapper>
      <EditInterests />
    </Wrapper>
  );
});
