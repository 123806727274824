import { FC, memo, MouseEvent, useMemo } from 'react';

import Styled from './DropdownBodyOption.styles';
import {
  TDropdownBodyOptionLabelParts,
  TDropdownBodyOptionProps,
} from './DropdownBodyOption.types';

const DropdownBodyOption: FC<TDropdownBodyOptionProps> = ({
  option,
  searchValue,
  isSearch,
  onOptionClick,
}) => {
  const handleOptionClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    onOptionClick(option);
  };

  const labelParts = useMemo<TDropdownBodyOptionLabelParts>(() => {
    if (!isSearch) {
      return {
        firstLabelPart: '',
        lastLabelPart: '',
      };
    }

    const regExpSearch = new RegExp(searchValue, 'i');

    const foundPos = option.label.search(regExpSearch);

    if (foundPos !== 0) {
      return {
        firstLabelPart: '',
        lastLabelPart: option.label,
      };
    }

    const firstLabelPart = option.label.match(new RegExp(`${searchValue}`, 'gi'));

    const lastLabelPart = option.label.split(firstLabelPart?.[0]);

    return {
      firstLabelPart: firstLabelPart?.[0] || '',
      lastLabelPart: lastLabelPart?.join(''),
    };
  }, [option, searchValue, isSearch]);

  if (!isSearch) {
    return <Styled.Option onClick={handleOptionClick}>{option.label}</Styled.Option>;
  }

  return (
    <Styled.Option onClick={handleOptionClick}>
      <Styled.MatchedValue>{labelParts.firstLabelPart}</Styled.MatchedValue>
      {labelParts.lastLabelPart}
    </Styled.Option>
  );
};

DropdownBodyOption.displayName = 'DropdownBodyOption';

export default memo(DropdownBodyOption);
