import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { Button } from '@farmlink/farmik-ui';
import { generatePath, useHistory } from 'react-router';

import { Footer, Header, Label, LabelWrapper, Wrapper, TasksFilterWrapper } from '../../style';
import { useStore } from '../../../../../shared/utils/IoC';
import { TasksFilter } from '../../components/TasksFilter';
import { TasksList } from '../../../operations/components/TasksList/TasksList';
import { OperationsStore } from '../../../operations/stores/operations.store';
import { TasksEdit } from '../../../operations/components/TaskEdit/TaskEdit';
import { FullScreenMode, TasksStore } from '../../../operations/stores/tasks.store';
import { SeasonCultureStore } from '../../../operations/stores/season.culture.store';
import { NoTasksPlaceholderAfterFilter } from '../../components/NoTasksPlaceholder/NoTasksPlaceholderAfterFilter';
import { ModalContainer } from '../../../../../modals/containers';
import { tasksModalUniqueKey } from '../../../operations/modals/modalsConfig';
import { EModalSize } from '../../../../../modals/components/Modal/styles';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import { DashboardRoutes } from '../../../../dashboard.routes';
import { OrganizationsStore } from '../../../../stores/organizations.store';

export const TasksListing = observer(() => {
  const store = useStore(OperationsStore);
  const seasonCultureStore = useStore(SeasonCultureStore);
  const tasksStore = useStore(TasksStore);
  const history = useHistory();
  const { selectedOrganizationId } = useStore(OrganizationsStore);

  const { accessRules, loading: accessRulesLoading, getAccessRuleValue } = useStore(
    CheckAccessStore
  );

  const [showCreateTaskForm, setShowCreateTaskForm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  let prevTasksListLength = 0;

  useEffect(() => {
    tasksStore.fromTasksPage = true;
    tasksStore.setFullScreenMode(null);
    return () => {
      tasksStore.fromTasksPage = false;
    };
  }, []);

  const isOrgMy = useMemo(() => selectedOrganizationId === 'my', [selectedOrganizationId]);

  useEffect(() => {
    setShowSpinner(tasksStore.totalPages > 0);
    prevTasksListLength = 0;
  }, [
    seasonCultureStore.selectedCultureId,
    store.selectedOperationId,
    store.selectedOperationFieldId,
  ]);

  useEffect(() => {
    if (isOrgMy || !accessRules.length) return;
    const isAllowTasks =
      getAccessRuleValue(`${SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS}`) ||
      getAccessRuleValue(`${SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS}`);
    console.log('isAllowTasks:', isAllowTasks);
    if (!isAllowTasks) {
      const url = generatePath(DashboardRoutes.Fields, {
        orgId: selectedOrganizationId,
      });
      history.push(url);
    }
  }, [accessRules, selectedOrganizationId]);

  const tasksListRef = useRef(null);

  return (
    (isOrgMy || (accessRules.length && !accessRulesLoading)) && (
      <Wrapper data-test-id={'tasks-container-wrapper'}>
        <Header data-test-id={'tasks-container-header'}>
          <LabelWrapper>
            <Label data-test-id={'tasks-container-header-label'}>Задачи</Label>
            <Button
              color={'primary'}
              type={'button'}
              className={'add-tasks-button'}
              onClick={() => {
                setShowCreateTaskForm(true);
              }}
              dataTestId={'tasks-module-footer-add-button'}
            >
              Создать задачу
            </Button>
          </LabelWrapper>
          <TasksFilterWrapper>
            <TasksFilter tasksListBlockRef={tasksListRef} />
          </TasksFilterWrapper>
        </Header>
        <Footer data-test-id={'tasks-container-footer'} ref={tasksListRef}>
          <TasksList showCulture={true} />
          {!tasksStore.tasksMap.size && <NoTasksPlaceholderAfterFilter />}
          {showCreateTaskForm && (
            <TasksEdit
              onCloseDialog={() => setShowCreateTaskForm(false)}
              onCancel={() => setShowCreateTaskForm(false)}
              taskId={null}
            />
          )}
        </Footer>
        <ModalContainer uniqueKey={tasksModalUniqueKey} $size={EModalSize.Medium} />
      </Wrapper>
    )
  );
});
