import { FC, ChangeEvent, useState, useRef, ReactElement } from 'react';
import { Input, Icon, Link as LinkFarmik } from '@farmlink/farmik-ui';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import { Colors } from '../../constans/colors';
import { useStore } from '../../utils/IoC';
import MapStore from '../../../map/stores/map.store';

import { SearchWrapper, BtnSearchWrapper, InputSearchWrapper } from './style';

export const GoogleSearch: FC = () => {
  const [closed, setClosed] = useState(true);
  const searchInput = useRef(null);
  const mapStore = useStore(MapStore);
  const firstElemSuggestion: React.MutableRefObject<HTMLLIElement> = useRef();
  // search code
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const toggleClosed = () => {
    inputClear();
    clearSuggestions();
    setClosed(prev => !prev);
  };

  const registerRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    if (!closed) toggleClosed();
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const inputClear = () => {
    // Update the keyword of the input element
    setValue('');
  };

  const handleSelect = ({ description }: { description: string }) => (): void => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    // clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('📍 Coordinates: ', { lat, lng });
        mapStore.setCoordinates(lat, lng);
      })
      .catch(error => {
        console.log('😱 Error: ', error);
      });
    toggleClosed();
  };

  const enterHandler = e => {
    e.which = e.which || e.keyCode;
    if (e.which == 13 && data.length) {
      firstElemSuggestion.current.click();
    }
  };

  const renderSuggestions = (): JSX.Element[] =>
    data.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      if (index === 0) {
        return (
          <li
            key={place_id}
            onClick={handleSelect(suggestion)}
            ref={firstElemSuggestion}
            data-test-id="search-suggestion-item"
          >
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      } else {
        return (
          <li key={place_id} onClick={handleSelect(suggestion)} data-test-id="search-suggestion-item">
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      }
    });
  // search code

  return (
    <SearchWrapper ref={registerRef} data-test-id="search-input-component">
      <InputSearchWrapper
        className={
          (closed ? 'closed ' : '') +
          (status === 'OK' ? ' autocomplete' : '') +
          (status === 'ZERO_RESULTS' ? ` autocomplete_${status}` : '')
        }
        >
        <div className={'inner_wrapper'}>
          <BtnSearchWrapper
            onClick={toggleClosed}
            className={
              (!closed ? 'opened' : 'closed') +
              (value.length ? ' not-empty-text' : '') +
              (status === 'OK' ? ' autocomplete' : '')
            }
            data-test-id="search-toggle-btn"
            >
            <Icon
              icon="search"
              size="large"
              fill={Colors.black}
              onClick={() => searchInput.current.focus()}
            ></Icon>
          </BtnSearchWrapper>

          <input
            type="text"
            value={value}
            ref={searchInput}
            onChange={handleInput}
            onKeyUp={enterHandler}
            onClick={() => searchInput.current.focus()}
            disabled={!ready}
            placeholder="Введите адрес"
            data-test-id="search-input"
          />

          {value.length ? (
            <div
              className={'close_btn'}
              onClick={() => {
                inputClear();
                searchInput.current.focus();
              }}
              data-test-id="search-input-clear"
            ></div>
          ) : (
            ''
          )}
        </div>

        {status === 'OK' && <ul className={status}>{renderSuggestions()}</ul>}
        {value.length && status === 'ZERO_RESULTS' ? (
          <div className={status} data-test-id="search-suggestions-zero_results">К сожалению, мы ничего не смогли найти</div>
        ) : (
          ''
        )}
      </InputSearchWrapper>
    </SearchWrapper>
  );
};
