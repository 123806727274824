import styled, { css, keyframes } from 'styled-components';

import { Colors } from '../../../shared/constans/colors';
import { FontSizes, BorderRadius } from '../../../shared/constans/sizes';
import { ESidebarWidth } from '../../stores/ui.store';

const showWrapper = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.backgroundSecondaryGray};
  display: grid;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1006;
  /* animation: ${showWrapper} 0.3s ease-in; */
  grid-template-columns: minmax(1px, 1fr);
  grid-template-rows: auto minmax(1px, 1fr);
  // z-index: 10100;
`;

export const StyledFullscreenWrapper = styled.div<{ $isShort: boolean }>`
  min-width: ${({ $isShort }) =>
    $isShort ? `calc(100% - ${ESidebarWidth.SHORT})` : `calc(100% - ${ESidebarWidth.LARGE})`};
  max-width: ${({ $isShort }) =>
    $isShort ? `calc(100% - ${ESidebarWidth.SHORT})` : `calc(100% - ${ESidebarWidth.LARGE})`};
  height: 100%;
  background: ${Colors.backgroundSecondaryGray};
  display: grid;
  flex-direction: column;
  position: fixed;
  left: ${({ $isShort }) => ($isShort ? ESidebarWidth.SHORT : ESidebarWidth.LARGE)};
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1006;
  grid-template-columns: minmax(1px, 1fr);
  grid-template-rows: auto minmax(1px, 1fr);
  transition: 1s;
  &.header_hidden {
    transform: translateY(-72px);
    height: calc(100vh + 72px);
    padding-bottom: 96px;
  }
`;

export const Footer = styled.div`
  padding: 20px 0 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    width: 100%;
  }
  overflow-y: auto;
  margin: 0 24px;
`;

export const StyledFullscreenFooter = styled.div`
  padding: 20px 0 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    width: 100%;
  }
  overflow-y: auto;
  margin: 0 24px;
`;

export const OperationsListWrapper = styled.div`
  width: 400px;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  transition: 1s;
  &.header_hidden {
    transform: translateY(-100%);
  }
  width: 100%;
  min-height: 72px;
  /* flex-direction: column;
  justify-content: space-between;
  align-items: start; */
  padding: 14px 24px;
  /* margin-bottom: 16px; */
  border-bottom: 0.5px solid ${Colors.grayBorder};
  background: ${Colors.white};
  z-index: 100;
`;

export const FullScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 72px;
  padding: 14px 24px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  background: ${Colors.white};
  z-index: 100;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;

  .add-tasks-button {
    height: 40px;
    width: 160px;
    justify-content: center;
    border-radius: ${BorderRadius.small};
    ${FontSizes.mediumBold};
  }
`;
export const Label = styled.p`
  margin-top: 14px;
  color: ${Colors.black};
  white-space: nowrap;
  ${FontSizes.xxlargeBold};
`;

export const TasksFilterWrapper = styled.div`
  /* border-top: 0.5px solid ${Colors.grayBorder};
  border-bottom: 0.5px solid ${Colors.grayBorder};
  padding: 16px 0; */
`;
