import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import { OrganizationCard } from '../OrganizationCard';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { OrganizationListingStore } from '../../stores/organization.listing.store';
import { OrganizationInvitationStore } from '../../stores/organization.invitation.store';
import { Modal } from '../../../../../../../../components/Modal/style';
import { CreateOraganizationWizard } from '../../../../modules/organizations/components/CreateOraganizationWizard';
import { OrganizationRoutes } from '../../../../../organization/organization.routes';
import { ApplicationView } from '../ApplicationView';
import { ApplicationType } from '../../types/ApplicationType';
import { OrganizationCard as OrganizationCardType } from '../../types/OrganizationCard';
import { Grid, WidgetWrapper } from '../OrganizationList/style';
import { MyListsControlBar } from '../MyListsControllBar';
import { AddCard } from '../AddCard';

export const MyOrganizationsWidget: FC<RouteComponentProps> = observer(({ history }) => {
  const { fetchMyOrganizationCards, myOrganizations, idToMyApplication } = useStore(
    OrganizationListingStore
  );
  const {
    acceptInviteFromOrganization,
    declineInviteFromOrganization,
    acceptApplicationFromUser,
    declineApplicationFromUser,
  } = useStore(OrganizationInvitationStore);

  const [createOrganizationFormShowed, setCreateOrganizationFormShowed] = useState(false);
  const [chosenApplication, setChosenApplication] = useState<ApplicationType | null>(null);

  useEffect(() => {
    fetchMyOrganizationCards();
  }, [fetchMyOrganizationCards]);

  const handleClickOnOrganization = (item: OrganizationCardType) => (organizationId: string) => {
    if (item.type === 'participate') {
      history.push(`${OrganizationRoutes.main}/${organizationId}`);
    } else if (item.applicationId) {
      const application = idToMyApplication.get(item.applicationId);

      if (application) {
        setChosenApplication(application);
      }
    }
  };

  return (
    <WidgetWrapper>
      <MyListsControlBar />
      <Grid>
        <AddCard
          onClick={() => {
            setCreateOrganizationFormShowed(true);
          }}
        />
        {myOrganizations &&
          myOrganizations.map(item => (
            <OrganizationCard
              organizationId={item.organizationId}
              title={item.title}
              roleName={item.roleName}
              type={item.type}
              onClick={handleClickOnOrganization(item)}
              avatar={item.avatar}
              key={`${item.organizationId}-${item.type}`}
            />
          ))}
      </Grid>
      <Modal
      // shown={createOrganizationFormShowed}
      // hideModal={() => {
      //   setCreateOrganizationFormShowed(false);
      // }}
      >
        <CreateOraganizationWizard
          hideWizard={() => {
            setCreateOrganizationFormShowed(false);
            fetchMyOrganizationCards();
          }}
        />
      </Modal>
      {chosenApplication && (
        <Modal
        // shown={Boolean(chosenApplication)}
        // hideModal={() => {
        //   setChosenApplication(null);
        // }}
        >
          <ApplicationView
            hideApplicationView={() => {
              fetchMyOrganizationCards();
              setChosenApplication(null);
            }}
            application={chosenApplication}
            acceptInviteFromOrganization={acceptInviteFromOrganization}
            declineInviteFromOrganization={declineInviteFromOrganization}
            acceptApplicationFromUser={acceptApplicationFromUser}
            declineApplicationFromUser={declineApplicationFromUser}
          />
        </Modal>
      )}
    </WidgetWrapper>
  );
});
