import styled from 'styled-components';

import { FontSizes } from '../../modules/shared/constans/sizes';
import { Colors } from '../../modules/shared/constans/colors';

export const DeviceScreenDummyElem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(21, 31, 50, 0.8);
    backdrop-filter: blur(16px);
    z-index: 100001;
    ${FontSizes.xlargeBold}
    color: ${Colors.white};
    img {
      margin-bottom: 24px;
    }
`;
 