import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';
import { BorderRadius, Breakpoints } from '../../../../../../../shared/constans/sizes';

export const ModalHeader = styled.div`
  font-size: 16px;
  border-bottom: 1px solid ${Colors.grayBorder};
  padding-bottom: 15px;
  margin-bottom: 32px;
  position: relative;

  & > span {
    position: absolute;
    top: 1px;
    right: 0;
    display: flex;
    color: ${Colors.red};
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    cursor: pointer;
  }

  & > span > div {
    margin-left: 5px;
  }
`;

export const Orgtitle = styled.div`
  font-size: 16px;
  margin-bottom: 28px;
`;

export const Comment = styled.div`
  border: 1px solid ${Colors.grayBorder};
  border-radius: ${BorderRadius.small};
  font-size: 12px;
  padding: 12px 16px;
  min-height: 110px;
  margin-bottom: 24px;
  margin-top: 30px;
`;

export const ActionsWrapper = styled.div`
  display: flex;

  & a:nth-child(2),
  & button:nth-child(2) {
    margin-left: 16px;
  }

  & button {
    justify-content: center;
    cursor: pointer;
  }
  @media (max-width: ${Breakpoints.small}) {
    flex-direction: column;
    & button:nth-child(2) {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`;

export const InfoTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 50px;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  margin: 30px auto 40px;
  display: flex;
  justify-content: center;
`;

export const ListWrapper = styled.div`
  line-height: 21px;
`;
