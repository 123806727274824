import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 16px;
    & > img {
        height: 64px;
        width: 64px;
    }
`;

export const Header1 = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #151F32;
    margin-top: 24px;
`;

export const Header2 = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #9AA0AD;
    margin-top: 12px;
`;
