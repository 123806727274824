import { useMemo } from 'react';

import { TUseDropdownCreateTextByMatch } from './useDropdownCreateTextByMatch.types';

const useDropdownCreateTextByMatch: TUseDropdownCreateTextByMatch = ({
  option,
  searchValue,
  isSearch,
  isCreateFullMatch,
}) => {
  const matchString = useMemo(() => {
    const emptyValue = {
      firstLabelPart: '',
      lastLabelPart: '',
    };

    if (!option) {
      return emptyValue;
    }

    if (!isSearch) {
      return emptyValue;
    }

    const regExpSearch = new RegExp(searchValue, 'i');

    const foundPos = option.label.search(regExpSearch);

    if (foundPos !== 0) {
      return emptyValue;
    }

    const firstLabelPart = option.label.match(regExpSearch);

    const lastLabelPart = option.label.split(firstLabelPart?.[0])?.join('');

    if (isCreateFullMatch) {
      return {
        firstLabelPart: searchValue,
        lastLabelPart,
      };
    }
  }, [option, searchValue, isSearch, isCreateFullMatch]);

  return matchString;
};

export default useDropdownCreateTextByMatch;
