import { Colors } from '../../constans/colors';

export const reactMultySelectCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#9AA0AD',
    backgroundColor: 'transparent',
    background: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    margin: '0',
    paddingLeft: '16px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    margin: '0',
    color: '#9AA0AD',
    paddingLeft: '8px',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#FAF9FD',
    border: `0.5px solid`,
    borderRadius: '12px',
    outline: 'none',
    boxShadow: 'none',
    borderColor: `${state.menuIsOpen ? '#9AA0AD' : '#E9E6F0'} !important`,
    height: '40px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#9AA0AD !important',
    },
  }),

  valueContainer: provided => ({
    ...provided,
    flexWrap: 'nowrap',
  }),
  multiValue: provided => ({
    ...provided,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingRight: '0',
  }),
  menu: provided => ({
    ...provided,
    marginTop: '-10px',
    boxShadow: 'none',
    backgroundColor: '#FAF9FD',
    border: '0.5px solid #9AA0AD',
    borderTop: 'none',
    borderRadius: '12px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    paddingRight: '6px',
  }),
};

export * from './ReactMultySelect';
