import React from 'react';
import { makeAutoObservable, reaction } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { OperationCulture } from '../../../../../api/models/operations/operation.culture';
import { Operation } from '../../../../../api/models/operations/operation.model';
import { SeasonsStore } from '../../../stores/seasons.store';
import { OperationField } from '../../../../../api/models/operations/operation.field.model';
import { OperationType } from '../../../../../api/models/operations/operation.type.model';
import { TasksController } from '../controllers/tasks.controller';

import { SeasonCultureStore } from './season.culture.store';


export const OperationsPageSizeDefault = 20;
 
@provide.singleton()
export class OperationsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(SeasonCultureStore)
  protected seasonCulture: SeasonCultureStore;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  @lazyInject(TasksController)
  protected tasksController: TasksController;

  constructor() {
    makeAutoObservable(this);
  }

  selectedOperationId = '';
  selectedOperationTypeId = '';
  selectedOperationFieldId = '';
  isLoading = false;
  OperationCulturesInFields: OperationCulture[] = [];
  culturesLoaded = false;
  OperationsList: Array<Operation> = [];
  OperationFieldsList: Array<OperationField> = [];
  OperationsType: Array<OperationType> = [];
  OperationsTypeForFilter: Array<OperationType> = [];
  DefaultOperationsTypeForFilter: Array<OperationType> = [];
  fieldsWithoutCulturesCount = 0;

  operationPageNumber = 0;
  operationTotalPages = 0;
  totalOperationsNumber = 0;
  

  selectOperationId = (operationId: string) => {
    if (this.selectedOperationId === operationId) {
      this.selectedOperationId = '';
    } else {
      this.selectedOperationId = operationId;
    }
  };

  selectOperationTypeId = (operationTypeId: string) => {
    this.selectedOperationTypeId = operationTypeId;
  };

  selectOperationFieldId = (operationFieldId: string) => {
    if (this.selectedOperationFieldId === operationFieldId) {
      this.selectedOperationFieldId = '';
    } else {
      this.selectedOperationFieldId = operationFieldId;
    }
  };

  fetchOperationsFieldsList = async () => {
    if(!this.selectedOperationId) return;
    const organizationId =
      this.organizations.selectedOrganizationId === 'my'
        ? ''
        : this.organizations.selectedOrganizationId;

    let response: TypeApiResponse<'getOperationFields'> | null = null;
    try {
      this.isLoading = true;
      response = await this.axios.api.getOperationFields({
        organizationId,
        operationId: this.selectedOperationId,
      });
    } catch (e) {
      this.isLoading = false;
      return;
    }

    this.OperationFieldsList = response;
  };

  resetOperationsFieldsList = () => {
    this.OperationFieldsList = [];
  };

  resetOperationCulturesInFields = () => {
    this.OperationCulturesInFields = [];
    this.fieldsWithoutCulturesCount = 0;
  };

  fetchCulturesList = async () => {
    const organizationId =
      this.organizations.selectedOrganizationId === 'my'
        ? undefined
        : this.organizations.selectedOrganizationId;

    let response: TypeApiResponse<'getSeasonCulture'> | null = null;
    try {
      this.isLoading = true;
      response = await this.axios.api.getSeasonCulture(
        {
          organizationId,
          year: Number(this.seasons.selectedSeason),
        },
        { omit: ['year'] }
      );
    } catch (e) {
      this.isLoading = false;
      this.culturesLoaded = true;
      return;
    }

    this.isLoading = false;
    this.OperationCulturesInFields = response.cultureAndVarietyList;
    this.fieldsWithoutCulturesCount = response.fieldsWithoutCulturesCount;
    this.culturesLoaded = true;
    console.log('*******************fetchCulturesList done!!!');
    console.log(this.OperationCulturesInFields.length);
  };

  getCulturesList = async () => {
    console.log('getCulturesList');
    await this.fetchCulturesList();
    return this.OperationCulturesInFields;
  };

  createOperation = async (data: TypeApiRequest<'createOperation'>) => {
    let response: TypeApiResponse<'createOperation'> | null = null;
    try {
      this.isLoading = true;
      response = await this.axios.api.createOperation(data);
    } catch (e) {
      this.isLoading = false;
      return Promise.reject(e);
    }
    this.resetPageNumberSettings();
    this.getOperations();
    this.isLoading = false;
  };

  editOperation = async (data: TypeApiRequest<'editOperation'>) => {
    try {
      this.isLoading = true;
      await this.axios.api.editOperation(data);
    } catch (e) {
      this.isLoading = false;
      return Promise.reject(e);
    }
    
    this.isLoading = false;
  };

  deleteOperation = async (data: TypeApiRequest<'deleteOperation'>) => {
    try {
      this.isLoading = true;
      await this.axios.api.deleteOperation(data);
      this.resetPageNumberSettings();
      this.getOperations();
    } catch (e) {
      this.isLoading = false;
      return Promise.reject(e);
    }
    this.isLoading = false;
  };

  resetPageNumberSettings = () => {
    this.operationPageNumber = 0;
    this.operationTotalPages = 0;
    this.totalOperationsNumber = 0;
  }

  getOperations = async (concat?: boolean) => {
    const organizationId =
      this.organizations.selectedOrganizationId === 'my'
        ? ''
        : this.organizations.selectedOrganizationId;

    let response: TypeApiResponse<'getOperations'> | null = null;
    try {
      this.isLoading = true;
      response = await this.axios.api.getOperations({
        organizationId,
        seasonYear: Number(this.seasons.selectedSeason),
        cultureId:
          this.seasonCulture.selectedCultureId === 'emptyCulture'
            ? undefined
            : this.seasonCulture.selectedCultureId,
        noCulture: this.seasonCulture.selectedCultureId === 'emptyCulture',
        page: this.operationPageNumber,
        size: OperationsPageSizeDefault,
      });
      
    } catch (e) {
      this.isLoading = false;
      return;
    }
    if (concat) {
      this.OperationsList = this.OperationsList.concat(response.content);
      this.operationPageNumber++;
    } else {
      this.OperationsList = response.content;
      this.operationPageNumber = 1;
    }
    
    this.operationTotalPages = response.totalPages;
    this.totalOperationsNumber = response.totalElements;
    this.isLoading = false;
  };

  getOperationsType = async (cultureId?: string) => {
    console.log('**********getOperationsType');
    let response: TypeApiResponse<'getOperationsType'> | null = null;
    try {
      this.isLoading = true;
      response = await this.axios.api.getOperationsType({
        cultureId: cultureId !== 'emptyCulture' ? cultureId : undefined,
        noCulture: cultureId === 'emptyCulture' ? true : undefined,
      });
    } catch (e) {
      this.isLoading = false;
      return;
    }
    console.log(response);
    this.OperationsType = response.content;
    this.isLoading = false;
  };

  getOperationsTypeForFilter = async (cultureId?: string, saveAsDefault?: boolean) => {
    console.log('**********getOperationsType');
    let response: TypeApiResponse<'getOperationsType'> | null = null;
    try {
      this.isLoading = true;
      response = await this.axios.api.getOperationsType({
        cultureId: cultureId !== 'emptyCulture' ? cultureId : undefined,
        noCulture: cultureId === 'emptyCulture' ? true : undefined,
      });
    } catch (e) {
      this.isLoading = false;
      return;
    }
    console.log(response);
    this.OperationsTypeForFilter = response.content;
    if (saveAsDefault) this.DefaultOperationsTypeForFilter = response.content;
    this.isLoading = false;
  };

  get getOperationsTypeSelectAdapter() {
    return this.OperationsType.map(type => ({
      label: type.name,
      value: type.id,
    }));
  }

  get getDefaultOperationsTypeForFilterAdapter() {
    return this.DefaultOperationsTypeForFilter.map(type => ({
      label: type.name,
      value: type.id,
    }));
  };

  resetOperationsTypeForFilter() {
    this.OperationsTypeForFilter = this.DefaultOperationsTypeForFilter;
  }

  clearOperationsStore = () => {
    this.selectedOperationId = '';
    this.selectedOperationTypeId = '';
    this.selectedOperationFieldId = '';
    this.isLoading = false;
    this.OperationCulturesInFields = [];
    this.OperationsList = [];
    this.OperationFieldsList = [];
    this.OperationsType = [];
    this.fieldsWithoutCulturesCount = 0;
  };
}
