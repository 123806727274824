import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';

export const CreateOraganizationWizardWrapper = styled.div`
  position: relative;

  & > img {
    margin: 0 auto;
    display: block;
  }
`;

export const CreateOraganizationWizardHeader = styled.div`
  font-weight: bold;
  font-size: 32px;
  position: relative;
  margin-bottom: 36px;

  & > span {
    text-align: center;
    font-size: 24px;
  }
`;

export const CreateOraganizationWizardStepCurrent = styled.span`
  font-weight: bold;
  font-size: 32px;
`;

export const CreateOraganizationWizardStepTotal = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: ${Colors.darkGray};
  margin-left: 5px;
`;

export const CreateOraganizationWizardSteps = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const CreateOraganizationWizardFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`;

export const CreateOraganizationWizardActions = styled.div`
  margin-top: 4px;
`;

export const CreateOraganizationWizardInputsGrid = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 20px;
  margin-bottom: 24px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 10px;
  cursor: pointer;

  & button {
    justify-content: center;
  }
`;

export const ErrorLabel = styled.div`
  color: ${Colors.red};
  font-size: 12px;
  margin-bottom: 24px;
`;
