import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';
import { Breakpoints } from '../../../../../../../shared/constans/sizes';

export const WidgetWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1068px;
`;

export const Grid = styled.div<{ search?: boolean }>`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, minmax(168px, 1fr));
  grid-template-rows: repeat(auto-fill, 188px);
  grid-gap: 12px;

  & > div {
    height: 188px;
  }

  @media (min-width: 1300px) {
    & > div {
      max-width: 168px;
    }
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(5, minmax(100px, 1fr));
  }

  @media (max-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }

  @media (max-width: ${Breakpoints.small}) {
    padding: 20px 16px 0;
    ${({ search }) => search && 'margin-top: 0; padding-top: 20px;'}

    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;

export const AddCardAvatar = styled.div`
  width: 60px;
  height: 60px;
  margin-top: 32px;
  margin-bottom: 20px;
`;

export const AddCardText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  max-width: 120px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const AddCardWrapper = styled.div`
  cursor: pointer;
  border: 1px dashed ${Colors.grayBorder};
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 300ms;
  &:hover {
    & ${AddCardText} {
      color: ${Colors.green};
    }
    border: 1px dashed ${Colors.green};
    & svg rect {
      fill: ${Colors.green};
    }
  }
`;
