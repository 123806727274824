import { observer } from 'mobx-react';
import { FC, memo } from 'react';

import { useStore } from '../../../../../../shared/utils/IoC';
import { FullScreenMode, TasksStore } from '../../../../operations/stores/tasks.store';
import { ChecklistInstancesController } from '../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../stores/checklist.instances.store';
import { ListOfChecklistInstance } from '../ListOfChecklistInstance';

import { TTechniqueListContainerProps } from './TechniqueListContainer.types';

const TechniqueListContainer: FC<TTechniqueListContainerProps> = observer(({ onClick }) => {
  const tasksStore = useStore(TasksStore);
  const checklistStore = useStore(ChecklistInstancesStore);
  const checklistsController = useStore(ChecklistInstancesController);

  const handleAddButtonClick = async (): Promise<void> => {
    const createdInst = await checklistsController.createMachineryInst();

    if (createdInst) {
      checklistStore.setSelectedInstId(createdInst.id);

      checklistsController.enableChecklistEditMode();

      tasksStore.setFullScreenMode(FullScreenMode.ChecklistFullScreen);
    }
  };

  return (
    <ListOfChecklistInstance
      mode={tasksStore.fullScreenMode === FullScreenMode.TaskFullScreen ? 'fullscreen' : 'listing'}
      type="technique"
      title={{ view: 'Техника', fullscreen: 'У вас ещё нет добавленной техники' }}
      description={{
        view: 'В полноэкранном шаблоне создания задачи вы можете добавить используемую технику',
        fullscreen:
          'Добавьте рабочую технику, чтобы проверить корректность её настроек в любой момент',
      }}
      instList={checklistStore.techniqueList}
      addButton={{
        title: 'Добавить технику',
        onClick: onClick ? onClick : handleAddButtonClick,
      }}
    />
  );
});

TechniqueListContainer.displayName = 'TechniqueListContainer';

export default memo(TechniqueListContainer);
