import { EModalType, IModal } from '../../../../modals/components/Modal/Modal';
import { IModalConfig } from '../../../../modals/config';

export const addFieldModalUniqueKey = 'fields-add';

export enum EAddFieldModalName {
  UnfinishedContourWarning = 'UNFINISHED_CONTOUR_WARNING',
  IntersectionWarning = 'INTERSECTION_WARNING',
  OversizedAreaWarning = 'OVERSIZED_AREA_WARNING',
  WarningBeforeLeavingThePage = 'WARNING_BEFORE_LEAVING_THE_PAGE',
}

const unfinishedContourWarning: IModal = {
  name: EAddFieldModalName.UnfinishedContourWarning,
  title: `Вы уверены, что хотите покинуть экран?
  Все несохраненные данные будут потеряны`,
  type: EModalType.Warning,
  successButton: {
    title: 'Да, продолжить',
    color: 'primary',
  },
  denyButton: {
    title: 'Нет, отменить',
  },
};

const intersectionWarning: IModal = {
  name: EAddFieldModalName.IntersectionWarning,
  title: 'Границы полей не могут пересекаться. Измените границы для сохранения изменений!',
  type: EModalType.Warning,
  successButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const oversizedAreaWarning: IModal = {
  name: EAddFieldModalName.OversizedAreaWarning,
  title:
    'Площадь поля не может быть больше 1000га. Уменьшите площадь поля для сохранения изменений!',
  type: EModalType.Warning,
  denyButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const warningBeforeLeavingThePage: IModal = {
  name: EAddFieldModalName.WarningBeforeLeavingThePage,
  title: 'Вы уверены, что хотите покинуть экран? Все несохраненные данные будут потеряны',
  type: EModalType.Warning,
  denyButton: {
    title: 'Нет, отменить',
  },
  successButton: {
    title: 'Да, продолжить',
    color: 'primary',
  },
};

export const addFieldModalConfig: IModalConfig = {
  [addFieldModalUniqueKey]: [
    unfinishedContourWarning,
    intersectionWarning,
    warningBeforeLeavingThePage,
    oversizedAreaWarning,
  ],
};
