import styled from 'styled-components';

import { Colors } from '../../constans/colors';

export const Wrapper = styled.div`
  & input {
    letter-spacing: 10px;
  }
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const CodeRepeatWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CodeRepeatMock = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const CodeDescription = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const CodeError = styled(CodeDescription)`
  color: ${Colors.red};
`;

export const CodeSendAgain = styled.div`
  & p {
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
  margin: 0;
  color: ${Colors.green};
`;
