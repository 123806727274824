import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { isObject } from 'lodash';
import moment from 'moment';

import { useStore } from '../../../shared/utils/IoC';
// import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { UiStore } from '../../stores/ui.store';
import { WeatherConditionToIcon } from '../../utils/WeatherConditionToIcon';
import { WeatherEngToRus } from '../../utils/WeatherEngToRus';
import { WindDirectionEngToRus } from '../../utils/WindDirectionEngToRus';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { FieldsController } from '../../modules/fields/controllers/fields.controller';

import { WeatherStore } from './../../stores/weather.store';
import { FieldWeatherWrapper, Label, Content, Panel } from './style';
import more_details_img from './assets/more_details.svg';
import go_back_img from './assets/go_back.svg';
import geopoint_img from './assets/geopoint.svg';
import sunrise_img from './assets/sunrise.svg';
import sunset_img from './assets/sunset.svg';
import clear_img from './assets/clear.svg';
import humidity_img from './assets/humidity.svg';
import thunder_img from './assets/thunder.svg';
import dewpoint_img from './assets/dewpoint.svg';
import pressure_img from './assets/pressure.svg';
import windspeed_img from './assets/windspeed.svg';
import winddirection_img from './assets/winddirection.svg';
import wind_gust from './assets/windgust.svg';
import moch from './moch.json';

type FieldWeatherProps = { 
  back_btn: boolean;
  forWeatherPage?: boolean;
};

type fieldWeather = {
  fact: any;
  forecasts: any;
  geoObject: geoObject;
};

type geoObjectUnit = { id: number; name: string };

type geoObject = {
  country: geoObjectUnit;
  district: geoObjectUnit;
  locality: geoObjectUnit;
  province: geoObjectUnit;
};

const currentGeoPoint = (geoPoint: geoObject): string => {
  if (!isObject(geoPoint) || !Object.keys(geoPoint).length) return 'Не определено';
  let result = 'Не определено';

  const geoQueue = ['locality', 'district', 'province', 'country'];

  for (const key of geoQueue) {
    if (key in geoPoint && geoPoint[key] && geoPoint[key].name) {
      result = geoPoint[key].name;
      return result;
    }
  }

  return result;
};

export const FieldWeather: FC<FieldWeatherProps> = observer(({ back_btn, forWeatherPage }) => {
  const weatherStore = useStore(WeatherStore);
  const uiStore = useStore(UiStore);
  const fieldStore = useStore(FieldsStore);
  const fieldsController = useStore(FieldsController);

  console.log(weatherStore);
  // useEffect(() => {
  //   fieldsController.renderAvailableFields();
  // }, []);
  const today = weatherStore.weatherById;

  const precTypeMap = (id: number) => {
    return ['без осадков', 'дождь', 'дождь со снегом', 'снег', 'град'][Number(id)];
  };

  const nowIsDayTime = (sr: string, ss: string): boolean => {
    const now = moment({
      h: new Date().getHours(),
      m: new Date().getMinutes(),
    });
    if (!/^\d{2}:\d{2}$/.test(sr) || !/^\d{2}:\d{2}$/.test(ss)) return;
    const _sunrise = moment({
      h: Number(sr.split(':')[0]),
      m: Number(sr.split(':')[1]),
    });
    const _sunset = moment({
      h: Number(ss.split(':')[0]),
      m: Number(ss.split(':')[1]),
    });
    return now.isBetween(_sunrise, _sunset);
  };

  return (
    <FieldWeatherWrapper
      isFullWeather={back_btn}
      data-test-id={back_btn ? 'weather-widget-full' : 'weather-widget-mini'}
    >
      <Label isFullWeather={back_btn}>
        {back_btn && (
          <p
            className="go_back"
            onClick={() => {
              uiStore.setFullWeatherMode(false);
            }}
            data-test-id="close-full-weather"
          >
            <img src={go_back_img} alt="" /> Назад
          </p>
        )}
        <p className="weather_condition">{WeatherEngToRus(today?.fact?.condition)}</p>
        {!back_btn && (
          <p
            className="more_details"
            onClick={() => {
              uiStore.setFullWeatherMode(true);
            }}
            data-test-id="open-full-weather"
          >
            Подробнее <img src={more_details_img} alt="" />
          </p>
        )}
      </Label>
      <Content isFullWeather={back_btn}>
        <Panel isFullWeather={back_btn} className="left_panel">
          <div className="geopoint">
            <img src={geopoint_img} alt="" />
            {/* Село Валабуевка, МО */}
            {currentGeoPoint(today?.geoObject)}
          </div>
          <div className="sunset_sunrise">
            <div className="sunrise">
              <img src={sunrise_img} alt="" />
              {today?.forecasts[0]?.sunrise ? today?.forecasts[0]?.sunrise : '–'}
            </div>
            <div className="sunset">
              <img src={sunset_img} alt="" />
              {today?.forecasts[0]?.sunset ? today?.forecasts[0]?.sunset : '–'}
            </div>
          </div>
          <div className="condition">
            <img
              src={WeatherConditionToIcon(
                today?.fact?.condition,
                nowIsDayTime(today?.forecasts[0]?.sunrise, today?.forecasts[0]?.sunset) ? 12 : 0
              )}
              alt=""
            />
            <div className="temperature">
              <div className="temperature_current">{today?.fact?.temp}°С</div>
              <div className="temperature_feelslike">Ощущается как {today?.fact?.feelsLike}°С</div>
            </div>
          </div>
        </Panel>
        <Panel
          isFullWeather={back_btn}
          className={`right_panel ${forWeatherPage ? `for-weather-page` : ``}`}
        >
          <div className="current_state">
            <div className="label">
              <img src={humidity_img} alt="" />
              <span>Влажность</span>
            </div>
            <div className="value">{today?.fact?.humidity} %</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={thunder_img} alt="" />
              <span>Осадки</span>
            </div>
            <div className="value">{precTypeMap(Number(today?.fact?.precType))}</div>
          </div>
          {/* <div className="current_state">
            <div className="label">
              <img src={dewpoint_img} alt="" />
              <span>Точка росы</span>
            </div>
            <div className="value">{today?.fact?.dewPoint}°С</div>
          </div> */}
          <div className="current_state">
            <div className="label">
              <img src={pressure_img} alt="" />
              <span>Давление</span>
            </div>
            <div className="value">{today?.fact?.pressureMm} мм рт.ст.</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={windspeed_img} alt="" />
              <span>Скорость ветра</span>
            </div>
            <div className="value">{today?.fact?.windSpeed} м/с</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={winddirection_img} alt="" />
              <span>Направление ветра</span>
            </div>
            <div className="value">{WindDirectionEngToRus(today?.fact?.windDirection)}</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={wind_gust} alt="" />
              <span>Порывы ветра</span>
            </div>
            <div className="value">{today?.fact?.windGust} м/с</div>
          </div>
        </Panel>
      </Content>
    </FieldWeatherWrapper>
  );
});
