import { FC, useEffect } from 'react';
import { Switch, Route, generatePath } from 'react-router-dom';
import { useHistory } from 'react-router';

import { FieldsRoute } from './fields.route';
import { FieldsListing } from './containers/listing/fields.listing';
import { FieldsAdd } from './containers/add/fields.add';
import { FieldsEdit } from './containers/edit/fields.edit';
import FieldsImportContainer from './containers/import/FieldsImportContainer';

export const FieldsRouter: FC<{ orgId: string }> = ({ orgId }) => {
  const { location } = useHistory();

  const isListing =
    location.pathname === generatePath(FieldsRoute.Listing, { orgId }) ||
    location.pathname === `${generatePath(FieldsRoute.Listing, { orgId })}/`;

  useEffect(() => {
    console.log('FieldsRouter mounted shit');
  }, []);

  return (
    <>
      <Switch>
        <Route path={generatePath(FieldsRoute.Add, { orgId })} component={() => <FieldsAdd />} />
        <Route
          path={`${generatePath(FieldsRoute.Edit, { orgId })}/:id`}
          component={() => <FieldsEdit />}
        />
        <Route
          path={`${generatePath(FieldsRoute.Import, { orgId })}`}
          component={() => <FieldsImportContainer />}
        />
      </Switch>
      <FieldsListing visible={isListing} />
    </>
  );
};
