import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { Breakpoints } from '../../../../../shared/constans/sizes';

export const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 32px;
  background: ${Colors.white};
`;

export const Header = styled.div`
  padding-bottom: 16px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  margin-bottom: 37px;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: ${Colors.black};
    margin: 0;
  }
`;

export const InputWrapper = styled.form`
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;

  @media (max-width: ${Breakpoints.small}) {
    flex-direction: column;
  }
`;

export const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 16px;

  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
    margin-bottom: 24px;
    margin-right: 0;
  }
`;

export const CustomButton = styled.button`
  background: #3fb65f;
  border-radius: 12px;
  width: 100%;
  outline: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${Colors.white};
`;

export const SecondDiv = styled.div`
  width: 153px;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
  }
`;

export const ExpertiseWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export const Competition = styled.div`
  padding: 6px 12px;
  user-select: none;
  border: 0.5px solid ${Colors.grayBorder};
  color: ${Colors.darkGray};
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-top: 8px;
  margin-right: 8px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }
  background: ${Colors.gray};
`;

export const DeleteIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 9px;
  margin-left: 1px;
`;

export const OkIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 9px;
  margin-right: 4px;
`;

export const FooterButtonWrapper = styled.div`
  width: 160px;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 0.5px solid ${Colors.grayBorder};

  div {
    margin-left: 20px;
  }
`;
