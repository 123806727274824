import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { ECheckListInstanceType } from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { Dropdown } from '../../../../../../../../shared/components/inputs/Dropdown';
import { TDropdownConfig } from '../../../../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { Task } from '../../../../../../operations/stores/tasks.store';
import { Container } from '../../../../../components/Container';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../../../stores/checklist.instances.store';
import { DEFAULT_INTENSITY_OPTION } from '../../../../../utils/checklist.instances';

interface IIntensityProps {
  task: Partial<Task>;
  selectedInstId: string;
}

const Intensity: FC<IIntensityProps> = observer(({ task, selectedInstId }) => {
  const {
    isChecklistViewMode,
    selectedIntensity,
    intensityModalResult,
    setSelectedChecklist,
    getTechnique,
  } = useStore(ChecklistInstancesStore);

  const { selectedOrganizationId } = useStore(OrganizationsStore);

  const {
    intensityOptionList,
    intensityDefaultValue,
    fetchIntensityByTaskId,
    fetchChecklistByKeys,
    warnBeforeChangingIntensity,
    selectIntensity,
  } = useStore(ChecklistInstancesController);

  useEffect(() => {
    (async () => {
      if (selectedInstId) {
        if (task?.intensityRequired) {
          await fetchIntensityByTaskId(task.id);
        } else {
          const fetchedChecklist = await fetchChecklistByKeys({
            organizationId: selectedOrganizationId,
            operationTypeId: task?.operationInfo?.operationTypeId,
            cultureId: task?.operationInfo?.cultureId,
            machinery: Boolean(getTechnique(selectedInstId)),
          });

          if (fetchedChecklist) {
            setSelectedChecklist(fetchedChecklist);
          }
        }
      }
    })();
  }, [task, selectedInstId]);

  const handleIntensityChange = useCallback<TDropdownConfig['field']['onChange']>(
    (intensityId: string): void => {
      if (selectedIntensity) {
        warnBeforeChangingIntensity({ intensityId, taskId: task.id });
      } else {
        selectIntensity({ intensityId, taskId: task.id });
      }
    },
    [task, selectedIntensity]
  );

  const dropdownConfig: TDropdownConfig = {
    field: {
      onChange: handleIntensityChange,
      defaultValue: intensityDefaultValue,
      icons: {
        clear: {
          options: {
            valueAfterCleaning: DEFAULT_INTENSITY_OPTION,
          },
        },
      },
    },
    body: {
      optionList: intensityOptionList,
    },
    visual: {
      label: 'Выбранная фенофаза',
      isBlocked: isChecklistViewMode,
    },
    validation: {
      modal: {
        isShowWarning: Boolean(selectedIntensity),
        modalResult: intensityModalResult,
      },
    },
  };

  return (
    <>
      {task?.intensityRequired && (
        <Container title="Фенофаза">
          <StyledWrapper>
            <Dropdown config={dropdownConfig} />
          </StyledWrapper>
        </Container>
      )}
    </>
  );
});

const StyledWrapper = styled.div`
  flex: 1 1 70%;
  margin: 0 8px;
`;

export default Intensity;
