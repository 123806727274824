import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    padding: 24px;
`;

export const PointTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #E9E6F0;
`;

export const TestWrapper = styled.div`
    width: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
    margin: 24px 0px;
`;
