import { Select } from '@farmlink/farmik-ui';
import { FC, memo, useCallback, useMemo } from 'react';

import { lazyInject, useStore } from '../../../../../../shared/utils/IoC';
import { FieldsStore } from '../../../../fields/stores/fields.store';
import { ChecklistInstancesStore } from '../../../../operationsAndTasks/stores/checklist.instances.store';
import { TaskEditController } from '../../../../operations/controllers/task.edit.controller';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';
import { PointsMapStore } from '../../../stores/pointsMap.store';

interface IProps {
  operationFields: TasksEditStore['operationFields'];
  task: TasksEditStore['task'];
  disabled: boolean;
  blocked?: boolean;
  setTask: TaskEditController['setTask'];
}

const FieldSelect: FC<IProps> = props => {
  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const pointsMapStore = useStore(PointsMapStore);
  const fieldsStore = useStore(FieldsStore);

  const { operationFields, task, disabled, setTask, blocked } = props;

  const getOperationFieldsSelectOptions = useMemo(() => {
    return operationFields.map(fieldItem => ({
      label: fieldItem.fieldName,
      value: fieldItem.fieldId,
    }));
  }, [operationFields]);

  const getOperationFieldSelectDefaultValue = useMemo(() => {
    const { fieldId } = task;

    return fieldId
      ? getOperationFieldsSelectOptions.find(fieldOption => fieldOption.value === fieldId)
      : { label: null, value: null };
  }, [task.fieldId, operationFields]);

  const handleChange = useCallback(
    (fieldId: string): void => {
      checklistInstancesStore.positionToInstance = new Map();
      checklistInstancesStore.setHasPositionToInstanceChanged(false);
      Array.from(pointsMapStore.idToMarker.keys()).forEach(markerKey => {
        pointsMapStore.idToMarker.get(markerKey).remove();
      });
      pointsMapStore.idToMarker = new Map();
      checklistInstancesStore.setField(fieldsStore.fields.find(field => field.id === fieldId));
      setTask({
        ...task,
        fieldId,
        field: {
          id: fieldId,
        },
      });
    },
    [task]
  );

  return (
    <Select
      label="Поле"
      options={getOperationFieldsSelectOptions}
      onChange={handleChange}
      defaultValue={getOperationFieldSelectDefaultValue}
      disabled={disabled && !blocked}
      blocked={blocked}
      dataTestId={'field-select'}
    />
  );
};

export default memo(FieldSelect);
