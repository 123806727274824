import { FC, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Input, Select, Button, DatePicker, PhoneInput } from '@farmlink/farmik-ui';

import { PaperBlock } from '../../../../../shared/components/PaperBlock';
import { UserInfoType, GenderType } from '../../../../../../api/models/user.model';
import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { ProfileRoutes } from '../../routes';
import { AvatarUploader } from '../../components/AvatarUploader/AvatarUploader';
import { ProfileStore } from '../../stores/ProfileStore';
import { ProfileController } from '../../controllers/profile.controller';
import { useStore } from '../../../../../shared/utils/IoC';

import {
  EditPersonalDataWrapper,
  EditPersonalDataHeader,
  EditPersonalDataContent,
  EditPersonalDataFormColumn,
  EditPersonalDataEditInputWrapper,
  EditPersonalDataEditInputAction,
  EditPersonalDataEditFormAction,
  EditPersonalDataEditFormActionButton,
  DatePickerWrapper,
  TopBarWrapper,
  BottomBarWrapper,
  AvatarEditPersonalDataFormColumn,
  InvisibleRowWrapper,
  BlockWrapper,
  DesktopWrapper,
  MobileWrapper,
} from './style';
import { ChangeEmailWizard } from './components/ChangeEmailWizard';
import { ChangePhoneNumberWizard } from './components/ChangePhoneNumberWizard';


const gendersMap: {
  [key: string]: GenderType;
} = {
  MALE: {
    genderEnum: 'MALE',
    genderShort: 'муж',
    genderName: 'Мужской',
  },
  FEMALE: {
    genderEnum: 'FEMALE',
    genderShort: 'жен',
    genderName: 'Женский',
  },
};

export const EditPersonalData: FC = observer(() => {
  const history = useHistory();
  const profileStore = useStore(ProfileStore);
  const profileController = useStore(ProfileController);

  const [changePhoneNumberModal, setChangePhoneNumberModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);

  const {
    user,
    errorsFields,
  } = profileStore;

  const {
    changePersonalDataInStore,
    updatePersonalData,
    confirmPhoneNumber,
    checkSMSCode,
    confirmEmail,
    uploadFile,
  } = profileController;


  if (!user || !profileStore) {
    return <div>Нет данных профиля</div>;
  }

  const {
    lastName,
    firstName,
    secondName,
    phone,
    email,
    gender,
    dateOfBirth,
  } = user as UserInfoType;

  const handleChangePersonalData = (field: string) => (value: any) => {
    changePersonalDataInStore({
      ...user,
      [field]: value,
    });
  };

  const handleChangeGender = (value: string) => {
    changePersonalDataInStore({
      ...user,
      gender: gendersMap[value],
    });
  };

  const handleChangeDate = (value: Date) => {
    const day = value.getDate().toString();
    const month = (value.getMonth() + 1).toString();
    const dateToUpdate = `${day.length === 2 ? day : `0${day}`}.${
      month.length === 2 ? month : `0${month}`
    }.${value.getFullYear()}`;

    changePersonalDataInStore({
      ...user,
      dateOfBirth: dateToUpdate,
    });
  };

  const handleUpdatePersonalData = () => {
    updatePersonalData(history);
  };

  const handleCancel = () => {
    history.push(ProfileRoutes.Dashboard);
  };

  return (
    <BlockWrapper>
      <PaperBlock>
        <EditPersonalDataWrapper>
          <EditPersonalDataHeader>Редактирование личных данных</EditPersonalDataHeader>
          <EditPersonalDataContent>
            <TopBarWrapper>
              <AvatarEditPersonalDataFormColumn>
                <div>
                  <AvatarUploader
                    title={'профиля'}
                    data-test-id={'profile-avatar-uploader'}
                    icon={user.avatar && user.avatar.downloadUrl}
                    onUpload={uploadFile}
                  />
                </div>
              </AvatarEditPersonalDataFormColumn>
              <EditPersonalDataFormColumn techId={2}>
                <div>
                  <EditPersonalDataEditInputWrapper>
                    <PhoneInput
                      data-test-id={'profile-phone-input'}
                      disabled
                      label="Телефон"
                      error=""
                      // eslint-ignore-next-line
                      onChange={() => 1}
                      // @ts-ignore
                      defaultCountryCode={phone ? phone.phoneNumberCode : '+7'}
                      defaultValue={phone ? phone.phoneNumber : undefined}
                    />
                    <EditPersonalDataEditInputAction
                      data-test-id={'profile-phone-input-change'}
                      onClick={() => {
                        setChangePhoneNumberModal(true);
                      }}
                    >
                      <span>Изменить</span>
                    </EditPersonalDataEditInputAction>
                  </EditPersonalDataEditInputWrapper>
                </div>
              </EditPersonalDataFormColumn>
              <EditPersonalDataFormColumn techId={3}>
                <div>
                  <EditPersonalDataEditInputWrapper>
                    <Input
                      disabled
                      label="E-mail"
                      error=""
                      value={email ? email.email : ''}
                      data-test-id={'profile-email-input'}
                    />
                    <EditPersonalDataEditInputAction
                      onClick={() => {
                        setChangeEmailModal(true);
                      }}
                      data-test-id={'profile-email-input'}
                    >
                      <span>Изменить</span>
                    </EditPersonalDataEditInputAction>
                  </EditPersonalDataEditInputWrapper>
                </div>
              </EditPersonalDataFormColumn>
            </TopBarWrapper>
            <DesktopWrapper>
              <BottomBarWrapper>
                <EditPersonalDataFormColumn>
                  <Input
                    data-test-id={'profile-lastname-input'}
                    label="Фамилия"
                    error={errorsFields.lastName}
                    value={lastName}
                    onChange={handleChangePersonalData('lastName')}
                  />
                  <DatePickerWrapper>
                    <DatePicker
                      data-test-id={'profile-datepicker'}
                      label="Дата рождения"
                      placeholder="Укажите дату"
                      onChange={handleChangeDate}
                      error={errorsFields.dateOfBirth}
                      value={dateOfBirth}
                      placement="bottom"
                    />
                  </DatePickerWrapper>
                </EditPersonalDataFormColumn>
                <EditPersonalDataFormColumn>
                  <Input
                    label="Имя"
                    data-test-id={'profile-firstname-input'}
                    error={errorsFields.firstName}
                    value={firstName}
                    onChange={handleChangePersonalData('firstName')}
                  />
                  <Select
                    label="Пол"
                    data-test-id={'profile-gender-select'}
                    error={errorsFields.gender}
                    onChange={handleChangeGender}
                    defaultValue={
                      gender
                        ? {
                            label: gender.genderName,
                            value: gender.genderEnum,
                          }
                        : undefined
                    }
                    options={[
                      {
                        label: 'Мужской',
                        value: 'MALE',
                      },
                      {
                        label: 'Женский',
                        value: 'FEMALE',
                      },
                    ]}
                  />
                </EditPersonalDataFormColumn>
                <EditPersonalDataFormColumn>
                  <Input
                    label="Отчество"
                    data-test-id={'profile-secondname-input'}
                    error={errorsFields.secondName}
                    value={secondName}
                    onChange={handleChangePersonalData('secondName')}
                  />
                </EditPersonalDataFormColumn>
              </BottomBarWrapper>
            </DesktopWrapper>
            <MobileWrapper>
              <BottomBarWrapper>
                <EditPersonalDataFormColumn>
                  <Input
                    data-test-id={'profile-lastname-input'}
                    label="Фамилия"
                    error={errorsFields.lastName}
                    value={lastName}
                    onChange={handleChangePersonalData('lastName')}
                  />
                  <Input
                    label="Имя"
                    data-test-id={'profile-firstname-input'}
                    error={errorsFields.firstName}
                    value={firstName}
                    onChange={handleChangePersonalData('firstName')}
                  />
                  <Input
                    label="Отчество"
                    data-test-id={'profile-secondname-input'}
                    error={errorsFields.secondName}
                    value={secondName}
                    onChange={handleChangePersonalData('secondName')}
                  />
                  <DatePickerWrapper>
                    <DatePicker
                      data-test-id={'profile-datepicker'}
                      label="Дата рождения"
                      placeholder="Укажите дату"
                      onChange={handleChangeDate}
                      error={errorsFields.dateOfBirth}
                      value={dateOfBirth}
                      placement="bottom"
                    />
                  </DatePickerWrapper>
                </EditPersonalDataFormColumn>
                <EditPersonalDataFormColumn>
                  <Select
                    label="Пол"
                    data-test-id={'profile-gender-select'}
                    error={errorsFields.gender}
                    onChange={handleChangeGender}
                    defaultValue={
                      gender
                        ? {
                            label: gender.genderName,
                            value: gender.genderEnum,
                          }
                        : undefined
                    }
                    options={[
                      {
                        label: 'Мужской',
                        value: 'MALE',
                      },
                      {
                        label: 'Женский',
                        value: 'FEMALE',
                      },
                    ]}
                  />
                </EditPersonalDataFormColumn>
                <EditPersonalDataFormColumn></EditPersonalDataFormColumn>
              </BottomBarWrapper>
            </MobileWrapper>

            <InvisibleRowWrapper>
              <EditPersonalDataFormColumn techId={9}>
                <div>
                  <EditPersonalDataEditInputWrapper>
                    <PhoneInput
                      data-test-id={'profile-phone-input'}
                      disabled
                      label="Телефон"
                      error=""
                      // eslint-ignore-next-line
                      onChange={() => 1}
                      // @ts-ignore
                      defaultCountryCode={phone ? phone.phoneNumberCode : '+7'}
                      defaultValue={phone ? phone.phoneNumber : undefined}
                    />
                    <EditPersonalDataEditInputAction
                      data-test-id={'profile-phone-input-change'}
                      onClick={() => {
                        setChangePhoneNumberModal(true);
                      }}
                    >
                      <span>Изменить</span>
                    </EditPersonalDataEditInputAction>
                  </EditPersonalDataEditInputWrapper>
                </div>
              </EditPersonalDataFormColumn>
              <EditPersonalDataFormColumn techId={10}>
                <div>
                  <EditPersonalDataEditInputWrapper>
                    <Input
                      disabled
                      label="E-mail"
                      error=""
                      value={email ? email.email : ''}
                      data-test-id={'profile-email-input'}
                    />
                    <EditPersonalDataEditInputAction
                      onClick={() => {
                        setChangeEmailModal(true);
                      }}
                      data-test-id={'profile-email-input'}
                    >
                      <span>Изменить</span>
                    </EditPersonalDataEditInputAction>
                  </EditPersonalDataEditInputWrapper>
                </div>
              </EditPersonalDataFormColumn>
              <div style={{ width: '148px' }} />
            </InvisibleRowWrapper>
          </EditPersonalDataContent>
          <EditPersonalDataEditFormAction>
            <EditPersonalDataEditFormActionButton>
              <Button
                type="button"
                color="default"
                onClick={handleCancel}
                data-test-id={'profile-cancel'}
              >
                Отменить
              </Button>
            </EditPersonalDataEditFormActionButton>
            <EditPersonalDataEditFormActionButton>
              <Button
                type="button"
                color="primary"
                onClick={handleUpdatePersonalData}
                data-test-id={'profile-save'}
              >
                Сохранить
              </Button>
            </EditPersonalDataEditFormActionButton>
          </EditPersonalDataEditFormAction>
        </EditPersonalDataWrapper>

        {changeEmailModal && (
          <ModalComponent
            onClose={() => {
              setChangeEmailModal(false);
            }}
          >
            <ChangeEmailWizard
              email={email ? email.email : ''}
              setChangeEmailModal={setChangeEmailModal}
              confirmEmail={confirmEmail}
            />
          </ModalComponent>
        )}

        {changePhoneNumberModal && (
          <ModalComponent
            onClose={() => {
              setChangePhoneNumberModal(false);
            }}
          >
            <ChangePhoneNumberWizard
              phoneNumberCode={phone ? phone.phoneNumberCode : '+7'}
              phoneNumber={phone.phoneNumber}
              setChangePhoneNumberModal={setChangePhoneNumberModal}
              confirmPhoneNumber={confirmPhoneNumber}
              checkSMSCode={checkSMSCode}
            />
          </ModalComponent>
        )}
      </PaperBlock>
    </BlockWrapper>
  );
});
