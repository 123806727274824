import { observer } from 'mobx-react';
import { FC } from 'react';

import * as Styled from './styles';

export type ICommonDataProps = {
  CultureElement: JSX.Element;
  OperationElement: JSX.Element;
  FieldElement: JSX.Element;
  AssigneeElement: JSX.Element;
  PlanDateStartElement: JSX.Element;
  PlanDateEndElement: JSX.Element;
  CommentElement: JSX.Element;
};

const CommonData: FC<ICommonDataProps> = observer(props => {
  const {
    CultureElement,
    OperationElement,
    FieldElement,
    AssigneeElement,
    PlanDateStartElement,
    PlanDateEndElement,
    CommentElement,
  } = props;

  return (
    <Styled.Form>
      <Styled.Col $variant="calc(50% - 8px) calc(50% - 8px)">
        <Styled.Row>{CultureElement}</Styled.Row>
        <Styled.Row>{OperationElement}</Styled.Row>
        <Styled.Row>{FieldElement}</Styled.Row>
        <Styled.Row>{AssigneeElement}</Styled.Row>
        <Styled.Row>{PlanDateStartElement}</Styled.Row>
        <Styled.Row>{PlanDateEndElement}</Styled.Row>
      </Styled.Col>
      <Styled.Col $variant="1fr">
        <Styled.Row>{CommentElement}</Styled.Row>
      </Styled.Col>
    </Styled.Form>
  );
});

export default CommonData;
