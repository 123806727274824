import { sortBy } from 'lodash';

import {
  IGetChecklistAttributeListPayload,
  IGetChecklistByTaskAndIntensityPayload,
  IPostAttributeValuesPayload,
  TGetChecklistAttributeByCheckListPublicIdPayload,
  TGetChecklistByKeysPayload,
  TGetDictListByRemoteNameReq,
} from '../../../../../api/api';
import {
  EChecklistAttributeType,
  IChecklistAttributeWithValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeEnumValue,
  IGetChecklistAttributeUserDictionary,
  IPutChecklistAttributeValue,
} from '../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IGetChecklist } from '../../../../../api/models/checklist/checklist.model';
import {
  ECheckListInstanceType,
  IDrawingNestedInstance,
  IGetChecklistInstance,
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import {
  IChecklistDrawingStage,
  IGetChecklistStage,
} from '../../../../../api/models/checklist/stage/checklist.stage.model';
import { IGetDictionary } from '../../../../../api/models/dictionary/dictionary.model';
import { IGetIntensity } from '../../../../../api/models/intensity/intensity.model';
import { ISelectOption } from '../../../../../types/selectOption';
import { displayModalWindow } from '../../../../modals/config';
import { ModalsStore } from '../../../../modals/store/modals.store';
import { Axios } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { TasksEditStore } from '../../operations/stores/task.edit.store';
import { TasksStore } from '../../operations/stores/tasks.store';
import { PointsMapStore } from '../../tasks/stores/pointsMap.store';
import {
  checklistModalUniqueKey,
  EChecklistModalName,
} from '../modules/fullscreen/checklist/modals/modalsConfig';
import {
  checklistAttrChangeHelpers,
  checklistAttrTypeHelpers,
  userDictLinkHelpers,
} from '../modules/fullscreen/checklist/utils/attribute/helpers';
import { ChecklistsService } from '../services/checklists/checklists.service';
import { ChecklistFileUploaderStore } from '../stores/checklist.fileUploader.store';
import { ChecklistInstancesStore, EChecklistMode } from '../stores/checklist.instances.store';
import {
  createListOfDepAttrVal,
  DEFAULT_INTENSITY_OPTION,
  ESaveInstanceAttributeValuesResults,
  generateAttributeWithValueList,
  generateDrawingNestedInstance,
  generateDrawingStage,
  getValidatedAttrWithValueList,
  THandleChangeChecklistAttributeValue,
} from '../utils/checklist.instances';

@provide.singleton()
export class ChecklistInstancesController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  @lazyInject(ChecklistInstancesStore)
  protected checklistInstancesStore: ChecklistInstancesStore;

  @lazyInject(ChecklistsService)
  protected checklistsService: ChecklistsService;

  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  @lazyInject(TasksEditStore)
  protected tasksEditStore: TasksEditStore;

  @lazyInject(PointsMapStore)
  protected pointsMapStore: PointsMapStore;

  @lazyInject(ChecklistFileUploaderStore)
  protected checklistFileUploaderStore: ChecklistFileUploaderStore;

  fetchInstanceData = async (selectedInstId: string): Promise<void> => {
    const {
      techniqueList,
      getInstanceByPosition,
      setSelectedChecklist,
      setSelectedInstance,
      setSelectedIntensity,
    } = this.checklistInstancesStore;

    // Избавляемся от выбранных элементов при смене точки
    this.clearStoreAfterChangedInstance();

    if (!selectedInstId) {
      return;
    }

    // Загружаем данные точки
    const instance = await this.fetchInstanceById(selectedInstId);

    if (instance) setSelectedInstance(instance);

    if (!instance?.checkListId) return;

    // Если у точки заполнен чек-лист, то получаем и его
    const fetchedChecklist = await this.fetchChecklistById(instance.checkListId);

    if (!fetchedChecklist) return;

    setSelectedChecklist(fetchedChecklist);

    // Если у чек-листа есть фенофаза, то делаем ее выбранной
    if (fetchedChecklist?.cultureIntensity) {
      setSelectedIntensity(fetchedChecklist.cultureIntensity);
    }
  };

  fetchInstanceById = async (instanceId: string): Promise<IGetChecklistInstance> => {
    const { getExtendedInstance, setExtendedInstance } = this.checklistInstancesStore;

    const { fetchInstanceById } = this.checklistsService;

    const previouslyFetchedInstance = getExtendedInstance(instanceId);

    if (previouslyFetchedInstance) return previouslyFetchedInstance;

    const fetchedInstance = await fetchInstanceById(instanceId);

    if (!fetchedInstance) return;

    setExtendedInstance(fetchedInstance);

    return fetchedInstance;
  };

  fetchChecklistByTaskAndIntensity = async (
    payload: IGetChecklistByTaskAndIntensityPayload
  ): Promise<IGetChecklist> => {
    const { checklistList, setChecklist } = this.checklistInstancesStore;

    const { fetchChecklistByTaskAndIntensity } = this.checklistsService;

    // const previouslyFetchedChecklist = checklistList?.find(
    //   checklist => checklist?.cultureIntensity?.id === payload.intensityId
    // );

    // if (previouslyFetchedChecklist) return previouslyFetchedChecklist;

    const fetchedChecklist = await fetchChecklistByTaskAndIntensity(payload);

    if (!fetchedChecklist) return;

    setChecklist(fetchedChecklist);

    return fetchedChecklist;
  };

  fetchChecklistByKeys = async (payload: TGetChecklistByKeysPayload): Promise<IGetChecklist> => {
    const { checklistList, setChecklist } = this.checklistInstancesStore;

    const { fetchChecklistByKeys } = this.checklistsService;

    // const previouslyFetchedChecklist = checklistList?.find(
    //   checklist =>
    //     checklist?.organizationId === payload?.organizationId &&
    //     checklist?.operationType?.id === payload?.operationTypeId &&
    //     checklist?.cultureId === payload?.cultureId
    // );

    // if (previouslyFetchedChecklist) return previouslyFetchedChecklist;

    const fetchedChecklist = await fetchChecklistByKeys(payload);

    if (!fetchedChecklist) return;

    setChecklist(fetchedChecklist);

    return fetchedChecklist;
  };

  fetchChecklistById = async (checklistId: string): Promise<IGetChecklist> => {
    const { getChecklist, setChecklist } = this.checklistInstancesStore;

    const { fetchChecklistById } = this.checklistsService;

    const previouslyFetchedChecklist = getChecklist(checklistId);

    if (previouslyFetchedChecklist) return previouslyFetchedChecklist;

    const fetchedChecklist = await fetchChecklistById(checklistId);

    if (!fetchedChecklist) return;

    setChecklist(fetchedChecklist);

    return fetchedChecklist;
  };

  fetchStageList = async (checklistId: string): Promise<IGetChecklistStage[]> => {
    const { getStageList, setStageList } = this.checklistInstancesStore;

    const { fetchStageList } = this.checklistsService;

    const previouslyFetchedStageList = getStageList(checklistId);

    if (previouslyFetchedStageList) return previouslyFetchedStageList;

    const fetchedStageList = await fetchStageList(checklistId);

    if (!fetchedStageList) return;

    setStageList(checklistId, fetchedStageList);

    return fetchedStageList;
  };

  fetchAttributeList = async (
    payload: IGetChecklistAttributeListPayload
  ): Promise<IGetChecklistAttribute[]> => {
    const { getAttributeList, setAttributeList } = this.checklistInstancesStore;

    const { fetchAttributeList } = this.checklistsService;

    const previouslyFetchedAttributeList = getAttributeList(payload.checkListId);

    if (previouslyFetchedAttributeList) return previouslyFetchedAttributeList;

    const fetchedAttributeList = await fetchAttributeList(payload);

    if (!fetchedAttributeList) return;

    setAttributeList(payload.checkListId, fetchedAttributeList);

    return fetchedAttributeList;
  };

  fetchAttributesByCheckListPublicId = async (
    publicId: string,
    payload?: TGetChecklistAttributeByCheckListPublicIdPayload
  ): Promise<IGetChecklistAttribute[]> => {
    const { getAttributeList, setAttributeList } = this.checklistInstancesStore;

    const { fetchAttributesByCheckListPublicId } = this.checklistsService;

    const previouslyFetchedAttributeList = getAttributeList(publicId);

    if (previouslyFetchedAttributeList) return previouslyFetchedAttributeList;

    const fetchedAttributeList = await fetchAttributesByCheckListPublicId(publicId, payload);

    if (!fetchedAttributeList) return;

    setAttributeList(publicId, fetchedAttributeList);

    return fetchedAttributeList;
  };

  fetchEnumListByAttributeId = async (
    attributeId: string
  ): Promise<IGetChecklistAttributeEnumValue[]> => {
    const { getAttributeEnumList, setEnumList } = this.checklistInstancesStore;

    const { fetchEnumListByAttributeId } = this.checklistsService;

    const previouslyFetchedEnumList = getAttributeEnumList(attributeId);

    if (previouslyFetchedEnumList) return previouslyFetchedEnumList;

    const fetchedEnumList = await fetchEnumListByAttributeId(attributeId);

    if (!fetchedEnumList) return;

    setEnumList(attributeId, fetchedEnumList);

    return fetchedEnumList;
  };

  // Intensity REST method
  fetchIntensityByTaskId = async (taskId: string): Promise<IGetIntensity[]> => {
    const { setIdToIntensity } = this.checklistInstancesStore;

    try {
      const fetchedIntensityList = await this.axios.api.getIntensityByTaskId(
        { taskId },
        { omit: ['taskId'] }
      );

      fetchedIntensityList.forEach(intensity => setIdToIntensity(intensity.id, intensity));

      return fetchedIntensityList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchUserDictionaryListByAttributeId = async (
    attributeId: string
  ): Promise<IGetChecklistAttributeUserDictionary[]> => {
    try {
      const { setAttributeIdToUserDictionaryList } = this.checklistInstancesStore;

      const { getStoredValueList } = userDictLinkHelpers;

      const { content } = await this.axios.api.getChecklistUserDictionaryListByAttributeId(
        { attributeId, size: 2000 },
        { omit: ['attributeId'] }
      );

      setAttributeIdToUserDictionaryList(attributeId, [
        ...content,
        ...getStoredValueList(attributeId),
      ]);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchDictionaryListByRemoteName = async (
    attributeId: string,
    payload: TGetDictListByRemoteNameReq = {}
  ): Promise<IGetDictionary[]> => {
    try {
      const { setDictionaryList } = this.checklistInstancesStore;

      const { content } = await this.axios.api.getDictionaryListByRemoteName(payload, {
        omit: ['remoteName'],
      });

      setDictionaryList(attributeId, content);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  delTechnique = async (id: string): Promise<void> => {
    const { delTechnique } = this.checklistInstancesStore;

    try {
      if (id) {
        await this.axios.api.deleteChecklistInstance({
          id,
        });

        delTechnique(id);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  delEmptyTechnique = async (isDeleteAnyway?: boolean): Promise<void> => {
    const { techniqueList, selectedInstance } = this.checklistInstancesStore;

    const emptyTechnique = techniqueList.find(technique => !technique?.machineryName);

    if (emptyTechnique) {
      if (isDeleteAnyway) {
        await this.delTechnique(emptyTechnique.id);

        return;
      }

      const isNotTechnique = emptyTechnique.id !== selectedInstance.id;

      if (isNotTechnique) {
        await this.delTechnique(emptyTechnique.id);
      }
    }
  };

  deleteInstance = async (id: number, instId?: string): Promise<void> => {
    try {
      if (instId) {
        await this.axios.api.deleteChecklistInstance({
          id: instId,
        });
      }

      if (this.checklistInstancesStore.deleteImmediately) {
        await this.axios.api.deleteChecklistInstance({
          id: this.checklistInstancesStore.positionToInstance.get(id).id,
        });

        this.checklistInstancesStore.setHasPositionToInstanceChanged(false);
      }
      if (this.checklistInstancesStore.positionToInstance.get(id).onlyFrontend) {
        const newIdToInstance = new Map();
        Array.from(this.checklistInstancesStore.positionToInstance.keys())
          .filter(key => key < id)
          .forEach(key =>
            newIdToInstance.set(key, this.checklistInstancesStore.positionToInstance.get(key))
          );
        Array.from(this.checklistInstancesStore.positionToInstance.keys())
          .filter(key => key > id)
          .forEach(key =>
            newIdToInstance.set(key - 1, {
              ...this.checklistInstancesStore.positionToInstance.get(key),
              name: String(key - 1),
            })
          );
        this.checklistInstancesStore.positionToInstance = newIdToInstance;
      } else {
        this.checklistInstancesStore.positionToInstance.delete(id);
        this.checklistInstancesStore.setHasPositionToInstanceChanged(
          !this.checklistInstancesStore.deleteImmediately
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  saveInstances = async (
    creationTaskId?: string,
    isAddInstancesToStore?: boolean
  ): Promise<IGetChecklistInstanceByTaskId[]> => {
    const {
      instanceList,
      techniqueList,
      clearPositionToInstance,
      setInstance,
    } = this.checklistInstancesStore;
    const { temporaryTask } = this.tasksStore;
    const { task: taskEdit } = this.tasksEditStore;

    const taskId = temporaryTask?.id || taskEdit?.id;

    if (!taskId) return;

    const payload: IPutChecklistInstance[] = instanceList.map(
      instance =>
        ({
          ...instance,
          taskId: this.checklistInstancesStore.taskId
            ? this.checklistInstancesStore.taskId
            : creationTaskId,
          planCoords: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: instance.planCoords?.coordinates?.length
                ? instance.planCoords?.coordinates.slice()
                : instance.planCoords?.geometry?.coordinates.slice(),
            },
          },
          intensity: instance?.intensity?.id,
        } as IPutChecklistInstance)
    );

    try {
      const response = await this.axios.api.putChecklistInstances(payload, {
        query: {
          taskId,
        },
      });

      if (isAddInstancesToStore && response?.length) {
        clearPositionToInstance();

        const sortedInstanceList = sortBy(response, ['name']);

        sortedInstanceList.forEach(instance => {
          const instanceNumber = parseInt(instance.name, 10);

          if (!isNaN(instanceNumber)) setInstance(instanceNumber, instance);
        });
      }

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchInstanceList = async (taskId: string): Promise<void> => {
    const {
      setInstance,
      setTechnique,
      clearIdToTechnique,
      clearPositionToInstance,
    } = this.checklistInstancesStore;

    const response = await this.axios.api.getChecklistInstancesByTaskId(
      { taskId, size: 10000 },
      { omit: ['taskId'] }
    );

    clearPositionToInstance();
    clearIdToTechnique();

    const actualPoints = [];

    response.content.forEach(inst => {
      const isTechnique = inst.type === ECheckListInstanceType.Machinery;

      if (isTechnique) {
        setTechnique(inst as IGetChecklistInstanceByTaskId);
      } else {
        const instNumber = parseInt(inst.name, 10);

        if (!isNaN(instNumber)) {
          setInstance(instNumber, inst);
        } else if (inst.type === ECheckListInstanceType.ActualPoint) {
          actualPoints.push(inst);
        }
      }
    });

    if (actualPoints.length) {
      actualPoints.forEach((instActual, index) => {
        const newNumber = index + 100;

        setInstance(newNumber, { ...instActual, virtualPosition: newNumber });
      });
    }
  };

  saveInstanceAttributeValues = async (): Promise<ESaveInstanceAttributeValuesResults> => {
    try {
      const {
        selectedInstance,
        selectedChecklist,
        selectedIntensity,
        drawingStageList,
        drawingNestedInstanceList,
        listOfEditNestedInst,
        templateNestedInstanceList,
        getDrawingNestedInstance,
        setDrawingStage,
        clearIdOfUnsavedAttr,
        setFocusTargetId,
        clearFocusTargetId,
        clearAttrIdToFileList,
        clearStrangeProps,
        clearIdToTechnique,
        setIsFocused,
      } = this.checklistInstancesStore;

      clearFocusTargetId();

      const checklistLinkAttributeList: IChecklistAttributeWithValue[] = [];
      const attributeValueList: IPutChecklistAttributeValue[] = [];
      const invalidAttributeWithErrorList: IChecklistAttributeWithValue[] = [];
      const drawingNestedInstanceListToSave: IChecklistAttributeWithValue[] = [];
      const listOfAttrWithInvalidChildren: IChecklistAttributeWithValue[] = [];
      const listOfDrawNestedInst: IDrawingNestedInstance[] = [...drawingNestedInstanceList];

      // Включаем валидацию
      if (listOfEditNestedInst.length) {
        listOfEditNestedInst?.forEach(inst => {
          const drawNestedInst = getDrawingNestedInstance(inst.id);

          if (drawNestedInst && !drawNestedInst?.isRootAttributeHidden) {
            const listOfInvalidAttrWithValue = this.getInvalidAttrWithValueListOfNestedInstance(
              drawNestedInst
            );

            if (listOfInvalidAttrWithValue.length) {
              invalidAttributeWithErrorList.push(...listOfInvalidAttrWithValue);
            }
          }
        });
      }

      if (templateNestedInstanceList?.length) {
        templateNestedInstanceList.forEach(tempInst => {
          const tempDrawNestInst = getDrawingNestedInstance(tempInst.id);

          if (tempDrawNestInst && !tempDrawNestInst?.isRootAttributeHidden) {
            const addedData = this.addDrawingNestedInstance(tempDrawNestInst);

            if (addedData.listOfInvalidAttrWithValue.length) {
              invalidAttributeWithErrorList.push(...addedData.listOfInvalidAttrWithValue);
            } else {
              listOfDrawNestedInst.push(addedData.addedNestedInst);
            }
          }
        });
      }

      drawingStageList.forEach(stage => {
        const {
          attributeWithValueList,
          invalidAttributeWithValueList,
          attributeValueListToSave,
          drawingNestedInstanceList: validatedDrawingNestedInstanceList,
          listOfAttrWithInvalidChildren: validatedListOfAttrWithInvalidChildren,
        } = getValidatedAttrWithValueList(stage.attributeWithValueList, listOfDrawNestedInst);

        if (validatedListOfAttrWithInvalidChildren.length) {
          listOfAttrWithInvalidChildren.push(...validatedListOfAttrWithInvalidChildren);
        }

        if (!invalidAttributeWithValueList.length) {
          attributeValueList.push(...attributeValueListToSave);
          checklistLinkAttributeList.push(...attributeWithValueList);

          if (validatedDrawingNestedInstanceList.length) {
            drawingNestedInstanceListToSave.push(...validatedDrawingNestedInstanceList);
          }
        } else {
          invalidAttributeWithErrorList.push(...invalidAttributeWithValueList);

          setDrawingStage(stage.id, {
            ...stage,
            attributeWithValueList,
          });
        }
      });

      if (
        Boolean(invalidAttributeWithErrorList.length) ||
        Boolean(listOfAttrWithInvalidChildren.length)
      ) {
        const commonListOfInvalidAttrWithValue: IChecklistAttributeWithValue[] = [
          ...invalidAttributeWithErrorList,
          ...listOfAttrWithInvalidChildren,
        ];

        setFocusTargetId(commonListOfInvalidAttrWithValue?.[0]?.checklistAttribute?.id);
        setIsFocused(true);

        return ESaveInstanceAttributeValuesResults.validationError;
      }

      const payload: IPostAttributeValuesPayload = {
        checkListId: selectedChecklist.id,
        checkListInstanceId: selectedInstance.id,
        intensityId: selectedIntensity?.id,
        values: attributeValueList,
      };

      await this.axios.api.postChecklistInstanceAttributeValues(payload);

      clearIdToTechnique();

      // Если есть незаполненная техника, то удаляем ее
      await this.delEmptyTechnique();

      /**
       * Если сохранение прошло успешно, то обнуляем значение в сторе, что отслеживает
       *  несохраненные данные
       */
      clearIdOfUnsavedAttr();

      // Удаляем значения узерских справочников из sessionStorage
      userDictLinkHelpers.clearStoredData();

      clearStrangeProps();
      clearAttrIdToFileList();

      return ESaveInstanceAttributeValuesResults.success;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return ESaveInstanceAttributeValuesResults.saveError;
    }
  };

  createMachineryInst = async (): Promise<IGetChecklistInstanceByTaskId> => {
    const { techniqueList, setTechnique } = this.checklistInstancesStore;
    const { temporaryTask } = this.tasksStore;
    const { task: taskEdit } = this.tasksEditStore;

    const taskId = temporaryTask?.id || taskEdit?.id;

    if (!taskId) return;

    const machineryInst: IPutChecklistInstance = {
      taskId,
      name: String(techniqueList.length + 1),
      type: ECheckListInstanceType.Machinery,
      isActive: true,
    };

    try {
      const createdInst = await this.axios.api.createChecklistInstance(machineryInst);

      setTechnique(createdInst);

      return createdInst;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  // Other methods
  enableChecklistViewMode = (): void => {
    this.checklistInstancesStore.setChecklistMode(EChecklistMode.View);
  };

  enableChecklistEditMode = (): void => {
    this.checklistInstancesStore.setChecklistMode(EChecklistMode.Edit);
  };

  get isFetching(): boolean {
    return this.checklistsService.isLoading;
  }

  get isAllowToSaveChecklist(): boolean {
    const { temporaryTask } = this.tasksStore;
    const { selectedIntensity } = this.checklistInstancesStore;

    if (!temporaryTask?.intensityRequired) return true;

    if (temporaryTask?.intensityRequired && Boolean(selectedIntensity)) {
      return true;
    } else {
      return false;
    }
  }

  get intensityOptionList(): ISelectOption[] {
    return [
      DEFAULT_INTENSITY_OPTION,
      ...this.checklistInstancesStore.intensityList.map<ISelectOption>(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ];
  }

  get intensityDefaultValue(): ISelectOption {
    const { selectedIntensity } = this.checklistInstancesStore;

    if (selectedIntensity) {
      return { label: selectedIntensity.name, value: selectedIntensity.id };
    }

    return DEFAULT_INTENSITY_OPTION;
  }

  addNewUserDictLinkValue = (
    attr: IChecklistAttributeWithValue,
    partialValue: Partial<IPutChecklistAttributeValue>
  ): void => {
    const { setNewUserDictionaryValue } = this.checklistInstancesStore;

    const { isAddedNewValue } = userDictLinkHelpers;

    const attrId = attr.checklistAttribute.id;

    partialValue?.userDictionaryValues?.forEach(value => {
      const isANewVal = value.id === value.value;

      if (isANewVal) {
        if (isAddedNewValue(attrId, value)) {
          setNewUserDictionaryValue(attrId, value);
        }
      }
    });
  };

  handleChangeAttributeValue: THandleChangeChecklistAttributeValue = ({
    attributeWithValue,
    drawingNestedInstanceId,
    value,
    listOfOptionValue,
  }) => {
    const {
      getDrawingStage,
      setDrawingStage,
      getDrawingNestedInstance,
      getDepAttrValue,
      setDrawingNestedInstance,
      setIdOfUnsavedAttr,
      setDepAttrValue,
    } = this.checklistInstancesStore;

    const {
      isExactlyThisAttr,
      isBelongToTheThisParentAttr,
      isBelongToTheProgenitor,
      getChangedAttr,
      getChangedBoolAttr,
      getChangedParentAttr,
      getChangedParentAttrWithFile,
      getChangedProgenitorAttrWithFile,
    } = checklistAttrChangeHelpers;

    const { isBoolAttr, isUserDictLinkAttr, isFileLinkAttr } = checklistAttrTypeHelpers;

    const drawingTarget = drawingNestedInstanceId
      ? getDrawingNestedInstance(drawingNestedInstanceId)
      : getDrawingStage(attributeWithValue.stageId);

    if (!drawingTarget) return;

    const changedListOfAttrWithValue = drawingTarget.attributeWithValueList.map(
      stageAttrWithValue => {
        if (isExactlyThisAttr(attributeWithValue, stageAttrWithValue)) {
          if (isBoolAttr(attributeWithValue)) {
            return getChangedBoolAttr(stageAttrWithValue, value);
          }

          return getChangedAttr(stageAttrWithValue, value, listOfOptionValue);
        }

        if (isBelongToTheThisParentAttr(attributeWithValue, stageAttrWithValue)) {
          if (isFileLinkAttr(attributeWithValue)) {
            return getChangedParentAttrWithFile(stageAttrWithValue, value);
          }

          return getChangedParentAttr(
            stageAttrWithValue,
            attributeWithValue,
            value,
            listOfOptionValue
          );
        }

        if (isBelongToTheProgenitor(attributeWithValue, stageAttrWithValue)) {
          if (isFileLinkAttr(attributeWithValue)) {
            return getChangedProgenitorAttrWithFile(stageAttrWithValue, attributeWithValue, value);
          }
        }

        return stageAttrWithValue;
      }
    );

    // Связываем созависимые справочники
    if (
      attributeWithValue.checklistAttribute.attribute.type ===
      EChecklistAttributeType.DictionaryLink
    ) {
      if (getDepAttrValue(attributeWithValue.checklistAttribute.id)) {
        setDepAttrValue({
          checkListAttributeId: attributeWithValue.checklistAttribute.id,
          ...value,
        });
      }
    }

    /**
     * Сохраняем id атрибута в стор, где храним тот атрибут, в которым были произведены изменения,
     * чтобы, перед сменой точки, после нажатия на кнопку "отменить" в отобразившемся
     * модальном окне предупреждения, произвести скролл к последнему измененному
     * атрибуту.
     */
    setIdOfUnsavedAttr(attributeWithValue.checklistAttribute.id);

    /**
     * Если это пользовательский справочник, то делаем проверку, является ли значение
     * новым для добавления и, если так, то добавляем его и сохраняем в sessionStorage
     */
    if (isUserDictLinkAttr(attributeWithValue)) {
      this.addNewUserDictLinkValue(attributeWithValue, value);
    }

    if (drawingNestedInstanceId) {
      setDrawingNestedInstance({
        ...(drawingTarget as IDrawingNestedInstance),
        attributeWithValueList: changedListOfAttrWithValue,
      });
    } else {
      setDrawingStage(attributeWithValue.stageId, {
        ...(drawingTarget as IChecklistDrawingStage),
        attributeWithValueList: changedListOfAttrWithValue,
      });
    }
  };

  selectIntensity = async (payload: { intensityId: string; taskId: string }): Promise<void> => {
    const {
      intensityList,
      setSelectedIntensity,
      setSelectedChecklist,
      clearSelectedIntensity,
    } = this.checklistInstancesStore;

    const { intensityId, taskId } = payload;

    if (intensityId === DEFAULT_INTENSITY_OPTION.value || intensityId === '') {
      clearSelectedIntensity();
      this.clearStoreAfterChangeIntensity();
      return;
    }

    setSelectedIntensity(intensityList.find(({ id }) => id === intensityId));
    this.clearStoreAfterChangeIntensity();

    const fetchedChecklist = await this.fetchChecklistByTaskAndIntensity({ taskId, intensityId });

    if (fetchedChecklist) setSelectedChecklist(fetchedChecklist);
  };

  addDrawingNestedInstance = (
    templateDrawingNestedInstance: IDrawingNestedInstance
  ): {
    listOfInvalidAttrWithValue: IChecklistAttributeWithValue[];
    addedNestedInst: IDrawingNestedInstance | null;
  } => {
    const {
      getAttributeList,
      setDrawingNestedInstance,
      deleteTemplateNestedInstance,
    } = this.checklistInstancesStore;

    // Проверяем на валидность введенных значений
    const {
      attributeWithValueList,
      invalidAttributeWithValueList,
      attributeValueListToSave,
    } = getValidatedAttrWithValueList(templateDrawingNestedInstance.attributeWithValueList);

    if (invalidAttributeWithValueList.length) {
      setDrawingNestedInstance({
        ...templateDrawingNestedInstance,
        attributeWithValueList,
      });

      return {
        listOfInvalidAttrWithValue: invalidAttributeWithValueList,
        addedNestedInst: null,
      };
    }

    const availableAttributeList = getAttributeList(
      templateDrawingNestedInstance.rootChecklistAttribute.attribute.checklistLinkPublicId
    );

    console.log('attributeWithValueList', attributeWithValueList);

    const drawingNestedInstance = generateDrawingNestedInstance({
      rootChecklistAttribute: templateDrawingNestedInstance.rootChecklistAttribute,
      attributeList: availableAttributeList,
      putAttributeValueList: attributeValueListToSave,
      listOfAttrWithVal: attributeWithValueList,
    });

    setDrawingNestedInstance(drawingNestedInstance);

    // Обнуляем значения формы добавления

    this.makeDefaultDrawingNestedInstance(templateDrawingNestedInstance);

    deleteTemplateNestedInstance(templateDrawingNestedInstance.rootChecklistAttribute.id);

    return {
      listOfInvalidAttrWithValue: [],
      addedNestedInst: drawingNestedInstance,
    };
  };

  makeDefaultDrawingNestedInstance = (
    templateDrawingNestedInstance: IDrawingNestedInstance
  ): void => {
    const { getAttributeList, setDrawingNestedInstance } = this.checklistInstancesStore;

    const { rootChecklistAttribute } = templateDrawingNestedInstance;

    const availableAttributeList = getAttributeList(
      rootChecklistAttribute.attribute.checklistLinkPublicId
    );

    const defaultAttributeWithValueList = generateAttributeWithValueList({
      stageId: rootChecklistAttribute.id,
      listOfChecklistAttribute: availableAttributeList,
      drawingNestedInstanceId: templateDrawingNestedInstance.id,
    });

    setDrawingNestedInstance({
      ...templateDrawingNestedInstance,
      attributeWithValueList: defaultAttributeWithValueList,
    });
  };

  getInvalidAttrWithValueListOfNestedInstance = (
    drawingNestedInstance: IDrawingNestedInstance
  ): IChecklistAttributeWithValue[] => {
    const { getDrawingNestedInstance, setDrawingNestedInstance } = this.checklistInstancesStore;

    const returnInvalidAttributeWithValueList: IChecklistAttributeWithValue[] = [];

    if (drawingNestedInstance) {
      const blankNestedInstance = getDrawingNestedInstance(drawingNestedInstance.id);

      const {
        invalidAttributeWithValueList,
        attributeWithValueList,
      } = getValidatedAttrWithValueList(blankNestedInstance.attributeWithValueList);

      if (invalidAttributeWithValueList.length) {
        returnInvalidAttributeWithValueList.push(...invalidAttributeWithValueList);

        setDrawingNestedInstance({
          ...blankNestedInstance,
          attributeWithValueList,
        });
      }
    }

    return returnInvalidAttributeWithValueList;
  };

  deleteDrawingNestedInstance = (drawingNestedInstanceId: string): void => {
    this.checklistInstancesStore.deleteDrawingNestedInstance(drawingNestedInstanceId);
  };

  toggleDisplayOfDrawingNestedInstance = (
    drawingNestedInstance: IDrawingNestedInstance,
    visibilityValue: boolean
  ): void => {
    const { setDrawingNestedInstance } = this.checklistInstancesStore;

    setDrawingNestedInstance({ ...drawingNestedInstance, isCollapsed: visibilityValue });
  };

  handleDrawingNestedInstanceEditClick = (drawNestedInst: IDrawingNestedInstance): void => {
    const {
      getDrawingNestedInstance,
      getTempNestedInst,
      getEditNestedInst,
    } = this.checklistInstancesStore;

    const tempNestedInst = getTempNestedInst(drawNestedInst.rootChecklistAttribute.id);

    if (tempNestedInst) {
      const tempDrawNestedInst = getDrawingNestedInstance(tempNestedInst.id);

      if (tempDrawNestedInst && !tempDrawNestedInst?.isRootAttributeHidden) {
        const { addedNestedInst } = this.addDrawingNestedInstance(tempDrawNestedInst);

        if (!addedNestedInst) {
          return;
        }
      }
    }

    const editNestedInst = getEditNestedInst(drawNestedInst.rootChecklistAttribute.id);

    if (editNestedInst) {
      const invalidListOfAttrWithValue = this.getInvalidAttrWithValueListOfNestedInstance(
        editNestedInst
      );

      if (!invalidListOfAttrWithValue.length) {
        /**
         * Если запись, что мы редактировали перед нажатием на следующую,
         * имеет все корректно заполненные атрибуты, то сохраняем ее и коллапсим
         */
        this.toggleDisplayOfDrawingNestedInstance(editNestedInst, false);

        this.enableDrawingNestedInstanceEditing(drawNestedInst);
        this.toggleDisplayOfDrawingNestedInstance(drawNestedInst, true);
      }
    } else {
      this.enableDrawingNestedInstanceEditing(drawNestedInst);
      this.toggleDisplayOfDrawingNestedInstance(drawNestedInst, true);
    }
  };

  generateDrawingStageList = async (): Promise<void> => {
    const {
      selectedInstance,
      selectedChecklist,
      setIdToDrawingStage,
      setFileListByAttrId,
      setDepAttrValue,
    } = this.checklistInstancesStore;

    const { setFileToFileMapById } = this.checklistFileUploaderStore;

    const fetchedStageList = await this.fetchStageList(selectedChecklist.id);

    const fetchedAttributeList = await this.fetchAttributeList({
      checkListId: selectedChecklist.id,
      activeOnly: true,
      size: 2000,
    });

    if (!selectedInstance) return;

    // Пробрасываем значения атрибутов-файлов в стор
    selectedInstance.attributeValues.forEach(attrVal => {
      if (attrVal?.fileValue) {
        setFileListByAttrId(attrVal.checkListAttributeId, attrVal.fileValue);

        attrVal.fileValue.forEach(fileItem => {
          setFileToFileMapById(fileItem.id, fileItem);
        });
      }
    });

    const headStage: IGetChecklistStage = {
      name: selectedChecklist.name,
      order: 0,
      checklistId: selectedChecklist.id,
      id: selectedChecklist.id,
    };

    const availableStageList: IGetChecklistStage[] = [headStage, ...(fetchedStageList || [])];

    const idToDrawingStage = new Map<string, IChecklistDrawingStage>();

    availableStageList.forEach(stage => {
      const drawStage = generateDrawingStage(
        stage,
        fetchedAttributeList || [],
        selectedInstance.attributeValues
      );

      const listOfDepAttrVal = createListOfDepAttrVal(drawStage.attributeWithValueList);

      if (listOfDepAttrVal.length) {
        listOfDepAttrVal.forEach(attrVal => {
          setDepAttrValue(attrVal);
        });
      }

      idToDrawingStage.set(stage.id, drawStage);
    });

    setIdToDrawingStage(idToDrawingStage);
  };

  generateDrawingNestedInstanceList = async (
    attributeWithValue: IChecklistAttributeWithValue
  ): Promise<void> => {
    const {
      selectedInstance,
      setAttributeList,
      setDrawingNestedInstanceList,
      setDepAttrValue,
      setFileListByAttrId,
    } = this.checklistInstancesStore;

    const { setFileToFileMapById } = this.checklistFileUploaderStore;

    const { checklistAttribute } = attributeWithValue;

    const { checklistLinkPublicId } = attributeWithValue.checklistAttribute.attribute;

    if (!checklistLinkPublicId) return;

    const fetchedAttributeList = await this.fetchAttributesByCheckListPublicId(
      checklistLinkPublicId,
      {
        activeOnly: true,
        size: 2000,
      }
    );

    if (fetchedAttributeList) setAttributeList(checklistLinkPublicId, fetchedAttributeList);

    const templateDrawingNestedInstance = generateDrawingNestedInstance({
      rootChecklistAttribute: checklistAttribute,
      attributeList: fetchedAttributeList,
      isTemplate: true,
    });

    const listOfDepAttrVal = createListOfDepAttrVal(
      templateDrawingNestedInstance.attributeWithValueList
    );

    if (listOfDepAttrVal.length) {
      listOfDepAttrVal.forEach(attrVal => {
        setDepAttrValue(attrVal);
      });
    }

    const drawingNestedInstanceList: IDrawingNestedInstance[] = [templateDrawingNestedInstance];

    const instanceList =
      selectedInstance.attributeValues.find(
        attribute =>
          attribute.checkListAttributeId === checklistAttribute.id &&
          attribute?.checkListInstanceValue
      )?.checkListInstanceValue || [];

    instanceList.forEach(instance => {
      // Пробрасываем значения вложенных атрибутов-файлов в стор
      instance.attributeValues.forEach(attrVal => {
        if (attrVal?.fileValue) {
          setFileListByAttrId(attrVal.id, attrVal.fileValue);

          attrVal.fileValue.forEach(fileItem => {
            setFileToFileMapById(fileItem.id, fileItem);
          });
        }
      });

      const drawNestedInst = generateDrawingNestedInstance({
        rootChecklistAttribute: checklistAttribute,
        attributeList: fetchedAttributeList,
        getAttributeValueList: instance.attributeValues,
      });

      drawingNestedInstanceList.push(drawNestedInst);
    });

    setDrawingNestedInstanceList(drawingNestedInstanceList);
  };

  getEnumOptionList = (attributeId: string): ISelectOption[] => {
    const { getAttributeEnumList } = this.checklistInstancesStore;

    return getAttributeEnumList(attributeId)?.map<ISelectOption>(enumValue => ({
      label: enumValue.value,
      value: enumValue.id,
    }));
  };

  getEnumDefaultValue = (attributeId: string, enumId: string): ISelectOption => {
    return this.getEnumOptionList(attributeId)?.find(enumOption => enumOption.value === enumId);
  };

  getUserDictionaryDefaultValue = (
    attributeId: string,
    userDictionaryValueId: string
  ): ISelectOption => {
    const { getUserDictionaryList } = this.checklistInstancesStore;

    const userDictionaryList = getUserDictionaryList(attributeId);

    return userDictionaryList?.find(option => option.value === userDictionaryValueId);
  };

  getDictionaryDefaultValue = (attributeId: string, dictionaryValueId: string): ISelectOption => {
    const { getDictionaryOptionListByAttrId } = this.checklistInstancesStore;

    const dictionaryList = getDictionaryOptionListByAttrId(attributeId);

    return dictionaryList?.find(option => option.value === dictionaryValueId);
  };

  setDeleteImmediately = (value: boolean) => {
    this.checklistInstancesStore.deleteImmediately = value;
  };

  enableDrawingNestedInstanceEditing = (drawNestedInst: IDrawingNestedInstance): void => {
    const {
      setPreviousStateOfEditableDrawingNestedInstance,
      setEditNestedInst,
    } = this.checklistInstancesStore;

    setEditNestedInst(drawNestedInst);
    setPreviousStateOfEditableDrawingNestedInstance(drawNestedInst);
  };

  disableDrawingNestedInstanceEditing = (attrId: string): void => {
    const {
      delEditNestedInst,
      clearPreviousStateOfEditableDrawingNestedInstance,
    } = this.checklistInstancesStore;

    delEditNestedInst(attrId);
    clearPreviousStateOfEditableDrawingNestedInstance();
  };

  handleHidingTheChecklistLinkAttribute = (config: {
    rootAttributeId: string;
    rootAttributeBooleanValue?: boolean;
    isLeavingThisChecklist?: boolean;
  }): void => {
    const {
      editableDrawingNestedInstance,
      drawingNestedInstanceList,
      previousStateOfEditableDrawingNestedInstance,
      setDrawingNestedInstance,
    } = this.checklistInstancesStore;

    const templateNestedInstance = drawingNestedInstanceList?.find(
      drawingNestedInstance =>
        drawingNestedInstance.rootChecklistAttribute.id === config.rootAttributeId &&
        drawingNestedInstance.isTemplate
    );

    if (templateNestedInstance) {
      setDrawingNestedInstance({
        ...templateNestedInstance,
        isRootAttributeHidden: config?.rootAttributeBooleanValue === false,
      });
    }

    if (editableDrawingNestedInstance) {
      setDrawingNestedInstance(previousStateOfEditableDrawingNestedInstance);

      this.disableDrawingNestedInstanceEditing(config?.rootAttributeId);
    }

    if (config?.isLeavingThisChecklist) {
      this.hideTemplateNestedInstance(config?.rootAttributeId);
    }
  };

  showTemplateNestedInstance = (templateNestedInstance: IDrawingNestedInstance): void => {
    this.checklistInstancesStore.setTemplateNestedInstance(templateNestedInstance);
  };

  hideTemplateNestedInstance = (rootAttributeId: string): void => {
    this.checklistInstancesStore.deleteTemplateNestedInstance(rootAttributeId);
  };

  getModalWarningBeforeChangeInstance = (config: {
    instId?: string;
    instancePositionNumber?: number;
    techniqueId?: string;
    isBackButton?: boolean;
  }): void => {
    const {
      setFocusTargetId,
      setIsFocused,
      setSelectedInstId,
      idOfUnsavedAttr,
    } = this.checklistInstancesStore;
    const { setModal } = this.modalsStore;
    const { setSelectedInstanceNumber } = this.pointsMapStore;
    const { setFullScreenMode } = this.tasksStore;

    const { instancePositionNumber, isBackButton, techniqueId, instId } = config;

    const successHandler = () => {
      if (techniqueId) {
        setSelectedInstId(techniqueId);
      } else if (instancePositionNumber) {
        setSelectedInstanceNumber(instancePositionNumber);
        setSelectedInstId(instId);
      }

      if (isBackButton) {
        setFullScreenMode(null);
      }
    };

    const denyHandler = () => {
      setFocusTargetId(idOfUnsavedAttr);
      setIsFocused(true);
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeChangeInstance,
        successHandler,
        denyHandler
      )
    );
  };

  warnBeforeChangingIntensity = (payload: { intensityId: string; taskId: string }) => {
    const { setModal } = this.modalsStore;

    const { setIntensityModalResult, clearIntensityModalResult } = this.checklistInstancesStore;

    clearIntensityModalResult();

    const successHandler = async (): Promise<void> => {
      await this.selectIntensity(payload);

      setIntensityModalResult(true);
    };

    const denyHandler = () => {
      setIntensityModalResult(false);
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeChangeIntensity,
        successHandler,
        denyHandler
      )
    );
  };

  warnBeforeLeavingThePage = (onSuccess?: () => void) => {
    const { setModal } = this.modalsStore;

    const { setFullScreenMode } = this.tasksStore;

    const successHandler = () => {
      if (onSuccess) onSuccess();
      setFullScreenMode(null);
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeLeavingThePage,
        successHandler
      )
    );
  };

  clearStoreAfterChangedInstance = (): void => {
    this.checklistInstancesStore.clearSelectedInstance();
    this.checklistInstancesStore.clearSelectedIntensity();
    this.checklistInstancesStore.clearSelectedChecklist();

    this.checklistInstancesStore.clearFocusTargetId();
    this.checklistInstancesStore.clearIsFocused();

    this.checklistInstancesStore.clearIdToDrawingStage();
    this.checklistInstancesStore.clearIdToDrawingNestedInstance();

    this.checklistInstancesStore.clearIdOfUnsavedAttr();

    this.checklistFileUploaderStore.clearUploadKeyToFiles();
  };

  clearStoreAfterChangeIntensity = (): void => {
    this.checklistInstancesStore.clearSelectedChecklist();

    this.checklistInstancesStore.clearFocusTargetId();
    this.checklistInstancesStore.clearIsFocused();

    this.checklistInstancesStore.clearIdToDrawingStage();
    this.checklistInstancesStore.clearIdToDrawingNestedInstance();

    this.checklistInstancesStore.clearIdOfUnsavedAttr();

    this.checklistFileUploaderStore.clearUploadKeyToFiles();
  };

  clearStoreAfterLeavePage = (): void => {
    this.checklistInstancesStore.clearStore();

    this.checklistsService.clearStore();

    this.checklistFileUploaderStore.clearStore();
  };

  clearInstances = (): void => {
    this.checklistInstancesStore.clearPositionToInstance();
    this.checklistInstancesStore.clearField();
    this.checklistInstancesStore.clearIdToTechnique();
  };
}
