import React, { FC } from 'react';
import { observer } from 'mobx-react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useHistory } from 'react-router';

import { useStore } from '../../../shared/utils/IoC';
import { UiStore } from '../../stores/ui.store';
import { PopupPages } from '../../constants/popup.pages';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { MapMode } from '../../../map/stores/map.store';

import { PopupSliderController } from './controlles/popupSlider.controller';
import { PSlider } from './style';
import { Main } from './components/Main/Main';
import { CultureZone } from './components/CultureZone/CultureZone';
import { Seasons } from './components/Seasons/Seasons';

export const PopupSlider: FC = observer(() => {
  const history = useHistory();
  const popupSliderController = useStore(PopupSliderController);
  const { selectedFieldId } = useStore(FieldsStore);

  const uiStore = useStore(UiStore);

  const popupSliderRef = useOnclickOutside(
    e => {
      // @ts-ignore
      if (e.target.id === 'mapId' && uiStore.showPopupSlider) {
        // @ts-ignore
        const originalX = e.x;
        // @ts-ignore
        const originalY = e.y;
        const mapElem = e.target;
        // @ts-ignore
        mapElem.onmouseup = event => {
          if (
            event.x === originalX &&
            event.y === originalY &&
            uiStore.popupPageState !== PopupPages.None &&
            uiStore.popupPageState !== PopupPages.CultureZone
          ) {
            popupSliderController.blurField();
          }
          // @ts-ignore
          mapElem.onmouseup = null;
        };
      }
    },
    {
      ignoreClass: 'add-field-button',
    }
  );

  const { popupPageState } = uiStore;

  const renderContent = () => {
    const variants = {
      [PopupPages.None]: null,
      [PopupPages.Main]: <Main />,
      [PopupPages.Seasons]: <Seasons />,
      [PopupPages.CultureZone]: <CultureZone />,
    };

    return variants[popupPageState];
  };

  // todo костылище, потом убрать
  const isEditingRoute = history.location.pathname.includes('fields/edit');

  return (
    <PSlider
      visibility={
        popupPageState !== PopupPages.None || (Boolean(selectedFieldId) && !isEditingRoute)
      }
      type={popupPageState}
      ref={popupSliderRef}
    >
      {renderContent()}
    </PSlider>
  );
});
