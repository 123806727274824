import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = {};

export const deleteChecklistInstance: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => {
    console.log(req);
    return `/api/as-fields/checklist/instance/${req.id}`;
  },
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
