import { observer } from 'mobx-react';
import { FC, forwardRef, memo, useEffect, useMemo } from 'react';

import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesController } from '../../../../../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../../../../../stores/checklist.instances.store';
import editDefaultSvg from '../../../../../../../../../static/action-icons/edit-default-17x16.svg';
import editHoverSvg from '../../../../../../../../../static/action-icons/edit-hover-17x16.svg';
import deleteDefaultSvg from '../../../../../../../../../static/action-icons/delete-default-24x24.svg';
import deleteHoverSvg from '../../../../../../../../../static/action-icons/delete-hover-24x24.svg';
import arrowDownDefaultSvg from '../../../../../../../../../static/action-icons/arrow-down-default-24x24.svg';
import arrowDownHoverSvg from '../../../../../../../../../static/action-icons/arrow-down-hover-24x24.svg';
import arrowTopDefaultSvg from '../../../../../../../../../static/action-icons/arrow-top-default-24x24.svg';
import arrowTopHoverSvg from '../../../../../../../../../static/action-icons/arrow-top-hover-24x24.svg';
import subtitleIncludeSvg from '../../../../../../../../../static/include.svg';
import { IDrawingNestedInstance } from '../../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { useGetOrderedAttributeWithValueList } from '../../../../../../../hooks/useGetAvailableChecklistAttributeList';
import {
  generateNestedInstanceTitle,
  IGenerateNestedInstanceTitleResult,
  isNeedToAdjustPenultimateAttributeMargin,
} from '../../../../../../../utils/checklist.instances';
import { useCommonState } from '../../../../../../../../../../shared/hooks';
import ContainerForAttributeWithChildren from '../../../../containers/ContainerForAttributeWithChildren/ContainerForAttributeWithChildren';
import ChecklistAttributeContainer from '../../../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';

import Styled from './styles';

type TNestedInstanceProps = {
  drawingNestedInstance: IDrawingNestedInstance;
  isTemplate?: boolean;
  recordNumber?: number;
  totalNumberOfRecords?: number;
  isEditing?: boolean;
  focusTarget?: string;
};

type TNestedInstanceState = {
  isAttributesAdjusted: boolean;
};

const NestedInstance = forwardRef<HTMLInputElement, TNestedInstanceProps>(
  (
    {
      drawingNestedInstance,
      isTemplate,
      recordNumber,
      totalNumberOfRecords,
      isEditing,
      focusTarget,
    },
    ref
  ) => {
    const {
      checklistMode,
      isChecklistViewMode,
      isChecklistEditMode,
      enumList,
      userDictionaryList,
      dictionaryList,
    } = useStore(ChecklistInstancesStore);

    const {
      handleChangeAttributeValue,
      addDrawingNestedInstance,
      makeDefaultDrawingNestedInstance,
      deleteDrawingNestedInstance,
      getInvalidAttrWithValueListOfNestedInstance,
      disableDrawingNestedInstanceEditing,
      hideTemplateNestedInstance,
      handleDrawingNestedInstanceEditClick,
      toggleDisplayOfDrawingNestedInstance,
    } = useStore(ChecklistInstancesController);

    const [state, setState] = useCommonState<TNestedInstanceState>({
      isAttributesAdjusted: false,
    });

    const orderedAttributeWithValueList = useGetOrderedAttributeWithValueList(
      drawingNestedInstance?.attributeWithValueList
    );

    // Выравнивает внешний отступ предпоследнего атрибута
    useEffect(() => {
      setState({
        isAttributesAdjusted: isNeedToAdjustPenultimateAttributeMargin(
          orderedAttributeWithValueList
        ),
      });
    }, [orderedAttributeWithValueList]);

    // Отображает название добавленной записи
    const addedRecordTitle: IGenerateNestedInstanceTitleResult = useMemo(
      () =>
        generateNestedInstanceTitle({
          attributeWithValueList: orderedAttributeWithValueList,
          enumList,
          userDictionaryList,
          dictionaryList,
        }) || { titleValue: `Запись №${recordNumber}`, subTitleValue: null },
      [recordNumber, orderedAttributeWithValueList, enumList, userDictionaryList, dictionaryList]
    );

    // Отображает название для новой записи
    const newRecordTitle: string = useMemo(
      () => (totalNumberOfRecords ? `Запись №${totalNumberOfRecords + 1}` : 'Запись №1'),
      [isTemplate, totalNumberOfRecords]
    );

    // Блокирует атрибуты для ввода новых значений
    const isBlocked: boolean = useMemo(
      () =>
        isTemplate
          ? false
          : (isChecklistViewMode && !isEditing) || (isChecklistEditMode && !isEditing),

      [checklistMode, isEditing]
    );

    const handleAddingDrawingNestedInstance = (): void => {
      addDrawingNestedInstance(drawingNestedInstance);
    };

    const handleSavingDrawingNestedInstance = (): void => {
      const invalidListOfAttrWithValue = getInvalidAttrWithValueListOfNestedInstance(
        drawingNestedInstance
      );

      if (!invalidListOfAttrWithValue.length) {
        disableDrawingNestedInstanceEditing(drawingNestedInstance.rootChecklistAttribute.id);
        toggleDisplayOfDrawingNestedInstance(drawingNestedInstance, false);
      }
    };

    const handleEditClick = (): void => {
      handleDrawingNestedInstanceEditClick(drawingNestedInstance);
    };

    const handleDeleteClick = (): void => {
      deleteDrawingNestedInstance(drawingNestedInstance.id);
    };

    const handleTextAsDeleteButtonClick = (): void => {
      if (isTemplate) {
        hideTemplateNestedInstance(drawingNestedInstance.rootChecklistAttribute.id);
        makeDefaultDrawingNestedInstance(drawingNestedInstance);
      } else {
        disableDrawingNestedInstanceEditing(drawingNestedInstance.rootChecklistAttribute.id);
        deleteDrawingNestedInstance(drawingNestedInstance.id);
      }
    };

    const handleCollapseClick = (): void => {
      toggleDisplayOfDrawingNestedInstance(
        drawingNestedInstance,
        !drawingNestedInstance.isCollapsed
      );
    };

    const actionButtonListElement = (
      <Styled.ButtonsWrapper>
        {isChecklistEditMode && (
          <>
            <Styled.EditButton
              onClick={handleEditClick}
              $editDefaultSvg={editDefaultSvg}
              $editHoverSvg={editHoverSvg}
            />
            <Styled.DeleteButton
              onClick={handleDeleteClick}
              $deleteDefaultSvg={deleteDefaultSvg}
              $deleteHoverSvg={deleteHoverSvg}
            />
          </>
        )}

        <Styled.CollapseButton
          onClick={handleCollapseClick}
          $isCollapsed={drawingNestedInstance.isCollapsed}
          $arrowDownDefaultSvg={arrowDownDefaultSvg}
          $arrowDownHoverSvg={arrowDownHoverSvg}
          $arrowTopDefaultSvg={arrowTopDefaultSvg}
          $arrowTopHoverSvg={arrowTopHoverSvg}
        />
      </Styled.ButtonsWrapper>
    );

    const textAsDeleteButtonElement = (
      <Styled.TextAsDeleteButton
        onClick={handleTextAsDeleteButtonClick}
        $isDisabled={isTemplate && !totalNumberOfRecords}
      >
        Удалить
      </Styled.TextAsDeleteButton>
    );

    const addNewNestedInstanceButtonElement = isTemplate && (
      <Styled.SaveButtonWrapper>
        <Styled.SaveButton onClick={handleAddingDrawingNestedInstance}>
          Добавить запись
        </Styled.SaveButton>
      </Styled.SaveButtonWrapper>
    );

    const saveNestedInstanceButtonElement = isEditing && (
      <Styled.SaveButtonWrapper>
        <Styled.SaveButton onClick={handleSavingDrawingNestedInstance}>Сохранить</Styled.SaveButton>
      </Styled.SaveButtonWrapper>
    );

    return (
      <Styled.Wrapper
        $isHiddenAttributes={!drawingNestedInstance.isCollapsed}
        $isAttributesAdjusted={state.isAttributesAdjusted}
      >
        <Styled.Header>
          <Styled.Title>{isTemplate ? newRecordTitle : addedRecordTitle.titleValue}</Styled.Title>

          {isTemplate || isEditing ? textAsDeleteButtonElement : actionButtonListElement}

          {addedRecordTitle.subTitleValue && !isTemplate ? (
            <Styled.Subtitle $subtitleIncludeSvg={subtitleIncludeSvg}>
              {addedRecordTitle.subTitleValue}
            </Styled.Subtitle>
          ) : null}
        </Styled.Header>

        {orderedAttributeWithValueList.map(attributeWithValue => {
          if (attributeWithValue?.children?.length) {
            return (
              <ContainerForAttributeWithChildren
                key={attributeWithValue.checklistAttribute.id}
                attributeWithValue={attributeWithValue}
                onChange={handleChangeAttributeValue}
                isBlocked={isBlocked}
                focusTarget={focusTarget}
                ref={ref}
              />
            );
          } else {
            return (
              <ChecklistAttributeContainer
                key={attributeWithValue.checklistAttribute.id}
                attributeWithValue={attributeWithValue}
                onChange={handleChangeAttributeValue}
                isBlocked={isBlocked}
                focusTarget={focusTarget}
                ref={ref}
              />
            );
          }
        })}

        {saveNestedInstanceButtonElement}

        {addNewNestedInstanceButtonElement}
      </Styled.Wrapper>
    );
  }
);

NestedInstance.displayName = 'NestedInstance';

export default memo(observer(NestedInstance));
