import { TaskStatuses } from '../../../modules/dashboard/modules/operations/stores/tasks.store';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  taskId: string;
  status: TaskStatuses;
  cancellationReason: string;
};

type TypeResponse = {};

export const setStatus: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/task/setStatus`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
