import { action, runInAction } from 'mobx';

import { EAppSettingsType, lsAppSettingsKey } from '../../../../..';
import { UserInfoType } from '../../../../../api/models/user.model';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { getPurePhone } from '../../../../shared/utils/getPurePhone';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OrganizationsController } from '../../../controllers/organizations/organizations.controller';
import { SeasonsController } from '../../../controllers/seasons/seasons.controller';
import { ProfileRoutes as ProfileRoutesStatic } from '../routes';
import { defaultErrorsFields, ProfileStore } from '../stores/ProfileStore';

@provide.singleton()
export class ProfileController {
  @lazyInject(Axios)
  protected axios2: Axios;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(OrganizationsController)
  protected organizationsController: OrganizationsController;

  @lazyInject(SeasonsController)
  protected seasonsController: SeasonsController;

  toggleWelcomeWidget = (collapsed: boolean) => {
    this.profileStore.collapsedWelcomeWidget = collapsed;
  };

  getPendingContracts = () =>
    this.axios2.api.getPendingContracts({}).then(response => {
      runInAction(() => {
        this.profileStore.pendingContracts = response;
      });
    });

  getUserInfo = (setAppSettings?: boolean) => {
    this.profileStore.loading = true;
    this.getPendingContracts().then(() => {
      this.axios2.api
        .getProfile({})
        .then(response => {
          runInAction(() => {
            this.organizationsController.fetchOrganizations();
            if (setAppSettings) {
              this.profileStore.login = response.id;

              if (this.profileStore.login) {
                if (this.checkAppSettings(this.profileStore.login)) {
                  const appSettingsStr = localStorage.getItem(lsAppSettingsKey);
                  const appSettings = JSON.parse(appSettingsStr);
                  const organizationFromAppSettings = appSettings[EAppSettingsType.Organization];
                  if (organizationFromAppSettings) {
                    this.organizationsController.selectOrganization(
                      organizationFromAppSettings,
                      true
                    );
                  }
                } else {
                  this.setAppSettings(EAppSettingsType.Login, true)(this.profileStore.login);
                }
              }
            }

            this.profileStore.user = response;

            const { firstName, secondName, lastName } = response;

            this.setFullName(firstName, secondName, lastName);
            this.setPersonalDataItems(response);
            this.profileStore.expertises = response.expertises;
            this.profileStore.interests = response.interests;

            this.seasonsController.fetchMySeasons();
            this.profileStore.loading = false;
          });
        })
        .catch(error => {
          console.error(error);
          this.profileStore.loading = false;
          this.profileStore.user = null;
        });
    });
  };

  deleteSeasonFromAppSettings = (appSettings: any): void => {
    if (appSettings?.season) {
      const newAppSettings = { ...appSettings };
      delete newAppSettings.season;
      localStorage.setItem(lsAppSettingsKey, JSON.stringify(newAppSettings));
    } else {
      localStorage.setItem(lsAppSettingsKey, JSON.stringify(appSettings));
    }
  };

  setAppSettings = (key: EAppSettingsType, clear?: boolean) => {
    return (value: any) => {
      const appSettingsStr = localStorage.getItem(lsAppSettingsKey);
      const appSettings = appSettingsStr && !clear ? JSON.parse(appSettingsStr) : {};
      appSettings[key] = value;

      if (key === EAppSettingsType.Organization) {
        this.deleteSeasonFromAppSettings(appSettings);
      } else {
        localStorage.setItem(lsAppSettingsKey, JSON.stringify(appSettings));
      }
    };
  };

  getAppSettings = () => {
    const appSettingsStr = localStorage.getItem(lsAppSettingsKey);
    return appSettingsStr ? JSON.parse(appSettingsStr) : {};
  };

  checkAppSettings = (login: string): boolean => {
    const appSettingsStr = localStorage.getItem(lsAppSettingsKey);
    const appSettings = appSettingsStr ? JSON.parse(appSettingsStr) : {};
    return appSettings[EAppSettingsType.Login] === login;
  };

  setFullName = (firstName?: string, secondName?: string, lastName?: string) => {
    this.profileStore.fullName = [lastName, firstName, secondName].join(' ');
  };

  setPersonalDataItems = (user: UserInfoType) => {
    const { gender, dateOfBirth, phone, email } = user;

    // eslint-disable-next-line no-nested-ternary
    const emailValue = this.profileStore.pendingContracts.emails.length
      ? this.profileStore.pendingContracts.emails[0]
      : email
      ? email.email
      : '';

    this.profileStore.personalDataItems = [
      {
        key: 'Пол',
        value: gender ? gender.genderName : '',
      },
      {
        key: 'Дата рождения',
        value: dateOfBirth || '',
      },
      {
        key: 'Телефон',
        value: phone ? `${phone.phoneNumberCode} ${phone.phoneNumber}` : '',
      },
      {
        key: 'E-mail',
        value: emailValue,
        status: this.profileStore.pendingContracts.emails.length ? 'error' : 'default',
        helperActionText: this.profileStore.pendingContracts.emails.length
          ? 'Отправить еще раз'
          : '',
        helperAction: this.profileStore.pendingContracts.emails.length
          ? () => {
              this.confirmEmail(this.profileStore.pendingContracts.emails[0]);
            }
          : undefined,
        helper: this.profileStore.pendingContracts.emails.length
          ? 'Необходимо подтвердить свой\n электронный адрес. На указанный\n адрес отправлено письмо.\n'
          : '',
      },
    ];
  };

  changePersonalDataInStore = (user: UserInfoType) => {
    this.profileStore.user = user;
  };

  updatePersonalData = (history: any) => {
    if (this.profileStore.user && this.validateUserData()) {
      const { firstName, lastName, secondName, gender, dateOfBirth } = this.profileStore.user;
      const updatedUserData: TypeApiRequest<'updateProfile'> = {
        firstName,
        lastName,
        secondName,
        dateOfBirth,
        gender: gender?.genderEnum,
        avatar: this.profileStore.avatarHash || this.profileStore.user.avatar?.id || '',
      };

      return this.axios2.api.updateProfile(updatedUserData).then(() => {
        this.getUserInfo();
        history.push(ProfileRoutesStatic.Dashboard);
      });
    }
  };

  confirmPhoneNumber = async (phoneNumber: string, countryCode: string) => {
    console.log('confirmPhoneNumber');
    let result = null;
    try {
      result = await this.axios2.api.userSendSmsCode({
        phoneNumber: getPurePhone(phoneNumber, countryCode),
        countryCode,
      });
    } catch (r) {
      return Promise.reject(r);
    }

    console.log('confirmPhoneNumber finish');
    return result;
  };

  checkSMSCode = async (
    data: TypeApiRequest<'verifySmsCode'>
  ): Promise<TypeApiResponse<'verifySmsCode'>> => {
    let response = null;
    try {
      response = await this.axios2.api.checkUserSmsCode(data);
    } catch (r) {
      return Promise.reject(r);
    }
    localStorage.setItem('accessToken', response.token);
    return this.getUserInfo(true) as any;
  };

  confirmEmail = (email: string) =>
    this.axios2.api.sendEmailVerification({ email }, { omit: ['email'] });

  validateField = (field: string) =>
    // @ts-ignore
    !this.user[field];

  validateUserData = () => {
    this.profileStore.errorsFields = defaultErrorsFields;

    const validateUserData = ['firstName', 'lastName', 'dateOfBirth', 'gender'].filter(
      this.validateField
    );

    validateUserData.forEach(field => {
      // @ts-ignore
      this.errorsFields[field] = 'Поле обязательно для заполнения';
    });

    return !validateUserData.length;
  };

  @action
  uploadFile = (file: File) => {
    console.log(file);
    this.profileStore.avatarFile = file;
    this.axios2.api
      .uploadFile({
        ownerId: this.profileStore.user?.id!,
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(this.onUploadFileSuccess)
      .catch(this.onUploadFileError);
  };

  @action.bound
  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    this.profileStore.avatarHash = response.id;
    this.axios2.api
      .uploadFileFinish({ data: this.profileStore.avatarFile, url: response.uploadUrl })
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  @action.bound
  onUploadFileError = () => {
    console.log('onUploadFileError');
  };

  @action.bound
  onUploadFileFinishSuccess = () => {
    console.log('File uploaded');
    this.getUserInfo();
  };

  @action.bound
  onUploadFileFinishError = () => {
    console.log('onUploadFileFinishError');
  };

  reset = () => {
    this.profileStore.loading = false;
  };
}
