import { FC } from 'react';
import { observer } from 'mobx-react';

import { EditCompetitions as EditCompetitionsBlock } from '../../components/EditCompetitions/EditCompetitions';

import { Wrapper } from './style';

export const EditCompetitionsPage: FC = observer(() => {
  return (
    <Wrapper>
      <EditCompetitionsBlock />
    </Wrapper>
  );
});
