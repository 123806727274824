import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Input, Button } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { OrganizationStore } from '../../../../../../../organization/stores/organization.store';
import { OrganizationSendInviteFormStore } from '../../../../stores/organization.sendInvite.form.store';
import application_time from '../../../../../../../../../shared/assets/images/application_time.png';
import { ActionsWrapper } from '../../../ApplicationView/style';

import { ModalTitle, InputWrapper, OrgName, InfoTitle, ImageWrapper } from './style';

type Props = {
  organizationId: string;
  hideForm: () => void;
};

export const SendInviteToOrganizationForm: FC<Props> = observer(({ organizationId, hideForm }) => {
  const store = useStore(OrganizationStore);
  const formStore = useStore(OrganizationSendInviteFormStore);
  const { getOrganizationInfo, organization } = store;
  const { comment, setComment, joinToOrganization } = formStore;

  const [sended, setSended] = useState(false);

  useEffect(() => {
    setComment('');
  }, []);

  useEffect(() => {
    if (organizationId) {
      getOrganizationInfo(organizationId);
    }
  }, [organizationId]);

  const handleSubmit = () => {
    joinToOrganization(organizationId).then(() => {
      setSended(true);
    });
  };

  if (sended) {
    return (
      <>
        <ImageWrapper>
          <img src={application_time} alt="" />
        </ImageWrapper>
        <InfoTitle>Ваша заявка направлена на рассмотрение</InfoTitle>
        <ActionsWrapper>
          <Button type="button" color="primary" onClick={hideForm}>
            Хорошо
          </Button>
        </ActionsWrapper>
      </>
    );
  }

  return (
    <>
      <ModalTitle>
        Присоединиться
        <br />к организации
      </ModalTitle>
      <OrgName>{organization?.name}</OrgName>
      <InputWrapper>
        <Input label="Название организации" error="" value={organization?.name} disabled />
      </InputWrapper>
      <InputWrapper>
        <Input label="ИНН" error="" value={organization?.INN} disabled />
      </InputWrapper>
      <InputWrapper>
        <Input
          label="Сообщение"
          error=""
          value={comment}
          placeholder="Комментарий к заявке"
          onChange={setComment}
        />
      </InputWrapper>

      <InputWrapper>
        <Button type="button" color="primary" alignment="center" onClick={handleSubmit}>
          Отправить заявку
        </Button>
      </InputWrapper>
      <InputWrapper>
        <Button type="button" color="default" alignment="center" onClick={hideForm}>
          Отмена
        </Button>
      </InputWrapper>
    </>
  );
});
