import { observer } from 'mobx-react';
import { FC, memo, useCallback, useState } from 'react';

import { EFieldPointsMapMode } from '../../PointsOnTheMap';
import { DialogModal } from '../../../../../../../../components/DialogModal/DialogModal';
import { ICustomColor } from '../../../../../../../shared/constans/customColorsPrests';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ReactComponent as BasketSvg } from '../../../../../../static/basket.svg';
import { FullScreenMode, TasksStore } from '../../../../../operations/stores/tasks.store';
import { TaskEditController } from '../../../../../operations/controllers/task.edit.controller';
import { ProfileStore } from '../../../../../profile/stores/ProfileStore';
import { PointsMapStore } from '../../../../stores/pointsMap.store';
import { ChecklistInstancesController } from '../../../../../operationsAndTasks/controllers/checklist.instances.controller';
import {
  ECheckListInstanceType,
  IInstance,
} from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { ChecklistInstancesStore } from '../../../../../operationsAndTasks/stores/checklist.instances.store';
import { ReactComponent as MobileIcon } from '../assets/mobile.svg';
import { CheckAccessStore } from '../../../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../shared/constants/access-rules-action';

import LabelByCoordinates from './LabelByCoordinates';
import {
  StyledDescriptionWrapper,
  StyledInstance,
  StyledName,
  StyledOperationWrapper,
  StyledPositionMark,
  StyledOperationLabel,
  StyledStateLabel,
  StyledBaskedWrapper,
  StyledPositionText,
} from './styles';

interface IProps {
  instance: Partial<IInstance>;
  colors: ICustomColor;
  position: number;
  checklistsAvailable?: boolean;
  manageTasks?: boolean;
  workWithTasks?: boolean;
  isOrgMy?: boolean;
}

const Instance: FC<IProps> = observer(
  ({ instance, colors, position, isOrgMy, workWithTasks, manageTasks }) => {
    const { deleteInstance, enableChecklistViewMode, enableChecklistEditMode } = useStore(
      ChecklistInstancesController
    );
    const pointsMapStore = useStore(PointsMapStore);
    const taskStore = useStore(TasksStore);
    const profileStore = useStore(ProfileStore);
    const taskEditController = useStore(TaskEditController);
    const checklistInstancesStore = useStore(ChecklistInstancesStore);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { getAccessRuleValue, accessRules } = useStore(CheckAccessStore);

    const handleChecklistActionClick = useCallback((): void => {
      const selectedInst = checklistInstancesStore.getInstanceByPosition(position);

      if (selectedInst?.id) {
        pointsMapStore.setSelectedInstanceNumber(position);
        checklistInstancesStore.setSelectedInstId(selectedInst.id);
      }

      if (isOrgMy || manageTasks) {
        if (instance?.checkListId) {
          enableChecklistViewMode();
        } else {
          enableChecklistEditMode();
        }
      } else if (workWithTasks) {
        if (instance?.checkListId) {
          enableChecklistViewMode();
        } else {
          enableChecklistEditMode();
        }
      }

      taskStore.setFullScreenMode(FullScreenMode.ChecklistFullScreen);
    }, [position, instance]);

    const checkAccessRulesForEditChecklist = () => {
      if (!checklistInstancesStore.taskId) return false; // проверяем не оздание ли это новой задачи

      if (isOrgMy || manageTasks) return true;

      const isUserAssignedToTask =
        taskStore.tasksMap.get(checklistInstancesStore.taskId).assignee.id === profileStore.user.id;

      if (workWithTasks && isUserAssignedToTask) return true;

      if (workWithTasks && !isUserAssignedToTask) return false;

      return false;
    };

    const getCheckListActionLabel = () => {
      if (isOrgMy || manageTasks) {
        return instance.checkListId ? 'Просмотреть чек-лист' : 'Заполнить чек-лист';
      }

      const isUserAssignedToTask =
        taskStore.tasksMap.get(checklistInstancesStore.taskId).assignee.id === profileStore.user.id;

      if (workWithTasks && isUserAssignedToTask) {
        return instance.checkListId ? 'Просмотреть чек-лист' : 'Заполнить чек-лист';
      }

      if (workWithTasks && !isUserAssignedToTask) {
        return instance.checkListId ? 'Просмотреть чек-лист' : false;
      }
    };

    return (
      <StyledInstance data-test-id={'instance-row'}>
        <StyledDescriptionWrapper>
          <StyledPositionMark $colors={colors}>
            {instance.type === ECheckListInstanceType.ActualPoint ? (
              <MobileIcon />
            ) : (
              <StyledPositionText>{position}</StyledPositionText>
            )}
          </StyledPositionMark>
          <StyledName data-test-id={'instance-position-name'}>
            {instance.type === ECheckListInstanceType.ActualPoint
              ? instance.name
              : `Точка №${position}`}
          </StyledName>
          <LabelByCoordinates isActual={Boolean(instance.type === 'ACTUAL_POINT')} />
        </StyledDescriptionWrapper>
        <StyledOperationWrapper>
          {taskStore.isViewMode && !instance.onlyFrontend && (
            <>
              {getCheckListActionLabel() && (
                <StyledOperationLabel
                  onClick={handleChecklistActionClick}
                  data-test-id={'instance-checklist-action'}
                >
                  {getCheckListActionLabel()}
                </StyledOperationLabel>
              )}
              <StyledStateLabel
                $isFilled={Boolean(instance?.checkListId)}
                data-test-id={'instance-status'}
              >
                {instance.checkListId ? 'Заполнено' : 'Не заполнено'}
              </StyledStateLabel>
            </>
          )}
          <StyledBaskedWrapper
            data-test-id={'instance-delete'}
            disabled={
              checkAccessRulesForEditChecklist()
                ? false
                : instance.createdBy && profileStore.user?.id !== instance.createdBy
            }
            onClick={event => {
              event.stopPropagation();
              setShowDeleteDialog(true);
            }}
          >
            <BasketSvg />
          </StyledBaskedWrapper>
        </StyledOperationWrapper>
        {showDeleteDialog && (
          <DialogModal
            title={'Вы действительно хотите удалить чеклист?'}
            status={'warning'}
            accept={{
              name: 'Да, продолжить',
              handler: event => {
                event.preventDefault();
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                deleteInstance(position);
                if (pointsMapStore.mapMode !== EFieldPointsMapMode.LISTING_ONLY) {
                  pointsMapStore.deleteMarker(position);
                }
                if (!checklistInstancesStore.deleteImmediately) {
                  taskEditController.addFiledToTouchedFields('pointOnMap');
                }
                setShowDeleteDialog(false);
              },
              color: 'primary',
            }}
            cancel={{
              name: 'Нет, отменить',
              handler: event => {
                event.preventDefault();
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                setShowDeleteDialog(false);
              },
              color: 'default',
            }}
          />
        )}
      </StyledInstance>
    );
  }
);

export default memo(Instance);
