import styled from 'styled-components';

import { Colors } from '../../../../constans/colors';

const Wrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.red};
  margin-top: 4px;
`;

const StyledInputFieldErrorItem = {
  Wrapper,
};

export default StyledInputFieldErrorItem;
