import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

export const AvatarWrapper = styled.div`
  width: 68px;
  height: 68px;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 17px;
`;

export const Avatar = styled.img`
  width: 68px;
`;

export const AvatarMock = styled.div`
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  background: ${Colors.gray};
  border-radius: 16px;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
`;

export const LinkWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;
export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const FileUploader = styled.input`
  display: none;
`;
