import { reaction } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import MapStore from '../../../../map/stores/map.store';
import { IndicesStore } from '../../../stores/indices.store';
import { FieldsStore } from '../../../modules/fields/stores/fields.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { CultureFillEnum } from '../../../constants/culture.fill.enum';
import { Axios } from '../../../../shared/utils/axios2';
import { IndiceModel } from '../../../../../api/models/indices.model';
import { MapController } from '../../../../map/controllers/map.controller';

@provide.singleton()
export class IndicesController {
  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(IndicesStore)
  protected indicesStore: IndicesStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(MapController)
  protected mapController: MapController;

  constructor() {
    reaction(() => this.fieldsStore.selectedFieldId, this.changeActiveField);
    reaction(() => this.fieldsStore.showCulturesFillPanel, this.changeActiveFilter);
  }

  fetchIndices = async (fieldId: string): Promise<void> => {
    if (
      this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Msavi ||
      this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Ndvi
    ) {
      this.indicesStore.setLoading(true);

      try {
        const response = await this.axios.api.getIndicesList({
          fieldId,
          indexName: this.fieldsStore.showCulturesFillPanel,
          year: this.seasonsStore.selectedSeason,
        });

        if (fieldId !== this.fieldsStore.selectedFieldId) throw new Error('Error: old indices');

        const completedIndices = response.content.filter(item => item.status === 'COMPLETED');

        this.indicesStore.setIndices(completedIndices);

        const isPrevSelectedFieldAndIndex = this.isPrevSelectedFiledAndIndex(fieldId);

        if (isPrevSelectedFieldAndIndex) {
          const prevIndex = completedIndices.find(
            index => index.toDate === this.indicesStore.selectedIndex.toDate
          );

          if (prevIndex) this.selectIndices(prevIndex);
        } else {
          const firstIndex = response.content.find(item => item.status === 'COMPLETED');

          if (firstIndex) this.selectIndices(firstIndex);
        }

        this.indicesStore.setLoading(false);
        this.indicesStore.setPrevState(fieldId, this.fieldsStore.showCulturesFillPanel);
      } catch (e) {
        this.indicesStore.setLoading(false);
      }
    }
  };

  selectIndices = (index: IndiceModel): void => {
    try {
      if (this.indicesStore.layoutId) {
        this.mapController.removeLayout(this.indicesStore.layoutId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    this.indicesStore.setSelectedIndex(index);
    const field = this.fieldsStore.getSelectedField();

    if (!field) return;

    const polygon = this.mapStore.getLayer(field.polyId);

    this.indicesStore.setLayoutId(this.mapStore.setLayout(polygon.getBounds(), index.visImage));
  };

  isPrevSelectedFiledAndIndex = (fieldId: string): boolean => {
    const { prevSelectedFieldId, prevCulturesFillPanel } = this.indicesStore.prevState;
    const isTheSameFieldId = fieldId === prevSelectedFieldId;
    const isTheSameCultureFill = this.fieldsStore.showCulturesFillPanel === prevCulturesFillPanel;

    return isTheSameFieldId && isTheSameCultureFill;
  };

  changeActiveFilter = () => {
    if (this.indicesStore.layoutId) {
      this.mapController.removeLayout(this.indicesStore.layoutId);
    }

    this.indicesStore.setLayoutId(0);
  };

  changeActiveField = () => {
    if (this.indicesStore.layoutId) {
      this.mapController.removeLayout(this.indicesStore.layoutId);
      this.indicesStore.setLayoutId(0);
    }
  };

  refreshPrevIndicesState = (): void => {
    this.indicesStore.setPrevState(null, null);
  };
}
