import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { EModalType, useModal } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../shared/utils/IoC';
import PointsOnTheMapWidget, {
  EFieldPointsMapMode,
} from '../../../components/Widgets/PointsOnTheMap';
import { ChecklistInstancesStore } from '../../../../operationsAndTasks/stores/checklist.instances.store';
import { PointsMapStore } from '../../../stores/pointsMap.store';
import Fullscreen, { IFullscreenProps } from '../../fullscreen';
import { TasksStore } from '../../../../operations/stores/tasks.store';
import { ChecklistInstancesController } from '../../../../operationsAndTasks/controllers/checklist.instances.controller';
import ControlButtons from '../../../../operationsAndTasks/modules/fullscreen/checklist/components/ControlButtons';
import { Checklist } from '../../../../operationsAndTasks/modules/fullscreen/checklist/containers/Checklist';
import { Intensity } from '../../../../operationsAndTasks/modules/fullscreen/checklist/components/Intensity';
import { ModalContainer } from '../../../../../../modals/containers';
import { checklistModalUniqueKey } from '../../../../operationsAndTasks/modules/fullscreen/checklist/modals/modalsConfig';
import { EModalSize } from '../../../../../../modals/components/Modal/styles';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';
import {
  ECheckListInstanceType,
  IInstance,
} from '../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../shared/constants/access-rules-action';
import { ProfileStore } from '../../../../profile/stores/ProfileStore';
import { TaskViewStore } from '../../../../operations/stores/task.view.store';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { CheckAccessStore } from '../../../../../../authorization/stores/checkAccess.store';

import { InstanceCard } from './components/InstanceCard/InstanceCard';
import { CardsListBlock } from './components/InstanceCard/style';
import { Content, PointTitle } from './style';

type IChecklistFullscreenProps = {} & IFullscreenProps;

const ChecklistFullscreen: FC<IChecklistFullscreenProps> = observer(props => {
  const { selectedInstance: selectedInstanceNumber, setSelectedInstanceNumber } = useStore(
    PointsMapStore
  );

  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const {
    idOfUnsavedAttr,
    selectedChecklist,
    selectedInstance,
    techniqueList,
    instanceList,
    selectedInstId,
    getTechnique,
    setSelectedInstId,
  } = checklistInstancesStore;

  const {
    getModalWarningBeforeChangeInstance,
    isFetching,
    clearStoreAfterLeavePage: clearChecklistsStore,
    fetchInstanceData,
    delEmptyTechnique,
  } = useStore(ChecklistInstancesController);

  const { temporaryTask } = useStore(TasksStore);
  const { task: taskEdit } = useStore(TasksEditStore);

  const taskViewStore = useStore(TaskViewStore);
  const task = taskViewStore.taskForView;

  const profileStore = useStore(ProfileStore);

  const organizationsStore = useStore(OrganizationsStore);

  const { getAccessRuleValue } = useStore(CheckAccessStore);

  const { registerModalList, getModalPayload } = useModal();

  useEffect(() => {
    registerModalList(
      [
        {
          name: 'resendChecklistData',
          title: 'Во время сохранения произошла ошибка. Попробуйте повторить попытку',
          type: EModalType.Warning,
          id: 'resendChecklistData',
          styledProps: {
            $size: EModalSize.Medium,
          },
          successButton: {
            title: 'Повторить',
            color: 'primary',
            preventClose: true,
            handler: () => getModalPayload().resendHandler(),
          },
          denyButton: {
            title: 'Отменить',
            color: 'default',
          },
        },
      ],
      'checklistModals'
    );

    // При анмаутинге очищаем стор чек-листов
    return () => {
      (async () => {
        await delEmptyTechnique(true);

        clearChecklistsStore();
      })();
    };
  }, []);

  // Получаем данные точки. Если у точки заполнен чек-лист, то и его данные тоже
  useEffect(() => {
    (async () => {
      if (selectedInstId) {
        await fetchInstanceData(selectedInstId);
      }
    })();
  }, [selectedInstId]);

  const handleClickOnInstanceCard = (id: string): void => {
    if (isFetching) {
      return;
    }

    const isExactlyThisSelectedInst = id === selectedInstId;

    const selectedPointInst = instanceList.find(inst => inst.id === id);

    const technique = getTechnique(id);

    if (idOfUnsavedAttr && !isExactlyThisSelectedInst) {
      getModalWarningBeforeChangeInstance({
        instancePositionNumber:
          selectedPointInst?.type === ECheckListInstanceType.ActualPoint
            ? selectedPointInst?.virtualPosition
            : Number(selectedPointInst?.name),
        instId: id,
        techniqueId: id === technique.id ? id : null,
      });

      return;
    }

    if (technique) {
      setSelectedInstId(id);
      setSelectedInstanceNumber(null);

      return;
    }

    if (selectedPointInst) {
      setSelectedInstId(id);
      setSelectedInstanceNumber(
        selectedPointInst.type === ECheckListInstanceType.ActualPoint
          ? selectedPointInst.virtualPosition
          : Number(selectedPointInst.name)
      );
    }
  };

  const availableInstCardList = [
    ...Array.from(checklistInstancesStore.positionToInstance.values()),
    ...techniqueList,
  ] as Partial<IInstance>[];

  const isUserIsAssignee = Boolean((task.assigneeId || task.assignee?.id) === profileStore.user.id);

  const isOrgMy = organizationsStore.selectedOrganizationId === 'my';

  const allowGoToEditAndDelete =
    isOrgMy ||
    (getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS) && isUserIsAssignee) ||
    getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);

  return (
    <>
      <Fullscreen {...props}>
        <Content>
          <PointTitle>
            {selectedInstance?.type === ECheckListInstanceType.Machinery
              ? `Техника ${selectedInstance?.machineryName || ''}`
              : `Точка №${
                  selectedInstance?.type === ECheckListInstanceType.ActualPoint
                    ? selectedInstance?.name.slice(-3)
                    : selectedInstanceNumber
                }`}
          </PointTitle>
          <CardsListBlock>
            {availableInstCardList.map(inst => (
              <InstanceCard
                key={inst?.id}
                instance={inst}
                number={Number(inst?.name)}
                isSelected={
                  inst.type === ECheckListInstanceType.ActualPoint
                    ? inst.virtualPosition === selectedInstanceNumber
                    : inst?.id === selectedInstId
                }
                onCardClick={handleClickOnInstanceCard}
                disabled={!allowGoToEditAndDelete}
              />
            ))}
          </CardsListBlock>

          {selectedInstance && selectedInstance?.type !== ECheckListInstanceType.Machinery ? (
            <PointsOnTheMapWidget
              mode={EFieldPointsMapMode.READ_ONLY_WITH_SELECTED}
              field={checklistInstancesStore.field}
              taskId={checklistInstancesStore.taskId}
            />
          ) : null}
        </Content>
        <Intensity task={temporaryTask || taskEdit} selectedInstId={selectedInstId} />
        {selectedChecklist && <Checklist />}
        <ControlButtons task={temporaryTask || taskEdit} />
      </Fullscreen>
      <ModalContainer uniqueKey={checklistModalUniqueKey} $size={EModalSize.Medium} />
    </>
  );
});

export default ChecklistFullscreen;
