import { FC } from "react";

 import {
    Content as ToolContent,
    IconWrapper,
    Item,
    Label as ToolLabel,
  } from '../../../shared/components/ToolTip/style';
import { ReactComponent as LogoutSvg } from '../../static/logout16x16.svg';
import { ReactComponent as ProfileSvg } from '../../static/profile.svg';

import { Arrow, ContextMenuWrapper, LogoutToolLabel, MenuOverlay } from "./style";

export interface AvatarContextMenuProps {
    isOpen: boolean;
    onGoToProfileClick: () => void;
    onLogoutClick: () => void;
    onClose: () => void;
}

export const SidebarContextMenu:FC<AvatarContextMenuProps> = ({
    isOpen,
    onGoToProfileClick,
    onLogoutClick,
    onClose,
}) => {

    return (
            <>
                {isOpen && (<MenuOverlay data-test-id={'avatar-context-menu-overlay'}
                    onClick={onClose}
                />)}
                <ContextMenuWrapper isOpen={isOpen} className={'context-menu-wrapper'}>
                    <ToolContent>
                        <Item data-test-id={'context-menu-profile'} onClick={onGoToProfileClick}>
                            <IconWrapper>
                                <ProfileSvg />
                            </IconWrapper>
                            <ToolLabel>Перейти в профиль</ToolLabel>
                        </Item>
                        <Item data-test-id={'context-menu-exit'} onClick={onLogoutClick}>
                            <IconWrapper>
                                <LogoutSvg />
                            </IconWrapper>
                            <LogoutToolLabel>Выйти</LogoutToolLabel>
                        </Item>
                    </ToolContent>
                    <Arrow />
                </ContextMenuWrapper>
                
            </>
    );
}