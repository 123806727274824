import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
`;

export const StatusActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & > button {
    justify-content: center;
    width: 160px;
  }
`;