import { Task, TaskStatuses } from "../../../modules/dashboard/modules/operations/stores/tasks.store";
import { TypeApiRoute } from "../../models/type.api.request";

type TypeRequest = {
};

type TypeResponse = {
    
};

export const createTask: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/task/`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};