import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useStore } from '../../../shared/utils/IoC';
import { UiStore } from '../../stores/ui.store';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { FieldWeather } from '../FieldWeather';
import { PlantProtection } from '../PlantProtection';
import { Forecast } from '../Forecast';
import { GetCkeckedImgUrl } from '../../utils/GetCkeckedImgUrl';

import Dummy_map_bg from './assets/dummy.svg';
import {
  FWSlider,
  MainInformation,
  SnapshotOfMap,
  NoPicDummyWrapper,
  PicDummy,
  DummyText,
} from './style';

export const FullWeatherSlider: FC = observer(() => {
  const uiStore = useStore(UiStore);
  const fieldsStore = useStore(FieldsStore);

  const [weatherPic, SetWeatherPic] = useState<string>('');

  const { selectedFieldId } = fieldsStore;

  const { showFullWeather } = uiStore;

  const idsPreventCloseFullWeatherMode = [
    'fieldsShortIcon',
    'seasonShortIcon',
    'fullContentAccordeon',
    'avatarImg',
    'avatarTitle',
  ];

  const fwSliderRef = useOnclickOutside((e: Event) => {
    if (!uiStore.showFullWeather) return;
    // @ts-ignore
    if (e.path && e.path.length) {
      // @ts-ignore
      for (const iterator of e.path) {
        // @ts-ignore
        if (
          iterator.hasAttribute &&
          (iterator.hasAttribute('id') || iterator.hasAttribute('class'))
        ) {
          if (iterator.hasAttribute('id') && idsPreventCloseFullWeatherMode.includes(iterator.id))
            return;
          if (iterator.hasAttribute('class') && iterator.classList.contains('fieldMenuButton'))
            return;
        }
      }
    }

    uiStore.setFullWeatherMode(false);
  });

  useEffect(() => {
    const selectedField = fieldsStore.idToFields.get(selectedFieldId);
    GetCkeckedImgUrl(`${selectedField?.weatherPic?.downloadUrl}`, Dummy_map_bg)
      .then(SetWeatherPic)
      .catch(console.error);
  }, [selectedFieldId]);

  return (
    <FWSlider data-visible={showFullWeather} visibility={showFullWeather} ref={fwSliderRef}>
      <MainInformation>
        <FieldWeather back_btn={true} forWeatherPage={true}/>
        {weatherPic && <SnapshotOfMap imgUrl={weatherPic} />}
        {!weatherPic && (
          <NoPicDummyWrapper>
            <PicDummy></PicDummy>
            <DummyText>Отображение карты временно недоступно</DummyText>
          </NoPicDummyWrapper>
        )}
      </MainInformation>
      <PlantProtection />
      <Forecast />
    </FWSlider>
  );
});
