import { FC, memo } from 'react';

import { DropdownMultiselectValue } from './components';
import Styled from './DropdownMultiselect.styles';
import { TDropdownMultiselectProps } from './DropdownMultiselect.types';

const DropdownMultiselect: FC<TDropdownMultiselectProps> = ({ selectValueList, onCloseClick }) => {
  return (
    <Styled.Wrapper>
      {selectValueList.map(selectValue => (
        <DropdownMultiselectValue
          key={selectValue.value}
          selectValue={selectValue}
          onCloseClick={onCloseClick}
        />
      ))}
    </Styled.Wrapper>
  );
};

DropdownMultiselect.displayName = 'DropdownMultiselect';

export default memo(DropdownMultiselect);
