import { IPutChecklistAttributeValue } from '../../../models/checklist/attribute/checklist.attribute.model';
import { TypeApiRoute } from '../../../models/type.api.request';

export interface IPostAttributeValuesPayload {
  checkListId: string;
  checkListInstanceId: string;
  intensityId: string;
  values: IPutChecklistAttributeValue[];
}

type TypeRequest = {} & IPostAttributeValuesPayload;

type TypeResponse = {};

export const postChecklistInstanceAttributeValues: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/checklist/instance/attributeValues`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
