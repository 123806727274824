import { forwardRef, memo, useCallback, useMemo } from 'react';

import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import { ImagesUploader } from '../../../../../../../tasks/containers/fullscreen/checklist/components/Phenophase/components/ImagesUploader/ImagesUploader';
import { useHandleChecklistAttrChange } from '../../../../../../hooks/useHandleChecklistAttrChange';
import { getChecklistAttributeId } from '../../../../../../utils/checklist.instances';
import { TChecklistAttributeProps } from '../../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';
import Styled from '../../../containers/ChecklistAttributeContainer/styles';
import {
  useGetChecklistAttrPlaceholder,
  useGetErrorListOfChecklistAttr,
} from '../../../hooks/attribute';
import { Input } from '../../shared/Input';

type TLongStringAttributeProps = {} & TChecklistAttributeProps;

const MAX_LONGSTRING_LENGTH = 4000;

const LongStringAttribute = forwardRef<HTMLInputElement, TLongStringAttributeProps>(
  ({ attributeWithValue, onChange, isBlocked, focusTarget }, ref) => {
    const { isRequired, attribute, id } = attributeWithValue.checklistAttribute;

    const {
      attributeValue,
      schema,
      isActive,
      attributeWithFileValue,
      checklistAttribute,
    } = attributeWithValue;

    const { partialValue, setPartialValue } = useHandleChecklistAttrChange(
      attributeWithValue,
      { longStringValue: attributeValue.longStringValue ?? '' },
      onChange
    );

    const placeholder = useGetChecklistAttrPlaceholder(
      checklistAttribute.attribute.type,
      checklistAttribute.attribute?.placeholder
    );

    const attributeId: string = useMemo(() => getChecklistAttributeId(id), [id]);

    const errorList = useGetErrorListOfChecklistAttr(schema, attributeWithFileValue?.schema);

    const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.scrollTo({ top: 0 });
    };

    const handleChange = useCallback(
      (longStringValue: string): void => setPartialValue({ longStringValue }),
      []
    );

    return (
      <>
        {isActive && (
          <Styled.Wrapper
            $reduceMarginBottom={attributeWithFileValue ? false : Boolean(errorList?.length)}
          >
            <Input
              isRequired={isRequired}
              label={attribute.name}
              placeholder={placeholder}
              value={partialValue.longStringValue ?? ''}
              onChange={handleChange}
              isBlocked={isBlocked}
              onBlur={onBlurHandler}
              error={errorList?.[0] || ''}
              isWithoutErrorText={true}
              id={attributeId}
              ref={id === focusTarget ? ref : null}
              maxLength={MAX_LONGSTRING_LENGTH}
              tooltip={checklistAttribute?.toolTip}
              as="textarea"
              isResizable={false}
              height="100px"
              dataTestId={`${attributeId}-longstring`}
              hideScrollbar
            />

            <Styled.Container>
              <Styled.InnerContainer>
                <InputFieldError error={{ errorList }} />
              </Styled.InnerContainer>
              <Styled.InnerContainer
                $isFullWidth={Boolean(attributeWithFileValue?.attributeValue?.fileValue?.length)}
              >
                {attributeWithFileValue && (
                  <ImagesUploader
                    onImgUrlsChange={onChange}
                    attributeWithValue={attributeWithFileValue}
                    isBlocked={isBlocked}
                    id={attributeId}
                  />
                )}
              </Styled.InnerContainer>
            </Styled.Container>
          </Styled.Wrapper>
        )}
      </>
    );
  }
);

LongStringAttribute.displayName = 'LongStringAttribute';

export default memo(LongStringAttribute);
