import { FC, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import { ExternalRedirect } from '../../../authorization/containters/AuthorizationWrapper';
import { ApplicationRoutes } from '../../../routes';

import { Profile } from './Profile';
import { ProfileRoutes } from './routes';
import { EditPersonalData } from './pages/editPersonalData';
import { ProfileGrid, ProfileLayoutWrapper } from './style';
import { ProfileStore } from './stores/ProfileStore';
import { OrganizationalRouting } from './pages/organizational/OrganizationalRouting';
import { EditCompetitionsPage } from './pages/editCompetitions/EditCompetitions';
import { EditInterestsPage } from './pages/editInterests/EditInterestsPage';
import { ProfileController } from './controllers/profile.controller';

export const ProfileRouter: FC = observer(() => {
  const profileStore = useStore(ProfileStore);
  const { user, loading } = profileStore;

  const profileController = useStore(ProfileController);

  const externalAgroidProfileUrl =
          process.env?.NODE_ENV === 'development'
            ? ApplicationRoutes.ExternalAgroidProfileDev
             : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalAgroidProfile}`;

  useEffect(() => {
    
    if (!user && !loading) {
      profileController.getUserInfo();
    }
    
  }, []);

  return (
    <ProfileLayoutWrapper>
      <ProfileGrid>
          <Switch>
            <ExternalRedirect redirectTo={externalAgroidProfileUrl}/>
            <Route path={ProfileRoutes.Organizations} component={OrganizationalRouting} />
            <Route path={ProfileRoutes.EditPersonalData} component={EditPersonalData} />
            <Route path={ProfileRoutes.EditCompetitionsData} component={EditCompetitionsPage} />
            <Route path={ProfileRoutes.EditInterestsData} component={EditInterestsPage} />
            <Route path={ProfileRoutes.Dashboard} exact component={Profile} />
          </Switch>
      </ProfileGrid>
    </ProfileLayoutWrapper>
  );
});
