import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import { OrganizationCard } from '../OrganizationCard';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { Modal } from '../../../../../../../../components/Modal/style';
import { CreateOraganizationWizard } from '../../../../modules/organizations/components/CreateOraganizationWizard';
import { OrganizationIncomeStore } from '../../stores/organization.income.store';
import { ApplicationType } from '../../types/ApplicationType';
import { OrganizationCard as OrganizationCardType } from '../../types/OrganizationCard';
import { ApplicationView } from '../ApplicationView';
import { OrganizationInvitationStore } from '../../stores/organization.invitation.store';
import { MyListsControlBar } from '../MyListsControllBar';
import { Grid, WidgetWrapper } from '../OrganizationList/style';

export const IncomeOrganizationsWidget: FC<RouteComponentProps> = observer(() => {
  const { fetchApplicationsAndInvites, invitations, idToInvitation } = useStore(
    OrganizationIncomeStore
  );
  const {
    acceptInviteFromOrganization,
    declineInviteFromOrganization,
    acceptApplicationFromUser,
    declineApplicationFromUser,
  } = useStore(OrganizationInvitationStore);

  const [createOrganizationFormShowed, setCreateOrganizationFormShowed] = useState(false);
  const [chosenApplication, setChosenApplication] = useState<ApplicationType | null>(null);

  useEffect(() => {
    fetchApplicationsAndInvites();
  }, [fetchApplicationsAndInvites]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickOnOrganization = (item: OrganizationCardType) => (organizationId: string) => {
    if (item.applicationId) {
      const application = idToInvitation.get(item.applicationId);

      if (
        application &&
        application.applicationId &&
        application.state &&
        application.organization
      ) {
        setChosenApplication({
          applicationId: application.applicationId,
          status: application.state,
          type: 'INVITATION',
          organization: application.organization,
          createdWhen: application.createdWhen || '',
          comment: application.comment || '',
        });
      }
    }
  };

  return (
    <WidgetWrapper>
      <MyListsControlBar />
      <Grid>
        {invitations &&
          invitations.map(item => (
            <OrganizationCard
              organizationId={item.organizationId}
              title={item.title}
              roleName={item.roleName}
              type={item.type}
              onClick={handleClickOnOrganization(item)}
              avatar={item.avatar}
              state={item.state}
            />
          ))}
      </Grid>
      <Modal
      // shown={createOrganizationFormShowed}
      // hideModal={() => {
      //   setCreateOrganizationFormShowed(false);
      // }}
      >
        <CreateOraganizationWizard
          hideWizard={() => {
            setCreateOrganizationFormShowed(false);
          }}
        />
      </Modal>

      {chosenApplication && (
        <Modal
        // shown={Boolean(chosenApplication)}
        // hideModal={() => {
        //   setChosenApplication(null);
        // }}
        >
          <ApplicationView
            hideApplicationView={() => {
              setChosenApplication(null);
              fetchApplicationsAndInvites();
            }}
            application={chosenApplication}
            acceptInviteFromOrganization={acceptInviteFromOrganization}
            declineInviteFromOrganization={declineInviteFromOrganization}
            acceptApplicationFromUser={acceptApplicationFromUser}
            declineApplicationFromUser={declineApplicationFromUser}
          />
        </Modal>
      )}
    </WidgetWrapper>
  );
});
