import { FC, memo } from 'react';

import { ReactComponent as BackArrow } from '../../../../static/backArrow.svg';

import * as Styled from './styles';

export type IFullscreenBackButtonProps = {
  backButtonHandler: () => void;
  backButtonText: string;
};

const FullscreenBackButton: FC<IFullscreenBackButtonProps> = props => {
  const { backButtonHandler, backButtonText } = props;

  return (
    <Styled.BackButton onClick={() => backButtonHandler()}>
      <BackArrow />
      <Styled.BackButtonTitle>{backButtonText}</Styled.BackButtonTitle>
    </Styled.BackButton>
  );
};

export default memo(FullscreenBackButton);
