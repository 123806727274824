import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { PaperBlock } from '../../../../../shared/components/PaperBlock';
// import EDIT_SVG from '../../../../../shared/assets/images/edit.svg';

import {
  ProfileWidgetInner,
  ProfileWidgetHeader,
  ProfileWidgetTitle,
  ProfileWidgetEditLink,
  // ProfileImage,
} from './style';

type Props = {
  title: string;
  editLink: string;
};

export const ProfileWidgetWrapper: FC<Props> = ({ title, editLink, children }) => {
  const history = useHistory();

  return (
    <PaperBlock>
      <ProfileWidgetInner>
        <ProfileWidgetHeader>
          <ProfileWidgetTitle>{title}</ProfileWidgetTitle>
          <ProfileWidgetEditLink
            onClick={() => history.push(editLink)}
            data-test-id={'profile-edit-link'}
          >
            {/* <ProfileImage src={EDIT_SVG} />*/}
            <p>Редактировать</p>
          </ProfileWidgetEditLink>
        </ProfileWidgetHeader>
        {children}
      </ProfileWidgetInner>
    </PaperBlock>
  );
};
