import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { Breakpoints } from '../../../../../shared/constans/sizes';

export const EditPersonalDataWrapper = styled.div`
  padding: 30px;

  @media (max-width: ${Breakpoints.extraSmall}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const EditPersonalDataHeader = styled.div`
  font-size: 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${Colors.lightnessGray};
`;

export const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 46px;
  padding-bottom: 46px;

  @media (max-width: ${Breakpoints.medium}) {
    padding-top: 0;
  }
  @media (max-width: ${Breakpoints.small}) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

export const BottomBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  @media (max-width: ${Breakpoints.small}) {
    flex-direction: column;
  }
`;

export const DesktopWrapper = styled.div`
  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  width: 100%;
  @media (max-width: ${Breakpoints.small}) {
    display: flex;
    flex-direction: column;
  }
`;

export const EditPersonalDataContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 12px;
`;

export const EditPersonalDataFormColumn = styled.div<{ techId?: number }>`
  width: 100%;
  max-width: 280px;
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: max-content;

  ${({ techId }) => techId === 9 && 'display: none;'}
  ${({ techId }) => techId === 10 && 'display: none;'}
  & .react-datepicker-wrapper {
    display: block;
  }

  @media (max-width: ${Breakpoints.large}) {
    max-width: 228px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    max-width: 148px;
    ${({ techId }) => techId === 2 && 'display: none;'}
    ${({ techId }) => techId === 3 && 'display: none;'}
    ${({ techId }) => techId === 9 && 'display: flex;'}
    ${({ techId }) => techId === 10 && 'display: flex;'}
    grid-gap: 40px;
  }

  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
    max-width: 100%;
    ${({ techId }) => techId === 9 && '&>div {width: 100%}'}
    ${({ techId }) => techId === 10 && '&>div {width: 100%}'};
    grid-gap: 4px;
    margin-top: 4px;
  }
`;

export const BlockWrapper = styled.div`
  width: 1068px;
  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
  }
`;

export const InvisibleRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: ${Breakpoints.medium}) {
    margin-top: 40px;
  }

  @media (max-width: ${Breakpoints.small}) {
    flex-direction: column;
    margin-top: 0;
    & > div {
      margin-top: 4px;
    }
  }
`;

export const AvatarEditPersonalDataFormColumn = styled(EditPersonalDataFormColumn)`
  @media (max-width: ${Breakpoints.medium}) {
    max-width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    //border-top: 1px solid ${Colors.grayBorder};
    border-bottom: 1px solid ${Colors.grayBorder};
  }
`;

export const EditPersonalDataEditInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const EditPersonalDataEditInputAction = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${Colors.green};
  cursor: pointer;
  font-size: 12px;

  & span {
    text-decoration: underline;
  }

  &:hover span {
    text-decoration: none;
  }
`;

export const EditPersonalDataEditFormAction = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: flex-end;
  @media (max-width: ${Breakpoints.small}) {
    margin-top: 24px;
    justify-content: center;
  }
  & > div:first-child {
    margin-left: 0;
  }
`;

export const EditPersonalDataEditFormActionButton = styled.div`
  width: 160px;
  margin-left: 20px;

  & button {
    justify-content: center;
    cursor: pointer;
  }
`;

export const ModalButtonWrapper = styled(EditPersonalDataEditFormActionButton)`
  width: 100%;
  margin-left: 0;
  margin-bottom: 10px;
`;

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 32px;
`;

export const ModalDescription = styled.div`
  font-size: 14px;
  padding-bottom: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${Colors.lightnessGray};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ModalAction = styled.div`
  margin-top: 28px;
`;

export const DatePickerWrapper = styled.div`
  & input {
    color: ${Colors.black};
    background: ${Colors.lightGray};
  }
`;
