import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { Field } from '../../../../api/models/field.model';
import { useStore } from '../../../shared/utils/IoC';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import {
  EFieldPointsMapMode,
  PointsOnTheMapWidgetProps,
} from '../../modules/tasks/components/Widgets/PointsOnTheMap';
import { ChecklistInstancesStore } from '../../modules/operationsAndTasks/stores/checklist.instances.store';
import { PointsMapStore } from '../../modules/tasks/stores/pointsMap.store';
import { ECheckListInstanceType } from '../../../../api/models/checklist/instance/checklist.instance.model';

import { StyledMapContainer } from './style';

export type FieldPointsMapProps = PointsOnTheMapWidgetProps;

export const FieldPointsMap: FC<FieldPointsMapProps> = observer((props: FieldPointsMapProps) => {
  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const pointsMapStore = useStore(PointsMapStore);
  const fieldsStore = useStore(FieldsStore);

  const drawMarkers = () => {
    console.log('drawmarkers className');
    console.log(checklistInstancesStore.positionToInstance);
    const selectMode =
      props.mode === EFieldPointsMapMode.READ_ONLY_WITH_SELECTED &&
      Boolean(pointsMapStore.selectedInstance);
    for (const key of pointsMapStore.idToMarker.keys()) {
      pointsMapStore.idToMarker.get(key).remove();
      pointsMapStore.idToMarker.delete(key);
    }
    for (const key of checklistInstancesStore.positionToInstance.keys()) {
      const instance = checklistInstancesStore.positionToInstance.get(key);
      const isSelected = pointsMapStore.selectedInstance === key;
      const coordinates =
        instance.type === ECheckListInstanceType.ActualPoint
          ? instance.actualCoords?.coordinates
          : instance.planCoords?.coordinates
          ? instance.planCoords.coordinates
          : instance.planCoords?.geometry?.coordinates;

      if (coordinates?.length) {
        pointsMapStore.setMarker(
          {
            lat: coordinates[1],
            lng: coordinates[0],
            number: key,
          },
          selectMode,
          isSelected
        );
      }
    }
  };

  useEffect(() => {
    if (pointsMapStore.instance) {
      pointsMapStore.setAllowSetMarker(
        props.mode === EFieldPointsMapMode.STANDART && props.allowAddPoints
      );
    }
  }, [props.mode, props.allowAddPoints]);

  useEffect(() => {
    pointsMapStore.setMapMode(props.mode);
    checklistInstancesStore.setField(props.field);
    pointsMapStore.initInstance();
    if (props.field) {
      pointsMapStore.setPolygon(props.field.geometry.coordinates);
      pointsMapStore.centerMapToPoint(false);
    }
    if (checklistInstancesStore.positionToInstance.size) {
      drawMarkers();
    }
    return () => {
      // pointsMapStore.removeInstance();
      // pointsMapStore.deletePolygon();
    };
  }, []);

  useEffect(() => {
    checklistInstancesStore.setField(props.field);

    if (!props.field) return;
    // pointsMapStore.initInstance();
    if (pointsMapStore.instance && props.field?.geometry?.coordinates) {
      pointsMapStore.setPolygon(props.field.geometry.coordinates);
      pointsMapStore.centerMapToPoint(false);
      if (checklistInstancesStore.positionToInstance.size) {
        drawMarkers();
      }
    }
  }, [
    props.field,
    checklistInstancesStore.positionToInstance,
    checklistInstancesStore.positionToInstance.size,
  ]);

  useEffect(() => {
    drawMarkers();
  }, [
    checklistInstancesStore.positionToInstance,
    pointsMapStore.selectedInstance,
    pointsMapStore.selectedInstance,
  ]);

  return <StyledMapContainer id="pointsMapId" />;
});
