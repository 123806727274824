import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useStore } from '../../../../../../../../shared/utils/IoC';
import { FullScreenMode, Task, TasksStore } from '../../../../../../operations/stores/tasks.store';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../../../stores/checklist.instances.store';
import {
  ChecklistFileUploaderStore,
  EFileLoadingStatus,
} from '../../../../../stores/checklist.fileUploader.store';
import { ESaveInstanceAttributeValuesResults } from '../../../../../utils/checklist.instances';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../../shared/constants/access-rules-action';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { CheckAccessStore } from '../../../../../../../../authorization/stores/checkAccess.store';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';

import * as Styled from './style';

type IControlButtonsProps = {
  task: Partial<Task>;
};

const ControlButtons: FC<IControlButtonsProps> = observer(({ task }) => {
  const { saveInstanceAttributeValues, isAllowToSaveChecklist, fetchInstanceList } = useStore(
    ChecklistInstancesController
  );

  const checklistStore = useStore(ChecklistInstancesStore);
  const { isChecklistViewMode, isChecklistEditMode, selectedInstId, getTechnique } = checklistStore;

  const { fileLoadingStatus } = useStore(ChecklistFileUploaderStore);

  const { setFullScreenMode, tasksMap } = useStore(TasksStore);

  const { openModalByModalId } = useModal();

  const profileStore = useStore(ProfileStore);
  const { getAccessRuleValue } = useStore(CheckAccessStore);
  const { selectedOrganizationId } = useStore(OrganizationsStore);

  const isOrgMy = selectedOrganizationId === 'my';

  const workWithTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS);
  const manageTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);
  const isUserAssignedToTask =
    tasksMap.get(checklistStore.taskId).assignee.id === profileStore.user.id;

  const handleSaveClick = async (): Promise<void> => {
    const savingResult = await saveInstanceAttributeValues();

    if (savingResult === ESaveInstanceAttributeValuesResults.success) {
      await fetchInstanceList(task.id);
      setFullScreenMode(FullScreenMode.TaskFullScreen);
    }

    if (savingResult === ESaveInstanceAttributeValuesResults.validationError) {
      return;
    }

    if (savingResult === ESaveInstanceAttributeValuesResults.saveError) {
      openModalByModalId('resendChecklistData', { resendHandler: handleSaveClick });
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.ButtonWrapper>
        {isChecklistEditMode && (
          <Button
            color="primary"
            type="button"
            disabled={!isAllowToSaveChecklist && fileLoadingStatus !== EFileLoadingStatus.pending}
            onClick={handleSaveClick}
          >
            {getTechnique(selectedInstId)?.id === selectedInstId
              ? 'Завершить заполнение'
              : 'Завершить осмотр в точке'}
          </Button>
        )}
        {isChecklistViewMode && (
          <Button color="default" type="button" onClick={() => setFullScreenMode(null)}>
            Вернуться назад
          </Button>
        )}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
});

export default ControlButtons;
