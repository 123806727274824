import { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { IPutChecklistAttributeValue } from '../../../../../../api/models/checklist/attribute/checklist.attribute.model';

import { TUseHandleChecklistAttrChange } from './useHandleChecklistAttrChange.types';

const useHandleChecklistAttrChange: TUseHandleChecklistAttrChange = (
  attrWithValue,
  defaultValue,
  onChange
) => {
  const [partialValue, setPartialValue] = useState<Partial<IPutChecklistAttributeValue>>(
    defaultValue
  );

  const [isEntered, setIsEntered] = useState<boolean>(false);

  const handleChecklistAttrChange = useCallback((value: Partial<IPutChecklistAttributeValue>) => {
    onChange?.({
      attributeWithValue: attrWithValue,
      value,
      drawingNestedInstanceId: attrWithValue?.drawingNestedInstanceId,
    });
  }, []);

  const throttledOnChange = useRef(
    _.throttle(
      (value: Partial<IPutChecklistAttributeValue>) => handleChecklistAttrChange(value),
      1000
    )
  );

  useEffect(() => {
    if (isEntered) {
      if (attrWithValue.schema.isShownError) {
        handleChecklistAttrChange(partialValue);
        setIsEntered(false);
      } else {
        throttledOnChange.current(partialValue);
        setIsEntered(false);
      }
    }
  }, [isEntered]);

  const handleChangePartialValue = useCallback(
    (value: Partial<IPutChecklistAttributeValue>): void => {
      setPartialValue(value);
      setIsEntered(true);
    },
    []
  );

  return {
    partialValue,
    setPartialValue: handleChangePartialValue,
  };
};

export default useHandleChecklistAttrChange;
