import { FC, useRef, useState } from 'react';
import { Link } from '@farmlink/farmik-ui';

import { toBase64 } from '../../../../../shared/utils/getBase64';
import AvatarIcon from '../../assets/user-avatar.svg';

import {
  Wrapper,
  Avatar,
  AvatarMock,
  Column,
  Title,
  Description,
  LinkWrapper,
  FileUploader,
  AvatarWrapper,
} from './style';

type AvatarUploaderProps = {
  title: string;
  icon: string;
  onUpload: (v: File) => void;
};

export const AvatarUploader: FC<AvatarUploaderProps> = ({ icon, onUpload, title }) => {
  const referrence = useRef(null);
  const [avatarPreview, setAvatarPreview] = useState('');

  const onChangeFile = async (event: any) => {
    if (event.target.files.length === 1) {
      onUpload(event.target.files[0]);
      const link = await toBase64(event.target.files[0]);

      setAvatarPreview(link as string);
    }
  };

  return (
    <Wrapper>
      <AvatarWrapper>
        {icon || avatarPreview ? (
          <Avatar src={avatarPreview ? avatarPreview : icon} />
        ) : (
          <AvatarMock>
            <img src={AvatarIcon} alt="" />
          </AvatarMock>
        )}
      </AvatarWrapper>
      <Column>
        <Title>Фотография {title}</Title>
        <LinkWrapper>
          <Link
            color={'primary'}
            // @ts-ignore
            onClick={() => referrence.current && referrence.current.click()}
            label={'Загрузить'}
          />
          <FileUploader
            type="file"
            name="file"
            ref={referrence}
            accept="image/jpeg,image/png,image/jpg"
            onChange={onChangeFile}
          />
        </LinkWrapper>
        <Description>
          Формат .png или .jpg
          <br />
          Максимальный размер 2Mb
        </Description>
      </Column>
    </Wrapper>
  );
};
