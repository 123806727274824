import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';

import { useStore } from '../../../../../../shared/utils/IoC';
import { FieldsStore } from '../../../../fields/stores/fields.store';
import { TaskStatusesButtonsBlock } from '../../../../operations/components/TaskStatusesButtonsBlock/TaskStatusesButtonsBlock';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';
import { TasksStore } from '../../../../operations/stores/tasks.store';
import PointsOnTheMapWidget, {
  EFieldPointsMapMode,
} from '../../../components/Widgets/PointsOnTheMap';
import { ChecklistInstancesStore } from '../../../../operationsAndTasks/stores/checklist.instances.store';
import Widget from '../../../components/Widgets/style';
import Fullscreen, { IFullscreenProps } from '../../fullscreen';
import { TechniqueWidget } from '../../../../operationsAndTasks/components/instance';
import { TasksController } from '../../../../operations/controllers/tasks.controller';

import CommonData, { ICommonDataProps } from './CommonData';
import ControlButtons, { IControlButtonsProps } from './ControlButtons';
import {
  NoPointsHeader,
  NoPointsText,
  PointsOnTheMapWidgetWrapper,
  TaskStatusesButtonsBlockWrapper,
} from './styles';

type IProps = { onAddButtonClick?: () => void } & IFullscreenProps &
  ICommonDataProps &
  IControlButtonsProps;

const TasksFullscreen: FC<IProps> = observer(props => {
  const {
    handleCloseDialog,
    actionType,
    action,
    CultureElement,
    OperationElement,
    FieldElement,
    AssigneeElement,
    PlanDateStartElement,
    PlanDateEndElement,
    CommentElement,
    ...fullscreenProps
  } = props;
  const { task } = useStore(TasksEditStore);
  const fieldsStore = useStore(FieldsStore);
  const taskStore = useStore(TasksStore);
  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const tasksController = useStore(TasksController);

  const disableSaveButton = useMemo(() => {
    return (
      !(task.operationInfo?.id || task.operationId) ||
      !(task.fieldId || task.field?.id) ||
      !task.priority ||
      !(task.assigneeId || task.assignee?.id) ||
      !task.planStartDate ||
      !task.planEndDate
    );
  }, [task]);

  return (
    <Fullscreen {...fullscreenProps}>
      <Widget title={'Общие данные'}>
        <CommonData
          CultureElement={CultureElement}
          OperationElement={OperationElement}
          FieldElement={FieldElement}
          AssigneeElement={AssigneeElement}
          PlanDateStartElement={PlanDateStartElement}
          PlanDateEndElement={PlanDateEndElement}
          CommentElement={CommentElement}
        />
      </Widget>
      <PointsOnTheMapWidgetWrapper>
        <PointsOnTheMapWidget
          mode={taskStore.isViewMode ? EFieldPointsMapMode.READ_ONLY : EFieldPointsMapMode.STANDART}
          taskId={task.id}
          onAddFieldClick={() => console.log('click')}
          field={checklistInstancesStore.field}
          noPointsTextBlock={
            <>
              <NoPointsHeader>У вас ещё нет добавленных точек</NoPointsHeader>
              <NoPointsText>
                Укажите точки в частях поля, где требуется ваше повышенное внимание — так вы сможете
                построить удобный маршрут и ничего не пропустить
              </NoPointsText>
            </>
          }
          title={'Точки осмотра'}
        />
      </PointsOnTheMapWidgetWrapper>

      {task?.checklistsMachineryAvailable ? (
        <TechniqueWidget onClick={props?.onAddButtonClick} />
      ) : null}

      {taskStore.isViewMode ? (
        <>
          {tasksController.isTaskAvailableForEdit(task) && (
            <TaskStatusesButtonsBlockWrapper>
              <TaskStatusesButtonsBlock task={task} handleCloseDialog={handleCloseDialog} />
            </TaskStatusesButtonsBlockWrapper>
          )}
        </>
      ) : (
        <ControlButtons
          disableSaveButton={disableSaveButton}
          handleCloseDialog={handleCloseDialog}
          actionType={actionType}
          action={action}
        />
      )}
    </Fullscreen>
  );
});

export default TasksFullscreen;
