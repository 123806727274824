import moment, { Moment } from 'moment';
import { forwardRef, memo, useCallback, useMemo } from 'react';

import { Calendar } from '../../../../../../../../../shared/components/Calendar/Calendar';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import { ImagesUploader } from '../../../../../../../tasks/containers/fullscreen/checklist/components/Phenophase/components/ImagesUploader/ImagesUploader';
import { getChecklistAttributeId } from '../../../../../../utils/checklist.instances';
import { TChecklistAttributeProps } from '../../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';
import StyledAttributeContainer, {
  EAttributeContainerMode,
} from '../../../containers/ChecklistAttributeContainer/styles';
import { useGetChecklistAttrPlaceholder } from '../../../hooks/attribute';

import Styled from './DateAttribute.styles';

type TDateAttributeProps = {} & TChecklistAttributeProps;

const DateAttribute = forwardRef<HTMLInputElement, TDateAttributeProps>(
  ({ attributeWithValue, onChange, isBlocked, focusTarget }, ref) => {
    const { isRequired, attribute, id } = attributeWithValue.checklistAttribute;

    const {
      attributeValue,
      drawingNestedInstanceId,
      schema,
      isActive,
      attributeWithFileValue,
      checklistAttribute,
    } = attributeWithValue;

    const seasonsStore = useStore(SeasonsStore);

    const attributeId: string = useMemo(() => getChecklistAttributeId(id), [id]);

    const placeholder = useGetChecklistAttrPlaceholder(
      checklistAttribute.attribute.type,
      checklistAttribute.attribute?.placeholder
    );

    const error: string = useMemo(() => {
      if (schema.isShownError) {
        return schema.error;
      } else {
        return '';
      }
    }, [schema]);

    const handleChange = useCallback((dateValue: Moment): void => {
      const parsedDateValue = dateValue.format('DD.MM.YYYY');

      onChange({
        attributeWithValue,
        value: { dateValue: parsedDateValue },
        drawingNestedInstanceId,
      });
    }, []);

    return (
      <>
        {isActive && (
          <StyledAttributeContainer.Wrapper
            $mode={EAttributeContainerMode.Half}
            $reduceMarginBottom={attributeWithFileValue ? false : Boolean(error)}
          >
            <Calendar
              label={attribute.name}
              value={
                attributeValue?.dateValue ? moment(attributeValue.dateValue, 'DD.MM.YYYY') : null
              }
              onChange={handleChange}
              placeholder={placeholder}
              minDate={moment(seasonsStore.selectedSeassonData.startDate, 'YYYY-MM-DD')}
              maxDate={moment(seasonsStore.selectedSeassonData.endDate, 'YYYY-MM-DD')}
              initialViewDate={moment(seasonsStore.selectedSeassonData.startDate, 'YYYY-MM-DD')}
              dataTestId={'checklist-date-attribute'}
              autoPlacement
              isRequired={isRequired}
              ref={id === focusTarget ? ref : null}
              isError={Boolean(error)}
              id={getChecklistAttributeId(id)}
              focusTarget={focusTarget}
              tooltip={checklistAttribute.toolTip}
              blocked={isBlocked}
            />

            {error && (
              <Styled.ErrorMessage data-test-id={'checklist-date-attribute_errorMessage'}>
                {error}
              </Styled.ErrorMessage>
            )}

            {attributeWithFileValue && (
              <ImagesUploader
                onImgUrlsChange={onChange}
                attributeWithValue={attributeWithFileValue}
                isBlocked={isBlocked}
                id={attributeId}
              />
            )}
          </StyledAttributeContainer.Wrapper>
        )}
      </>
    );
  }
);

DateAttribute.displayName = 'DateAttribute';

export default memo(DateAttribute);
