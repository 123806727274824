import moment from 'moment';

import { Operation } from '../../../../../api/models/operations/operation.model';
import { displayModalWindow } from '../../../../modals/config';
import { ModalsStore } from '../../../../modals/store/modals.store';
import { Axios } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { ETasksModalName, tasksModalUniqueKey } from '../modals/modalsConfig';
import { OperationsStore } from '../stores/operations.store';
import { SeasonCultureStore } from '../stores/season.culture.store';
import { TasksEditStore } from '../stores/task.edit.store';
import { Task, TasksStore } from '../stores/tasks.store';
import { ChecklistInstancesStore } from '../../operationsAndTasks/stores/checklist.instances.store';

import { TasksController } from './tasks.controller';
import { PreparedTask } from './tasks.listing.controller';

@provide.singleton()
export class TaskEditController {
  @lazyInject(Axios)
  axios: Axios;

  @lazyInject(TasksEditStore)
  taskEditStore: TasksEditStore;

  @lazyInject(OrganizationsStore)
  organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(SeasonCultureStore)
  protected seasonCulture: SeasonCultureStore;

  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  @lazyInject(OperationsStore)
  protected operationsStore: OperationsStore;

  @lazyInject(SeasonCultureStore)
  protected seasonCultureStore: SeasonCultureStore;

  @lazyInject(TasksController)
  protected tasksController: TasksController;

  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  @lazyInject(ChecklistInstancesStore)
  protected checklistInstancesStore: ChecklistInstancesStore;

  setSelectedFormCultureId = cultureId => {
    this.taskEditStore.cultureId = cultureId;
  };

  setSelectedFormOperationId = operationId => {
    this.taskEditStore.selectedOperationId = operationId;
  };

  setSelectedFormFieldId = fieldId => {
    this.taskEditStore.selectedFieldId = fieldId;
  };

  setAssigneeId = assigneeId => {
    this.taskEditStore.assigneeId = assigneeId;
  };

  getSeasonCultures = async (year, organizationId?) => {
    this.taskEditStore.culturesLoading = true;
    const response = await this.axios.api.getSeasonCulture(
      {
        year,
        organizationId: organizationId === 'my' ? undefined : organizationId,
      },
      { omit: ['year'] }
    );
    this.operationsStore.fieldsWithoutCulturesCount = response.fieldsWithoutCulturesCount;
    this.taskEditStore.cultureList = response;
    this.taskEditStore.culturesLoading = false;
  };

  getOperationsByCultureId = async (cultureId?) => {
    const organizationId =
      this.organizationsStore.selectedOrganizationId === 'my'
        ? ''
        : this.organizationsStore.selectedOrganizationId;
    this.taskEditStore.operationsLoading = true;
    let noCulture = false;
    let cultureIdForRequest = '';
    if (cultureId === '') {
      noCulture = true;
      cultureIdForRequest = undefined;
    } else {
      noCulture = undefined;
      cultureIdForRequest = cultureId;
    }

    const response = await this.axios.api.getOperations({
      organizationId,
      cultureId: cultureIdForRequest,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      noCulture,
      size: 10000,
    });
    this.taskEditStore.operations = response.content;
    this.taskEditStore.operationsLoading = false;
  };

  getFieldsOfOperation = async ({
    operationId,
    organizationId,
  }: {
    operationId?: string;
    organizationId?: string;
  }) => {
    this.taskEditStore.fieldsLoading = true;

    if (operationId) {
      const response = await this.axios.api.getOperationFields({
        operationId,
        organizationId: organizationId === 'my' ? '' : organizationId,
      });
      this.taskEditStore.operationFields = response;
      console.log(this.taskEditStore.operationFields);
      this.taskEditStore.fieldsLoading = false;
    }
  };

  getOrganizationUsersList = async (organizationId: string) => {
    const response = await this.axios.api.getOrganizationUsers({
      organizationID: organizationId === 'my' ? '' : organizationId,
      status: 'ACTIVE',
    });
    this.taskEditStore.usersList = response.content;
  };

  setTask = (task: Partial<Task> | PreparedTask) => {
    console.log('setTask');
    console.log(task);
    this.taskEditStore.task = {
      ...task,
      planStartDate: task.planStartDate ? moment(task.planStartDate) : undefined,
      planEndDate: task.planEndDate ? moment(task.planEndDate) : undefined,
    };
  };

  createTask = async () => {
    const { task } = this.taskEditStore;
    const payload: Partial<Task> = {
      ...this.taskEditStore.task,
      planStartDate: (task.planStartDate as moment.Moment).format('YYYY-MM-DD'),
      planEndDate: (task.planEndDate as moment.Moment).format('YYYY-MM-DD'),
      executedDate: undefined,
      assigneeId:
        this.organizationsStore.selectedOrganizationId === 'my'
          ? undefined
          : task.assigneeId
          ? task.assigneeId
          : (task as Task).assignee?.id,
    };

    const createTaskResponse = await this.axios.api.createTask(payload);

    if (!this.tasksStore.fromTasksPage) {
      this.tasksController.resetPageSettings();

      await this.tasksController.fetchTasks({
        organizationId: this.organizationsStore.selectedOrganizationId,
        seassonCultureId: this.seasonCultureStore.selectedCultureId,
        operationId: this.operationsStore.selectedOperationId,
        fieldId: this.operationsStore.selectedOperationFieldId,
        noCulture: this.seasonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
      });
    }
    console.log((createTaskResponse as any).id);
    return (createTaskResponse as any).id;
  };

  deleteTask = async (taskId: string) => {
    await this.axios.api.deleteTask({
      id: taskId,
    });
  };

  getStartDate = (operationParam: Operation, fromOperationChange?: boolean) => {
    let result;

    if (
      !fromOperationChange &&
      (this.taskEditStore.task.planStartDate || this.tasksStore.selectedTask.planStartDate)
    ) {
      result = moment(
        this.taskEditStore.task.planStartDate || this.tasksStore.selectedTask.planStartDate
      );
    } else if (operationParam) {
      const currentDate = moment();
      const startDate = moment(operationParam.startDate);
      const endDate = moment(operationParam.endDate).add(1, 'days');
      result = currentDate >= startDate && currentDate < endDate ? currentDate : null;
    }

    return result;
  };

  getModalTaskCompletedSuccessfully = (closeCallback): void => {
    const { setModal } = this.modalsStore;

    const successHandler = (): void => closeCallback();

    setModal(
      tasksModalUniqueKey,
      displayModalWindow(
        tasksModalUniqueKey,
        ETasksModalName.TaskCompletedSuccessfully,
        successHandler
      )
    );
  };

  getModalWarningBeforeChangeInstance = (closeCallback): void => {
    const { setModal } = this.modalsStore;

    const successHandler = (): void => {
      closeCallback();
      // Обнуляем усоловия при  которых отображается модалка если выходим из редактирования/создания задачи
      this.checklistInstancesStore.setHasPositionToInstanceChanged(false);
      this.clearTouchedFields();
    };

    setModal(
      tasksModalUniqueKey,
      displayModalWindow(
        tasksModalUniqueKey,
        ETasksModalName.WarningBeforeChangeInstance,
        successHandler
      )
    );
  };

  getModalTaskCreationError = (): void => {
    const { setModal } = this.modalsStore;

    setModal(
      tasksModalUniqueKey,
      displayModalWindow(tasksModalUniqueKey, ETasksModalName.TaskCreationError)
    );
  };

  addFiledToTouchedFields = (fieldName: string) => this.taskEditStore.touchedFields.add(fieldName);
  removeFiledToTouchedFields = (fieldName: string) =>
    this.taskEditStore.touchedFields.delete(fieldName);
  clearTouchedFields = () => {
    this.taskEditStore.touchedFields = new Set<string>();
  };

  isCultureSelectDisabled = (): boolean =>
    Boolean(this.taskEditStore.task.id) || this.taskEditStore.culturesLoading;

  isOperationSelectDisabled = (): boolean =>
    Boolean(this.taskEditStore.task.id) ||
    !this.taskEditStore.cultureId ||
    this.taskEditStore.culturesLoading ||
    this.taskEditStore.operationsLoading;

  isFiledSelectDisabled = (): boolean =>
    Boolean(this.taskEditStore.task.id) ||
    !this.taskEditStore.cultureId ||
    this.taskEditStore.culturesLoading ||
    !(this.taskEditStore.task.operationInfo?.id || this.taskEditStore.task.operationId) ||
    this.taskEditStore.operationsLoading ||
    this.taskEditStore.fieldsLoading;

  isAssigneeSelectDisabled = (): boolean => this.organizationsStore.selectedOrganizationId === 'my';

  clearStore = () => {
    this.taskEditStore.assigneeId = '';
    this.taskEditStore.cultureId = '';
    this.taskEditStore.cultureList = null;
    this.taskEditStore.operationFields = [];
    this.taskEditStore.operations = [];
    this.taskEditStore.selectedFieldId = '';
    this.taskEditStore.selectedOperationId = '';
    this.taskEditStore.task = {};
    this.taskEditStore.usersList = [];
    this.taskEditStore.presetDateFromTaskList = null;
    this.taskEditStore.touchedFields = new Set();
  };
}
