import { action, computed, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios, TypeApiResponse } from '../../../../shared/utils/axios2';

export type ExpertiseType = {
  expertiseId: string;
  expertiseName: string;
  confirmations: number;
};

@provide.singleton()
export class EditInterestsStore {
  @lazyInject(Axios)
  axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  idToInterest: Map<string, any> = new Map();

  @computed
  get interests() {
    return Array.from(this.idToInterest.values());
  }

  @action
  addToInterests = async () => {
    const user = await this.axios.api.getProfile({});
    user.interests.forEach(item => this.idToInterest.set(item.interestId, item));
  };

  @action
  selectExpertise = (value: string, label: string) => {
    this.selectedInterest = {
      interestName: label,
      interestId: value,
    };
  };

  selectedInterest: any = {
    interestId: '',
    interestName: '',
  };

  @action
  addToList = () => {
    if (!this.selectedInterest.interestId) {
      return;
    }

    this.idToInterest.set(this.selectedInterest.interestId, this.selectedInterest);
    this.searchExpertise = '';
    this.selectedInterest = {
      interestId: '',
      interestName: '',
    };
    this.fetchExpertiseList();
  };

  searchExpertise = '';

  expertiseOptions: Array<{ value: string; label: string; active?: boolean }> = [];

  @action
  setExpertise = (v: string) => {
    this.searchExpertise = v;
    this.selectedInterest = {
      interestId: '',
      interestName: '',
    };
    this.fetchExpertiseList();
  };

  @action
  deleteExpertise = (id: string) => {
    this.idToInterest.delete(id);
    this.fetchExpertiseList();
  };

  @action
  fetchExpertiseList = async () => {
    await this.axios.api
      .getInterests({ page: 0, size: 10, search: this.searchExpertise })
      .then(this.onFetchExpertiseListSuccess)
      .catch(this.onFetchExpertiseListError);
  };

  @action.bound
  protected onFetchExpertiseListSuccess = (response: TypeApiResponse<'getInterests'>) => {
    this.expertiseOptions = response.content.map(i => {
      return {
        value: i.interestId,
        label: i.interestName,
        active: Boolean(this.idToInterest.get(i.interestId)),
      };
    });
  };

  @action.bound
  protected onFetchExpertiseListError = () => {
    console.warn('onFetchExpertiseListError');
  };

  @action
  saveExpertises = async () => {
    await this.axios.api.saveInterestsToProfile({
      ids: this.interests.length > 0 ? this.interests.map(i => i.interestId) : [],
    });
  };

  @action
  reset = () => {
    this.idToInterest.clear();
    this.expertiseOptions = [];
    this.selectedInterest = {
      interestId: '',
      interestName: '',
    };
    this.searchExpertise = '';
  };
}
