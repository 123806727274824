import { makeAutoObservable } from 'mobx';

import { Field } from '../../../../../../api/models/field.model';
import { IGetImportedField } from '../../../../../../api/models/fields/getImportedField.model';
import { provide } from '../../../../../shared/utils/IoC';

@provide.singleton()
class FieldsImportStore {
  private _isDataBeingProcessed = false;

  private _changedImportedFieldProps: Partial<Field> | null = null;

  private _idToImportedField: Map<string, IGetImportedField> = new Map<string, IGetImportedField>();

  constructor() {
    makeAutoObservable(this);
  }

  get isDataBeingProcessed() {
    return this._isDataBeingProcessed;
  }

  get listOfImportedField() {
    return Array.from(this._idToImportedField.values());
  }

  get changedImportedFieldProps() {
    return this._changedImportedFieldProps;
  }

  setIsDataBeingProcessed = (value: boolean): void => {
    this._isDataBeingProcessed = value;
  };

  setChangedImportedFieldProps = (props: Partial<Field>): void => {
    this._changedImportedFieldProps = props;
  };

  setIdToImportedField = (listOfImportedField: IGetImportedField[] = []): void => {
    const newIdToImportedField: Map<string, IGetImportedField> = new Map<
      string,
      IGetImportedField
    >();

    if (!listOfImportedField?.length) {
      return;
    }

    listOfImportedField.forEach(field => newIdToImportedField.set(field.id, field));

    this._idToImportedField = newIdToImportedField;
  };

  clearIsDataBeingProcessed = (): void => {
    this._isDataBeingProcessed = false;
  };

  clearChangedImportedFieldProps = (): void => {
    this._changedImportedFieldProps = null;
  };

  clearIdToImportedField = (): void => {
    this._idToImportedField.clear();
  };

  clearStore = (): void => {
    this.clearIsDataBeingProcessed();
    this.clearChangedImportedFieldProps();

    this.clearIdToImportedField();
  };
}

export default FieldsImportStore;
