import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { useStore } from '../shared/utils/IoC';
import { ApplicationRoutes } from '../routes';
import { AuthorizationWrapper } from '../authorization/containters/AuthorizationWrapper';
import { TipWrapper } from '../shared/containers/TipWrapper';
import { DeviceScreenDummy } from '../../components/DeviceScreenDummy';
import { AdminPanel } from '../../components/AdminPanel/AdminPanel';
import { MobilePlaceHolder } from '../MobilePlaceHolder';
import MapStore from '../map/stores/map.store';

import { Layout, RightColumn, SideBarWrapper } from './components/DashboardLayout/style';
import { Sidebar } from './components/Sidebar/Sidebar';
import { AdaptiveSidebar } from './components/AdaptiveSidebar/AdaptiveSidebar';
import { LeaveUnitModalDialog } from './components/LeaveUnitModalDialog/LeaveUnitModalDialog';
import { OrganizationRouter } from './organization.router';
import { FieldsStore } from './modules/fields/stores/fields.store';

interface IParams {
  orgId: string;
  id: string;
}

export const DashboardRouter: FC = () => {
  const { orgId } = useParams<IParams>();
  const mapStore = useStore(MapStore);
  const fieldsStore = useStore(FieldsStore);

  useEffect(() => {
    mapStore.clearPolygons();
    fieldsStore.clearIdToFields();
  }, [orgId]);

  const authPath =
    process.env?.NODE_ENV === 'development'
      ? ApplicationRoutes.ExternalAuthorizationDev
      : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalAuthorization}`;

  return (
    <>
      <AuthorizationWrapper excludedRoutes={[]} isZoneForAuthorized={true} redirectTo={authPath}>
        <MobilePlaceHolder />
        <TipWrapper children={null} />
        <AdaptiveSidebar />
        <AdminPanel />
        <Layout>
          <DeviceScreenDummy />
          <LeaveUnitModalDialog />
          <SideBarWrapper>
            <Sidebar isAdaptive={false} />
          </SideBarWrapper>

          <RightColumn>
            <Switch>
              <Route exact path={'/:orgId/*'} component={OrganizationRouter} />
              <Redirect to={`${orgId}/fields`} />
            </Switch>
          </RightColumn>
        </Layout>
      </AuthorizationWrapper>
    </>
  );
};
