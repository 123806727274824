import { ModalFooter } from '@farmlink/farmik-ui';
import { TModalButton } from '@farmlink/farmik-ui/dist/feature/Modal/components/Modal/Modal';
import { FC, memo, useMemo } from 'react';

import Styled from './FieldsImportSomethingWentWrongModal.styles';
import { TFieldsImportSomethingWentWrongModalProps } from './FieldsImportSomethingWentWrongModal.types';

const FieldsImportSomethingWentWrongModal: FC<TFieldsImportSomethingWentWrongModalProps> = ({
  onFileUploaderClick,
  closeModal,
}) => {
  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Выбрать файл',
      handler: onFileUploaderClick,
    };
  }, []);

  const denyButton = useMemo<TModalButton>(() => {
    return {
      title: 'Отменить',
      handler: () => closeModal(),
    };
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Title>Кажется, что-то пошло не так :(</Styled.Title>

      <Styled.Description>Попробуйте загрузить файл повторно или выберите новый</Styled.Description>

      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </Styled.Wrapper>
  );
};

FieldsImportSomethingWentWrongModal.displayName = 'FieldsImportSomethingWentWrongModal';

export default memo(FieldsImportSomethingWentWrongModal);
