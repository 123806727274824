import { FC } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { ReactComponent as MobilePlaceholderSVG } from '../static/mobilePlaceholder.svg';

import { useStore } from './shared/utils/IoC';
import { UiStore } from './dashboard/stores/ui.store';
import { Colors } from './shared/constans/colors';

export const MobilePlaceHolder: FC = observer(() => {
  const ui = useStore(UiStore);
  const showMobilePlaceHolder = ui.pageWidth <= 428 || ui.pageHeight <= 428;

  if (!showMobilePlaceHolder) return null;
  
  return (
    <MainBlock>
      <MobilePlaceholderSVG />
      <Header1>
        В настоящий момент сервис доступен для работы только на десктопных и планшeтных устройствах
      </Header1>
      <Header2>По любым интересующим вопросам Вы можете написать нам на электронную почту</Header2>
      <MailToLink href="mailto: info@digitalagro.ru">info@digitalagro.ru</MailToLink>
      <Description>
        Мобильное приложение для iOS и Android находятся в стадии разработки и скоро будут доступны
        для скачивания
      </Description>
    </MainBlock>
  );
});

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  text-align: center;
  position: fixed;
  z-index: 2000;
  background: ${Colors.white};
  top: 0;
  left: 0;
  right: 0;
  @media (min-width: 375px) {
    padding: 32px;
  }
`;

const Header1 = styled.h1`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 32px;
`;

const Header2 = styled.h2`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  align-text: center;
  margin-top: 32px;
`;
const MailToLink = styled.a`
  width: 100%;
  height: 56px;
  border-radius: 20px;
  background-color: #3fb65f;
  color: #fff;
  font-size: 14px;
  line-height: 56px;
  font-weight: 600;
  margin-top: 20px;
`;

const Description = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  align-text: center;
  color: #9aa0ad;
  margin-top: 44px;
`;
