import { lazyInject, provide } from '../../../../../shared/utils/IoC';
import { Axios, TypeApiResponse } from '../../../../../shared/utils/axios2';
import { ProfileStore } from '../../../profile/stores/ProfileStore';

@provide.singleton()
export class ChecklistFileUploaderService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  protected serviceName = 'da-profile';

  uploadFile = (file: File): Promise<TypeApiResponse<'uploadFile'>> => {
    return this.axios.api.uploadFile({
      fileContentType: file.type,
      fileName: file.name,
      fileSize: file.size,
      serviceName: this.serviceName,
    });
  };

  finalFileUpload = (response, uploadFileBuffer: File) => {
    return this.axios.api.uploadFileFinish({ data: uploadFileBuffer, url: response.uploadUrl })
  };
}
