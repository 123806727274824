import { useMemo } from 'react';

import { IDrawingNestedInstance } from '../../../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';

interface INestedInstanceList {
  nestedInstanceList: IDrawingNestedInstance[];
  templateNestedInstance: IDrawingNestedInstance | null;
}

export const useGetAvailableDrawingNestedInstanceList = (
  drawingNestedInstanceList: IDrawingNestedInstance[] = [],
  rootAttributeId: string
): INestedInstanceList => {
  const initialDrawingNestedInstanceList: INestedInstanceList = {
    nestedInstanceList: [],
    templateNestedInstance: null,
  };

  return useMemo(() => {
    return drawingNestedInstanceList.reduce<INestedInstanceList>((acc, instance) => {
      if (instance.rootChecklistAttribute.id === rootAttributeId && !instance.isTemplate) {
        acc.nestedInstanceList = [...(acc?.nestedInstanceList || []), instance];
      }

      if (instance.rootChecklistAttribute.id === rootAttributeId && instance.isTemplate) {
        acc.templateNestedInstance = instance;
      }

      return acc;
    }, initialDrawingNestedInstanceList);
  }, [drawingNestedInstanceList]);
};
