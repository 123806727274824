import { FC, useState } from 'react';
import { Button, Icon } from '@farmlink/farmik-ui';

import { ApplicationType } from '../../types/ApplicationType';
import { List, ListItemStatus } from '../../../../../../../shared/components/List';
import success from '../../../../../../../shared/assets/images/success.png';
import cancel from '../../../../../../../shared/assets/images/cancel.png';
import { Colors } from '../../../../../../../shared/constans/colors';

import {
  ActionsWrapper,
  Comment,
  ImageWrapper,
  InfoTitle,
  ListWrapper,
  ModalHeader,
  Orgtitle,
} from './style';

type Props = {
  application: ApplicationType;
  hideApplicationView: () => void;
  acceptInviteFromOrganization: (applicationId: string) => Promise<any>;
  declineInviteFromOrganization: (applicationId: string) => Promise<any>;
  acceptApplicationFromUser: (applicationId: string) => Promise<any>;
  declineApplicationFromUser: (applicationId: string) => Promise<any>;
  deleteInvite?: (applicationId: string) => Promise<any>;
  isOutgoing?: boolean;
};

const statusInvitationMap = {
  SENT: 'Новое',
  ACCEPTED: 'Принято',
  DECLINED: 'Отклонено',
  CANCELLED: 'Отменено',
};

const statusApplicationMap = {
  SENT: 'Новая заявка',
  ACCEPTED: 'Принята',
  DECLINED: 'Отклонена',
  CANCELLED: 'Отменена',
};

const listStatusMap = {
  SENT: 'success',
  ACCEPTED: 'success',
  DECLINED: 'error',
  CANCELLED: 'error',
};

export const ApplicationView: FC<Props> = ({
  application,
  hideApplicationView,
  acceptInviteFromOrganization,
  declineInviteFromOrganization,
  acceptApplicationFromUser,
  declineApplicationFromUser,
  deleteInvite,
  isOutgoing = false,
}) => {
  const { organization, status, createdWhen, comment, applicationId, type } = application;

  const [operation, setOperation] = useState<'accept' | 'decline' | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleAccept = () => {
    if (type === 'INVITATION') {
      acceptInviteFromOrganization(applicationId).then(() => {
        setOperation('accept');
      });
    } else {
      acceptApplicationFromUser(applicationId).then(() => {
        setOperation('accept');
      });
    }
  };

  const handleDecline = () => {
    if (type !== 'INVITATION') {
      declineApplicationFromUser(applicationId).then(() => {
        setOperation('decline');
      });
    } else {
      declineInviteFromOrganization(applicationId).then(() => {
        setOperation('decline');
      });
    }
  };

  if (operation === 'accept') {
    return (
      <>
        <ImageWrapper>
          <img src={success} alt="" />
        </ImageWrapper>
        <InfoTitle>{type === 'INVITATION' ? 'Приглашение принято' : 'Заявка принята'}</InfoTitle>
        <ActionsWrapper>
          <Button type="button" color="primary" onClick={hideApplicationView}>
            Хорошо
          </Button>
        </ActionsWrapper>
      </>
    );
  }

  if (operation === 'decline') {
    return (
      <>
        <ImageWrapper>
          <img src={cancel} alt="" />
        </ImageWrapper>
        <InfoTitle>
          {type === 'INVITATION' ? 'Приглашение отклонено' : 'Заявка отклонена'}
        </InfoTitle>
        <ActionsWrapper>
          <Button type="button" color="primary" onClick={hideApplicationView}>
            Хорошо
          </Button>
        </ActionsWrapper>
      </>
    );
  }

  if (showDeleteDialog) {
    return (
      <>
        <ImageWrapper>
          <img src={cancel} alt="" />
        </ImageWrapper>
        <InfoTitle>Вы уверены, что хотите удалить заявку?</InfoTitle>
        <ActionsWrapper>
          <Button
            type="button"
            color="default"
            onClick={() => {
              setShowDeleteDialog(false);
            }}
          >
            Отмена
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              if (deleteInvite) {
                deleteInvite(applicationId).then(() => {
                  hideApplicationView();
                });
              }
            }}
          >
            Удалить
          </Button>
        </ActionsWrapper>
      </>
    );
  }

  return (
    <>
      <ModalHeader>
        {type === 'INVITATION' ? 'Приглашение' : 'Заявка'}
        {status === 'SENT' && type === 'APPLICATION' && (
          <span
            onClick={() => {
              setShowDeleteDialog(true);
            }}
          >
            Удалить <Icon size="small" fill={Colors.red} icon="close" />
          </span>
        )}
      </ModalHeader>
      <Orgtitle>{organization && organization.name}</Orgtitle>
      <ListWrapper>
        <List
          items={[
            {
              key: 'Статус',
              value:
                type === 'INVITATION' ? statusInvitationMap[status] : statusApplicationMap[status],
              status: listStatusMap[status] as ListItemStatus,
            },
            {
              key: 'ИНН',
              value: organization ? (organization.inn ? organization.inn : '') : '',
            },
            {
              key: 'Дата заявки',
              value: createdWhen,
            },
          ]}
        />
      </ListWrapper>
      <Comment>{comment}</Comment>
      {!isOutgoing && (
        <ActionsWrapper>
          <Button
            disabled={status !== 'SENT' || (status === 'SENT' && type === 'APPLICATION')}
            type="button"
            color="default"
            onClick={handleDecline}
          >
            Отклонить
          </Button>
          <Button
            disabled={status !== 'SENT' || (status === 'SENT' && type === 'APPLICATION')}
            type="button"
            color="primary"
            onClick={handleAccept}
          >
            Принять
          </Button>
        </ActionsWrapper>
      )}
    </>
  );
};
