import { useCallback } from 'react';

import { TUseDropdownIconHandlers } from './useDropdownIconHandlers.types';

const useDropdownIconHandlers: TUseDropdownIconHandlers = ({ state, setState, props }) => {
  const handleClearClick = useCallback(() => {
    const valueAfterCleaning = props.config.field?.icons?.clear?.options?.valueAfterCleaning;

    const isShowWarning = props.config?.validation?.modal?.isShowWarning;

    props.config.field.onChange('');

    if (isShowWarning) {
      setState({
        temporaryData: {
          onChangeData: {
            option: valueAfterCleaning || null,
          },
        },
      });

      return;
    }

    setState({
      values: {
        ...state.values,
        searchValue: null,
        selectValue: valueAfterCleaning || null,
        selectValueList: [],
      },
      settings: { ...state.settings, isSearchFieldFocused: false, isDropped: false },
    });
  }, [
    state,
    props.config.field?.icons?.clear?.options?.valueAfterCleaning,
    props.config?.validation?.modal,
    props.config.field.onChange,
  ]);

  const handleArrowClick = useCallback(() => {
    setState({ settings: { ...state.settings, isDropped: !state.settings.isDropped } });
  }, [state]);

  const handleCloseClick = useCallback(
    (value: string) => {
      const listOfValWithoutDeleted = [...state.values.selectValueList].filter(
        selectValue => selectValue.value !== value
      );

      props.config.field.onChange('', {
        selectedValueList: listOfValWithoutDeleted,
        optionList: props.config.body.optionList,
      });

      setState({
        values: {
          ...state.values,
          selectValueList: listOfValWithoutDeleted,
        },
      });
    },
    [state, props]
  );

  const handlers: ReturnType<TUseDropdownIconHandlers> = {
    onClearClick: handleClearClick,
    onArrowClick: handleArrowClick,
    onCloseClick: handleCloseClick,
  };

  return handlers;
};

export default useDropdownIconHandlers;
