import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { Breakpoints } from '../../../../../shared/constans/sizes';

import TrasparentSVG from './transparent.svg';

export const Wrapper = styled.div`
  height: 312px;
  width: 100%;
  border-radius: 16px;
  background: ${Colors.white};
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${Colors.grayBorder};
`;

export const Content = styled.div`
  width: 100%;
`;

export const EditZoneNotificationWrapper = styled.div`
  width: 100%;
  height: 200px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
  }
`;
export const ButtonsWrapper = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: row;
`;

export const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 220px;
  width: 100%;
  padding-bottom: 300px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const CultureRow = styled.div<{ isActive: boolean }>`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & input,
  & .date-picker {
    ${({ isActive }) => isActive && `border-color: ${Colors.green}`}
  }
  @media (max-width: ${Breakpoints.medium}) {
    padding-left: 12px;
    padding-right: 12px;
  }

  justify-content: space-between;
  & .rdtPicker {
    bottom: 42px;
  }
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MarginBase = styled.div`
  margin-right: 24px;
  @media (max-width: 1912px) {
    margin-right: 16px;
  }
  @media (max-width: ${Breakpoints.large}) {
    margin-right: 8px;
  }
`;

export const PalleteWrapper = styled(MarginBase)`
  width: 40px;
`;

export const AreaWrapper = styled(MarginBase)`
  max-width: 100;
  min-width: 72px;
  flex: 0 0 72px;
`;

export const CultureWrapper = styled(MarginBase)`
  min-width: 100px;
  max-width: 496px;
  width: 100%;
`;

export const VarietyWrapper = styled(MarginBase)`
  min-width: 84px;
  max-width: 496px;
  width: 100%;
`;

export const SowingWrapper = styled(MarginBase)`
  min-width: 117px;
  max-width: 320px;
  width: 100%;
`;

export const HarvestWrapper = styled(MarginBase)`
  min-width: 118px;
  max-width: 320px;
  width: 100%;
`;

export const EditWrapper = styled(MarginBase)`
  width: 40px;
`;

export const DeleteWrapper = styled.div`
  width: 40px;
`;

export const Pallete = styled.div<{ colorFill?: string; transparent?: boolean }>`
  background: ${({ colorFill }) => colorFill};
  background: ${({ transparent }) => transparent && `url(${TrasparentSVG})`};

  border-radius: 48px;
  width: 40px;
  height: 40px;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15px;
  color: ${Colors.black};
  margin: 0;
`;

export const HeaderButtonWrapper = styled.div`
  text-align: center;

  > button {
    display: inline-block;
  }
`;

export const BinButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${Colors.grayBorder};
  background: ${Colors.white};
  border-radius: 16px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${Colors.red};
  }
`;

export const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${Colors.grayBorder};
  background: ${Colors.white};
  border-radius: 16px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${Colors.green};
  }
`;

export const AddNewLink = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: 12px;
  svg {
    width: 8px;
    height: 8px;
    fill: ${Colors.green};
    margin-right: 6px;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: ${Colors.green};
  }
`;
