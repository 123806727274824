import styled from 'styled-components';

export const ValueContainer = styled.div`
  padding: 10px 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #151f32;
  position: relative;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
