const monthsName = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const formatDateToNameString = (date: string) => {
  const [year, month, day] = date.split('-');

  return `${day} ${monthsName[Number(month) - 1]} ${year}`;
};
