import { FC } from 'react';
// eslint-disable-next-line import/order
import DatetimeRangePicker from 'react-datetime-range-picker';
import 'react-datetime/css/react-datetime.css';
import moment, { Moment } from 'moment';

import { DateInput, Icon, Label, Wrapper, Placeholder } from './style';
import { ReactComponent as CalendarSvg } from './icon.svg';

type CalendarProps = {
  label?: string;
  placeholder?: string;
  value?: Moment;
  onChange: (v: Moment | string | { start: Date; end: Date }) => void;
  minDate?: Moment;
  maxDate?: Moment;
  dataTestId?: string;
  onOpen?: () => void;
  dateFormat?: string;
  startDate: Moment;
  endDate: Moment;
};

export const CalendarRange: FC<CalendarProps> = ({
  label,
  placeholder,
  value,
  onChange,
  maxDate,
  minDate,
  dataTestId,
  dateFormat,
  startDate,
  endDate,
}) => {
  const renderInput = (props, openCalendar, closeCalendar) => {
    const handleClick = () => {
      openCalendar();
    };

    return (
      <DateInput onClick={handleClick} data-test-id={dataTestId} className={'date-picker'}>
        {props.value ? props.value : <Placeholder>{placeholder}</Placeholder>}
        <Icon>
          <CalendarSvg />{' '}
        </Icon>
      </DateInput>
    );
  };

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <DateInput data-test-id={dataTestId} className={'date-picker'}>
        <DatetimeRangePicker
          startDate={startDate}
          endDate={endDate}
          className={'datetime-range-custom'}
          onChange={onChange}
          closeOnSelect={true}
          dateFormat={dateFormat || 'DD/MM/YYYY'}
          timeFormat={''}
          renderInput={renderInput}
          inline={true}
          startTimeConstraints={minDate}
          endTimeConstraints={maxDate}
          isValidStartDate={currentDate => {
            const momentDate = moment(currentDate);
            if (
              (minDate && momentDate.isBefore(minDate)) ||
              (maxDate && momentDate.isAfter(maxDate))
            ) {
              return false;
            }

            return true;
          }}
          isValidEndDate={currentDate => {
            const momentDate = moment(currentDate);
            if (
              (minDate && momentDate.isBefore(minDate)) ||
              (maxDate && momentDate.isAfter(maxDate))
            ) {
              return false;
            }

            return true;
          }}
        />
        <Icon>
          <CalendarSvg />{' '}
        </Icon>
      </DateInput>
    </Wrapper>
  );
};
