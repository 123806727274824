import {
  IChecklistAttributeWithValue,
  IPutChecklistAttributeValue,
} from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ISelectOption } from '../../../../../../../../../../../types/selectOption';

const isExactlyThisAttr = (
  firstAttr: IChecklistAttributeWithValue,
  secondAttr: IChecklistAttributeWithValue
): boolean => {
  return firstAttr.checklistAttribute.id === secondAttr.checklistAttribute.id;
};

const isBelongToTheThisParentAttr = (
  attr: IChecklistAttributeWithValue,
  parentAttr: IChecklistAttributeWithValue
): boolean => {
  return attr.checklistAttribute?.parentId === parentAttr.checklistAttribute.id;
};

const isBelongToTheProgenitor = (
  attr: IChecklistAttributeWithValue,
  progenitorAttr: IChecklistAttributeWithValue
): boolean => {
  return attr?.progenitorId === progenitorAttr.checklistAttribute.id;
};

const getChangedAttr = (
  attrWithValue: IChecklistAttributeWithValue,
  value: Partial<IPutChecklistAttributeValue>,
  listOfOptionValue?: ISelectOption[]
): IChecklistAttributeWithValue => ({
  ...attrWithValue,
  attributeValue: { checkListAttributeId: attrWithValue.checklistAttribute.id, ...value },
  schema: {
    ...attrWithValue.schema,
    isShownError: false,
  },
  listOfOptionValue: listOfOptionValue?.length ? listOfOptionValue : null,
});

const getChangedBoolAttr = (
  attrWithValue: IChecklistAttributeWithValue,
  value: Partial<IPutChecklistAttributeValue>
): IChecklistAttributeWithValue => ({
  ...attrWithValue,
  attributeValue: { checkListAttributeId: attrWithValue.checklistAttribute.id, ...value },
  attributeWithFileValue: attrWithValue?.attributeWithFileValue
    ? {
        ...attrWithValue.attributeWithFileValue,
        isActive: Boolean(value?.booleanValue),
        schema: {
          ...attrWithValue.schema,
          isShownError: false,
        },
      }
    : null,
  children: attrWithValue?.children
    ? attrWithValue.children.map<IChecklistAttributeWithValue>(childAttrWithValue => ({
        ...childAttrWithValue,
        isActive: Boolean(value?.booleanValue),
      }))
    : null,
  schema: {
    ...attrWithValue.schema,
    isShownError: false,
  },
});

const getChangedParentAttr = (
  parentAttrWithValue: IChecklistAttributeWithValue,
  attrWithValue: IChecklistAttributeWithValue,
  value: Partial<IPutChecklistAttributeValue>,
  listOfOptionValue?: ISelectOption[]
): IChecklistAttributeWithValue => ({
  ...parentAttrWithValue,
  children: parentAttrWithValue.children.map<IChecklistAttributeWithValue>(childAttrWithValue => {
    if (isExactlyThisAttr(attrWithValue, childAttrWithValue)) {
      return getChangedAttr(childAttrWithValue, value, listOfOptionValue);
    }

    return childAttrWithValue;
  }),
});

const getChangedParentAttrWithFile = (
  parentAttrWithValue: IChecklistAttributeWithValue,
  value: Partial<IPutChecklistAttributeValue>
): IChecklistAttributeWithValue => ({
  ...parentAttrWithValue,
  attributeWithFileValue: {
    ...parentAttrWithValue.attributeWithFileValue,
    attributeValue: {
      checkListAttributeId: parentAttrWithValue.attributeWithFileValue.checklistAttribute.id,
      ...value,
    },
    schema: {
      ...parentAttrWithValue.attributeWithFileValue.schema,
      isShownError: false,
    },
  },
});

const getChangedProgenitorAttrWithFile = (
  progenitorAttrWithValue: IChecklistAttributeWithValue,
  attrWithFileValue: IChecklistAttributeWithValue,
  value: Partial<IPutChecklistAttributeValue>
): IChecklistAttributeWithValue => ({
  ...progenitorAttrWithValue,
  children: progenitorAttrWithValue.children.map<IChecklistAttributeWithValue>(
    childAttrWithValue => {
      if (isBelongToTheThisParentAttr(attrWithFileValue, childAttrWithValue)) {
        return getChangedParentAttrWithFile(childAttrWithValue, value);
      }

      return childAttrWithValue;
    }
  ),
});

const checklistAttrChangeHelpers = {
  isExactlyThisAttr,
  isBelongToTheThisParentAttr,
  isBelongToTheProgenitor,
  getChangedAttr,
  getChangedBoolAttr,
  getChangedParentAttr,
  getChangedParentAttrWithFile,
  getChangedProgenitorAttrWithFile,
};

export default checklistAttrChangeHelpers;
