import { FC } from 'react';
import styled from 'styled-components';

import { Container } from '../../Container';
import { TechniqueListContainer } from '../TechniqueListContainer';

type TTechniqueWidgetProps = { onClick?: () => void };

const TechniqueWidget: FC<TTechniqueWidgetProps> = ({ onClick }) => {
  return (
    <Container title="Техника">
      <StyledContainer>
        <TechniqueListContainer onClick={onClick} />
      </StyledContainer>
    </Container>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  padding: 8px;
`;

export default TechniqueWidget;
