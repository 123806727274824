import { v4 } from 'uuid';
import _, { uniqBy } from 'lodash';

import {
  EChecklistAttributeType,
  IChecklistAttributeWithValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeEnumValue,
  IGetChecklistAttributeUserDictionary,
  IGetChecklistAttributeValue,
  IPutChecklistAttributeValue,
} from '../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  IDrawingNestedInstance,
  IPutChecklistInstance,
  IPutNestedChecklistInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import {
  IChecklistDrawingStage,
  IGetChecklistStage,
} from '../../../../../api/models/checklist/stage/checklist.stage.model';
import { IGetDictionary } from '../../../../../api/models/dictionary/dictionary.model';
import { ISelectOption } from '../../../../../types/selectOption';
import {
  checklistAttrErrorHelpers,
  checklistAttrTypeHelpers,
} from '../modules/fullscreen/checklist/utils/attribute/helpers';

export const DEFAULT_INTENSITY_OPTION: ISelectOption = { label: 'Не выбрана', value: 'Не выбрана' };

const hasNumberValue = (value): boolean => {
  const isEmpty: boolean = value === null || value === '';

  return !isEmpty;
};

export const getChecklistAttributeId = (id: string): string => `checklist-attribute-id-${id}`;

export const isNeedToAdjustPenultimateAttributeMargin = (
  attributeWithValueList: IChecklistAttributeWithValue[]
): boolean => {
  if (attributeWithValueList?.length < 2) return false;

  const reversedAttributeWithValueList = [...attributeWithValueList].reverse();

  const normalAttributeList: IChecklistAttributeWithValue[] = [];

  for (const attributeWithValue of reversedAttributeWithValueList) {
    if (
      attributeWithValue.checklistAttribute.attribute.type === EChecklistAttributeType.Boolean ||
      attributeWithValue.checklistAttribute.attribute.type ===
        EChecklistAttributeType.ChecklistInstanceLink
    ) {
      break;
    }

    if (attributeWithValue.isActive) {
      normalAttributeList.push(attributeWithValue);
    }
  }

  if (normalAttributeList.length % 2 !== 0) {
    return false;
  } else {
    return true;
  }
};

const createNamePart = (attrName: string, value): string => {
  return `${attrName}: ${value}`;
};

type TGenerateNestedInstanceTitle = (config: {
  attributeWithValueList: IChecklistAttributeWithValue[];
  enumList: IGetChecklistAttributeEnumValue[];
  userDictionaryList: IGetChecklistAttributeUserDictionary[];
  dictionaryList: IGetDictionary[];
}) => IGenerateNestedInstanceTitleResult;

export interface IGenerateNestedInstanceTitleResult {
  titleValue: string;
  subTitleValue: string;
}

export const generateNestedInstanceTitle: TGenerateNestedInstanceTitle = ({
  attributeWithValueList,
  enumList,
  userDictionaryList,
  dictionaryList,
}) => {
  const subTitleList = [];
  const titleValue = attributeWithValueList
    .reduce<string[]>((titleList, { checklistAttribute, attributeValue }) => {
      switch (checklistAttribute.attribute.type) {
        case EChecklistAttributeType.String: {
          if (attributeValue?.stringValue) {
            if (!checklistAttribute.isTitle) {
              subTitleList.push(
                `${checklistAttribute.attribute.name}: ${attributeValue?.stringValue}`
              );
            } else {
              titleList.push(attributeValue?.stringValue);
            }
          }

          return titleList;
        }
        case EChecklistAttributeType.Double: {
          if (attributeValue?.doubleValue) {
            const parsedDoubleValue = `${checklistAttribute.attribute.name}: ${attributeValue?.doubleValue}`;
            if (!checklistAttribute.isTitle) {
              subTitleList.push(parsedDoubleValue);
            } else {
              titleList.push(parsedDoubleValue);
            }
          }

          return titleList;
        }
        case EChecklistAttributeType.Int: {
          if (attributeValue?.integerValue) {
            const parsedIntegerValue = `${checklistAttribute.attribute.name}: ${attributeValue?.integerValue}`;
            if (!checklistAttribute.isTitle) {
              subTitleList.push(parsedIntegerValue);
            } else {
              titleList.push(parsedIntegerValue);
            }
          }

          return titleList;
        }

        case EChecklistAttributeType.Enum: {
          const enumValueToReturn = enumList.find(
            enumValue => enumValue.id === attributeValue?.enumValues?.[0]
          )?.value;

          if (enumValueToReturn) {
            if (!checklistAttribute.isTitle) {
              subTitleList.push(`${checklistAttribute.attribute.name}: ${enumValueToReturn}`);
            } else {
              titleList.push(enumValueToReturn);
            }
          }

          return titleList;
        }
        case EChecklistAttributeType.UserDictionaryLink: {
          const addedUserDictVal = userDictionaryList.find(
            val => val.id === attributeValue?.userDictionaryValues?.[0]?.id
          )?.value;

          if (addedUserDictVal) {
            const namePart = createNamePart(checklistAttribute.attribute.name, addedUserDictVal);

            if (!checklistAttribute.isTitle) {
              subTitleList.push(namePart);
            } else {
              titleList.push(addedUserDictVal);
            }

            return titleList;
          }

          const userDictVal = userDictionaryList.find(
            val => val.id === attributeValue?.userDictionaryValues?.[0]?.id
          )?.value;

          if (userDictVal) {
            const namePart = createNamePart(checklistAttribute.attribute.name, userDictVal);

            if (!checklistAttribute.isTitle) {
              subTitleList.push(namePart);
            } else {
              titleList.push(userDictVal);
            }

            return titleList;
          }

          return titleList;
        }
        case EChecklistAttributeType.DictionaryLink: {
          const dictionaryValueToReturn = dictionaryList.find(
            dictionaryValue => dictionaryValue.id === attributeValue?.dictionaryValueList?.[0]
          )?.name;

          if (dictionaryValueToReturn) {
            if (!checklistAttribute.isTitle) {
              subTitleList.push(`${checklistAttribute.attribute.name}: ${dictionaryValueToReturn}`);
            } else {
              titleList.push(dictionaryValueToReturn);
            }
          }

          return titleList;
        }
        default:
          return titleList;
      }
    }, [])
    .join('. ');

  const subTitleValue = subTitleList.join('. ');

  return {
    titleValue,
    subTitleValue,
  };
};

export type THandleChangeChecklistAttributeValue = (config: {
  attributeWithValue: IChecklistAttributeWithValue;
  value: Partial<IPutChecklistAttributeValue>;
  drawingNestedInstanceId?: string;
  listOfOptionValue?: ISelectOption[];
}) => void;

export enum ESaveInstanceAttributeValuesResults {
  success = 'SUCCESS',
  validationError = 'VALIDATION_ERROR',
  saveError = 'SAVE_ERROR',
}

const getChecklistAttributeValue = (
  checklistAttribute: IGetChecklistAttribute,
  attributeValue?: IGetChecklistAttributeValue
): IPutChecklistAttributeValue => {
  switch (checklistAttribute.attribute.type) {
    case EChecklistAttributeType.Int:
      return {
        checkListAttributeId: checklistAttribute.id,
        integerValue: attributeValue?.integerValue ?? null,
      };
    case EChecklistAttributeType.Double:
      return {
        checkListAttributeId: checklistAttribute.id,
        doubleValue: attributeValue?.doubleValue ?? null,
      };

    case EChecklistAttributeType.String:
      return {
        checkListAttributeId: checklistAttribute.id,
        stringValue: attributeValue?.stringValue || null,
      };
    case EChecklistAttributeType.LongString:
      return {
        checkListAttributeId: checklistAttribute.id,
        longStringValue: attributeValue?.longStringValue || null,
      };
    case EChecklistAttributeType.Boolean:
      return {
        checkListAttributeId: checklistAttribute.id,
        booleanValue: attributeValue?.booleanValue || false,
      };

    case EChecklistAttributeType.Enum:
      return {
        checkListAttributeId: checklistAttribute.id,
        enumValues: attributeValue?.enumValues?.map(({ id }) => id) || [],
      };
    case EChecklistAttributeType.UserDictionaryLink:
      return {
        checkListAttributeId: checklistAttribute.id,
        userDictionaryValues: attributeValue?.userDictionaryValues || [],
      };
    case EChecklistAttributeType.DictionaryLink:
      return {
        checkListAttributeId: checklistAttribute.id,
        dictionaryValueList: attributeValue?.dictionaryValueList?.map(({ id }) => id) || [],
      };
    case EChecklistAttributeType.ChecklistInstanceLink:
      return {
        checkListAttributeId: checklistAttribute.id,
        checkListInstanceValue: [],
      };

    case EChecklistAttributeType.FileLink:
      return {
        checkListAttributeId: checklistAttribute.id,
        fileValue: attributeValue?.fileValue?.map(({ id }) => id) || [],
      };

    case EChecklistAttributeType.Date:
      return {
        checkListAttributeId: checklistAttribute.id,
        dateValue: attributeValue?.dateValue || null,
      };

    default:
      return null;
  }
};

const getListOfOptionVal = (
  checklistAttribute: IGetChecklistAttribute,
  listOfAttrVal: IGetChecklistAttributeValue[] = [],
  listOfAttrWithVal?: IChecklistAttributeWithValue[]
): ISelectOption[] => {
  if (listOfAttrWithVal?.length) {
    const foundAttr = listOfAttrWithVal.find(
      attrWithVal => attrWithVal.checklistAttribute.id === checklistAttribute.id
    );

    if (foundAttr) {
      return foundAttr.listOfOptionValue;
    }
  }

  return listOfAttrVal.reduce<ISelectOption[]>((listOfOptionVal, attrVal) => {
    const isEnum = checklistAttribute.attribute.type === EChecklistAttributeType.Enum;
    const isDict = checklistAttribute.attribute.type === EChecklistAttributeType.DictionaryLink;
    const isUserDict =
      checklistAttribute.attribute.type === EChecklistAttributeType.UserDictionaryLink;

    const isValueOfThisAttr = checklistAttribute.id === attrVal?.checkListAttributeId;

    if (isValueOfThisAttr && isEnum) {
      const optionList = attrVal?.enumValues?.map<ISelectOption>(val => ({
        label: val.value,
        value: val.id,
      }));

      listOfOptionVal.push(...(optionList || []));

      return listOfOptionVal;
    }

    if (isValueOfThisAttr && isDict) {
      const optionList = attrVal?.dictionaryValueList?.map<ISelectOption>(val => ({
        label: val.name,
        value: val.id,
      }));

      listOfOptionVal.push(...(optionList || []));

      return listOfOptionVal;
    }

    if (isValueOfThisAttr && isUserDict) {
      const optionList = attrVal?.userDictionaryValues?.map<ISelectOption>(val => ({
        label: val.value,
        value: val.id,
      }));

      listOfOptionVal.push(...(optionList || []));

      return listOfOptionVal;
    }

    return listOfOptionVal;
  }, []);
};

type TAttributeWithValueConfig = {
  stageId: string;
  checklistAttribute: IGetChecklistAttribute;
  getAttributeValueList?: IGetChecklistAttributeValue[];
  putAttributeValueList?: IPutChecklistAttributeValue[];
  drawingNestedInstanceId?: string;
  listOfAttrWithVal?: IChecklistAttributeWithValue[];
};

type TGetChecklistAttributeWithValue = (
  config: TAttributeWithValueConfig
) => IChecklistAttributeWithValue;

export const getChecklistAttributeWithValue: TGetChecklistAttributeWithValue = ({
  stageId,
  checklistAttribute,
  drawingNestedInstanceId,
  getAttributeValueList,
  putAttributeValueList,
  listOfAttrWithVal,
}) => {
  const { getRequiredText } = checklistAttrErrorHelpers;

  const attributeWithValue: IChecklistAttributeWithValue = {
    stageId,
    checklistAttribute,
    attributeValue: getChecklistAttributeValue(checklistAttribute),
    isActive: !checklistAttribute?.parentId,
    drawingNestedInstanceId,
    schema: {
      error: checklistAttribute.isRequired
        ? getRequiredText(checklistAttribute.attribute.type)
        : '',
      isShownError: false,
    },
    listOfOptionValue: getListOfOptionVal(
      checklistAttribute,
      getAttributeValueList || [],
      listOfAttrWithVal
    ),
  };

  if (getAttributeValueList?.length) {
    getAttributeValueList.forEach(attributeValue => {
      if (attributeValue.checkListAttributeId === checklistAttribute.id) {
        attributeWithValue.attributeValue = getChecklistAttributeValue(
          checklistAttribute,
          attributeValue
        );
      }

      if (checklistAttribute?.parentId === attributeValue?.checkListAttributeId) {
        attributeWithValue.isActive = attributeValue.booleanValue ?? attributeWithValue.isActive;
      }
    });

    return attributeWithValue;
  }

  if (putAttributeValueList?.length) {
    putAttributeValueList.forEach(attributeValue => {
      if (attributeValue.checkListAttributeId === checklistAttribute.id) {
        attributeWithValue.attributeValue = attributeValue;

        if (checklistAttribute?.parentId === attributeValue?.checkListAttributeId) {
          attributeWithValue.isActive = attributeValue.booleanValue ?? attributeWithValue.isActive;
        }
      }
    });

    return attributeWithValue;
  }

  return attributeWithValue;
};

const createListOfAttrWithValueAndWithAddedFileAttr = (
  listOfAttrWithValue: IChecklistAttributeWithValue[]
): IChecklistAttributeWithValue[] => {
  return listOfAttrWithValue.map((attrWithValue, _index, comparableListOfAttributeWithValue) => {
    const { hasChildren } = attrWithValue.checklistAttribute;

    if (hasChildren) {
      return comparableListOfAttributeWithValue.reduce<IChecklistAttributeWithValue>(
        (attrWithAddedFileAttr, comparableAttrWithValue) => {
          const isChild: boolean =
            comparableAttrWithValue.checklistAttribute?.parentId ===
            attrWithValue.checklistAttribute.id;

          const isFileLink: boolean =
            comparableAttrWithValue.checklistAttribute.attribute.type ===
            EChecklistAttributeType.FileLink;

          if (isChild && isFileLink) {
            const isBooleanType: boolean =
              attrWithValue.checklistAttribute.attribute.type === EChecklistAttributeType.Boolean;

            if (isBooleanType) {
              attrWithAddedFileAttr.attributeWithFileValue = {
                ...comparableAttrWithValue,
                isActive: Boolean(attrWithValue.attributeValue?.booleanValue),
              };
            } else {
              attrWithAddedFileAttr.attributeWithFileValue = {
                ...comparableAttrWithValue,
                isActive: true,
              };
            }
          }

          return attrWithAddedFileAttr;
        },
        attrWithValue
      );
    }

    return attrWithValue;
  });
};

const createListOfAttrWithValueAndWithAddedChildren = (
  listOfAttrWithValue: IChecklistAttributeWithValue[]
): IChecklistAttributeWithValue[] => {
  const listOfAttrWithValueToOmit: IChecklistAttributeWithValue[] = [];

  const listOfAttrWithValueAndWithAddedChildren = listOfAttrWithValue.map(
    (attrWithValue, _index, comparableListOfAttributeWithValue) => {
      const { hasChildren } = attrWithValue.checklistAttribute;

      const isBooleanType: boolean =
        attrWithValue.checklistAttribute.attribute.type === EChecklistAttributeType.Boolean;

      if (hasChildren && isBooleanType) {
        const newAttrWithValue: IChecklistAttributeWithValue = comparableListOfAttributeWithValue.reduce<IChecklistAttributeWithValue>(
          (attrWithAddedChildren, comparableAttrWithValue) => {
            const isChild: boolean =
              comparableAttrWithValue.checklistAttribute?.parentId ===
              attrWithValue.checklistAttribute.id;

            const isNotFileLink: boolean =
              comparableAttrWithValue.checklistAttribute.attribute.type !==
              EChecklistAttributeType.FileLink;

            if (isChild && isNotFileLink) {
              attrWithAddedChildren.children = [
                ...(attrWithAddedChildren?.children || []),
                {
                  ...comparableAttrWithValue,
                  isActive: Boolean(attrWithValue.attributeValue?.booleanValue),
                  attributeWithFileValue: comparableAttrWithValue?.attributeWithFileValue
                    ? {
                        ...comparableAttrWithValue?.attributeWithFileValue,
                        progenitorId: attrWithValue.checklistAttribute.id,
                      }
                    : null,
                },
              ];

              listOfAttrWithValueToOmit.push(comparableAttrWithValue);
            }

            return attrWithAddedChildren;
          },
          attrWithValue
        );

        if (newAttrWithValue?.children?.length) {
          newAttrWithValue.children = _.sortBy(
            newAttrWithValue.children,
            'checklistAttribute.order'
          );
        }

        return newAttrWithValue;
      }

      return attrWithValue;
    }
  );

  if (listOfAttrWithValueToOmit.length) {
    const listOfAttrWithValueWithoutFile = listOfAttrWithValueAndWithAddedChildren.filter(
      attrWithValue =>
        attrWithValue.checklistAttribute.attribute.type !== EChecklistAttributeType.FileLink
    );

    return _.without(listOfAttrWithValueWithoutFile, ...listOfAttrWithValueToOmit);
  }

  return listOfAttrWithValueAndWithAddedChildren;
};

type TGenerateAttributeWithValueList = (config: {
  stageId: string;
  stage?: IGetChecklistStage;
  listOfChecklistAttribute: IGetChecklistAttribute[];
  getAttributeValueList?: IGetChecklistAttributeValue[];
  putAttributeValueList?: IPutChecklistAttributeValue[];
  drawingNestedInstanceId?: string;
  listOfAttrWithVal?: IChecklistAttributeWithValue[];
}) => IChecklistAttributeWithValue[];

export const generateAttributeWithValueList: TGenerateAttributeWithValueList = ({
  stageId,
  stage,
  listOfChecklistAttribute = [],
  drawingNestedInstanceId,
  getAttributeValueList,
  putAttributeValueList,
  listOfAttrWithVal,
}) => {
  /**
   * Возвращаем массив из новых объектов, что являются атрибутами,
   * но с коллекцией различных данных, необходимых для отображения на странице
   */
  const listOfAttrWithValue = listOfChecklistAttribute.reduce<IChecklistAttributeWithValue[]>(
    /**
     * Если это атрибуты вложенного чек-листа,
     * то просто складываем созданный объект в массив
     */
    (returnListOfAttrWithValue, checklistAttribute) => {
      if (drawingNestedInstanceId) {
        returnListOfAttrWithValue.push(
          getChecklistAttributeWithValue({
            stageId,
            checklistAttribute,
            getAttributeValueList,
            drawingNestedInstanceId,
            putAttributeValueList,
            listOfAttrWithVal,
          })
        );

        return returnListOfAttrWithValue;
      }

      // Условие: принадлежит ли проверяемый атрибут переданному стэйджу
      const isAttributeOfThisStage: boolean = stage?.id === checklistAttribute.stageId;

      /**
       * Условие: принадлежит ли проверяемый атрибут основному стэйджу
       * (основной стэйдж - этой стэйдж, который создается с порядковым номером 0 и складывает
       * в себя те атрибуты, которым не был присвоен никакой из стэйджей)
       */
      const isAttributeOfHeadStage: boolean = stage?.order === 0 && !checklistAttribute.stageId;

      // Если одно из условий верно, то создаем объект и складываем его в массив
      if (isAttributeOfThisStage || isAttributeOfHeadStage) {
        returnListOfAttrWithValue.push(
          getChecklistAttributeWithValue({
            stageId,
            checklistAttribute,
            getAttributeValueList,
            drawingNestedInstanceId,
            putAttributeValueList,
          })
        );

        return returnListOfAttrWithValue;
      }

      return returnListOfAttrWithValue;
    },
    []
  );

  /**
   * Возвращаем массив из ранее созданных объектов,
   * но с добавленным новым, что является атрибутом-файлом
   */
  const listOfAttrWithValueAndWithAddedFileAttr = createListOfAttrWithValueAndWithAddedFileAttr(
    listOfAttrWithValue
  );

  /**
   * Далее делаем еще одну итерацию, где атрибутам добавляем массив из детей,
   * но с добавленными файлами
   */
  const listOfAttrWithValueAndWithAddedChildren = createListOfAttrWithValueAndWithAddedChildren(
    listOfAttrWithValueAndWithAddedFileAttr
  );

  return listOfAttrWithValueAndWithAddedChildren;
};

export const generateDrawingStage = (
  stage: IGetChecklistStage,
  checklistAttributeList: IGetChecklistAttribute[],
  getAttributeValueList: IGetChecklistAttributeValue[]
): IChecklistDrawingStage => {
  const drawingStage: IChecklistDrawingStage = {
    ...stage,
    attributeWithValueList: generateAttributeWithValueList({
      stageId: stage.id,
      stage,
      listOfChecklistAttribute: checklistAttributeList,
      getAttributeValueList,
    }),
    isActive: false,
  };

  return { ...drawingStage, isActive: Boolean(drawingStage.attributeWithValueList.length) };
};

type TGenerateDrawingNestedInstance = (config: {
  rootChecklistAttribute: IGetChecklistAttribute;
  attributeList: IGetChecklistAttribute[];
  getAttributeValueList?: IGetChecklistAttributeValue[];
  putAttributeValueList?: IPutChecklistAttributeValue[];
  isTemplate?: boolean;
  listOfAttrWithVal?: IChecklistAttributeWithValue[];
}) => IDrawingNestedInstance;

export const generateDrawingNestedInstance: TGenerateDrawingNestedInstance = ({
  rootChecklistAttribute,
  attributeList,
  getAttributeValueList,
  putAttributeValueList,
  isTemplate,
  listOfAttrWithVal,
}) => {
  const nestedInstance: IPutNestedChecklistInstance = {
    checkListId: rootChecklistAttribute.attribute.checklistLink,
    values: [],
  };

  const drawingNestedInstance: IDrawingNestedInstance = {
    id: v4(),
    rootChecklistAttribute,
    nestedInstance,
    attributeWithValueList: [],
    isTemplate,
    isCollapsed: Boolean(isTemplate),
  };

  return {
    ...drawingNestedInstance,
    attributeWithValueList: generateAttributeWithValueList({
      stageId: rootChecklistAttribute.id,
      listOfChecklistAttribute: attributeList,
      getAttributeValueList,
      putAttributeValueList,
      drawingNestedInstanceId: drawingNestedInstance.id,
      listOfAttrWithVal,
    }),
  };
};

export const hasAttributeValue = (
  attributeWithValue: IChecklistAttributeWithValue,
  attributeValue?: Partial<IPutChecklistAttributeValue>
): boolean => {
  const attributeValueForValidation = attributeValue || attributeWithValue.attributeValue;

  switch (attributeWithValue.checklistAttribute.attribute.type) {
    case EChecklistAttributeType.Int: {
      return hasNumberValue(attributeValueForValidation?.integerValue);
    }
    case EChecklistAttributeType.Double: {
      return hasNumberValue(attributeValueForValidation?.doubleValue);
    }
    case EChecklistAttributeType.String: {
      return Boolean(attributeValueForValidation?.stringValue);
    }
    case EChecklistAttributeType.LongString: {
      return Boolean(attributeValueForValidation?.longStringValue);
    }
    case EChecklistAttributeType.Boolean: {
      return true;
    }
    case EChecklistAttributeType.Enum: {
      return Boolean(attributeValueForValidation?.enumValues?.length);
    }
    case EChecklistAttributeType.UserDictionaryLink: {
      return Boolean(attributeValueForValidation?.userDictionaryValues?.length);
    }
    case EChecklistAttributeType.DictionaryLink: {
      return Boolean(attributeValueForValidation?.dictionaryValueList?.length);
    }
    case EChecklistAttributeType.FileLink: {
      return Boolean(attributeValueForValidation?.fileValue?.length);
    }
    case EChecklistAttributeType.ChecklistInstanceLink: {
      return Boolean(attributeValueForValidation?.checkListInstanceValue?.length);
    }
    case EChecklistAttributeType.Date: {
      return Boolean(attributeValueForValidation?.dateValue?.length);
    }
    default:
      return true;
  }
};

const createListOfAttrVal = (
  listOfAttrWithVal: IChecklistAttributeWithValue[] = []
): IPutChecklistAttributeValue[] => {
  return listOfAttrWithVal.reduce<IPutChecklistAttributeValue[]>((listOfAttrVal, attrWithVal) => {
    listOfAttrVal.push(attrWithVal.attributeValue);

    const hasChildren = attrWithVal?.children;
    const hasFileValue = attrWithVal?.attributeWithFileValue;

    if (hasFileValue) {
      listOfAttrVal.push(attrWithVal.attributeWithFileValue.attributeValue);
    }

    if (hasChildren) {
      listOfAttrVal.push(...createListOfAttrVal(attrWithVal.children));
    }

    return listOfAttrVal;
  }, []);
};

const createListOfPutNestedInst = (
  attrWithVal: IChecklistAttributeWithValue,
  listOfDrawNestedInst: IDrawingNestedInstance[] = []
): IPutNestedChecklistInstance[] => {
  return listOfDrawNestedInst.reduce<IPutNestedChecklistInstance[]>((listOfPutInst, drawInst) => {
    const isExactlyThisAttr: boolean =
      drawInst.rootChecklistAttribute.id === attrWithVal.checklistAttribute.id;

    const isDoNotTemplate = !drawInst.isTemplate;

    if (isExactlyThisAttr && isDoNotTemplate) {
      listOfPutInst.push({
        ...drawInst.nestedInstance,
        values: uniqBy(
          createListOfAttrVal(drawInst.attributeWithValueList),
          'checkListAttributeId'
        ),
      });
    }
    return listOfPutInst;
  }, []);
};

const getConvertedAttrWithValueListToDesiredFormat = (
  attributeWithValueList: IChecklistAttributeWithValue[],
  drawingNestedInstanceList?: IDrawingNestedInstance[]
): IChecklistAttributeWithValue[] => {
  if (!attributeWithValueList) {
    return;
  }

  const convertedAttrWithValueListToDesiredFormat = attributeWithValueList?.map(
    attributeWithValue => {
      switch (attributeWithValue.checklistAttribute.attribute.type) {
        case EChecklistAttributeType.Int: {
          const { integerValue } = attributeWithValue.attributeValue;

          const isMinus: boolean = integerValue === '-';

          return {
            ...attributeWithValue,
            attributeValue: {
              ...attributeWithValue.attributeValue,
              integerValue: !hasNumberValue(integerValue)
                ? integerValue
                : isMinus
                ? ''
                : Number(attributeWithValue.attributeValue.integerValue),
            },
          };
        }
        case EChecklistAttributeType.Double: {
          const { doubleValue } = attributeWithValue.attributeValue;

          const isMinus: boolean = doubleValue === '-';

          return {
            ...attributeWithValue,
            attributeValue: {
              ...attributeWithValue.attributeValue,
              doubleValue: !hasNumberValue(doubleValue)
                ? doubleValue
                : isMinus
                ? ''
                : Number(attributeWithValue.attributeValue.doubleValue),
            },
          };
        }
        case EChecklistAttributeType.String:
          return {
            ...attributeWithValue,
            attributeValue: {
              ...attributeWithValue.attributeValue,
              stringValue: attributeWithValue.attributeValue.stringValue?.trim(),
            },
          };
        case EChecklistAttributeType.LongString:
          return {
            ...attributeWithValue,
            attributeValue: {
              ...attributeWithValue.attributeValue,
              longStringValue: attributeWithValue.attributeValue.longStringValue?.trim(),
            },
          };
        case EChecklistAttributeType.Boolean:
          return {
            ...attributeWithValue,
            children: attributeWithValue?.children
              ? getConvertedAttrWithValueListToDesiredFormat(attributeWithValue.children)
              : null,
          };
        case EChecklistAttributeType.UserDictionaryLink:
          return {
            ...attributeWithValue,
            attributeValue: {
              ...attributeWithValue.attributeValue,
              userDictionaryValues: [...attributeWithValue.attributeValue.userDictionaryValues].map(
                val => {
                  const isANewVal = val.id === val.value;

                  if (isANewVal) {
                    return {
                      id: null,
                      value: val.value,
                    };
                  } else {
                    return {
                      id: val.id,
                      value: null,
                    };
                  }
                }
              ),
            },
          };
        case EChecklistAttributeType.ChecklistInstanceLink: {
          return {
            ...attributeWithValue,
            attributeValue: {
              ...attributeWithValue.attributeValue,
              checkListInstanceValue: createListOfPutNestedInst(
                attributeWithValue,
                drawingNestedInstanceList
              ),
            },
          };
        }
        default:
          return attributeWithValue;
      }
    }
  );

  return convertedAttrWithValueListToDesiredFormat;
};

type TValidatedAttrWithValueList = {
  attributeWithValueList: IChecklistAttributeWithValue[];
  invalidAttributeWithValueList: IChecklistAttributeWithValue[];
  attributeValueListToSave: IPutChecklistAttributeValue[];
  drawingNestedInstanceList: IChecklistAttributeWithValue[];
  listOfAttrWithInvalidChildren: IChecklistAttributeWithValue[];
};

type TGetValidatedAttrWithValueList = (
  attributeWithValueList: IChecklistAttributeWithValue[],
  drawingNestedInstanceList?: IDrawingNestedInstance[]
) => TValidatedAttrWithValueList;

export const getValidatedAttrWithValueList: TGetValidatedAttrWithValueList = (
  attributeWithValueList = [],
  drawingNestedInstanceList = []
) => {
  const { isBoolAttr } = checklistAttrTypeHelpers;

  const idToAttrWithValue: Map<string, IChecklistAttributeWithValue> = new Map<
    string,
    IChecklistAttributeWithValue
  >();

  const idToInvalidAttrWithValue: Map<string, IChecklistAttributeWithValue> = new Map<
    string,
    IChecklistAttributeWithValue
  >();

  const idToAttrValueToSave: Map<string, IPutChecklistAttributeValue> = new Map<
    string,
    IPutChecklistAttributeValue
  >();

  const idToDrawingNestedInstance: Map<string, IChecklistAttributeWithValue> = new Map<
    string,
    IChecklistAttributeWithValue
  >();

  const idToInvalidAttrWithChildren: Map<string, IChecklistAttributeWithValue> = new Map<
    string,
    IChecklistAttributeWithValue
  >();

  const convertedAttrWithValueListToDesiredFormat = getConvertedAttrWithValueListToDesiredFormat(
    attributeWithValueList,
    drawingNestedInstanceList
  );

  convertedAttrWithValueListToDesiredFormat.forEach(attributeWithValue => {
    if (
      attributeWithValue.checklistAttribute.isRequired &&
      attributeWithValue.isActive &&
      !hasAttributeValue(attributeWithValue) &&
      attributeWithValue.checklistAttribute.attribute.type !==
        EChecklistAttributeType.ChecklistInstanceLink
    ) {
      const invalidAttribute: IChecklistAttributeWithValue = {
        ...attributeWithValue,
        schema: { ...attributeWithValue.schema, isShownError: true },
      };

      idToInvalidAttrWithValue.set(invalidAttribute.checklistAttribute.id, invalidAttribute);
    }

    if (attributeWithValue.isActive && hasAttributeValue(attributeWithValue)) {
      idToAttrValueToSave.set(
        attributeWithValue.checklistAttribute.id,
        attributeWithValue.attributeValue
      );
    }

    idToAttrWithValue.set(attributeWithValue.checklistAttribute.id, attributeWithValue);

    const hasThisAttrBoolType = isBoolAttr(attributeWithValue);

    const hasListOfChildren = Boolean(attributeWithValue?.children?.length);

    if (hasThisAttrBoolType && hasListOfChildren) {
      const listOfValidatedBoolAttrWithValue = getValidatedAttrWithValueList(
        attributeWithValue?.children,
        drawingNestedInstanceList
      );

      if (
        listOfValidatedBoolAttrWithValue.attributeWithValueList.some(
          attr => attr.schema.isShownError
        )
      ) {
        const invalidAttribute: IChecklistAttributeWithValue = {
          ...attributeWithValue,
          children: listOfValidatedBoolAttrWithValue.attributeWithValueList,
        };

        idToInvalidAttrWithValue.set(invalidAttribute.checklistAttribute.id, invalidAttribute);

        listOfValidatedBoolAttrWithValue.listOfAttrWithInvalidChildren.forEach(attrWithValue => {
          idToInvalidAttrWithChildren.set(attrWithValue.checklistAttribute.id, attrWithValue);
        });
      } else {
        listOfValidatedBoolAttrWithValue.attributeValueListToSave.forEach(attrValue => {
          idToAttrValueToSave.set(attrValue.checkListAttributeId, attrValue);
        });
      }
    }

    if (
      attributeWithValue?.attributeWithFileValue?.checklistAttribute?.isRequired &&
      attributeWithValue?.attributeWithFileValue?.isActive &&
      !hasAttributeValue(attributeWithValue?.attributeWithFileValue)
    ) {
      const parentInvalidAttrWithValue = idToInvalidAttrWithValue.get(
        attributeWithValue.attributeWithFileValue.checklistAttribute.parentId
      );

      if (parentInvalidAttrWithValue) {
        const invalidAttribute: IChecklistAttributeWithValue = {
          ...parentInvalidAttrWithValue,
          attributeWithFileValue: {
            ...parentInvalidAttrWithValue.attributeWithFileValue,
            schema: {
              ...parentInvalidAttrWithValue.attributeWithFileValue.schema,
              isShownError: true,
            },
          },
        };

        idToInvalidAttrWithValue.set(invalidAttribute.checklistAttribute.id, invalidAttribute);
      } else {
        const invalidAttribute: IChecklistAttributeWithValue = {
          ...attributeWithValue,
          attributeWithFileValue: {
            ...attributeWithValue.attributeWithFileValue,
            schema: { ...attributeWithValue.attributeWithFileValue.schema, isShownError: true },
          },
        };

        idToInvalidAttrWithValue.set(invalidAttribute.checklistAttribute.id, invalidAttribute);
      }
    }

    // Если у атрибута есть дочерний атрибут-файл и он активен, то закидываем его в возвращаемый объект
    if (
      attributeWithValue?.attributeWithFileValue &&
      attributeWithValue?.attributeWithFileValue?.isActive &&
      hasAttributeValue(attributeWithValue?.attributeWithFileValue)
    ) {
      idToAttrValueToSave.set(
        attributeWithValue.attributeWithFileValue.checklistAttribute.id,
        attributeWithValue.attributeWithFileValue.attributeValue
      );
    }
  });

  Array.from(idToInvalidAttrWithValue.entries()).forEach(([id, attrWithValue]) => {
    idToAttrWithValue.set(id, attrWithValue);
  });

  return {
    attributeWithValueList: Array.from(idToAttrWithValue.values()),
    invalidAttributeWithValueList: Array.from(idToInvalidAttrWithValue.values()),
    attributeValueListToSave: Array.from(idToAttrValueToSave.values()),
    drawingNestedInstanceList: Array.from(idToDrawingNestedInstance.values()),
    listOfAttrWithInvalidChildren: Array.from(idToInvalidAttrWithChildren.values()),
  };
};

const createListOfDepAttrVal = (
  listOfAttrWithVal: IChecklistAttributeWithValue[] = []
): IPutChecklistAttributeValue[] => {
  return listOfAttrWithVal.reduce<IPutChecklistAttributeValue[]>(
    (listOfDepAttrVal, attrWithVal, _index, comparableListOfAttrWithVal) => {
      const depAttr = attrWithVal.checklistAttribute?.dependencyAttribute;

      if (depAttr) {
        const depAttrWithVal = comparableListOfAttrWithVal.find(
          ({ attributeValue }) => depAttr.id === attributeValue.checkListAttributeId
        );

        if (depAttrWithVal) {
          listOfDepAttrVal.push(depAttrWithVal.attributeValue);
        }
      }

      return listOfDepAttrVal;
    },
    []
  );
};

export { createListOfDepAttrVal };
