import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { Breakpoints } from '../../../../../shared/constans/sizes';

export const ProfileWidgetInner = styled.div`
  padding: 32px;

  @media (max-width: ${Breakpoints.large}) {
    padding: 20px;
  }
`;

export const ProfileWidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.lightnessGray};
  padding-bottom: 16px;
  margin-bottom: 30px;
`;

export const ProfileWidgetTitle = styled.div`
  font-size: 16px;
`;

export const ProfileWidgetEditLink = styled.div`
  color: ${Colors.darkGray};
  font-size: 12px;
  cursor: pointer;
  display: flex;
  p {
    color: ${Colors.darkGray};
    font-size: 12px;
    margin: 0 0 0 5px;
  }

  @media (max-width: ${Breakpoints.small}) {
    p {
      display: none;
    }
  }
`;

export const ProfileImage = styled.img`
  width: 12px;
  height: 12px;
`;
