import { FC, memo, MouseEvent } from 'react';

import Styled from './DropdownMultiselectValue.styles';
import { TDropdownMultiselectValueProps } from './DropdownMultiselectValue.types';

const DropdownMultiselectValue: FC<TDropdownMultiselectValueProps> = ({
  selectValue,
  onCloseClick,
}) => {
  const handleCloseClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    onCloseClick(selectValue.value);
  };

  return (
    <Styled.Wrapper>
      <Styled.Label>{selectValue.label}</Styled.Label>
      <Styled.Close onClick={handleCloseClick} />
    </Styled.Wrapper>
  );
};

DropdownMultiselectValue.displayName = 'DropdownMultiselectValue';

export default memo(DropdownMultiselectValue);
