import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';
import styled from 'styled-components';

import { useStore } from '../../../../../../shared/utils/IoC';
import { TasksController } from '../../../../operations/controllers/tasks.controller';
import { Task, TasksStore, TaskStatuses } from '../../../../operations/stores/tasks.store';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';
import { TaskViewStore } from '../../../../operations/stores/task.view.store';
import { ChecklistInstancesStore } from '../../../../operationsAndTasks/stores/checklist.instances.store';
import { LeaveUnitModalStore } from '../../../../../stores/leave.unit.modal.store';

export type IControlButtonsProps = {
  handleCloseDialog?: () => void;
  actionType: ETaskActionType;
  disableSaveButton?: boolean;
  action: () => void;
};

export enum ETaskActionType {
  UPDATE = 'edit',
  CREATE = 'update',
}

const ControlButtons: FC<IControlButtonsProps> = observer(props => {
  const { handleCloseDialog, actionType, action } = props;
  const taskController = useStore(TasksController);
  const tasksStore = useStore(TasksStore);
  const taskEditStore = useStore(TasksEditStore);
  const taskViewStore = useStore(TaskViewStore);
  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const leaveUnitModal = useStore(LeaveUnitModalStore);
  const { openModalByModalId } = useModal();

  return (
    <StyledWrapper>
      <StyledButtonWrapper>
        {!tasksStore.isViewMode ? (
          <Button
            color={'primary'}
            type={'button'}
            onClick={() => action()}
            disabled={props.disableSaveButton}
            dataTestId={
              actionType === ETaskActionType.CREATE
                ? 'fullscreen-create-task-button'
                : 'fullscreen-save-task-button'
            }
          >
            {actionType === ETaskActionType.CREATE ? 'Создать задачу' : 'Сохранить'}
          </Button>
        ) : (
          <Button
            color={'primary'}
            type={'button'}
            onClick={() => {
              taskController.setStatus(tasksStore.temporaryTask.id, TaskStatuses.InWork);
              taskController.hideFullScreenCreationPage();
            }}
            disabled={taskEditStore.task.status === TaskStatuses.InWork}
            dataTestId={
              taskEditStore.task.status === TaskStatuses.InWork
                ? 'button-in-work'
                : 'button-push-to-work'
            }
          >
            {taskEditStore.task.status === TaskStatuses.InWork ? 'В работе' : 'Взять в работу'}
          </Button>
        )}
      </StyledButtonWrapper>
      <StyledButtonWrapper>
        {!tasksStore.isViewMode ? (
          <Button
            color={'default'}
            type={'button'}
            onClick={() => {
              if (handleCloseDialog) {
                // if (Boolean(taskEditStore.touchedFields.size) || checklistInstancesStore.hasPositionToInstanceChanged) {
                //   leaveUnitModal.setDialogSettings({
                //     actionHandler: () => {
                //       leaveUnitModal.setIsShow(false);
                //       handleCloseDialog();
                //     },
                //     isShow: true,
                //   });
                // } else handleCloseDialog();
                handleCloseDialog();
              }
            }}
            dataTestId={'fullscreen-button-return-to-tasks-list'}
          >
            Вернуться к списку задач
          </Button>
        ) : taskViewStore.taskForView.status !== TaskStatuses.Canceled ? (
          <Button
            color={'secondary'}
            styleType={'outlined'}
            type={'button'}
            onClick={() => {
              openModalByModalId('cancellationModal', {
                id: tasksStore.temporaryTask.id,
                status: TaskStatuses.Canceled,
              });
            }}
            dataTestId={'fullscreen-button-cancel-task'}
          >
            Отменить задачу
          </Button>
        ) : null}
      </StyledButtonWrapper>
    </StyledWrapper>
  );
});

const StyledButtonWrapper = styled.div`
  flex: 0 0 300px;
  & > button {
    justify-content: center;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default ControlButtons;
