import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { provide } from '../../shared/utils/IoC';
import { SeasonModel } from '../../../api/models/season.model';
import { Option } from '../../shared/components/SeasonAccordeon/SeasonAccordeon';
import 'moment/locale/ru';

@provide.singleton()
export class SeasonsStore {
  currentSeasons: Array<SeasonModel> = [];

  selectedSeason = '';

  loading = false;

  constructor() {
    moment.locale('ru');
    makeAutoObservable(this);
  }

  get selectedSeassonData(): SeasonModel | null {
    const selectedSeassonData = this.currentSeasons.find(
      seasson => String(seasson.year) === this.selectedSeason
    );

    return selectedSeassonData || null;
  }

  get seasonsOptions(): Array<Option> {
    return this.currentSeasons.map(s => {
      const startDateAlias = moment(s.startDate, 'YYYY-MM-DD').format('DD MMM');
      const endDateAlias = moment(s.endDate, 'YYYY-MM-DD').format('DD MMM');
      return {
        value: s.year.toString(),
        label: s.label,
        description: `${startDateAlias} - ${endDateAlias}`,
      };
    });
  }

  getSeasonDataByYear = (): SeasonModel =>
    this.currentSeasons.filter(season => season.year === Number(this.selectedSeason))?.[0];

  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  setCurrentSeasons = (seasonList: SeasonModel[]): void => {
    this.currentSeasons = seasonList;
  };

  setSelectedSeason = (season: string): void => {
    this.selectedSeason = season;
  };

  clearSelectedSeason = (): void => {
    this.selectedSeason = '';
  };

  clearCurrentSeasons = (): void => {
    this.currentSeasons = [];
  };

  clear = (): void => {
    this.clearSelectedSeason();
    this.clearCurrentSeasons();
  };
}
