import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';

export const Wrapper = styled.div<{ visible?: boolean; $expanded?: boolean }>`
  width: 284px;
  @media (max-width: 1024px) {
    width: ${({ $expanded }) => ($expanded ? '284px' : '240px')};
  }
  height: 100%;
  background: ${Colors.white};
  display: ${({ visible = true }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
`;

export const Footer = styled.div`
  height: 106px;
  width: 100%;
  border-top: 0.5px solid ${Colors.grayBorder};
  margin-top: auto;
  position: absolute;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  background: ${Colors.white};
  z-index: 100;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  padding-left: 18px;
  padding-right: 18px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  position: absolute;
  top: 0;
  background: ${Colors.white};
  z-index: 100;
`;

export const StyledBurgerWrapper = styled.div`
  margin-top: 5px;
  position: absolute;
`;

export const StyledLabelWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  color: ${Colors.black};
  margin: 0;
`;

export const EditIcon = styled.div`
  svg {
    width: 16px;
    height: 16px;
    fill: ${Colors.black};
  }
`;
