import { forwardRef, memo, useCallback, useMemo } from 'react';

import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import { ImagesUploader } from '../../../../../../../tasks/containers/fullscreen/checklist/components/Phenophase/components/ImagesUploader/ImagesUploader';
import { useHandleChecklistAttrChange } from '../../../../../../hooks/useHandleChecklistAttrChange';
import { getChecklistAttributeId } from '../../../../../../utils/checklist.instances';
import { TChecklistAttributeProps } from '../../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';
import Styled, {
  EAttributeContainerMode,
} from '../../../containers/ChecklistAttributeContainer/styles';
import {
  useGetChecklistAttrPlaceholder,
  useGetErrorListOfChecklistAttr,
} from '../../../hooks/attribute';
import { Input } from '../../shared/Input';

type TStringAttributeProps = {} & TChecklistAttributeProps;

const StringAttribute = forwardRef<HTMLInputElement, TStringAttributeProps>(
  ({ attributeWithValue, onChange, isBlocked, focusTarget }, ref) => {
    const { isRequired, attribute, id } = attributeWithValue.checklistAttribute;

    const {
      attributeValue,
      schema,
      isActive,
      attributeWithFileValue,
      checklistAttribute,
    } = attributeWithValue;

    const { partialValue, setPartialValue } = useHandleChecklistAttrChange(
      attributeWithValue,
      { stringValue: attributeValue.stringValue ?? '' },
      onChange
    );

    const placeholder = useGetChecklistAttrPlaceholder(
      checklistAttribute.attribute.type,
      checklistAttribute.attribute?.placeholder
    );

    const attributeId: string = useMemo(() => getChecklistAttributeId(id), [id]);

    const errorList = useGetErrorListOfChecklistAttr(schema, attributeWithFileValue?.schema);

    const handleChange = useCallback(
      (stringValue: string): void => setPartialValue({ stringValue }),
      []
    );

    return (
      <>
        {isActive && (
          <Styled.Wrapper
            $mode={EAttributeContainerMode.Half}
            $reduceMarginBottom={attributeWithFileValue ? false : Boolean(errorList?.length)}
          >
            <Input
              isRequired={isRequired}
              label={attribute.name}
              placeholder={placeholder}
              value={partialValue.stringValue ?? ''}
              onChange={handleChange}
              isBlocked={isBlocked}
              error={errorList?.[0] || ''}
              isWithoutErrorText={true}
              id={attributeId}
              tooltip={checklistAttribute?.toolTip}
              ref={id === focusTarget ? ref : null}
            />

            <Styled.Container>
              <Styled.InnerContainer>
                <InputFieldError error={{ errorList }} />
              </Styled.InnerContainer>
              <Styled.InnerContainer
                $isFullWidth={Boolean(attributeWithFileValue?.attributeValue?.fileValue?.length)}
              >
                {attributeWithFileValue && (
                  <ImagesUploader
                    onImgUrlsChange={onChange}
                    attributeWithValue={attributeWithFileValue}
                    isBlocked={isBlocked}
                    id={attributeId}
                  />
                )}
              </Styled.InnerContainer>
            </Styled.Container>
          </Styled.Wrapper>
        )}
      </>
    );
  }
);

StringAttribute.displayName = 'StringAttribute';

export default memo(StringAttribute);
