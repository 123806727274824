import { IGetChecklistAttribute } from '../../../models/checklist/attribute/checklist.attribute.model';
import { TypeApiRoute } from '../../../models/type.api.request';

export type TGetChecklistAttributeByCheckListPublicIdPayload = {
  attributeName?: string;
  stageId?: string;
  activeOnly?: boolean;
  size?: number;
};

type TRequest = {} & TGetChecklistAttributeByCheckListPublicIdPayload;

type TResponse = { content: IGetChecklistAttribute[] };

export const getChecklistAttributeByCheckListPublicId: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/checklist/attribute/byCheckListPublicId`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
