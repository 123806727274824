import { IInstance } from '../../../models/checklist/instance/checklist.instance.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  taskId: string;
  size: number;
};

type TypeResponse = { content: IInstance[] };

export const getChecklistInstancesByTaskId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => {
    console.log(req);
    return `/api/as-fields/checklist/instances/byTaskId/${req.taskId}`;
  },
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
