import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { toBase64 } from '../../../../../../../../../../shared/utils/getBase64';
import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesStore } from '../../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { ChecklistFileUploaderStore } from '../../../../../../../../operationsAndTasks/stores/checklist.fileUploader.store';
import { IChecklistAttributeWithValue } from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { THandleChangeChecklistAttributeValue } from '../../../../../../../../operationsAndTasks/utils/checklist.instances';
import { ChecklistFileUploaderController } from '../../../../../../../../operationsAndTasks/controllers/checklist.fileUploader.controller';

import {
  AddFirstImgLabel,
  FileUploader,
  ImagesUploaderWrapper,
  ImgsRowBlock,
  PaperClipIconImg,
  Spinner,
  SpinnerWrapper,
} from './style';
import PaperClipIcon from './assets/paperClipIcon.svg';
import { ImageCarousel } from './ImageCarousel';
import { ImageMiniRow } from './ImageMiniRow';

export type ImgUploaderProps = {
  attributeWithValue: IChecklistAttributeWithValue;
  onImgUrlsChange: THandleChangeChecklistAttributeValue;
  isBlocked?: boolean;
  id: string;
};

const maxImagesNumber = 10;

export const ImagesUploader: FC<ImgUploaderProps> = observer(
  ({ onImgUrlsChange, attributeWithValue, isBlocked, id }) => {
    const {
      removeItemFromFilesArrayById,
      fileKeyToFile,
      setFileToFileMapById,
      removeFileByFileKey,
    } = useStore(ChecklistFileUploaderStore);
    const checklistFileUploaderController = useStore(ChecklistFileUploaderController);

    const fileUploaderRef = useRef(null);
    const [selectedImgIndex, setSelectedImgIndex] = useState<number | null>(null);
    const [isPending, setIsPending] = useState(false);
    const [maxImgIndex, setMaxImgIndex] = useState(0);
    const [parentElem, setParentElem] = useState<null | Element>(null);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
      setFileList(fileKeyToFile(attributeWithValue.attributeValue.fileValue));
      setMaxImgIndex(attributeWithValue.attributeValue.fileValue.length);

      console.log('items', fileKeyToFile(attributeWithValue.attributeValue.fileValue));
      
    }, [attributeWithValue.attributeValue.fileValue, attributeWithValue.drawingNestedInstanceId]);

    const onAddImg = () => {
      fileUploaderRef?.current?.click();
    };

    useLayoutEffect(() => {
      setParentElem(document.querySelector('#images-uploader-wrapper'));
    }, []);

    const onSelectImage = (num: number) => {
      setSelectedImgIndex(num);
    };

    const onChangeFile = async event => {
      event.stopPropagation();
      const fileForUpload = event.target.files[0];
      if (fileForUpload) {
        setIsPending(true);
        const blob = await toBase64(fileForUpload);
        checklistFileUploaderController
          .uploadFile(fileForUpload)
          .then(resp => {
            const updatedFileValue = attributeWithValue?.attributeValue?.fileValue?.slice();
            updatedFileValue.splice(0, 0, resp.id);
            setMaxImgIndex(maxImgIndex + 1);

            onImgUrlsChange({
              attributeWithValue,
              value: { fileValue: updatedFileValue },
              drawingNestedInstanceId: attributeWithValue?.drawingNestedInstanceId,
            });

            const fileItem = {
              id: v4(),
              imagePreview: blob as string,
              isClientOnly: true,
              attributeId: attributeWithValue.checklistAttribute.id,
              nestedId: attributeWithValue?.drawingNestedInstanceId,
              uploadedImageKey: updatedFileValue,
            };

            setFileToFileMapById(resp.id, fileItem);
            event.target.value = null;
          })
          .catch(() => console.error('Ошибка загрузки файла'))
          .finally(() => setIsPending(false));
      }
    };

    const deleteByIndex = (index: number) => {
      const updatedFileValue = attributeWithValue?.attributeValue?.fileValue?.slice();
      const deletedFiles = updatedFileValue.splice(index, 1);
      onImgUrlsChange({
        attributeWithValue,
        value: { fileValue: updatedFileValue },
        drawingNestedInstanceId: attributeWithValue?.drawingNestedInstanceId,
      });

      removeFileByFileKey(deletedFiles[0]);

      const updatedImgsArr = fileList.slice();
      const removedItems = updatedImgsArr.splice(index, 1);
      removedItems.forEach(item =>
        removeItemFromFilesArrayById(
          attributeWithValue?.drawingNestedInstanceId || attributeWithValue.checklistAttribute.id,
          item.id
        )
      );
      if (maxImgIndex) {
        setMaxImgIndex(maxImgIndex - 1);
      } else {
        setMaxImgIndex(0);
      }
    };

    const onDeleteByIndex = (index: number) => () => {
      checklistFileUploaderController.warnBeforeDeleteFile(() => deleteByIndex(index));
    };

    const onDeleteInCarousel = () => {
      checklistFileUploaderController.warnBeforeDeleteFile(() => deleteInCarouselBySelectedIndex());
    };

    const deleteInCarouselBySelectedIndex = () => {
      const updatedFileValue = attributeWithValue?.attributeValue?.fileValue?.slice();
      updatedFileValue.splice(selectedImgIndex, 1);
      onImgUrlsChange({
        attributeWithValue,
        value: { fileValue: updatedFileValue },
        drawingNestedInstanceId: attributeWithValue?.drawingNestedInstanceId,
      });

      const updatedImgsArr = fileList;
      updatedImgsArr.splice(selectedImgIndex, 1);

      setMaxImgIndex(maxImgIndex - 1);

      if (fileList?.length === 0) {
        setSelectedImgIndex(null);
      } else if (selectedImgIndex === maxImgIndex - 1) {
        setSelectedImgIndex(0);
      }
    };

    return (
      <ImagesUploaderWrapper id={'images-uploader-wrapper'}>
        <FileUploader
          type="file"
          name="file"
          ref={fileUploaderRef}
          accept="image/jpeg,image/png,image/jpg"
          onChange={onChangeFile}
          onClick={event => event.stopPropagation()}
        />
        {fileList?.length > 0 ? (
          <ImgsRowBlock id={'imgs-row-block'}>
            {isPending ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : (
              <>
                {!isBlocked && fileList.length < maxImagesNumber && (
                  <PaperClipIconImg src={PaperClipIcon} onClick={onAddImg} />
                )}
              </>
            )}
            <ImageMiniRow
              imgsArr={fileList}
              isBlocked={isBlocked}
              onDeleteByIndex={onDeleteByIndex}
              setSelectedImgIndex={onSelectImage}
              parentElem={parentElem}
            />
          </ImgsRowBlock>
        ) : (
          !isBlocked && (
            <AddFirstImgLabel
              $isRequired={attributeWithValue.checklistAttribute?.isRequired}
              onClick={onAddImg}
            >
              Прикрепить фотографию
            </AddFirstImgLabel>
          )
        )}
        {selectedImgIndex !== null && Boolean(fileList.length) && (
          <ImageCarousel
            imgUrl={(() => {
              return fileList[selectedImgIndex]?.downloadUrl
                ? fileList[selectedImgIndex]?.downloadUrl
                : // @ts-ignore
                  fileList[selectedImgIndex]?.imagePreview;
            })()}
            isBlocked={isBlocked}
            onClose={() => setSelectedImgIndex(null)}
            onDelete={onDeleteInCarousel}
            onSwitchLeft={() => {
              if (selectedImgIndex > 0) {
                setSelectedImgIndex(selectedImgIndex - 1);
              } else {
                setSelectedImgIndex(maxImgIndex - 1);
              }
            }}
            onSwitchRight={() => {
              if (selectedImgIndex < maxImgIndex - 1) {
                setSelectedImgIndex(selectedImgIndex + 1);
              } else {
                setSelectedImgIndex(0);
              }
            }}
          />
        )}
      </ImagesUploaderWrapper>
    );
  }
);
