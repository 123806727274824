import { FC } from 'react';

import { ProfileWidgetWrapper } from '../ProfileWidgetWrapper';
import { List } from '../../../../../shared/components/List';
import { PersonalDataType } from '../../types/personalData';
import { ProfileRoutes } from '../../routes';
import { UserInfo } from '../UserInfo';

import { PersonalDataWidgetWrapper, AvatarWrapper } from './style';

type PersonalDataWidgetType = {
  fullName: string;
  items: PersonalDataType[];
  avatar: string;
};

export const PersonalDataWidget: FC<PersonalDataWidgetType> = ({ fullName, items, avatar }) => (
  <PersonalDataWidgetWrapper>
    <ProfileWidgetWrapper title="Личные данные" editLink={ProfileRoutes.EditPersonalData}>
      <AvatarWrapper data-test-id="profile-avatar-wrapper">
        <UserInfo userName={fullName} avatar={avatar} size={'large'} />
      </AvatarWrapper>

      <List items={items} />
    </ProfileWidgetWrapper>
  </PersonalDataWidgetWrapper>
);
