import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';
import { ICustomColor } from '../../../../../../../shared/constans/customColorsPrests';

const normalText = css`
  font-weight: 400px;
  font-size: 14px;
  line-height: 20px;
`;

const smallText = css`
  font-weight: 400px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`;

export const StyledInstance = styled.div`
  border-bottom: 0.5px solid #e9e6f0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
`;

export const StyledName = styled.span`
  align-self: center;
  ${normalText};
  flex: 1 1 20%;
  max-width: 74px;
  //overflow: hidden;
  //text-overflow: ellipsis;
`;

export const StyledPositionMark = styled.div<{ $colors: ICustomColor }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  color: ${({ $colors }) => $colors.color};
  background-color: ${({ $colors }) => $colors.backgroundColor};
  margin-right: 12px;
  text-align: center;
  align-items: center;
`;

export const StyledPositionText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;

export const StyledDescriptionWrapper = styled.div`
  flex: 1 1 40%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const StyledOperationWrapper = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`;

export const StyledLabelByCoorWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 3px;
  align-items: center;
  margin-right: 24px;
`;

export const StyledLabelMark = styled.div<{ $isActual: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $isActual }) => ($isActual ? Colors.darkGray : Colors.red)};
`;

export const StyledLabel = styled.span`
  ${normalText}
  margin-left: 8px;
`;

export const StyledOperationLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.green};
`;

export const StyledStateLabel = styled.div<{ $isFilled: boolean }>`
  ${smallText};
  color: ${({ $isFilled }) => ($isFilled ? '#3FB65F' : Colors.black)};
  background: ${({ $isFilled }) => ($isFilled ? '#ECF8EF' : Colors.agateGray)};
  border-radius: 8px;
  padding: 2px 8px;
`;

export const StyledBaskedWrapper = styled.div<{ disabled?: boolean }>`
  width: 13.33px;
  height: 14.67px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
