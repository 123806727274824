import { includes, sortBy, uniqBy } from 'lodash';
import { useMemo } from 'react';

import { TUseDropdownGetAvailableOptionList } from './useDropdownGetAvailableOptionList.types';

const useDropdownGetAvailableOptionList: TUseDropdownGetAvailableOptionList = ({
  body,
  type,
  state: {
    settings: { isExactCompareOfSearchValue },
    values: { searchValue, selectValue, selectValueList },
  },
}) => {
  return useMemo(() => {
    const uniqueOptionList = uniqBy(body.optionList, 'value');

    const listOfOptionsWithoutSelected = type?.multiselect
      ? uniqueOptionList.filter(
          option => !selectValueList.some(value => option.value === value.value)
        )
      : uniqueOptionList.filter(value => value.value !== selectValue?.value);

    if (isExactCompareOfSearchValue) {
      const exactFilteredOptionList = listOfOptionsWithoutSelected?.filter(
        option => option.label === searchValue
      );

      return exactFilteredOptionList;
    }

    if (searchValue) {
      const filteredOptionList = uniqueOptionList?.filter(option => {
        const trimmedSearchValue = searchValue?.trim();

        const regExpSearch = new RegExp(trimmedSearchValue?.trim(), 'i');

        const searchValueWords = searchValue?.split(' ');

        if (searchValueWords?.length) {
          return option.label.toLowerCase().includes(trimmedSearchValue.toLocaleLowerCase());
        }

        const words = option.label.split(' ');

        const isFound = words.some(word => {
          const foundPos = word.search(regExpSearch);

          return foundPos === 0;
        });

        return isFound;
      });

      return sortBy(filteredOptionList, ['label']);
    } else {
      return listOfOptionsWithoutSelected;
    }
  }, [body, isExactCompareOfSearchValue, searchValue, searchValue, selectValueList, type]);
};

export default useDropdownGetAvailableOptionList;
