import { Button } from "@farmlink/farmik-ui";
import { useState } from "react";

import { TasksEdit } from "../TaskEdit/TaskEdit";

import { BottomBlockWrapperOfTasksList, CreateNewTaskButtonWrapper, CreateTasksSVG, CreateTasksText, CreateTasksTitle } from "./style";
import createTasksSvg from './svgCreateTasks.svg';

export const BottomBlockOfTasksList = () => {

    const [showCreateNewTaskForm, setShowCreateNewTaskForm] = useState(false);

    return (
        <BottomBlockWrapperOfTasksList>
            <CreateTasksSVG src={createTasksSvg}/>
            <CreateTasksTitle>
                Создайте новую задачу
            </CreateTasksTitle>
            <CreateTasksText>
                Планируйте работы и следите за ходом их выполнения
            </CreateTasksText>
            <CreateNewTaskButtonWrapper>
                <Button
                    color={'primary'}
                    type={'button'}
                    onClick={() => setShowCreateNewTaskForm(true)}
                >
                    Добавить задачу
                </Button>
            </CreateNewTaskButtonWrapper>
            {showCreateNewTaskForm ? (
                <TasksEdit 
                    onCloseDialog={() => setShowCreateNewTaskForm(false)}
                    onCancel={() => setShowCreateNewTaskForm(false)}
                />): null}
        </BottomBlockWrapperOfTasksList>
    );
};