import moment, { Moment } from 'moment';
import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../shared/utils/IoC';
import { TaskStatuses } from '../../operations/stores/tasks.store';

import { taskLabels } from './tasks.store';

export const defaultStatuses = [
  { value: TaskStatuses.InWork, label: taskLabels[TaskStatuses.InWork] },
  { value: TaskStatuses.New, label: taskLabels[TaskStatuses.New] },
];

@provide.singleton()
export class TasksFilterStore {
  constructor() {
    makeAutoObservable(this);
  }

  culture = '';
  asignee = '';

  status = defaultStatuses;
  startDate: Moment;
  endDate: Moment;
  operationType = '';
  priority = [];
  field = '';

  initDates = (startDate, endDate) => {
    this.startDate = moment(startDate);
    this.endDate = moment(endDate);
  };

  setCulture = newValue => {
    this.culture = newValue;
  };
  setAsignee = newValue => {
    this.asignee = newValue;
  };
  setStatus = newValue => {
    this.status = newValue;
  };
  setStartDate = newValue => {
    this.startDate = newValue;
  };
  setEndDate = newValue => {
    this.endDate = newValue;
  };
  setOperationType = newValue => {
    this.operationType = newValue;
  };
  setPriority = newValue => {
    this.priority = newValue;
  };
  setField = newValue => {
    this.field = newValue;
  };
}
