import { Select } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

export const TaskEditForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 585px;
  z-index: 3002;
  background-color: #ffffff;
  padding: 16px 24px 24px;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Overlay = styled.div`
  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
`;

export const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & > img {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 22px;
`;

export const StyledOperationLabelSelectWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  & * {
    pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  }
`;

export const StyledOperationSelectLabel = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: #9aa0ad;
`;

export const ForPresetDateLabel = styled.div`
  font-weight: 700;
  color: black;
`;

export const StyledOperationSelectWrapper = styled.div`
  width: 100%;
`;

export const StyledAssegneeSelectWrapper = styled.div<{ disabled?: boolean }>`
  width: calc(50% - 8px);
  /* margin-right: 16px; */
  // margin-bottom: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  & * {
    pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  }
`;

export const StyledFieldDateBlock = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;

export const FieldSelectWrapper = styled.div<{ disabled?: boolean }>`
  width: calc(50% - 8px);
  margin-right: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  & div {
    pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  }
`;

export const PlanDateWrapper = styled.div<{ blocked?: boolean }>`
  width: calc((100% - 16px) / 2);
  & .rdtPicker {
    right: 0;
  }
  pointer-events: ${({ blocked }) => (blocked ? `none` : `auto`)};
  & * {
    pointer-events: ${({ blocked }) => (blocked ? `none` : `auto`)};
  }
`;

export const StyledAssigneeProirityBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;

export const CultureSelectWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  margin-bottom: 20px;
  // margin-right: 16px;
  // pointer-events: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  & * {
    pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  }
`;

export const PriorityWrapper = styled.div`
  width: 171px;
`;

export const CommentWrapper = styled.div<{ blocked?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & > textarea {
    background: ${({ blocked }) => (blocked ? '#fffffff' : '#faf9fd')};
    color: #151f32;
    border: 1px solid;
    border-radius: 14px;
    font-size: 12px;
    width: 100%;
    max-height: 400px;
    // height: px;
    padding: 12px 16px;
    box-sizing: border-box;
    outline: none;
    border-color: #e9e6f0;
    color: #151f32;
    resize: vertical;
    pointer-events: ${({ blocked }) => (blocked ? 'none' : 'auto')};
  }
`;

export const TextareaCommentLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
  margin-bottom: 4px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CreateSaveBottonWrapper = styled.div<{ disabled?: boolean }>`
  width: 160px;
  & > button {
    justify-content: center;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
`;

export const CancelTaskButtonWrapper = styled.div<{ disabled?: boolean }>`
  width: 160px;
  & > button {
    justify-content: center;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
`;

export const StyledFieldAssigneeBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

export const PlanDatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  & > :first-child {
    margin-right: 16px;
  }
`;

export const StyledExpandButton = styled.div`
  cursor: pointer;
  color: ${Colors.darkGray};
  align-self: center;
  margin-right: 5px;
  font-size: 14px;
  line-height: 20px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  svg {
    cursor: pointer;
    &:hover {
      fill: ${Colors.black}!important;
    }
  }
`;

export const PointsOnTheMapWidgetWrapper = styled.div`
  width: 100%;
  & .instances-list {
    max-height: calc(100vh - 700px);
  }
`;

export const NoPointsHeader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9AA0AD;
`;
