import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { OrganizationSearchStore } from '../../stores/organization.search.store';
import { OrganizationCard } from '../OrganizationCard';
import { Modal } from '../../../../../../../../components/Modal/style';
import { Grid, WidgetWrapper } from '../OrganizationList/style';

import { SendInviteToOrganizationForm } from './components/SendInviteToOrganizationForm';
import { CardMock } from './components/CardMock';
import { NothingFound } from './components/NothingFound';
import { SearchControlBar } from './components/SearchControlBar';

export const OrganizationSearchWidget: FC<RouteComponentProps> = observer(() => {
  const [choseOrganization, setChoseOrganization] = useState('');
  const store = useStore(OrganizationSearchStore);
  const { fetchSearchedOrganizations, isLoading, cards } = store;

  useEffect(() => {
    fetchSearchedOrganizations();
  }, []);

  const renderCards = () => {
    return cards.map(item => (
      <OrganizationCard
        onClick={() => {
          setChoseOrganization(item.organizationId);
        }}
        type={item.type}
        roleName={item.roleName}
        title={item.title}
        organizationId={item.organizationId}
        avatar={item.avatar}
      />
    ));
  };

  const renderLoading = () => {
    const mockArray = [];
    for (let i = 0; i <= 18; i++) {
      mockArray.push(i);
    }
    return mockArray.map(i => <CardMock key={i} />);
  };

  return (
    <WidgetWrapper>
      <SearchControlBar />
      {cards.length ? (
        <Grid search={true}>{isLoading ? renderLoading() : renderCards()}</Grid>
      ) : (
        <NothingFound />
      )}

      <Modal
      // shown={Boolean(choseOrganization)}
      // hideModal={() => {
      //   setChoseOrganization('');
      // }}
      >
        <SendInviteToOrganizationForm
          hideForm={() => {
            setChoseOrganization('');
          }}
          organizationId={choseOrganization}
        />
      </Modal>
    </WidgetWrapper>
  );
});
