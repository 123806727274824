import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../modules/shared/utils/IoC';
import { UiStore } from '../../modules/dashboard/stores/ui.store';

import { DeviceScreenDummyElem } from './style';
import dummy from './assets/tablets.png';

export const DeviceScreenDummy = observer(() => {
  const ui = useStore(UiStore);

  if (ui.pageWidth > 1023) {
    return null;
  }

  return (
    <DeviceScreenDummyElem data-test-id="device-screen-dummy">
      <img src={dummy} alt="" />
      Для продолжения работы
      <br />
      поверните устройство
      <br />в горизонтальную ориентацию
    </DeviceScreenDummyElem>
  );
});
