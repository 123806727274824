import { observer } from 'mobx-react-lite';
import { forwardRef, memo, useCallback, useEffect, useMemo } from 'react';

import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import { Dropdown } from '../../../../../../../../../shared/components/inputs/Dropdown';
import { TDropdownConfig } from '../../../../../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ImagesUploader } from '../../../../../../../tasks/containers/fullscreen/checklist/components/Phenophase/components/ImagesUploader/ImagesUploader';
import { ChecklistInstancesController } from '../../../../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../../../../stores/checklist.instances.store';
import { getChecklistAttributeId } from '../../../../../../utils/checklist.instances';
import { TChecklistAttributeProps } from '../../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';
import Styled, {
  EAttributeContainerMode,
} from '../../../containers/ChecklistAttributeContainer/styles';
import {
  useGetChecklistAttrPlaceholder,
  useGetErrorListOfChecklistAttr,
} from '../../../hooks/attribute';

type TDictionaryAttributeProps = {} & TChecklistAttributeProps;

const DictionaryAttribute = forwardRef<HTMLInputElement, TDictionaryAttributeProps>(
  ({ attributeWithValue, onChange, isBlocked, focusTarget }, ref) => {
    const { isRequired, attribute, id } = attributeWithValue.checklistAttribute;
    const {
      drawingNestedInstanceId,
      schema,
      isActive,
      attributeWithFileValue,
      checklistAttribute,
      listOfOptionValue,
    } = attributeWithValue;

    const checklistsStore = useStore(ChecklistInstancesStore);
    const {
      listOfDepAttrValue,
      getDictionaryOptionListByAttrId,
      getDepAttrValue,
      setDictionaryList,
    } = checklistsStore;

    const { fetchDictionaryListByRemoteName } = useStore(ChecklistInstancesController);

    const placeholder = useGetChecklistAttrPlaceholder(
      checklistAttribute.attribute.type,
      checklistAttribute.attribute?.placeholder
    );

    const attributeId: string = useMemo(() => getChecklistAttributeId(id), [id]);

    const errorList = useGetErrorListOfChecklistAttr(schema, attributeWithFileValue?.schema);

    useEffect(() => {
      (async () => {
        if (checklistAttribute?.dependencyAttribute) {
          const { dependencyAttribute } = checklistAttribute;

          const depValue = getDepAttrValue(dependencyAttribute.id);

          if (depValue?.dictionaryValueList?.length) {
            await fetchDictionaryListByRemoteName(id, {
              latestVersion: true,
              remoteName: attribute.dictionaryLink,
              dependencyName: dependencyAttribute.attribute.dictionaryLink,
              dependencyRecordId: depValue.dictionaryValueList?.[0],
            });
          } else {
            setDictionaryList(checklistAttribute.id, []);
          }
        } else {
          await fetchDictionaryListByRemoteName(id, {
            latestVersion: true,
            remoteName: attribute.dictionaryLink,
          });
        }
      })();
    }, [listOfDepAttrValue]);

    const handleChange = useCallback<TDropdownConfig['field']['onChange']>(
      (dictionaryValue, otherData) => {
        if (attribute?.isMultiselect) {
          onChange({
            attributeWithValue,
            value: {
              dictionaryValueList:
                otherData?.selectedValueList.map<string>(({ value }) => value) || [],
            },
            drawingNestedInstanceId,
            listOfOptionValue: otherData?.selectedValueList || [],
          });
        } else {
          onChange({
            attributeWithValue,
            value: {
              dictionaryValueList: otherData?.option ? [dictionaryValue] : [],
            },
            drawingNestedInstanceId,
            listOfOptionValue: otherData?.option ? [otherData?.option] : [],
          });
        }
      },
      []
    );

    const dropdownConfig: TDropdownConfig = {
      field: {
        onChange: handleChange,
        isRequired,
        defaultValueList: listOfOptionValue || null,
        icons: {
          clear: {},
        },
        type: {
          search: {},
          multiselect: attribute?.isMultiselect ? {} : null,
        },
        placeholder,
        id: getChecklistAttributeId(id),
      },
      body: {
        optionList: getDictionaryOptionListByAttrId(id) || [],
      },
      visual: {
        label: attribute?.name,
        isBlocked,
        tooltip: checklistAttribute?.toolTip,
      },
      validation: {
        error: {
          errorList,
          options: {
            isDoNotShowErrorText: true,
          },
        },
      },
    };

    return (
      <>
        {isActive && (
          <Styled.Wrapper
            $mode={EAttributeContainerMode.Half}
            $reduceMarginBottom={attributeWithFileValue ? false : Boolean(errorList?.length)}
          >
            <Dropdown config={dropdownConfig} ref={id === focusTarget ? ref : null} />

            <Styled.Container>
              <Styled.InnerContainer>
                <InputFieldError error={{ errorList }} />
              </Styled.InnerContainer>
              <Styled.InnerContainer
                $isFullWidth={Boolean(attributeWithFileValue?.attributeValue?.fileValue?.length)}
              >
                {attributeWithFileValue && (
                  <ImagesUploader
                    onImgUrlsChange={onChange}
                    attributeWithValue={attributeWithFileValue}
                    isBlocked={isBlocked}
                    id={attributeId}
                  />
                )}
              </Styled.InnerContainer>
            </Styled.Container>
          </Styled.Wrapper>
        )}
      </>
    );
  }
);

DictionaryAttribute.displayName = 'DictionaryAttribute';

export default memo(observer(DictionaryAttribute));
