import React, { FC, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Accordeon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import useOnclickOutside from 'react-cool-onclickoutside';

import ShortLogoSVG from '../../../../static/shortLogo.svg';
import TitleLogoSVG from '../../../../static/titleLogo.svg';
import AvatarMock from '../../../../static/avatar-mock.png';
import { ReactComponent as FieldsSvg } from '../../static/catalog.svg';
import { ReactComponent as CalendarSvg } from '../../static/calendar.svg';
import { ReactComponent as OperationsSvg } from '../../static/operations.svg';
import { ReactComponent as TasksSvg } from '../../static/tasks.svg';
import { ReactComponent as QuestionSvg } from '../../static/question.svg';
import { ReactComponent as SupportSvg } from '../../static/support.svg';
import { DashboardRoutes } from '../../dashboard.routes';
import OrgSvg from '../../../../static/organization-mock.svg';
import { useStore } from '../../../shared/utils/IoC';
import { UiStore } from '../../stores/ui.store';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { ProfileStore } from '../../modules/profile/stores/ProfileStore';
import { EAppSettingsType } from '../../../../index';
import { UserInfoType } from '../../../../api/models/user.model';
import { OrganizationsStore } from '../../stores/organizations.store';
import { SessionStore } from '../../../authorization/stores/session.store';
import SeasonAccordeon from '../../../shared/components/SeasonAccordeon/SeasonAccordeon';
import { CreateSeasonModal } from '../../modules/seasons/modals/CreateSeasonModal/CreateSeasonModal';
import { SeasonsStore } from '../../stores/seasons.store';
import { EditSeasonModal } from '../../modules/seasons/modals/EditSeasonModal/EditSeasonModal';
import { PopupPages } from '../../constants/popup.pages';
import { MapMode } from '../../../map/stores/map.store';
import { LeaveUnitModalStore } from '../../stores/leave.unit.modal.store';
import { ProfileController } from '../../modules/profile/controllers/profile.controller';
import { FieldsRoute } from '../../modules/fields/fields.route';
import { OrganizationsController } from '../../controllers/organizations/organizations.controller';
import { SeasonsController } from '../../controllers/seasons/seasons.controller';
import { FieldsController } from '../../modules/fields/controllers/fields.controller';
import { TasksController } from '../../modules/operations/controllers/tasks.controller';
import { OperationsStore } from '../../modules/operations/stores/operations.store';
import { ApplicationRoutes } from '../../../routes';
import { IndicesController } from '../Timeline/controllers/indices.controller';
import { ChecklistInstancesStore } from '../../modules/operationsAndTasks/stores/checklist.instances.store';
import { TasksEditStore } from '../../modules/operations/stores/task.edit.store';
import { TaskEditController } from '../../modules/operations/controllers/task.edit.controller';
import { CheckAccessStore } from '../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../shared/constants/access-rules-action';

import {
  AccordeonsWrapper,
  Avatar,
  AvatarWrapper,
  CalendarSmallBlock,
  Content,
  Footer,
  Header,
  Label,
  MenuItem,
  MenuShortItem,
  MenuWrapper,
  NameTipWrapper,
  OrganizationSmallBlock,
  ShortLogo,
  SupportLink,
  SupportLinkLabel,
  SupportLinksBlock,
  TipContent,
  TipTail,
  TitleLogo,
  Wrapper,
} from './style';
import { SidebarContextMenu } from './SidebarContextMenu';
import { SidebarController } from './sidebar.controller';

const PROFILE_LABEL_LENGTH = 15;

type SidebarProps = {
  isAdaptive?: boolean;
};

export const Sidebar: FC<SidebarProps> = observer(({ isAdaptive }) => {
  const history = useHistory();
  const location = useLocation();
  const ui = useStore(UiStore);
  const fieldsStore = useStore(FieldsStore);
  // todo refactor - много контроллеров в одном компоненте, возможно нужно все запихнуть в один sidebar.controller
  const fieldsController = useStore(FieldsController);
  const sideBarController = useStore(SidebarController);
  const { isEditSeasonModalOpen, setEditSeason } = ui;
  const profile = useStore(ProfileStore);
  const profileController = useStore(ProfileController);
  const organization = useStore(OrganizationsStore);
  const organizationsController = useStore(OrganizationsController);
  const session = useStore(SessionStore);
  const seasons = useStore(SeasonsStore);
  const seasonsController = useStore(SeasonsController);
  const taskEditController = useStore(TaskEditController);
  const leaveUnitModal = useStore(LeaveUnitModalStore);
  const operationsStore = useStore(OperationsStore);
  const tasksController = useStore(TasksController);
  const indicesController = useStore(IndicesController);
  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const taskEditStore = useStore(TasksEditStore);
  const { accessRules, getAccessRuleValue } = useStore(CheckAccessStore);
  const { seasonsOptions, selectedSeason } = seasons;
  const { setSelectedSeason, clear } = seasonsController;
  const { user } = profile;
  const [openArr, setOpenArr] = useState([false, false]);
  const accordeonWrapperRef = useOnclickOutside(() => {
    setOpenArr([false, false]);
  });
  const [currentRoute, setCurrentRoute] = useState<string>('');

  const [showContextMenu, setShowContextMenu] = useState(false);

  const menuItems = useMemo(() => {
    const result = [
      {
        icon: <FieldsSvg />,
        label: 'Поля',
        alias: 'field',
        link: generatePath(DashboardRoutes.Fields, {
          orgId: organization.selectedOrganizationId,
        }),
        // link: `/${orgId}/fields`,
        isDisabled: false,
      },
    ];

    const isOrgMy = organization.selectedOrganizationId === 'my';

    if (isOrgMy || getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS)) {
      result.push({
        icon: <OperationsSvg />,
        label: 'Операции',
        alias: 'operation',
        link: generatePath(DashboardRoutes.Operations, {
          orgId: organization.selectedOrganizationId,
        }),
        isDisabled: false,
        // link: `/${orgId}/operations`,
        // todo потом сделать заглушки на самих страницах если сезон не выбран
        // isDisabled: !Boolean(selectedSeason),
      });
    }

    if (
      isOrgMy ||
      getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS) ||
      getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS)
    ) {
      result.push({
        icon: <TasksSvg />,
        label: 'Задачи',
        alias: 'task',
        link: generatePath(DashboardRoutes.Tasks, {
          orgId: organization.selectedOrganizationId,
        }),
        isDisabled: false,
        // link: `/${orgId}/tasks`,
        // isDisabled: !Boolean(selectedSeason),
      });
    }
    return result;
  }, [accessRules, organization.selectedOrganizationId]);

  const handleSelectSeason = (season: string) => {
    profileController.setAppSettings(EAppSettingsType.Season)(season);
    // profileController.setAppSettings(EAppSettingsType.Organization)(
    //   organization.selectedOrganizationId
    // );

    setSelectedSeason(season);
  };

  const isAccordeonsDisabled = () => {
    return (
      ui.popupPageState === PopupPages.CultureZone ||
      ui.popupPageState === PopupPages.Seasons ||
      fieldsStore.mapMode === MapMode.Editing ||
      fieldsStore.mapMode === MapMode.Creating ||
      session.isLoading ||
      profile.loading ||
      organization.loading ||
      seasons.loading ||
      fieldsStore.isLoading ||
      Boolean(Object.keys(taskEditStore.task).length)
    );
  };

  const handleSelectOrganization = (organizationId: string) => {
    seasonsController.setSelectedSeason('');
    operationsStore.clearOperationsStore();
    tasksController.clear();
    replaceURLOrganization(organizationId);
    profileController.setAppSettings(EAppSettingsType.Organization)(organizationId);
    organizationsController.selectOrganization(organizationId);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    setCurrentRoute(currentPath);
  }, [location]);

  useEffect(() => {
    if (ui.isSidebarShort) {
      setIsSeasonAccordeon(false);
      setIsOrganizationAccordeon(false);
    }
  }, [ui.isSidebarShort]);

  useEffect(() => {
    return () => {
      if (ui.isAdaptive || isAdaptive) {
        return;
      }
      clear();
      profileController.reset();
    };
  }, []);

  const replaceURLOrganization = (organizationId: string) => {
    const previousOrgId = organization.currentOrganization?.organizationId || 'my';
    history.push(location.pathname.replace(previousOrgId, organizationId));
  };

  const isShowLeaveUnitModal = () =>
    ui.popupPageState === PopupPages.CultureZone ||
    fieldsStore.mapMode === MapMode.Editing ||
    fieldsStore.mapMode === MapMode.Creating ||
    checklistInstancesStore.hasPositionToInstanceChanged ||
    Boolean(taskEditStore.touchedFields.size);

  const onSidebarMenuItemClick = (itemLink, isDisabled) => {
    return () => {
      if (isDisabled) return;

      setOpenArr([false, false]);

      const showModal = isShowLeaveUnitModal();

      if (showModal) {
        leaveUnitModal.setDialogSettings({
          actionHandler: () => {
            fieldsController.resetSelectedField();
            leaveUnitModal.setIsShow(false);
            sideBarController.clearMainDashboardState();
            // Обнуляем усоловия при  которых отображается модалка из SIdebar
            checklistInstancesStore.setHasPositionToInstanceChanged(false);
            taskEditController.clearTouchedFields();

            history.push(itemLink);
          },
          isShow: true,
        });
      } else {
        fieldsController.resetSelectedField();
        fieldsController.resetFieldFill(false);
        indicesController.refreshPrevIndicesState();
        history.push(itemLink);
      }
    };
  };

  const renderShortContent = () => {
    const isDisabled = isAccordeonsDisabled();
    return (
      <div>
        <MenuWrapper>
          <OrganizationSmallBlock
            id="fieldsShortIcon"
            data-test-id={'fields-short-icon'}
            onClick={() => {
              ui.setIsSidebarShort(false);
              setOpenArr([true, false]);
              setIsOrganizationAccordeon(true);
            }}
            isDisabled={isDisabled}
          >
            <img src={organization?.currentOrganization?.logo?.downloadUrl || OrgSvg} />
          </OrganizationSmallBlock>
          <CalendarSmallBlock
            id="seasonShortIcon"
            data-test-id={'season-short-icon'}
            onClick={() => {
              ui.setIsSidebarShort(false);
              setOpenArr([false, true]);
              setIsSeasonAccordeon(true);
            }}
            isDisabled={isDisabled}
          >
            <CalendarSvg />
          </CalendarSmallBlock>
          {menuItems.map(item => (
            <MenuShortItem
              data-test-id={`menu-short-${item.alias}`}
              isActive={currentRoute.includes(item.link)}
              isDisabled={item.isDisabled}
              onClick={onSidebarMenuItemClick(item.link, item.isDisabled)}
            >
              {item.icon}
            </MenuShortItem>
          ))}
        </MenuWrapper>
      </div>
    );
  };

  const renderLabel = (userModel: UserInfoType) => {
    if (userModel.firstName) {
      return `${userModel.firstName} ${userModel.lastName}`;
    }

    if (userModel.phone.phoneNumber) {
      return `${userModel.phone.phoneNumberCode} ${userModel.phone.phoneNumber}`;
    }

    if (userModel.email.email) {
      return userModel.email.email;
    }

    return '';
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowContextMenu(true);
  };

  const [isOrganizationAccordeon, setIsOrganizationAccordeon] = useState(false);
  const [isSeasonAccordeon, setIsSeasonAccordeon] = useState(false);

  const handleOpen = (v: 'season' | 'organization') => {
    if (v === 'season') {
      setIsOrganizationAccordeon(false);
      setIsSeasonAccordeon(true);
      setOpenArr([false, true]);
      return;
    } else {
      setOpenArr([true, false]);
    }

    setIsSeasonAccordeon(false);
    setIsOrganizationAccordeon(true);
  };

  const isDisabled = isAccordeonsDisabled();

  const renderFullContent = () => {
    return (
      <div ref={accordeonWrapperRef}>
        <AccordeonsWrapper
          data-disabled={isDisabled}
          isDisabled={isDisabled}
          id="fullContentAccordeon"
          data-test-id="fullContentAccordeonWrapper"
        >
          <Accordeon
            onHover={() => 1}
            onChange={handleSelectOrganization}
            onMouseOut={() => 1}
            value={organization.selectedOrganizationId}
            options={[{ label: 'Мои поля', value: 'my' }, ...organization.organizationList]}
            placeholder={'Мои поля'}
            isOpen={isOrganizationAccordeon && openArr[0]}
            onOpen={() => handleOpen('organization')}
            dataTestId={'fields'}
          />
          <SeasonAccordeon
            onMouseOut={() => 1}
            options={seasonsOptions}
            value={selectedSeason}
            onCreate={() => setIsCreationModalOpen(true)}
            onChange={handleSelectSeason}
            dataTestId={'seasons'}
            placeholder={'Сезоны'}
            isOpen={isSeasonAccordeon && openArr[1]}
            onHover={() => 1}
            onOpen={() => handleOpen('season')}
            onEdit={() => setEditSeason(true)}
          />
        </AccordeonsWrapper>
        <MenuWrapper>
          {menuItems.map((item, index) => (
            <MenuItem
              isDisabled={item.isDisabled}
              isActive={currentRoute.includes(item.link)}
              onClick={onSidebarMenuItemClick(item.link, item.isDisabled)}
              key={item.link + index}
              data-test-id={`menu-item-${item.alias}`}
            >
              <MenuShortItem
                isDisabled={isDisabled || item.isDisabled}
                isActive={currentRoute.includes(item.link)}
                style={{ marginTop: '0' }}
                data-test-id={`menu-item-short-${item.alias}`}
              >
                {item.icon}
              </MenuShortItem>
              <p>{item.label}</p>
            </MenuItem>
          ))}
        </MenuWrapper>
      </div>
    );
  };

  const label = user ? renderLabel(user) : '';

  const handleOver = () => {
    if (label.length <= PROFILE_LABEL_LENGTH) {
      return;
    }

    setIsNameTipVisible(true);
  };

  const [isNameTipVisible, setIsNameTipVisible] = useState(false);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  const isShort = () => {
    if (!isAdaptive) {
      if (ui.isAdaptive) {
        return true;
      }
      return ui.isSidebarShort;
    }
    return false;
  };

  const onHeaderClick = () => {
    const showModal = isShowLeaveUnitModal();
    const fieldsListingRoute = generatePath(FieldsRoute.Listing, {
      orgId: organization.selectedOrganizationId,
    });
    if (showModal) {
      leaveUnitModal.setDialogSettings({
        actionHandler: () => {
          fieldsController.resetSelectedField();
          sideBarController.clearMainDashboardState();
          leaveUnitModal.isShow = false;
          history.push(fieldsListingRoute);
          // Обнуляем усоловия при  которых отображается модалка из SIdebar
          checklistInstancesStore.setHasPositionToInstanceChanged(false);
          taskEditController.clearTouchedFields();
        },
        isShow: true,
      });
    } else {
      fieldsController.resetSelectedField();
      indicesController.refreshPrevIndicesState();
      history.push(fieldsListingRoute);
    }
  };

  const onProfileOrLogoutClick = (goToProfile: boolean) => {
    return () => {
      setShowContextMenu(false);
      const showModal = isShowLeaveUnitModal();
      const externalAgroidProfileUrl =
        process.env?.NODE_ENV === 'development'
          ? ApplicationRoutes.ExternalAgroidProfileDev
          : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalAgroidProfile}`;
      if (showModal) {
        leaveUnitModal.setDialogSettings({
          actionHandler: () => {
            leaveUnitModal.isShow = false;
            if (goToProfile) {
              window.location.href = externalAgroidProfileUrl;
            } else {
              ui.setPageState(PopupPages.None, true, true);
              session.logout();
            }
          },
          isShow: true,
        });
      } else if (goToProfile) {
        window.location.href = externalAgroidProfileUrl;
      } else {
        ui.setPageState(PopupPages.None, true, true);
        session.logout();
      }
    };
  };

  const onProfileClick = onProfileOrLogoutClick(true);
  const onLogoutClick = onProfileOrLogoutClick(false);

  const getSupportLinksBlock = () =>
    isShort() ? (
      <SupportLinksBlock>
        <SupportLink
          href={'https://digitalagro.atlassian.net/servicedesk/customer/portal/18'}
          target={'_blank'}
        >
          <SupportSvg />
        </SupportLink>
        <SupportLink href={'https://guide.digitalagro.ru'} target={'_blank'}>
          <QuestionSvg />
        </SupportLink>
      </SupportLinksBlock>
    ) : (
      <SupportLinksBlock>
        <SupportLink
          href={'https://digitalagro.atlassian.net/servicedesk/customer/portal/18'}
          target={'_blank'}
        >
          <SupportSvg />
          <SupportLinkLabel>Служба поддержки</SupportLinkLabel>
        </SupportLink>
        <SupportLink href={'https://guide.digitalagro.ru'} target={'_blank'}>
          <QuestionSvg />
          <SupportLinkLabel>Руководство</SupportLinkLabel>
        </SupportLink>
      </SupportLinksBlock>
    );

  return (
    <Wrapper isShort={isShort()} id="testId">
      <Header onClick={onHeaderClick} title="1.3.2-demo">
        <ShortLogo src={ShortLogoSVG} data-test-id={'short-logo'} />
        <TitleLogo src={TitleLogoSVG} data-test-id={'long-logo'} />
      </Header>
      <Content>{ui.isSidebarShort ? renderShortContent() : renderFullContent()}</Content>
      {getSupportLinksBlock()}
      <Footer>
        <AvatarWrapper>
          <Avatar
            id="avatarImg"
            data-test-id={'avatar-img'}
            src={(user && user.avatar && user.avatar.downloadUrl) || AvatarMock}
            onClick={handleAvatarClick}
          />
          <Label
            id="avatarTitle"
            show={!ui.isSidebarShort}
            onMouseOver={handleOver}
            onMouseOut={() => setIsNameTipVisible(false)}
            onClick={handleAvatarClick}
            data-test-id={'avatar-title'}
          >
            {/* {label.length > PROFILE_LABEL_LENGTH
              ? `${label.substr(0, PROFILE_LABEL_LENGTH)}...`
              : `${label}`} */}
            {label}
          </Label>
          <SidebarContextMenu
            isOpen={showContextMenu}
            onClose={() => setShowContextMenu(false)}
            onGoToProfileClick={onProfileClick}
            onLogoutClick={onLogoutClick}
          />
        </AvatarWrapper>
        {isNameTipVisible && (
          <NameTipWrapper data-test-id={'profile-name-tip'}>
            <TipContent>
              <p>{label}</p>
            </TipContent>
            <TipTail />
          </NameTipWrapper>
        )}
        {isCreationModalOpen && (
          <CreateSeasonModal closeModal={() => setIsCreationModalOpen(false)} />
        )}
        {isEditSeasonModalOpen && selectedSeason && (
          <EditSeasonModal closeModal={() => setEditSeason(false)} />
        )}
      </Footer>
    </Wrapper>
  );
});
