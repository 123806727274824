import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

export const LabelTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 36px;
  color: ${Colors.black};
`;

export const MarkImg = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 35px;
`;

export const ButtonActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  text-align: center;
  width: 100%;
  > button {
    display: inline-block;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(21,31,50,0.3);
  backdrop-filter: blur(8px);
  z-index: 3001;
`

export const ModalElement = styled.div`
  z-index: 3002;
`