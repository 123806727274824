import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 28px;
  margin-bottom: 15px;
`;

export const OrgName = styled.div`
  font-size: 14px;
  color: ${Colors.darkGray};
  padding-bottom: 35px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${Colors.grayBorder};
`;

export const InputWrapper = styled.div`
  margin-bottom: 24px;

  & button {
    justify-content: center;
  }
`;

export const InfoTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 50px;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  margin: 30px auto 40px;
  display: flex;
  justify-content: center;
`;
