import { observer } from 'mobx-react';
import { forwardRef, memo } from 'react';

import { IChecklistDrawingStage } from '../../../../../../../../../api/models/checklist/stage/checklist.stage.model';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { Container } from '../../../../../components/Container';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import { useGetOrderedAttributeWithValueList } from '../../../../../hooks/useGetAvailableChecklistAttributeList';
import { ChecklistInstancesStore } from '../../../../../stores/checklist.instances.store';
import ChecklistAttributeContainer from '../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';
import ContainerForAttributeWithChildren from '../../containers/ContainerForAttributeWithChildren/ContainerForAttributeWithChildren';

type TStageProps = {
  drawingStage: IChecklistDrawingStage;
};

const Stage = forwardRef<HTMLInputElement, TStageProps>(({ drawingStage }, ref) => {
  const { isChecklistViewMode } = useStore(ChecklistInstancesStore);

  const { handleChangeAttributeValue } = useStore(ChecklistInstancesController);

  const orderedAttributeWithValueList = useGetOrderedAttributeWithValueList(
    drawingStage.attributeWithValueList
  );

  return (
    <Container title={drawingStage.name}>
      {orderedAttributeWithValueList.map(attributeWithValue => {
        if (attributeWithValue?.children?.length) {
          return (
            <ContainerForAttributeWithChildren
              key={attributeWithValue.checklistAttribute.id}
              attributeWithValue={attributeWithValue}
              onChange={handleChangeAttributeValue}
              isBlocked={isChecklistViewMode}
              ref={ref}
            />
          );
        } else {
          return (
            <ChecklistAttributeContainer
              key={attributeWithValue.checklistAttribute.id}
              attributeWithValue={attributeWithValue}
              onChange={handleChangeAttributeValue}
              isBlocked={isChecklistViewMode}
              ref={ref}
            />
          );
        }
      })}
    </Container>
  );
});

Stage.displayName = 'Stage';

export default memo(observer(Stage));
