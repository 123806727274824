import { makeAutoObservable, reaction, toJS } from 'mobx';
import L, {
  DivIcon,
  LatLng,
  LatLngBoundsExpression,
  LatLngTuple,
  Layer,
  marker,
  Polygon,
} from 'leaflet';
import area from '@turf/area';
import { throttle, debounce } from 'lodash';
import {
  intersect,
  polygon as turfPolygon,
  point as turfPoint,
  booleanPointInPolygon as turfBooleanPointInPolygon,
} from '@turf/turf';

import mapMarkerSvg from '../static/mapMarker.svg';
import { lazyInject, provide } from '../../shared/utils/IoC';
import { createPolygonEvent } from '../events/create.polygon.event';
import { editPolygon } from '../events/edit.polygon.event';
import { RenderPolygonOption } from '../consts/enum.render.option';
import { editOnePolygon } from '../events/edit.one.polygon.event';
import { LabelFieldFillEnum } from '../../dashboard/constants/label.fill.enum';
import { createCultureZoneEvent } from '../events/create.culture.zone.event';
import { clickMapEvent } from '../events/click.map.event';
import { CultureZone } from '../../../api/models/field.model';

const polygonOptions = {
  allowSelfIntersection: false,
  hintlineStyle: {
    fillColor: '#fff9e8',
    color: '#FFD765',
    dashArray: [5, 5],
  },
  snappable: true,
  snapDistance: 3,
  pathOptions: {
    fillColor: '#fff9e8',
    color: '#FFD765',
  },
  markerStyle: {
    color: '#FFD765',
  },
  templineStyle: {
    fillColor: '#fff9e8',
    color: '#FFD765',
  },
};

const cultureWizardPolygonOptions = {
  allowSelfIntersection: false,
  hintlineStyle: {
    fillColor: '#fff',
    fillOpacity: 0,
    color: '#F29993',
    dashArray: [5, 5],
  },
  snappable: true,
  snapDistance: 3,
  pathOptions: {
    fillColor: '#fff9e8',
    color: '#F29993',
    fillOpacity: 0,
  },
  markerStyle: {
    color: '#F29993',
  },
  templineStyle: {
    fillColor: '#fff',
    fillOpacity: 0,
    color: '#F29993',
    dashArray: [5, 5],
  },
};

export type GeoPoint = {
  lat: number;
  lng: number;
};

export type PolygonData = {
  id: string;
  points: Array<GeoPoint>;
  fill?: string;
};

export enum MapMode {
  Listing = 'listing',
  Creating = 'creating',
  Editing = 'editing',
  Import = 'import',
}

enum PointerStatus {
  None = 'none',
  Auto = 'auto',
}

@provide.singleton()
class MapStore {
  // needed to track unfinished contours
  pointsOfUnfinishedContour = 0;

  idToLayout: Map<number, any> = new Map();
  tempMakersSet: Set<HTMLElement> = new Set();

  // todo mapMode присутствует и в fieldStore и mapStore
  mapMode: MapMode = MapMode.Listing;

  private _idToEditableLayerGeometry: Map<number, any> = new Map<number, any>();

  private _idToUnchangedLayer: Map<number, any> = new Map<number, any>();

  private _idToImportedFieldLayer: Map<number, any> = new Map<number, any>();

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => Array.from(this.incorrectLayersId),
      () => {
        Array.from(this.idToPolygon.values()).forEach(l => {
          // @ts-ignore
          this.changeColorToNormal(RenderPolygonOption.View, l._leaflet_id);
        });
        Array.from(this.idToEditableLayer.values()).forEach(l => {
          // @ts-ignore
          this.changeColorToNormal(RenderPolygonOption.Creating, l._leaflet_id);
        });
        Array.from(this.incorrectLayersId).forEach(id => {
          this.showErrorInPolygon(id);
        });
        console.log('error reaction');
      }
    );

    // / 123

    reaction(
      () => this.pointsOfUnfinishedContour,
      pointsOfUnfinishedContour => {
        if (pointsOfUnfinishedContour === 0) {
          this.unlockMarkers();
        } else {
          this.lockMarkers();
        }
      }
    );
  }

  getEditableLayerGeometry = (id: number): any => {
    return this._idToEditableLayerGeometry.get(id);
  };

  unchangedLayer = (id: number): any => {
    return this._idToUnchangedLayer.get(id);
  };

  getImportedFieldLayer = (id: number): any => {
    return this._idToImportedFieldLayer.get(id);
  };

  setEditableLayerGeometry = (id: number, geometry: any): void => {
    this._idToEditableLayerGeometry.set(id, geometry);
  };

  setIdToImportedFieldLayer = (idToLayer: Map<number, any>): void => {
    this._idToImportedFieldLayer = idToLayer;
  };

  setImportedFieldLayer = (id: number, layer: any): void => {
    this._idToImportedFieldLayer.set(id, layer);
  };

  deleteImportedFieldLayer = (id: number): void => {
    this._idToImportedFieldLayer.delete(id);
  };

  setIdToUnchangedLayer = (idToLayer: Map<number, any>): void => {
    this._idToUnchangedLayer = idToLayer;
  };

  clearIdToImportedFieldLayer = (): void => {
    this._idToImportedFieldLayer.clear();
  };

  clearIdToUnchangedLayer = (): void => {
    this._idToUnchangedLayer.clear();
  };

  clearEditableLayerGeometry = (): void => {
    this._idToEditableLayerGeometry.clear();
  };

  deleteLayout = (id: number): void => {
    this.idToLayout.delete(id);
  };

  incrementPointsOfUnfinishedContour = (): void => {
    this.pointsOfUnfinishedContour++;
  };

  clearPointsOfUnfinishedContour = (): void => {
    this.pointsOfUnfinishedContour = 0;
  };

  changeColorToNormal = (colorOption: RenderPolygonOption, layerId: number) => {
    if (this.mapMode === MapMode.Import) {
      const layerOfUnchangedField = this.unchangedLayer(layerId);
      const layerOfImportedField = this.getImportedFieldLayer(layerId);

      if (layerOfUnchangedField) {
        layerOfUnchangedField.setStyle({
          fillColor: '#F2F1F4',
          fillOpacity: 0.25,
          color: '#F2F1F4',
          colorOpacity: 0.7,
        });
      }

      if (layerOfImportedField) {
        layerOfImportedField.setStyle({
          fillColor: '#fff9e8',
          color: '#FFD765',
        });
      }

      return;
    }

    if (colorOption === RenderPolygonOption.View) {
      const layer = this.idToPolygon.get(layerId);
      layer.setStyle({ color: '#FD7E09' });
      return;
    }

    if (colorOption === RenderPolygonOption.Listing) {
      const layer = this.idToPolygon.get(layerId);
      if (!layer) {
        return;
      }
      layer.setStyle({ color: '#FFD765' });
      return;
    }

    const layer = this.idToEditableLayer.get(layerId);
    // @ts-ignore
    layer.setStyle({ color: '#FFD765' });
  };

  highlightPolygon = (layerId: number, isPadding?: boolean) => {
    let layer;
    try {
      layer = this.getLayer(layerId);
    } catch (e) {
      console.log(e);
    }

    if (!layer) {
      return;
    }

    if (this.instance) {
      layer.setStyle({
        fillColor: '#ffffff',
        fillOpacity: 0.15,
        strokeWidth: 1,
        color: '#ffffff',
      });
    }

    this.centerMapToPoint(layerId, isPadding);
  };

  centerMapToPoint = (layerId: number, isPadding?: boolean) => {
    let layer;
    try {
      layer = this.getLayer(layerId);
    } catch (e) {
      console.log(e);
    }

    if (!layer) {
      return;
    }

    const layerBounds = layer.getBounds();
    const options = {
      padding: isPadding && [150, 150],
    };

    if (this.instance) this.instance.fitBounds(layerBounds, options);

    // const layerBounds = layer.getBounds();
    // console.log('East - West', layerBounds.getSouthWest(), layerBounds.getSouthEast());
    //
    // const horizontalLength = layerBounds.getSouthWest().distanceTo(layerBounds.getSouthEast());
    // // @ts-ignore
    // const latLon = new L.latLng(layerBounds.center);
    // const bounds = latLon.toBounds(horizontalLength); // 500 = metres
    // this.instance.panTo(latLon).fitBounds(bounds);
  };

  setTooltipContentForLayer = (layerId: number, content: string, isEditable?: boolean) => {
    const layer = isEditable ? this.getEditableLayer(layerId) : this.getLayer(layerId);
    if (!layer) return;
    layer.setTooltipContent(content);
  };

  setViewMode = () => {
    if (!this.instance) {
      return;
    }
    this.mapMode = MapMode.Listing;
    this.instance.pm.disableDraw();
  };

  setInstance = (v: any) => {
    // @ts-ignore
    window.leafletMap = v;
    this.instance = v;

    if (this.instance) {
      // @ts-ignore
      L.gridLayer.googleMutant({ type: 'hybrid' }).addTo(v);
      // this.instance.on('zoomend', this.handleZoom);
      this.instance.on('pm:drawstart', this.handleDrawStart);
      this.instance.on('pm:drawend', this.handleDrawEnd);
      this.instance.on('pm:create', this.handleNewPolygon);
      this.instance.on('click', this.handleMapClick);
      this.instance.pm.setLang('ru');
    }
  };

  handleMapClick = event => {
    event.originalEvent.preventDefault();
    clickMapEvent(event.latlng);

    if (this.mapMode === MapMode.Creating) {
      this.incrementPointsOfUnfinishedContour();
    }
  };

  isGraphicVisible = true;

  handleZoom = () => {
    const zoom = this.instance.getZoom();
    const allPolygons = [...this.polygons, ...this.editableLayers];
    if (zoom < 10 && this.isGraphicVisible) {
      this.isGraphicVisible = false;
      allPolygons.forEach(l => {
        // @ts-ignore
        l.closeTooltip();
        l.remove();
      });
    }

    if (zoom >= 10 && !this.isGraphicVisible) {
      this.isGraphicVisible = true;
      this.polygons.forEach(l => {
        l.openTooltip();
        l.addTo(this.instance);
      });
      this.editableLayers.forEach(l => {
        l.openTooltip();
        l.addTo(this.instance);
        // @ts-ignore
        l.pm.enable({ preventMarkerRemoval: true });
      });
    }
  };

  setMode = (v: MapMode) => {
    this.mapMode = v;
  };

  instance: any = null;

  setCoordinates = (lat: number, lng: number) => {
    this.coordinates = {
      lat,
      lng,
    };
  };

  coordinates: GeoPoint = {
    lat: 0,
    lng: 0,
  };

  idToPolygon: Map<number, Polygon> = new Map();

  setLayout = (boundPoints: LatLngBoundsExpression, url: string) => {
    // @ts-ignore
    const layer = L.imageOverlay(url, boundPoints).addTo(this.instance).bringToFront();
    // @ts-ignore
    this.idToLayout.set(layer._leaflet_id, layer);

    // @ts-ignore
    return layer._leaflet_id;
  };

  getLayout = (id: number) => {
    if (!this.idToLayout.has(id)) {
      throw new Error(`there is no layout with ${id}`);
    }

    return this.idToLayout.get(id);
  };

  get layouts() {
    return Array.from(this.idToLayout.values());
  }

  clearLayouts = () => {
    console.log('this.layouts ', this.layouts);
    this.layouts.forEach(l => {
      l.remove();
    });

    this.idToLayout.clear();
  };

  setLabelToTheField = (
    labelFieldFill: LabelFieldFillEnum,
    options: AddPolygonOptionsType
  ): string => {
    switch (labelFieldFill) {
      case LabelFieldFillEnum.Area:
        return options.area;
      case LabelFieldFillEnum.Culture:
        return options.culture;
      case LabelFieldFillEnum.None:
        return options.none;
      default:
        return options.name;
    }
  };

  // Add polygon by coords
  setPolygon = (
    geometry: Array<Array<number>> | Array<Array<Array<number>>>,
    options?: AddPolygonOptionsType,
    labelFieldFill?: LabelFieldFillEnum
  ) => {
    const points = geometry[0][0][0]
      ? geometry.map(feature => feature.map(dots => [dots[1], dots[0]]))
      : [geometry.map(dots => [dots[1], dots[0]])];
    // @ts-ignore
    let tempPoly;

    const isImportMode: boolean = this.mapMode === MapMode.Import;

    if (
      !isImportMode &&
      options.renderOption === RenderPolygonOption.CultureZone &&
      (!options.fill || options.fill === 'NONE')
    ) {
      // @ts-ignore
      tempPoly = new L.Polygon(points, {
        fill: `url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg)` as any,
        fillOpacity: 0.5,
        color: '#FD7E09',
      });
      tempPoly.addTo(this.instance);
      this.idToLayout.set(tempPoly._leaflet_id, tempPoly);
      return tempPoly._leaflet_id;
    } else {
      // @ts-ignore
      tempPoly = new L.Polygon(points);
    }

    if (
      !isImportMode &&
      options.renderOption === RenderPolygonOption.CultureZone &&
      options.fill === 'EMPTY'
    ) {
      // @ts-ignore
      tempPoly = new L.Polygon(points, {
        color: '#ffffff',
        fillColor: '#ffffff',
        fillOpacity: 0.15,
      });
      tempPoly.addTo(this.instance);
      this.idToLayout.set(tempPoly._leaflet_id, tempPoly);
      return tempPoly._leaflet_id;
    }

    if (isImportMode && options.renderOption === RenderPolygonOption.Import) {
      tempPoly.setStyle({
        fillColor: '#F2F1F4',
        fillOpacity: 0.25,
        color: '#F2F1F4',
        colorOpacity: 0.7,
      });
      if (options.area || options.culture || options.none || options.name) {
        tempPoly
          .bindTooltip(this.setLabelToTheField(labelFieldFill, options), {
            permanent: true,
            direction: 'center',
            className: 'field-label',
          })
          .openTooltip();
      }
    } else if (options.renderOption === RenderPolygonOption.View) {
      tempPoly.setStyle({
        fillColor: '#fff9e8',
        color: '#FFD765',
      });
      if (options.area || options.culture || options.none || options.name) {
        tempPoly
          .bindTooltip(this.setLabelToTheField(labelFieldFill, options), {
            permanent: true,
            direction: 'center',
            className: 'field-label',
          })
          .openTooltip();
      }
    } else if (options.renderOption === RenderPolygonOption.CultureZone) {
      // Polygon with fill
      if (options.fill) {
        tempPoly.setStyle({
          fillColor: options.fill,
          fillOpacity: options.fill === 'NONE' ? 0 : 1,
          color: '#fd7e09',
        });
      }
    } else if (options.renderOption === RenderPolygonOption.CultureZoneBorder) {
      tempPoly.setStyle({
        fillOpacity: 0,
        strokeWidth: 1,
        color: '#FD7E09',
      });
    } else if (options.renderOption === RenderPolygonOption.CultureZoneHole) {
      tempPoly.setStyle({
        fillColor: 'blue',
        strokeOpacity: '0',
      });
    } else {
      // Side polygon
      tempPoly.setStyle({
        fillColor: '#fd7e09',
        fillOpacity: 0.1,
        strokeWidth: 1,
        color: '#FD7E09',
      });
    }
    if (tempPoly && this.instance && tempPoly.addTo) {
      tempPoly.addTo(this.instance);
    }

    // @ts-ignore
    if (options.editable) {
      // @ts-ignore
      this.idToEditableLayer.set(tempPoly._leaflet_id, tempPoly);
    } else {
      // @ts-ignore
      this.idToPolygon.set(tempPoly._leaflet_id, tempPoly);
    }
    // @ts-ignore
    return tempPoly._leaflet_id;
  };

  setMarker = coords => {
    const geomanIcon = new DivIcon({
      html: `<img src='${mapMarkerSvg}'/>`,
      className: '',
    });

    const newMarker = marker([parseFloat(coords.latitude), parseFloat(coords.longitude)], {
      pmIgnore: true,
    })
      .setIcon(geomanIcon)
      .addTo(this.instance);
  };

  removePolygon = (key: number) => {
    this.idToPolygon.delete(key);
  };

  get polygons() {
    return Array.from(this.idToPolygon.values());
  }

  isShapeFinished = false;

  cancelDrawing = () => {
    // @ts-ignore
    this.instance.pm.disableDraw();
  };

  changeCreatableFieldName = (id: number, name: string) => {
    const layer = this.idToEditableLayer.get(id);
    layer.unbindTooltip();
    layer
      .bindTooltip(name, {
        permanent: true,
        direction: 'center',
        className: 'field-label',
      })
      .openTooltip();
  };

  changeMode = () => {
    if (!this.instance) {
      return;
    }
    this.isShapeFinished = false;
    this.setMode(MapMode.Creating);
    this.instance.pm.enableDraw('Polygon', polygonOptions);

    this.instance.on('pm:drawstart', this.handleDrawStart);
    this.instance.on('pm:drawend', this.handleDrawEnd);
    this.instance.on('pm:create', this.handleNewPolygon);
    this.instance.on('click', this.handleMapClick);
  };

  startDrawCultureWizard = () => {
    this.instance.pm.enableDraw('Polygon', cultureWizardPolygonOptions);
    this.instance.off('pm:create', this.handleNewPolygon);
    this.instance.on('pm:create', this.handleCultureWizardNewPolygon);
  };

  handleCultureWizardNewPolygon = event => {
    const { layer } = event;
    const geoJson = layer.toGeoJSON();
    layer.remove();
    createCultureZoneEvent(geoJson.geometry.coordinates[0]);
  };

  handleDrawStart = event => {
    if (!this.isShapeFinished) {
      return;
    }
    this.isShapeFinished = false;
    this.currentLayer = event.target;
  };

  handleDrawEnd = () => {
    if (this.isShapeFinished || this.isDragStarted) {
      return;
    }
    this.isShapeFinished = true;
    if (this.mapMode === MapMode.Listing) {
      return;
    }
    console.log('DRAW END');
    setTimeout(() => this.instance.pm.enableDraw('Polygon', polygonOptions), 50);
  };

  idToEditableLayer: Map<number, any> = new Map();

  get editableLayers() {
    return Array.from(this.idToEditableLayer.values());
  }

  showErrorInPolygon = (layerId: number) => {
    const layer: Layer = this.idToEditableLayer.has(layerId)
      ? this.idToEditableLayer.get(layerId)
      : this.idToPolygon.get(layerId);

    if (!layer) {
      return;
    }

    // @ts-ignore
    layer.setStyle({ color: 'red' });
  };

  isDragStarted = false;

  lockMarkers = (): void => {
    this.tempMakersSet.forEach(item => {
      item.style.pointerEvents = PointerStatus.None;
    });
  };

  unlockMarkers = (): void => {
    this.tempMakersSet.forEach(item => {
      item.style.pointerEvents = PointerStatus.Auto;
    });
  };

  addMarkersToSet = markersCollection => {
    markersCollection.forEach(item => this.tempMakersSet.add(item.pm._layer._icon));
  };

  handleNewPolygon = event => {
    const { layer } = event;
    const geoJson = layer.toGeoJSON();

    setTimeout(() => this.addMarkersToSet(event.marker.pm.markerCache), 50);

    layer.on('pm:markerdragstart', this.handleLayerMarkerDragStart);
    layer.on('pm:markerdragend', this.handleLayerMarkerDragEnd);
    // layer.on('pm:edit', this.handleEditPolygon);
    const layerId = layer._leaflet_id;
    this.idToEditableLayer.set(layerId, layer);
    layer.pm.enable({ preventMarkerRemoval: true });

    layer
      .bindTooltip('', {
        permanent: true,
        direction: 'center',
        className: 'field-label',
      })
      .openTooltip();
    layer.setStyle({ fillColor: '#fff9e8', color: '#FFD765' });
    this.checkCrossingOfFields();
    console.log('coords 0', geoJson.geometry.coordinates[0]);
    const coords = geoJson.geometry.coordinates[0].map(position => [
      position[0].toFixed(19),
      position[1].toFixed(19),
    ]);
    console.log('coords 1', coords);
    createPolygonEvent(coords, layerId);
  };

  reRenderLable = (layer: any) => {
    const label = layer.getTooltip()?.getContent();
    layer.unbindTooltip();
    layer
      .bindTooltip(label, {
        permanent: true,
        direction: 'center',
        className: 'field-label',
      })
      .openTooltip();
  };

  handleLayerMarkerDragEnd = event => {
    this.isDragStarted = false;
    this.reRenderLable(event.target);
    this.handleEditPolygon(event);
    setTimeout(() => {
      this.instance.pm.enableDraw('Polygon', polygonOptions);
      this.clearPointsOfUnfinishedContour();
      this.checkCrossingOfFields();
    }, 100);
  };

  handleLayerMarkerDragStart = () => {
    console.log('handleLayerMarkerDragStart');
    this.isDragStarted = true;
    this.instance.pm.disableDraw();
  };

  incorrectLayersId: Set<number> = new Set();
  currentLayer: Layer;

  checkCrossingOfFields = () => {
    console.log('cross check');
    this.incorrectLayersId.clear();
    this.clearPointsOfUnfinishedContour();
    // @ts-ignore
    // const targetGeoJson = this.idToEditableLayer.get(layerId).toGeoJSON();

    const allLayersInSession = [
      ...Array.from(this.idToEditableLayer.values()),
      ...Array.from(this.idToPolygon.values()),
    ];
    allLayersInSession.forEach(layerOne => {
      allLayersInSession.forEach(layerTwo => {
        // @ts-ignore
        const featureOne = layerOne.toGeoJSON();
        const areaInHectars = area(featureOne) / 10000;
        if (areaInHectars > 1000) {
          // @ts-ignore
          this.incorrectLayersId.add(layerOne._leaflet_id);
        }
        // @ts-ignore
        const featureTwo = layerTwo.toGeoJSON();
        if (JSON.stringify(featureOne) === JSON.stringify(featureTwo)) {
          return;
        }
        if (intersect(featureOne, featureTwo)) {
          // if (booleanIntersects(featureOne, featureTwo)) {
          console.log(featureOne.geometry, featureTwo.geometry);
          // @ts-ignore
          this.incorrectLayersId.add(layerOne._leaflet_id);
          // @ts-ignore
          this.incorrectLayersId.add(layerTwo._leaflet_id);
        }
      });
    });
  };

  handleEditPolygon = event => {
    const { target } = event;
    this.addMarkersToSet(target.pm.markerCache);
    this.reRenderLable(event.target);
    const leafletId = target._leaflet_id;
    const geoJson = target.toGeoJSON();
    this.isShapeFinished = false;
    this.checkCrossingOfFields();
    editPolygon(geoJson.geometry, leafletId);
    setTimeout(() => this.checkCrossingOfFields(), 100);
    console.log(geoJson.geometry);
    this.setEditableLayerGeometry(leafletId, geoJson.geometry);
  };

  handleEditOnePolygon = event => {
    const { target } = event;
    this.addMarkersToSet(target.pm.markerCache);
    this.reRenderLable(event.target);
    const leafletId = target._leaflet_id;
    const geoJson = target.toGeoJSON();
    this.isShapeFinished = false;
    this.checkCrossingOfFields();
    editOnePolygon(geoJson.geometry, leafletId);
    setTimeout(() => this.checkCrossingOfFields(), 100);
  };

  getLayer = (id: number) => {
    return this.idToEditableLayer.get(id) || this.idToPolygon.get(id) || this.idToLayout.get(id);
  };

  getEditableLayer = (id: number) => {
    return this.idToEditableLayer.get(id);
  };

  deletePolygon = (id: number) => {
    const layer = this.idToEditableLayer.get(id) || this.idToPolygon.get(id);
    if (!layer) {
      return;
    }
    layer.remove();
    this.idToPolygon.delete(id);
    this.idToEditableLayer.delete(id);
  };

  removeEditablePolygon = () => {
    this.currentLayer.remove();
    this.currentLayer = null;
  };

  clearPolygons = () => {
    console.log('clearPolygons shit', this.polygons);
    this.polygons.forEach(i => {
      i.unbindTooltip();
      i.remove();
    });
    this.idToPolygon.clear();
  };

  clearEditable = () => {
    this.editableLayers.forEach(i => {
      i.unbindTooltip();
      i.remove();
    });
    this.idToEditableLayer.clear();
  };

  clearAll = () => {
    this.clearEditable();
    this.clearPolygons();
    this.clearLayouts();
    this.unlockMarkers();
    this.clearEditableLayerGeometry();
    this.clearIdToUnchangedLayer();
    this.clearIdToImportedFieldLayer();
    console.log('CLEAR ALL');
  };

  unbindCreatingEvents = () => {
    this.instance.off('pm:drawstart', this.handleDrawStart);
    this.instance.off('pm:drawend', this.handleDrawEnd);
    // disables cultural zone drawing
    this.instance.off('pm:create', this.handleCultureWizardNewPolygon);
  };

  changeLayerToEditable = (id: number) => {
    const layer = this.idToEditableLayer.get(id);
    // @ts-ignore
    layer.on('pm:edit', this.handleEditPolygon);
    layer.on('pm:markerdragend', () => setTimeout(() => this.checkCrossingOfFields(), 100));
    // @ts-ignore
    layer.pm.enable({ preventMarkerRemoval: true });
    this.centerMapToPoint(id);
  };

  disableLayerEditing = (layerId: number) => {
    const layer = this.idToEditableLayer.get(layerId);

    if (layer) {
      layer.off('pm:edit', this.handleEditPolygon);
      layer.off('pm:markerdragend', this.checkCrossingOfFields);

      layer.pm.disable();
    }
  };

  enableLayerEditing = (layerId: number) => {
    const layer = this.idToEditableLayer.get(layerId);

    if (layer) {
      layer.on('pm:edit', this.handleEditPolygon);
      layer.on('pm:markerdragend', () => setTimeout(() => this.checkCrossingOfFields(), 100));

      layer.pm.enable({ preventMarkerRemoval: true });

      this.centerMapToPoint(layerId);
    }
  };

  drawCultureZones = (cultureZone: CultureZone): number =>
    this.setPolygon(cultureZone.geometry.coordinates, {
      renderOption: RenderPolygonOption.CultureZone,
      fill: cultureZone.culture.attrs.assistanceColorLegend,
    });
}

export type AddPolygonOptionsType = {
  name?: string;
  area?: string;
  culture?: string;
  none?: string;
  renderOption: RenderPolygonOption;
  editable?: boolean;
  fill?: string;
};
export default MapStore;
