import { observer } from 'mobx-react';
import { forwardRef, memo, useEffect, useMemo } from 'react';

import { IChecklistAttributeWithValue } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesController } from '../../../../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../../../../stores/checklist.instances.store';

import { NestedInstance } from './NestedInstance';
import { useGetAvailableDrawingNestedInstanceList } from './NestedInstance/hooks/useGetDrawingAvailableNestedInstanceList';
import StyledNestedInstance from './NestedInstance/styles';

type TChecklistLinkAttributeProps = {
  attributeWithValue: IChecklistAttributeWithValue;
  focusTarget?: string;
};

const ChecklistLinkAttribute = forwardRef<HTMLInputElement, TChecklistLinkAttributeProps>(
  ({ attributeWithValue, focusTarget }, ref) => {
    const { id } = attributeWithValue.checklistAttribute;
    const { isActive, checklistAttribute } = attributeWithValue;

    const {
      isChecklistEditMode,
      drawingNestedInstanceList,
      templateNestedInstanceList,
      getTempNestedInst,
      getEditNestedInst,
    } = useStore(ChecklistInstancesStore);

    const {
      generateDrawingNestedInstanceList,
      handleHidingTheChecklistLinkAttribute,
      showTemplateNestedInstance,
    } = useStore(ChecklistInstancesController);

    // Генерируем записи
    useEffect(() => {
      (async () => {
        await generateDrawingNestedInstanceList(attributeWithValue);
      })();
    }, []);

    /**
     * После перевода в положение 'off' родительского атрибута: очищаем в сторе
     * редактирование записи, дабы не мешать валидации.
     * Если у нас есть несохраненная запись, но мы закрываем форму ее заполнения, то
     * значения записи делаем до всех изменений с ней.
     */
    useEffect(() => {
      if (!isActive) {
        handleHidingTheChecklistLinkAttribute({
          rootAttributeId: checklistAttribute.id,
          rootAttributeBooleanValue: isActive,
        });
      }
    }, [isActive]);

    /**
     * После смены точки или фенофазы: очищаем в сторе редактирование записи, дабы не
     * плодить ошибок с редактированием
     */
    useEffect(() => {
      return () => {
        handleHidingTheChecklistLinkAttribute({
          isLeavingThisChecklist: true,
          rootAttributeId: checklistAttribute.id,
        });
      };
    }, []);

    const { nestedInstanceList, templateNestedInstance } = useGetAvailableDrawingNestedInstanceList(
      drawingNestedInstanceList,
      id
    );

    // Следит за тем, когда показывать/скрывать шаблон для создании записи
    useEffect(() => {
      if (!isChecklistEditMode) return;

      const isEmptyDrawingNestedInstanceList = !nestedInstanceList?.length;

      const isAllowedToAddANewRecord: boolean = checklistAttribute.attribute.isMultiselect
        ? true
        : nestedInstanceList?.length < 1;

      const suitableTemplateNestedInstance = getTempNestedInst(
        attributeWithValue.checklistAttribute.id
      );

      if (
        isActive &&
        isEmptyDrawingNestedInstanceList &&
        !suitableTemplateNestedInstance &&
        isAllowedToAddANewRecord &&
        templateNestedInstance
      ) {
        showTemplateNestedInstance(templateNestedInstance);
      }
    }, [
      nestedInstanceList,
      templateNestedInstance,
      isChecklistEditMode,
      isActive,
      attributeWithValue,
    ]);

    // Условие отображения шаблона для создания записи
    const isTemplateNestedInstance: boolean = useMemo(
      () => Boolean(getTempNestedInst(attributeWithValue.checklistAttribute.id)),
      [attributeWithValue, templateNestedInstanceList]
    );

    const handleAddingMoreInstanceClick = (): void => {
      showTemplateNestedInstance(templateNestedInstance);
    };

    return (
      isActive && (
        <>
          {nestedInstanceList?.map((drawingNestedInstance, index) =>
            !drawingNestedInstance?.isTemplate ? (
              <NestedInstance
                key={drawingNestedInstance.id}
                drawingNestedInstance={drawingNestedInstance}
                recordNumber={index + 1}
                isEditing={
                  getEditNestedInst(checklistAttribute.id)?.id === drawingNestedInstance.id
                }
                focusTarget={focusTarget}
                ref={ref}
              />
            ) : null
          )}

          {isTemplateNestedInstance ? (
            <NestedInstance
              drawingNestedInstance={templateNestedInstance}
              totalNumberOfRecords={nestedInstanceList.length}
              isTemplate
              focusTarget={focusTarget}
              ref={ref}
            />
          ) : null}

          {isChecklistEditMode &&
          checklistAttribute.attribute.isMultiselect &&
          !isTemplateNestedInstance &&
          !getEditNestedInst(checklistAttribute.id) ? (
            <StyledNestedInstance.SaveButtonWrapper>
              <StyledNestedInstance.SaveButton
                type="button"
                onClick={handleAddingMoreInstanceClick}
              >
                Добавить ещё
              </StyledNestedInstance.SaveButton>
            </StyledNestedInstance.SaveButtonWrapper>
          ) : null}
        </>
      )
    );
  }
);

ChecklistLinkAttribute.displayName = 'ChecklistLinkAttribute';

export default memo(observer(ChecklistLinkAttribute));
