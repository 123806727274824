import { provide, lazyInject } from '../../../../../../shared/utils/IoC';
import { CultureZoneStore } from '../stores/culture.zone.store';
import MapStore from '../../../../../../map/stores/map.store';

@provide.singleton()
export class CultureZoneController {
  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(CultureZoneStore)
  protected cultureZoneStore: CultureZoneStore;

  startDrawZone = (): void => {
    this.mapStore.startDrawCultureWizard();
    this.cultureZoneStore.setIsCultureZoneCreating(true);
    this.cultureZoneStore.setIsZoneNotificationWarning(true);
  };

  acceptTheCreatedNewZone = (): void => {
    this.cultureZoneStore.setIsCultureZoneCreating(false);
    this.cultureZoneStore.setIsZoneNotificationWarning(false);

    this.cultureZoneStore.clearInitialIdToCultureZone();
    this.cultureZoneStore.cancelDrawing();

    this.acceptCultureZoneEditing();
  };

  cancelTheCreatedNewZone = (): void => {
    const isEmptyInitialZonesList = !this.cultureZoneStore.initialCultureZonesList.length;
    this.cultureZoneStore.setIsCultureZoneCreating(false);
    this.cultureZoneStore.setIsZoneNotificationWarning(false);

    if (!isEmptyInitialZonesList) {
      this.cultureZoneStore.resetIdToCultureZone();

      this.cultureZoneStore.createHoles();
      this.cultureZoneStore.renderCultureZones();
    } else {
      this.cultureZoneStore.cancelDrawing();
    }
    this.cultureZoneStore.selectedCultureZoneModel = null;
  };

  acceptCultureZoneEditing = (): void => {
    this.disableChangeGeometryEvent(true);

    this.cultureZoneStore.setIsCultureZoneEditing(false);

    this.resetChangesOfSelectedZone();
  };

  cancelCultureZoneEditing = (): void => {
    this.disableChangeGeometryEvent(true);

    const selectedCultureZone = this.cultureZoneStore.selectedCultureZoneModel;

    const initialSelectedZoneModel = {
      ...selectedCultureZone,
      geometry: this.cultureZoneStore.initialGeometryOfSelectedZone || selectedCultureZone.geometry,
    };

    this.cultureZoneStore.setIdToCultureZone(selectedCultureZone.mapId, initialSelectedZoneModel);

    this.cultureZoneStore.setIsCultureZoneEditing(false);

    this.resetChangesOfSelectedZone();
  };

  getLayerOfSelectedCultureZone = (): any =>
    this.mapStore.getLayer(Number(this.cultureZoneStore.selectedCultureZoneModel?.polyId));

  disableChangeGeometryEvent = (isPmDisable?: boolean): void => {
    const layer = this.getLayerOfSelectedCultureZone();
    layer.off('pm:markerdragend', this.cultureZoneStore.handleChangeGeometry);

    if (isPmDisable) layer.pm.disable();
  };

  resetChangesOfSelectedZone = (): void => {
    this.cultureZoneStore.setInitialGeometryOfSelectedZone(null);

    this.cultureZoneStore.setSelectedCultureZoneModel(null);
    this.cultureZoneStore.setLastValidateGeometry(null);

    this.cultureZoneStore.createHoles();
    this.cultureZoneStore.renderCultureZones();

    this.cultureZoneStore.setIsZoneNotificationWarning(false);
  };
}
