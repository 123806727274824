import moment, { Moment } from 'moment';
import { FC, memo, useCallback } from 'react';

import { Operation } from '../../../../../../../api/models/operations/operation.model';
import { Calendar } from '../../../../../../shared/components/Calendar/Calendar';
import { TaskEditController } from '../../../../operations/controllers/task.edit.controller';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';

interface IProps {
  task: TasksEditStore['task'];
  setTask: TaskEditController['setTask'];
  operation: Operation | null;
  blocked?: boolean;
}

const PlanStartDate: FC<IProps> = props => {
  const { task, operation, setTask, blocked } = props;

  const handleChange = useCallback(
    (planStartDate: string): void =>
      setTask({
        ...task,
        planStartDate,
        planEndDate: planStartDate > task.planEndDate ? planStartDate : task.planEndDate,
      }),
    [task]
  );

  return (
    <Calendar
      label={'Старт задачи'}
      value={task?.planStartDate ? (task?.planStartDate as moment.Moment) : undefined}
      onChange={handleChange}
      placeholder={'Старт задачи'}
      minDate={(() => {
        const startDate = task.id ? task.operationInfo?.startDate : operation?.startDate;
        return moment(startDate).hours(0).minutes(0).seconds(0).milliseconds(0);
      })()}
      maxDate={(() => {
        const endDate = task.id ? task.operationInfo?.endDate : operation?.endDate;
        return moment(endDate).hours(23).minutes(59).seconds(59).milliseconds(999);
      })()}
      dataTestId={'operation-start-date'}
      dateFormat={'DD.MM.YYYY'}
      blocked={blocked}
      initialViewDate={operation?.startDate ? moment(operation?.startDate, 'YYYY-MM-DD') : moment()}
    />
  );
};

export default memo(PlanStartDate);
