import { FC, memo, useCallback, MouseEvent } from 'react';

import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { Input } from '../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import { ReactComponent as MapSvg } from '../../static/creation-map-mock.svg';
import { getErrorNameByType } from '../../utils/helpers/import';

import Styled from './EditableFieldItem.styles';
import { TEditableFieldItemProps } from './EditableFieldItem.types';

const EditableFieldItem: FC<TEditableFieldItemProps> = ({
  field,
  onDelete,
  onNameChange,
  onCardClick,
}) => {
  const { icon, name, area, error, errorType } = field;

  const handleNameChange = useCallback(
    (value: string) => {
      onNameChange?.(field, value);
    },
    [field]
  );

  const handleDeleteClick = useCallback(
    (event: MouseEvent<HTMLParagraphElement>) => {
      event.stopPropagation();

      onDelete?.(field);
    },
    [field]
  );

  const handleCardClick = useCallback(() => {
    onCardClick?.(field?.id);
  }, [field]);

  return (
    <Styled.Wrapper onClick={handleCardClick}>
      {error ? <Styled.Status /> : null}

      <Styled.ContentWrapper>
        {icon ? (
          <Styled.MapImage src={icon?.downloadUrl} />
        ) : (
          <Styled.MapIcon>
            <MapSvg />
          </Styled.MapIcon>
        )}

        <Styled.RightColumn>
          <Input onChange={handleNameChange} value={name} />

          <Styled.BottomSectionWrapper>
            <Styled.AreaLabel $isError={error}>
              {error
                ? getErrorNameByType(errorType)
                : `Площадь: ${toFixedWithCeilBackEnd(area)} га`}
            </Styled.AreaLabel>

            <Styled.Delete onClick={handleDeleteClick}>Удалить</Styled.Delete>
          </Styled.BottomSectionWrapper>
        </Styled.RightColumn>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};

EditableFieldItem.displayName = 'EditableFieldItem';

export default memo(EditableFieldItem);
