import styled, { css } from 'styled-components';

import { Colors } from '../../../../../constans/colors';

type TStyledDropdownSearchFieldInputProps = {
  isDisabled: boolean;
};

const Input = styled.input<TStyledDropdownSearchFieldInputProps>`
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: ${Colors.generalBlack};
  outline: none;
  border: none;
  background: transparent;
  caret-color: ${Colors.green};
  width: 100%;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: pointer;
      caret-color: transparent;
    `}

  ::placeholder {
    color: ${Colors.secondaryDarkGray};
  }
  z-index: 99;
`;

const StyledDropdownSearchField = {
  Input,
};

export default StyledDropdownSearchField;
