import styled from 'styled-components';

import { Breakpoints } from '../../../shared/constans/sizes';
import { Colors } from '../../../shared/constans/colors';

export const ProfileLayoutWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${Colors.backgroundSecondaryGray};
  z-index: 1010;
  padding: 20px 60px;
`;

export const ProfileGrid = styled.div`
  display: flex;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const ProfileInnerGrid = styled.div`
  display: grid;
  grid-template-areas:
    'welcome welcome welcome'
    'personalInfo competention interes';
  grid-template-columns: 600px;
  grid-gap: 20px;
  width: 100%;

  @media (max-width: ${Breakpoints.medium}) {
    display: flex;
    flex-direction: column;
  }
`;
