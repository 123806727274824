import styled, { css } from 'styled-components';

type TStyledDropdownIconsMinusProps = {
  $defaultSvg: string;
};

const Minus = styled.div<TStyledDropdownIconsMinusProps>`
  width: 8px;
  height: 8px;

  background: url(${({ $defaultSvg }) => $defaultSvg}) no-repeat center;
`;

type TStyledDropdownIconsArrowProps = {
  $isDropped: boolean;
  $arrowDownDefaultSvg: string;
  $arrowDownHoverSvg: string;
  $arrowTopHoverSvg: string;
};

const ArrowIcon = styled.div<TStyledDropdownIconsArrowProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;

  ${({ $isDropped, $arrowTopHoverSvg, $arrowDownDefaultSvg, $arrowDownHoverSvg }) =>
    $isDropped
      ? css`
          background: url(${$arrowTopHoverSvg});
        `
      : css`
          background: url(${$arrowDownDefaultSvg});

          :hover {
            background: url(${$arrowDownHoverSvg});
          }
        `}
`;

type TStyledDropdownIconsClearProps = {
  $defaultSvg: string;
  $hoverSvg: string;
  $isDropped: boolean;
};

const getClearIconBackgroundUrl = (
  $defaultSvg: string,
  $hoverSvg: string,
  $isDropped: boolean
) => css`
  background: url(${$isDropped ? $hoverSvg : $defaultSvg});
`;

const ClearIcon = styled.div<TStyledDropdownIconsClearProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;

  ${({ $defaultSvg, $hoverSvg, $isDropped }) =>
    getClearIconBackgroundUrl($defaultSvg, $hoverSvg, $isDropped)}

  :hover {
    background: url(${({ $hoverSvg }) => $hoverSvg});
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 12px;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

const StyledDropdownIcons = {
  Minus,
  ArrowIcon,
  ClearIcon,
  Wrapper,
};

export default StyledDropdownIcons;
