// eslint-disable-next-line import/order
import React, { useEffect, FC } from 'react';

import 'leaflet';
import '@farmlink/gefim';
import '@farmlink/gefim/dist/leaflet-geoman.css';
import 'leaflet.gridlayer.googlemutant';

import styled from 'styled-components';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { useStore } from '../shared/utils/IoC';
import { fillLeafletShapeWithImageExtension } from '../shared/utils/fillLeafletShapeWithImageExtension';

import './style.css';
import MapStore from './stores/map.store';
import { CreateControls } from './components/CreateControls/CreateControls';

const defaultLatLng = [55.751244, 37.618423];

const zoom = 19;

const StyledMapContainer = styled.div`
  && {
    height: 100%;
    max-height: 100%;
    flex: 1 1 0;
  }
`;

export const Map: FC<any> = observer(() => {
  const store = useStore(MapStore);

  const { coordinates, instance, setInstance } = store;

  useEffect(() => {
    // @ts-ignore
    L.SVG.include(fillLeafletShapeWithImageExtension);

    // @ts-ignore
    if (L.DomUtil.get('mapId') != null && instance) {
      instance.off();
      instance.remove();
    }

    // @ts-ignore
    const map = L.map('mapId', {
      // @ts-ignore
      center: defaultLatLng,
      zoom,
      zoomControl: false,
      minZoom: 3,
      maxZoom: 20,
    });
    setInstance(map);

    return () => {
      setInstance(null);
    };
  }, []);

  useEffect(() => {
    if (!instance || coordinates.lat === 0 || coordinates.lng === 0) return;
    // @ts-ignore

    instance.setView({ lat: coordinates.lat, lng: coordinates.lng }, 12);
  }, [coordinates]);

  return <StyledMapContainer id="mapId" />;
});
