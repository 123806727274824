import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { BorderRadius } from '../../../../../shared/constans/sizes';

export const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 16px;
  margin-bottom: 0;
  overflow-x: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const ArrowButton = styled.div`
  background: ${Colors.lightGray};
  border-radius: ${BorderRadius.small};
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ArrowButtonWrapperLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 16px;
  position: absolute;
  left: 0;
  z-index: 1;
  background: linear-gradient(90deg, #ffffff 63.54%, rgba(255, 255, 255, 0.64) 100%);
`;

export const ArrowButtonWrapperRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  position: absolute;
  right: 0;
  background: linear-gradient(270deg, #ffffff 63.54%, rgba(255, 255, 255, 0.64) 100%);
`;
