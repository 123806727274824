import { TypeApiRoute } from '../../models/type.api.request';
import { CultureList } from '../../models/operations/operation.culture.list.model';

type TypeRequest = {
  year: number;
  organizationId?: string;
};

type TypeResponse = CultureList;

export const getSeasonCulture: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => `/api/as-fields/seasons/${req.year}/cultureList`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
