import React, { FC, useState } from 'react';
import ReactSelect, { components, OptionsOrGroups, OnChangeValue } from 'react-select';
import { Checkbox } from '@farmlink/farmik-ui';

import { taskLabels } from '../../../dashboard/modules/tasks/stores/tasks.store';
import { TaskStatuses } from '../../../dashboard/modules/operations/stores/tasks.store';
import { ReactComponent as DropdownIconDown } from '../../assets/images/dropdown-arrow-down.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import { ValueContainer, CheckboxWrapper } from './style';

import { reactMultySelectCustomStyles } from './index';

type Props = {
  options: OptionsOrGroups<{ label: string; value: string }, any>;
  defaultValue?: OptionsOrGroups<{ label: string; value: string }, any>;
  onChange?: (newValue: OnChangeValue<any, any>[]) => void;
  placeholder: string;
  isSearchable?: boolean;
  hideSelectedOptions?: boolean;
  name: string;
  dataTestId?: string;
  value?: any;
};

export const ReactMultySelect: FC<Props> = ({
  options,
  defaultValue,
  onChange,
  placeholder,
  isSearchable = false,
  hideSelectedOptions = false,
  name,
  dataTestId,
  value,
}) => {
  const handleChange = (newValue: OnChangeValue<any, any>[]) => {
    onChange(newValue);
  };

  return (
    <ReactSelect
      id={dataTestId}
      value={value || undefined}
      isMulti={true}
      options={options}
      defaultValue={defaultValue}
      onChange={handleChange}
      placeholder={placeholder}
      styles={reactMultySelectCustomStyles}
      isSearchable={isSearchable}
      hideSelectedOptions={hideSelectedOptions}
      closeMenuOnSelect={false}
      components={{
        ValueContainer: ({ children, ...props }) => {
          if (value.length) {
            return (
              <components.ValueContainer {...props}>
                <ValueContainer data-test-id={dataTestId}>
                  {name}: Выбрано {value.length}
                  <div
                    style={{
                      // Хак чтобы при кастомном ValueContainer дропдаун скрывался при клике за его пределами
                      opacity: '0',
                      overflow: 'hidden',
                      width: '0px',
                      height: '0px',
                    }}
                  >
                    {children}
                  </div>
                </ValueContainer>
              </components.ValueContainer>
            );
          }

          return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
        },
        DropdownIndicator: ({ children, ...props }) => {
          return (
            <components.DropdownIndicator {...props}>
              <DropdownIconDown />
            </components.DropdownIndicator>
          );
        },
        ClearIndicator: ({ children, ...props }) => {
          return (
            <components.ClearIndicator {...props}>
              <CloseIcon />
            </components.ClearIndicator>
          );
        },
        Option: ({ children, ...props }) => {
          return (
            <components.Option {...props}>
              <CheckboxWrapper>
                <Checkbox onChange={() => 1} value={props.isSelected} /> {children}
              </CheckboxWrapper>
            </components.Option>
          );
        },
      }}
    />
  );
};
