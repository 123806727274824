import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios } from '../../shared/utils/axios2';
import { AccessRule } from '../../../api/api';
import { SCOUTING_ACCESS_ACTIONS } from '../../shared/constants/access-rules-action';

@provide.singleton()
export class CheckAccessStore {
  @lazyInject(Axios)
  protected axios: Axios;

  accessRules: Array<AccessRule> = [];

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;

  clearAccessRules = () => {
    this.accessRules = [];
  };

  getAccessRulesByModule = (
    organizationId: string,
    moduleCode: SCOUTING_ACCESS_ACTIONS | string
  ) => {
    this.loading = true;
    this.axios.api.checkAccessByModule({ organizationId, moduleCode }).then(response => {
      runInAction(() => {
        this.accessRules = this.accessRules.concat(response);
        this.loading = false;
      });
    });
  };

  getAccessRulesByCodePrefix = (organizationId: string, codePrefix?: string) => {
    this.loading = true;
    this.axios.api.checkAccessByCodePrefix({ organizationId, codePrefix }).then(response => {
      runInAction(() => {
        this.accessRules = this.accessRules.concat(response);
        this.loading = false;
      });
    });
  };

  getAccessRuleValue = (code: string) => {
    return this.accessRules.find(rule => rule.code === code)?.available || false;
  };
}
