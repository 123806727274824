import { useEffect } from 'react';

import { TUseHandleModalResult } from './useHandleModalResult.types';

const useHandleModalResult: TUseHandleModalResult = ({ state, setState, props }) => {
  useEffect(() => {
    if (!props.config?.validation?.modal) {
      return;
    }

    if (props.config?.validation?.modal?.modalResult) {
      setState({
        values: {
          ...state.values,
          searchValue: state.temporaryData.onChangeData?.searchValue || null,
          selectValue: state.temporaryData.onChangeData?.option || null,
          selectValueList: [],
        },
        settings: { ...state.settings, isSearchFieldFocused: false, isDropped: false },
        temporaryData: { ...state.temporaryData, onChangeData: null },
      });
    }
  }, [props.config?.validation?.modal?.modalResult]);
};

export default useHandleModalResult;
