import { runInAction } from 'mobx';
import moment from 'moment';

import { Axios } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { SeasonCultureStore } from '../stores/season.culture.store';
import { TasksEditStore } from '../stores/task.edit.store';
import { ETaskFormViewMode, TaskViewStore } from '../stores/task.view.store';
import { Task, TaskStatuses, taskLabels } from '../stores/tasks.store';

import { PreparedTask } from './tasks.listing.controller';

@provide.singleton()
export class TaskViewController {
  @lazyInject(Axios)
  axios: Axios;

  @lazyInject(TaskViewStore)
  taskViewStore: TaskViewStore;

  @lazyInject(OrganizationsStore)
  organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(SeasonCultureStore)
  protected seasonCulture: SeasonCultureStore;

  setSelectedFormCultureId = cultureId => {
    this.taskViewStore.cultureId = cultureId;
  };

  async getTaskById(id: string) {
    // console.log('***********getTaskById');
    const response = await this.axios.api.getTask({ id });
    // console.log('*********getTaskById.response!!!!!');

    // console.log(response);
    this.taskViewStore.taskForView = {
      ...(response as Task),
    };
  }

  getSeasonCultures = async (year, organizationId?) => {
    const response = await this.axios.api.getSeasonCulture(
      {
        year,
        organizationId: organizationId === 'my' ? undefined : organizationId,
      },
      { omit: ['year'] }
    );
    this.taskViewStore.cultureList = response;
  };

  getOperationsByCultureId = async (cultureId?) => {
    const organizationId =
      this.organizationsStore.selectedOrganizationId === 'my'
        ? ''
        : this.organizationsStore.selectedOrganizationId;
    let noCulture = false;
    let cultureIdForRequest = '';
    if (cultureId) {
      noCulture = undefined;
      cultureIdForRequest = cultureId;
    } else {
      noCulture = true;
      cultureIdForRequest = undefined;
    }

    const response = await this.axios.api.getOperations({
      organizationId,
      cultureId: cultureIdForRequest,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      noCulture,
      size: 10000,
    });
    this.taskViewStore.operations = response.content;
  };

  getFieldsOfOperation = async ({
    operationId,
    organizationId,
  }: {
    operationId?: string;
    organizationId?: string;
  }) => {
    const response = await this.axios.api.getOperationFields({
      operationId,
      organizationId: organizationId === 'my' ? '' : organizationId,
    });

    this.taskViewStore.operationFields = response;
  };

  setTask = (task: Partial<Task> | PreparedTask) => {
    console.log('setTask');
    console.log(task);
    this.taskViewStore.taskForView = { ...task };
  };

  setTaskFormLastViewMode = (value: ETaskFormViewMode) => {
    this.taskViewStore.taskFormLastViewMode = value;
  };

  setTaskViewStatus = (taskStatus: TaskStatuses, reason?: string) => {
    runInAction(() => {
      const task = this.taskViewStore.taskForView;
      this.taskViewStore.taskForView = {
        ...task,
        status: taskStatus,
        cancellationReason: reason,
      };
    });
  };

  getTaskStatus = (taskStatus: TaskStatuses): { title: string; color: any } => {
    const taskStatusMapping = {
      [TaskStatuses.New]: {
        title: taskLabels[TaskStatuses.New],
        color: 'primary',
      },

      [TaskStatuses.InWork]: {
        title: taskLabels[TaskStatuses.InWork],
        color: 'info',
      },

      [TaskStatuses.Completed]: {
        title: taskLabels[TaskStatuses.Completed],
        color: 'default',
      },

      [TaskStatuses.Canceled]: {
        title: taskLabels[TaskStatuses.Canceled],
        color: 'secondary',
      },

      [TaskStatuses.Impossible]: {
        title: taskLabels[TaskStatuses.Impossible],
        color: 'secondary',
      },
    };

    const status = taskStatusMapping[taskStatus];

    if (!status) {
      return taskStatusMapping[TaskStatuses.New];
    }

    return status;
  };

  deleteTask = async (taskId: string) => {
    await this.axios.api.deleteTask({
      id: taskId,
    });
  };

  clearStore = () => {
    this.taskViewStore.cultureId = '';
    this.taskViewStore.cultureList = null;
    this.taskViewStore.operationFields = [];
    this.taskViewStore.operations = [];
    this.taskViewStore.taskForView = {};
  };
}
