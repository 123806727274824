import styled from 'styled-components';

import { Colors } from '../../constans/colors';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  outline: none;
`;

type SuggestProps = {
  isOpen: boolean;
};
export const SuggestWrapper = styled.div<SuggestProps>`
  width: 100%;
  position: relative;

  ${({ isOpen }) =>
    isOpen &&
    `
     ${SuggestHeader} {
       border-bottom-left-radius: 0;
       border-bottom-right-radius: 0;
       // border-bottom: none !important;
     }
     
     ${SuggestBody} {
        display: flex;
        border-top: none
     }
  `}
`;

export const SuggestHeader = styled.input`
  background: ${Colors.gray};
  border: 0.5px solid ${Colors.grayBorder};
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.darkGray};
  }
  color: ${Colors.black};
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  outline: none;
`;

export const SuggestBody = styled.div`
  display: none;
  width: 100%;
  background: ${Colors.gray};
  border: 0.5px solid ${Colors.grayBorder};
  box-sizing: border-box;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  position: absolute;
  z-index: 99;
  top: 39px;
`;

export const SuggestInnerBody = styled.div`
  border-top: 0.5px solid ${Colors.grayBorder};
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 140px;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const SuggestItem = styled.p<{ active?: boolean }>`
  display: flex;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${({ active }) => (active ? Colors.green : Colors.black)};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  &:hover {
    font-weight: ${({ active }) => (active ? 'normal' : '600')};
    color: ${Colors.green};
  }
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.darkGray};
`;
