import { Tooltip } from '@farmlink/farmik-ui';
import e from 'express';
import { forwardRef, memo, useEffect, useMemo, useRef } from 'react';

import { useOnClickOutside } from '../../../../../hooks/useOnClickOutside';
import { useCommonState } from '../../../hooks';
import { InputFieldError } from '../../InputFieldError';

import { DropdownBody } from './components/DropdownBody';
import { DropdownIcons } from './components/DropdownIcons';
import { DropdownMultiselect } from './components/DropdownMultiselect';
import { DropdownSearchField } from './components/DropdownSearchField';
import Styled from './Dropdown.styles';
import { TDropdownProps, TDropdownState } from './Dropdown.types';
import { useDropdownBodyHandlers } from './hooks/useDropdownBodyHandlers';
import { useDropdownCreateTextByMatch } from './hooks/useDropdownCreateTextByMatch';
import { useDropdownGetAvailableOptionList } from './hooks/useDropdownGetAvailableOptionList';
import { useDropdownHandlers } from './hooks/useDropdownHandlers';
import { useDropdownIconHandlers } from './hooks/useDropdownIconHandler';
import { useDropdownSearchFieldHandlers } from './hooks/useDropdownSearchFieldHandlers';
import { useHandleModalResult } from './hooks/useHandleModalResult';
import { useShowError } from './hooks/useShowError';

const Dropdown = forwardRef<HTMLInputElement, TDropdownProps>((props, inputRef) => {
  const {
    config: { field, body, visual, validation },
  } = props;

  const [state, setState] = useCommonState<TDropdownState>({
    values: {
      selectValue: null,
      selectValueList: [],
      searchValue: null,
    },
    settings: {
      isDropped: false,
      isSearchFieldFocused: false,
      isValueContainerCentered: true,
      autocompleteWidth: null,
      isExactCompareOfSearchValue: false,
    },
    temporaryData: {
      onChangeData: null,
    },
  });

  // Определяет доступный список опций
  const availableOptionList = useDropdownGetAvailableOptionList({
    body,
    type: field?.type,
    state,
  });

  // Создает значение для автокомплита
  const autocompleteText = useDropdownCreateTextByMatch({
    option: availableOptionList?.[0],
    isSearch: Boolean(field?.type?.search),
    searchValue: state.values.searchValue,
    isCreateFullMatch: true,
  });

  // Отслеживает появление и скрытие тела выбора
  const fieldWrapperRef = useRef<HTMLDivElement>(null);

  const searchFieldWrapperRef = useRef<HTMLDivElement>(null);
  const valueContainerRef = useRef<HTMLDivElement>(null);

  // Обработчики событий для компонента 'Dropdown' (по общей части)
  const dropdownHandlers = useDropdownHandlers({ state, setState, props });

  // Обрабатывает появление и скрытие тела выбора
  useOnClickOutside(fieldWrapperRef, dropdownHandlers.onClickOutside);

  // Обработчики событий для поисковой строки
  const searchFieldHandlers = useDropdownSearchFieldHandlers({
    state,
    setState,
    props,
    availableOptionList,
  });

  // Обработчики событий для тела выбора
  const bodyHandlers = useDropdownBodyHandlers({ state, setState, props });

  // Обработчики событий для иконок
  const iconsHandlers = useDropdownIconHandlers({ state, setState, props });

  // Отвечает за появление и скрытие ошибок
  const isError = useShowError(validation?.error?.errorList);

  // Следит за результатом модального окна (если оно есть)
  useHandleModalResult({ state, setState, props });

  // Следит за шириной автокомпилта
  useEffect(() => {
    if (!field?.type?.search) {
      return;
    }

    setState({
      settings: {
        ...state.settings,
        autocompleteWidth: searchFieldWrapperRef?.current?.offsetWidth,
      },
    });
  }, [searchFieldWrapperRef, field?.type?.search]);

  useEffect(() => {
    const height = valueContainerRef?.current?.offsetHeight;

    if (height) {
      if (height <= 20) {
        setState({ settings: { ...state.settings, isValueContainerCentered: true } });
      } else {
        setState({ settings: { ...state.settings, isValueContainerCentered: false } });
      }
    }
  }, [state.values.selectValueList]);

  // Следит за значением по умолчанию
  useEffect(() => {
    if (field?.defaultValue) {
      setState({
        values: {
          ...state.values,
          selectValue: field?.defaultValue,
        },
      });
    } else if (field?.defaultValueList?.length && !field?.type?.multiselect) {
      setState({
        values: {
          ...state.values,
          selectValue: field?.defaultValueList?.[0],
        },
      });
    } else if (field?.defaultValueList?.length) {
      setState({
        values: {
          ...state.values,
          selectValueList: field?.defaultValueList,
        },
      });
    }
  }, [field?.defaultValue, field?.defaultValueList]);

  const isShownSearchField = useMemo<boolean>(() => {
    const isMultiselect = field?.type?.multiselect;
    const isSearch = field?.type?.search;
    const { isDropped } = state.settings;
    const isNotEmpty = state.values.selectValueList.length;

    if (isSearch) {
      if (!isDropped && isMultiselect && isNotEmpty) {
        return false;
      } else {
        return true;
      }
    } else if (!isSearch) {
      if (isMultiselect && isNotEmpty) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [
    state.settings.isDropped,
    field?.type?.multiselect,
    field?.type?.search,
    state.values.selectValueList,
  ]);

  return (
    <Styled.Wrapper
      $isDropped={state.settings.isDropped}
      $isError={isError}
      $isBlocked={visual?.isBlocked}
      $isDisabled={field?.isDisabled}
    >
      {visual?.label ? (
        <Styled.LabelWrapper>
          <Styled.Label $isRequired={field?.isRequired}>{visual?.label}</Styled.Label>

          {visual?.tooltip && (
            <Tooltip
              id={`tooltip-${field?.id}`}
              getContent={() => visual?.tooltip}
              offset={{ left: 4 }}
            >
              <Styled.LabelInfoIcon />
            </Tooltip>
          )}
        </Styled.LabelWrapper>
      ) : null}

      <Styled.FieldWrapper
        onClick={dropdownHandlers.onClick}
        ref={fieldWrapperRef}
        $isRemovePaddingBottom={!isShownSearchField}
        $isCentered={state.settings.isValueContainerCentered}
      >
        <Styled.ValueContainer ref={valueContainerRef}>
          {field?.type?.multiselect && state.values.selectValueList.length ? (
            <DropdownMultiselect
              selectValueList={state.values.selectValueList}
              onCloseClick={iconsHandlers.onCloseClick}
            />
          ) : null}

          {!isShownSearchField ? null : (
            <Styled.SearchFieldWrapper ref={searchFieldWrapperRef}>
              {state.values.searchValue && autocompleteText?.firstLabelPart ? (
                <Styled.Autocomplete $width={state.settings.autocompleteWidth}>
                  <Styled.AutocompleteMatchedValue>
                    {autocompleteText.firstLabelPart}
                  </Styled.AutocompleteMatchedValue>
                  {autocompleteText.lastLabelPart}
                </Styled.Autocomplete>
              ) : null}

              <DropdownSearchField
                handlers={{ ...searchFieldHandlers, onClick: dropdownHandlers.onClick }}
                values={state.values}
                configFieldProps={field}
                configVisualProps={visual}
                ref={inputRef}
              />
            </Styled.SearchFieldWrapper>
          )}
        </Styled.ValueContainer>

        <DropdownIcons field={field} visual={visual} state={state} handlers={iconsHandlers} />

        {state.settings.isDropped ? (
          <DropdownBody
            type={field?.type}
            handlers={bodyHandlers}
            optionList={availableOptionList}
            values={state.values}
          />
        ) : null}
      </Styled.FieldWrapper>

      {isError && !state.settings.isDropped ? <InputFieldError error={validation?.error} /> : null}
    </Styled.Wrapper>
  );
});

Dropdown.displayName = 'Dropdown';

export default memo(Dropdown);
