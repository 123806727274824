import { IGetChecklist } from '../../models/checklist/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

export type IGetChecklistByTaskAndIntensityPayload = {
  taskId: string;
  intensityId: string;
};

type TypeRequest = {} & IGetChecklistByTaskAndIntensityPayload;

type TypeResponse = {} & IGetChecklist;

export const getChecklistByTaskAndIntensity: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/checklist/byTaskAndIntensity`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
