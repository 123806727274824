import { Icon, Input } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Colors } from '../../../../../../../../../shared/constans/colors';
import CancelSvg from '../../../../assets/cancel.svg';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { OrganizationSearchStore } from '../../../../stores/organization.search.store';
import { ProfileRoutes } from '../../../../../../routes';
import { Spacer } from '../../../MyListsControllBar/style';

import {
  CancelButton,
  ControlBarWrapper,
  SearchDescription,
  SearchFieldWrapper,
  SearchTitle,
  TextWrapper,
  HideOnSmall,
} from './style';

export const SearchControlBar = withRouter(
  observer(({ history }: RouteComponentProps) => {
    const store = useStore(OrganizationSearchStore);
    const { searchQuery, setSearchQuery } = store;

    const handleBackButton = () => {
      history.push(ProfileRoutes.Organizations);
    };

    return (
      <>
        <Spacer />
        <ControlBarWrapper>
          <Icon size="small" icon="arrow_left" fill={Colors.black} onClick={handleBackButton} />
          <TextWrapper>
            <SearchTitle>
              Присоединиться <HideOnSmall>к организации</HideOnSmall>
            </SearchTitle>
            <SearchDescription>
              Введите название или ИНН{' '}
              <HideOnSmall>организации, к которой хотите присоединиться</HideOnSmall>
            </SearchDescription>
          </TextWrapper>
          <SearchFieldWrapper>
            <Input
              type="text"
              error=""
              startIcon="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Название или ИНН"
            />
          </SearchFieldWrapper>
          <CancelButton onClick={() => setSearchQuery('')}>
            <img src={CancelSvg} alt="" />
            <p>Отмена</p>
          </CancelButton>
        </ControlBarWrapper>
      </>
    );
  })
);
