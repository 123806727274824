import moment, { Moment } from 'moment';
import { FC, memo, useCallback } from 'react';

import { Operation } from '../../../../../../../api/models/operations/operation.model';
import { Calendar } from '../../../../../../shared/components/Calendar/Calendar';
import { TaskEditController } from '../../../../operations/controllers/task.edit.controller';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';

interface IProps {
  task: TasksEditStore['task'];
  setTask: TaskEditController['setTask'];
  operation: Operation | null;
  blocked?: boolean;
}

const PlanEndDate: FC<IProps> = props => {
  const { task, operation, setTask, blocked } = props;

  const handleChange = useCallback(
    (planEndDate: string): void =>
      setTask({
        ...task,
        planEndDate,
      }),
    [task]
  );

  return (
    <Calendar
      label={'Конец задачи'}
      value={(() => {
        return task?.planEndDate ? (task?.planEndDate as moment.Moment) : undefined;
      })()}
      onChange={handleChange}
      placeholder={'Конец задачи'}
      minDate={(() => {
        const startDate = task.planStartDate ? task.planStartDate : operation?.startDate;
        return moment(startDate).hours(0).minutes(0).seconds(0).milliseconds(0).subtract(1, 's');
      })()}
      maxDate={(() => {
        const endDate = task.id ? task.operationInfo?.endDate : operation?.endDate;
        return moment(endDate).hours(23).minutes(59).seconds(59).milliseconds(999);
      })()}
      dataTestId={'operation-end-date'}
      dateFormat={'DD.MM.YYYY'}
      blocked={blocked}
      initialViewDate={operation?.endDate ? moment(operation?.endDate, 'YYYY-MM-DD') : moment()}
    />
  );
};

export default memo(PlanEndDate);
