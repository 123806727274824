import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { OrganizationCard } from '../types/OrganizationCard';
import { Axios } from '../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationOutgoingStore {
  @lazyInject(Axios)
  protected axios2: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  searchQuery = '';

  setSearchQuery = (searchQuery: string) => {
    this.searchQuery = searchQuery;
    this.fetchApplicationsAndInvites();
  };

  idToApplication: Map<string, OrganizationCard> = new Map();

  get applications() {
    return Array.from(this.idToApplication.values());
  }

  fetchApplicationsAndInvites = () => {
    this.axios2.api
      .getOrganizationApplicationsMine({
        nameOrInn: this.searchQuery,
      })
      .then(response => {
        runInAction(() => {
          this.idToApplication.clear();
          response.content.forEach(item => {
            if (item.type !== 'APPLICATION') {
              return;
            }

            this.idToApplication.set(item.applicationId, {
              avatar: '',
              organizationId: item.organization.id,
              title: item.organization.name,
              roleName: '',
              type: 'application',
              applicationId: item.applicationId,
              organization: item.organization,
              createdWhen: item.createdWhen,
              comment: item.comment,
              state: item.status,
            });
          });
        });
      })
      .catch(error => {
        console.error(error);
      });
  };
}
