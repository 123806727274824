import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > div {
    margin-bottom: 10px;
  }
`;

const StyledDropdownMultiselect = {
  Wrapper,
};

export default StyledDropdownMultiselect;
