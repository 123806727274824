import { Switcher } from '@farmlink/farmik-ui';
import { FC, memo, useCallback, useMemo } from 'react';

import { ImagesUploader } from '../../../../../../../tasks/containers/fullscreen/checklist/components/Phenophase/components/ImagesUploader/ImagesUploader';
import { TChecklistAttributeProps } from '../../../containers/ChecklistAttributeContainer/ChecklistAttributeContainer';
import StyledAttributeContainer from '../../../containers/ChecklistAttributeContainer/styles';
import { getChecklistAttributeId } from '../../../../../../utils/checklist.instances';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import { useGetErrorListOfChecklistAttr } from '../../../hooks/attribute';

import * as Styled from './style';

type TBooleanAttributeProps = {} & TChecklistAttributeProps;

const BooleanAttribute: FC<TBooleanAttributeProps> = ({
  attributeWithValue,
  onChange,
  isBlocked,
}) => {
  const { attribute, id } = attributeWithValue.checklistAttribute;

  const {
    attributeValue,
    drawingNestedInstanceId,
    schema,
    isActive,
    attributeWithFileValue,
  } = attributeWithValue;

  const attributeId: string = useMemo(() => getChecklistAttributeId(id), [id]);

  const errorList = useGetErrorListOfChecklistAttr(schema, attributeWithFileValue?.schema);

  const error: string = useMemo(() => {
    if (schema.isShownError) {
      return schema.error;
    } else {
      return '';
    }
  }, [schema]);

  const handleChange = useCallback((booleanValue: boolean): void => {
    onChange({
      attributeWithValue,
      value: { booleanValue },
      drawingNestedInstanceId,
    });
  }, []);

  return (
    <>
      {isActive && (
        <StyledAttributeContainer.Wrapper>
          <Styled.Wrapper $error={error}>
            <Styled.Label>{attribute.name}</Styled.Label>
            <Switcher
              isBlocked={isBlocked}
              onChange={handleChange}
              value={attributeValue?.booleanValue || false}
            />
          </Styled.Wrapper>

          {attributeWithFileValue && attributeWithFileValue.isActive && (
            <ImagesUploader
              onImgUrlsChange={onChange}
              attributeWithValue={attributeWithFileValue}
              isBlocked={isBlocked}
              id={attributeId}
            />
          )}
          <InputFieldError error={{ errorList }} />
        </StyledAttributeContainer.Wrapper>
      )}
    </>
  );
};

export default memo(BooleanAttribute);
