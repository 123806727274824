import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Input } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { CustomButton } from '../../../../../../components/CustomButton/CustomButton';
import { ButtonType, ColorsState } from '../../../../../../components/CustomButton/style';
import { Calendar } from '../../../../../shared/components/Calendar/Calendar';
import { Autosuggest } from '../../../../../shared/components/Autosuggest/Autosuggest';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { UiStore } from '../../../../stores/ui.store';
import { PopupPages } from '../../../../constants/popup.pages';
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import MapStore from '../../../../../map/stores/map.store';
import { CancelButton, ContinueButton } from '../../../CultureZoneTopbar/style';
import { ReactComponent as CancelSvg } from '../../../CultureZoneTopbar/cancel.svg';
import { FieldsListingController } from '../../../../modules/fields/controllers/fields.listing.controller';
import { LeaveUnitModalStore } from '../../../../stores/leave.unit.modal.store';

import { CultureZoneController } from './controllers/cultureZone.controller';
import { ReactComponent as EditSvg } from './edit.svg';
import { ReactComponent as PlusSvg } from './plus.svg';
import { ReactComponent as BinSvg } from './bin.svg';
import {
  AddNewLink,
  AreaWrapper,
  BinButton,
  Content,
  ContentScroll,
  CultureRow,
  CultureWrapper,
  DeleteWrapper,
  EditButton,
  HarvestWrapper,
  Header,
  HeaderButtonWrapper,
  Pallete,
  PalleteWrapper,
  SowingWrapper,
  Title,
  VarietyWrapper,
  Wrapper,
  EditWrapper,
  EditZoneNotificationWrapper,
  ButtonsWrapper,
} from './style';
import { CultureZoneStore } from './stores/culture.zone.store';

export const CultureZone: FC = observer(() => {
  const store = useStore(CultureZoneStore);
  const ui = useStore(UiStore);
  const leaveUnitModalStore = useStore(LeaveUnitModalStore);

  const {
    seasonLabel,
    fetchCultures,
    selectCulture,
    cultureZones,
    seasonBorder,
    setCultureHarvestDate,
    initiateCulturePolygon,
    setCultureSort,
    setCultureSewDate,
    saveCultureZones,
    clear,
    getCultureOptions,
    handleEditClick,
    isAvailableSave,
    isZoneChanged,
    fields,
    isAcceptChangesAvailable,
    selectedCultureZoneModel,
    deleteZone,
    cancelDrawing,
    isZoneNotificationWarning,
    isCultureZoneCreating,
  } = store;
  const fieldsListingController = useStore(FieldsListingController);

  const cultureZoneController = useStore(CultureZoneController);
  const {
    acceptTheCreatedNewZone,
    acceptCultureZoneEditing,
    cancelTheCreatedNewZone,
    cancelCultureZoneEditing,
  } = cultureZoneController;

  const { clearAll } = useStore(MapStore);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedToDeleteId, setSelectedToDelete] = useState('');
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isCulturesValid, setIsCulturesValid] = useState(false);

  const handleCreateNewZone = () => {
    if (selectedCultureZoneModel) {
      return;
    }

    const isKeyExist = localStorage.getItem('culture-zone-not-show-again');

    if (isKeyExist) {
      cultureZoneController.startDrawZone();
      return;
    }

    setIsAddOpen(true);
  };

  useEffect(() => {
    clearAll();
    initiateCulturePolygon();
    console.log('fetch culture zone');
    return () => {
      console.log('Clear culture zones');
      clear();
      cancelDrawing();
    };
  }, []);

  useEffect(() => {
    initiateCulturePolygon();
    return () => {
      clear();
    };
  }, [fields.selectedFieldId]);

  const handleSave = async () => {
    if (selectedCultureZoneModel) {
      return;
    }

    if (isCultureZoneCreating) return;

    try {
      await saveCultureZones();
    } catch (e) {
      return;
    }

    await fieldsListingController.fetchFieldsList();
    if (ui.functionFireForm === 'sowing/harvest') {
      ui.setPageState(PopupPages.Seasons);
      ui.setFunctionFireFrom('none');
      clear();
      cancelDrawing();
      return;
    }
    clear();
    cancelDrawing();
    ui.setPageState(PopupPages.Seasons);
    ui.setFunctionFireFrom('none');
  };

  const handleExit = () => {
    if (selectedCultureZoneModel) {
      return;
    }

    if (isZoneChanged) {
      setIsAlertOpen(true);
      return;
    }

    if (isCultureZoneCreating) return;

    leaveUnitModalStore.setDialogSettings({
      actionHandler: () => {
        cancelDrawing();
        ui.setPageState(PopupPages.Seasons);
        ui.setFunctionFireFrom('none');
        leaveUnitModalStore.setIsShow(false);
      },
      isShow: true,
    });
    // if (ui.functionFireForm === 'sowing/harvest') {
    //   ui.setPageState(PopupPages.Seasons);
    //   ui.setFunctionFireFrom('none');
    //   return;
    // }

    // ui.setPageState(PopupPages.None);
  };

  const handleDelete = (mapId: string) => {
    setSelectedToDelete(mapId);
    setIsDeleteOpen(true);
  };

  const handleCheckbox = (v: boolean) => {
    if (!v) {
      localStorage.removeItem('culture-zone-not-show-again');
      return;
    }
    localStorage.setItem('culture-zone-not-show-again', 'true');
  };

  useEffect(() => {
    setIsCulturesValid(
      !cultureZones.filter(culture => culture.id).length ||
        cultureZones.every(zone => zone.culture.id.length > 0 || zone?.isHole)
    );
  });

  return (
    <Wrapper>
      <Header>
        <HeaderButtonWrapper>
          <CustomButton
            variant={ButtonType.Outlined}
            colorStyle={ColorsState.Secondary}
            onClick={handleExit}
            dataTestId="cancel-culture-zone"
          >
            Отменить
          </CustomButton>
        </HeaderButtonWrapper>
        <Title data-test-id="culture-zone-title">{seasonLabel}</Title>
        <HeaderButtonWrapper>
          <CustomButton
            variant={ButtonType.Default}
            colorStyle={
              isAvailableSave && isCulturesValid ? ColorsState.Accent : ColorsState.Disabled
            }
            onClick={handleSave}
            dataTestId="save-culture-zone"
            disabled={!isCulturesValid}
          >
            Сохранить
          </CustomButton>
        </HeaderButtonWrapper>
      </Header>
      <Content>
        {isZoneNotificationWarning ? (
          <EditZoneNotificationWrapper>
            <p>Для дальнейших действий необходимо окончить редактирование контура</p>
            <ButtonsWrapper>
              <ContinueButton
                disabled={!isAcceptChangesAvailable}
                onClick={() => {
                  if (!isAcceptChangesAvailable) return;
                  if (isCultureZoneCreating) acceptTheCreatedNewZone();
                  else acceptCultureZoneEditing();
                }}
              >
                Подтвердить
              </ContinueButton>
              <CancelButton
                onClick={() => {
                  if (isCultureZoneCreating) cancelTheCreatedNewZone();
                  else cancelCultureZoneEditing();
                }}
              >
                <CancelSvg />
              </CancelButton>
            </ButtonsWrapper>
          </EditZoneNotificationWrapper>
        ) : (
          <ContentScroll>
            {
              // exclude hole polygons from panel and render 'normal' culture zones only
              cultureZones
                .filter(zone => !zone.isHole)
                .map((zone, index) => {
                  return (
                    <CultureRow
                      data-test-id="culture-row"
                      key={`culture-zone-panel-id-${zone.polyId}${index}`}
                      isActive={
                        selectedCultureZoneModel && zone.mapId === selectedCultureZoneModel.mapId
                      }
                    >
                      <PalleteWrapper>
                        <Pallete
                          transparent={!zone.culture.attrs.assistanceColorLegend}
                          colorFill={zone.culture.attrs.assistanceColorLegend}
                          data-test-id="culture-palette"
                        />
                      </PalleteWrapper>
                      <AreaWrapper>
                        <Input
                          type={'text'}
                          label={'Участок, Га'}
                          onChange={() => 1}
                          value={toFixedWithCeilBackEnd(zone.area) || ''}
                          disabled={true}
                          dataTestId="field-square"
                        />
                      </AreaWrapper>
                      <CultureWrapper>
                        <Autosuggest
                          placeholder={'Введите название культуры'}
                          value={zone.culture.query}
                          label={'Культура'}
                          onSearch={v => fetchCultures(zone.mapId, v)}
                          options={getCultureOptions(zone.mapId)}
                          onChange={v => selectCulture(zone.mapId, v)}
                          onOpen={v => fetchCultures(zone.mapId, v)}
                          dataTestId="select-field-culture"
                        />
                      </CultureWrapper>
                      <VarietyWrapper>
                        <Input
                          type={'text'}
                          label={'Сорт'}
                          onChange={v => setCultureSort(zone.mapId, v)}
                          value={zone.variety}
                          placeholder={'Укажите сорт'}
                          maxLength={50}
                          dataTestId="select-variety-culture-zone"
                          disabled={true}
                        />
                      </VarietyWrapper>
                      <SowingWrapper>
                        <Calendar
                          onChange={v => setCultureSewDate(zone.mapId, v)}
                          value={zone.sowingDate}
                          label={'Дата сева'}
                          minDate={seasonBorder.min}
                          maxDate={
                            zone.harvestDate && !zone.harvestDate.isSame(seasonBorder.max)
                              ? zone.harvestDate
                              : moment(seasonBorder.max).subtract(1, 'd')
                          }
                          dataTestId="culture-sowing-date"
                        />
                      </SowingWrapper>
                      <HarvestWrapper>
                        <Calendar
                          onChange={v => setCultureHarvestDate(zone.mapId, v)}
                          value={zone.harvestDate}
                          label={'Дата уборки'}
                          minDate={
                            zone.sowingDate && !zone.sowingDate.isSame(seasonBorder.min)
                              ? zone.sowingDate
                              : moment(seasonBorder.min).add(1, 'd')
                          }
                          maxDate={seasonBorder.max}
                          dataTestId="culture-harvest-date"
                        />
                      </HarvestWrapper>
                      <EditWrapper>
                        <EditButton
                          onClick={() => handleEditClick(zone.mapId)}
                          data-test-id="edit-culture"
                        >
                          <EditSvg />
                        </EditButton>
                      </EditWrapper>
                      <DeleteWrapper>
                        <BinButton
                          onClick={() => handleDelete(zone.mapId)}
                          data-test-id="delete-culture"
                        >
                          <BinSvg />
                        </BinButton>
                      </DeleteWrapper>
                    </CultureRow>
                  );
                })
            }
            <AddNewLink onClick={handleCreateNewZone}>
              <PlusSvg />
              <p>Добавить культуру</p>
            </AddNewLink>
          </ContentScroll>
        )}
      </Content>
      {isAddOpen && (
        <DialogModal
          status={'culture-zone'}
          accept={{
            name: 'Продолжить',
            handler: () => {
              cultureZoneController.startDrawZone();
              setIsAddOpen(false);
            },
          }}
          title={'Нарисуйте участок для новой культуры'}
          description={`Вы можете ставить точки вне поля. \n После замыкания, все внешние участки удалятся`}
          onClose={() => setIsAddOpen(false)}
          checkbox={{
            checked: false,
            name: 'Больше не показывать',
            handler: handleCheckbox,
          }}
        />
      )}

      {isAlertOpen && (
        <DialogModal
          status={'warning'}
          title={
            'Вы уверены, что хотите покинуть экран культурных зон? Несохраненные данные будут утеряны'
          }
          accept={{
            name: 'Покинуть',
            handler: () => {
              console.log('HARVEST ', ui.functionFireForm);
              if (ui.functionFireForm === 'sowing/harvest') {
                ui.setPageState(PopupPages.Seasons);
                ui.setFunctionFireFrom('none');
                return;
              }
              ui.setPageState(PopupPages.None);
            },
          }}
          cancel={{ name: 'Отменить', handler: () => setIsAlertOpen(false) }}
        />
      )}
      {isDeleteOpen && (
        <DialogModal
          status={'warning'}
          title={`Вы уверены, что хотите удалить культуру `}
          accept={{
            name: 'Да, удалить',
            color: 'secondary',
            handler: () => {
              deleteZone(selectedToDeleteId);
              setIsDeleteOpen(false);
            },
          }}
          cancel={{ name: 'Отменить', color: 'default', handler: () => setIsDeleteOpen(false) }}
        />
      )}
    </Wrapper>
  );
});
