import { ISelectOption } from '../../../../types/selectOption';
import { IGetDictionary } from '../../dictionary/dictionary.model';
import {
  IGetChecklistInstance,
  IPutNestedChecklistInstance,
} from '../instance/checklist.instance.model';

export interface IGetChecklistAttribute {
  order: number;
  isRequired: boolean;
  isActive: boolean;
  isTitle: boolean;
  parentId?: string;
  min?: number;
  max?: number;
  normal?: number;
  stageId?: string;
  id: string;
  checkListId: string;
  attribute: {
    id: string;
    type: EChecklistAttributeType;
    name: string;
    checklistLink: string | null;
    checklistLinkPublicId: string | null;
    dictionaryLink: string | null;
    isMultiselect: boolean;
    toolTip?: string;
    placeholder?: string;
  };
  hasChildren: boolean;
  toolTip?: string;
  dependencyAttribute?: IGetChecklistAttribute;
  dependencyAttributeId?: string;
}

export interface IChecklistAttributeFileValue {
  id: string;
  fileName?: string;
  fileContentType?: string;
  fileSize?: number;
  downloadUrl?: string;
}

export interface IGetChecklistAttributeValue {
  id: string;
  checkListAttributeId: string;
  integerValue?: number;
  doubleValue?: number;
  stringValue?: string;
  longStringValue?: string;
  booleanValue?: boolean;
  enumValues?: IGetChecklistAttributeEnumValue[];
  userDictionaryValues?: IGetChecklistAttributeUserDictionary[];
  dictionaryValueList?: IGetDictionary[];
  fileValue?: IChecklistAttributeFileValue[];
  dateValue?: string;
  checkListInstanceValue?: IGetChecklistInstance[];
}

export interface IPutChecklistAttributeValue {
  checkListAttributeId: string;
  integerValue?: number | string;
  doubleValue?: number | string;
  stringValue?: string;
  longStringValue?: string;
  booleanValue?: boolean;
  enumValues?: string[];
  userDictionaryValues?: IGetChecklistAttributeUserDictionary[];
  dictionaryValueList?: string[];
  fileValue?: string[];
  dateValue?: string;
  checkListInstanceValue?: IPutNestedChecklistInstance[];
}

export interface IGetChecklistAttributeEnumValue {
  id: string;
  value: string;
}

export interface IGetChecklistAttributeUserDictionary {
  id: string;
  value: string;
}

export interface IChecklistAttributeWithValue {
  stageId: string;
  isActive: boolean;
  checklistAttribute: IGetChecklistAttribute;
  attributeValue: IPutChecklistAttributeValue;
  schema: {
    isShownError: boolean;
    error: string;
  };
  listOfOptionValue?: ISelectOption[];
  drawingNestedInstanceId?: string;
  progenitorId?: string;
  attributeWithFileValue?: IChecklistAttributeWithValue;
  children?: IChecklistAttributeWithValue[];
}

export enum EChecklistAttributeType {
  Boolean = 'BOOLEAN',
  Int = 'INT',
  Double = 'DOUBLE',
  String = 'STRING',
  DictionaryLink = 'DICTIONARY_LINK',
  Enum = 'ENUM',
  UserDictionaryLink = 'USER_DICTIONARY_LINK',
  FileLink = 'FILE_LINK',
  ChecklistInstanceLink = 'CHECKLIST_INSTANCE_LINK',
  LongString = 'LONGSTRING',
  Date = 'DATE',
}
