import { observer } from 'mobx-react';
import { FC, memo, useMemo } from 'react';

import { IInstance } from '../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { CheckAccessStore } from '../../../../../../authorization/stores/checkAccess.store';
import {
  CUSTOM_COLORS_LIST,
  ICustomColor,
} from '../../../../../../shared/constans/customColorsPrests';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../shared/constants/access-rules-action';
import { useStore } from '../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../stores/organizations.store';

import { ChecklistInstance } from './components';
import Styled from './ListOfChecklistInstance.styles';
import { TListOfChecklistInstanceProps } from './ListOfChecklistInstance.types';

type TInstWithColor = {
  num: number;
  inst: Partial<IInstance>;
  colors: ICustomColor;
  displayChecklist?: boolean;
};

const ListOfChecklistInstance: FC<TListOfChecklistInstanceProps> = observer(
  ({ title, description, instList, addButton, mode }) => {
    const { getAccessRuleValue } = useStore(CheckAccessStore);
    const { selectedOrganizationId } = useStore(OrganizationsStore);

    const workWithTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS);

    const isOrgMy = selectedOrganizationId === 'my';

    const manageTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);

    const hasData = Boolean(instList.length);

    const listOfInstWithColor: TInstWithColor[] = useMemo(() => {
      const totalColors = CUSTOM_COLORS_LIST.length - 1;
      let selectedColorPosition = -1;

      return instList.map<TInstWithColor>(inst => {
        ++selectedColorPosition;

        if (selectedColorPosition > totalColors) selectedColorPosition = 0;

        return {
          num: Number(inst.name),
          inst,
          colors: CUSTOM_COLORS_LIST[selectedColorPosition],
          displayChecklist: null,
        };
      });
    }, [instList]);

    const NoData: JSX.Element | null = useMemo(() => {
      return (
        <Styled.NoDataWrapper>
          <Styled.Description>
            {mode === 'listing' ? description.view : description.fullscreen}
          </Styled.Description>

          {mode === 'listing' ? <Styled.Icon /> : null}
        </Styled.NoDataWrapper>
      );
    }, [instList, description]);

    return (
      <Styled.Wrapper>
        <Styled.Header $hasData={!(!hasData || mode === 'fullscreen')}>
          <Styled.Title>
            {mode === 'listing' ? title.view : !hasData ? title.fullscreen : ''}
          </Styled.Title>
        </Styled.Header>

        <Styled.Body $length={listOfInstWithColor.length}>
          {hasData
            ? listOfInstWithColor.map(instWithColors => (
                <ChecklistInstance
                  key={instWithColors.inst.id}
                  {...instWithColors}
                  isOrgMy={isOrgMy}
                  workWithTasks={workWithTasks}
                  manageTasks={manageTasks}
                />
              ))
            : NoData}
        </Styled.Body>

        <Styled.Footer>
          <Styled.AddButton type={'button'} onClick={addButton.onClick}>
            {addButton.title}
          </Styled.AddButton>
        </Styled.Footer>
      </Styled.Wrapper>
    );
  }
);

ListOfChecklistInstance.displayName = 'ListOfChecklistInstance';

export default memo(ListOfChecklistInstance);
