import { observer } from 'mobx-react';
import { FC, FormEvent, useEffect, useMemo, useRef } from 'react';

import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../../../stores/checklist.instances.store';
import { getChecklistAttributeId } from '../../../../../utils/checklist.instances';
import { Stage } from '../../components/Stage';

import { StyledChecklistForm } from './styles';

const Checklist: FC = observer(() => {
  const {
    focusTargetId,
    isFocused,
    selectedChecklist,
    drawingStageList,
    selectedInstance,
    setIsFocused,
  } = useStore(ChecklistInstancesStore);

  const { generateDrawingStageList } = useStore(ChecklistInstancesController);

  const attributeRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!focusTargetId) return;

    if (attributeRef?.current?.id === getChecklistAttributeId(focusTargetId)) {
      if (attributeRef.current.nodeName === 'DIV') {
        attributeRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      } else {
        attributeRef.current.focus();
      }

      setIsFocused(false);
    }
  }, [isFocused]);

  useEffect(() => {
    (async () => {
      await generateDrawingStageList();
    })();
  }, [selectedChecklist, selectedInstance]);

  const orderedDrawingStageList = useMemo(
    () =>
      [...drawingStageList].sort(
        (firstDrawingStage, secondDrawingStage) =>
          firstDrawingStage.order - secondDrawingStage.order
      ),
    [drawingStageList]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => event.preventDefault();

  return (
    <StyledChecklistForm onSubmit={handleSubmit}>
      {orderedDrawingStageList.map(
        drawingStage =>
          drawingStage.isActive && (
            <Stage key={drawingStage.id} drawingStage={drawingStage} ref={attributeRef} />
          )
      )}
    </StyledChecklistForm>
  );
});

export default Checklist;
