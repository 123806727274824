import { FC, useEffect, useState } from 'react';
import { Button, PhoneInput } from '@farmlink/farmik-ui';

import { ModalAction, ModalButtonWrapper, ModalDescription, ModalTitle } from '../../style';
import { SmsCode } from '../../../../../../../shared/components/SmsCode/SmsCode';
import phoneImg from '../../../../../../../shared/assets/images/phone.png';
import { TypeApiRequest, TypeApiResponse } from '../../../../../../../shared/utils/axios2';
import { getError } from '../../../../../../../shared/utils/getError';

import { Image, InputWrapper } from './style';

type Props = {
  phoneNumberCode: string;
  phoneNumber: string;
  setChangePhoneNumberModal: (val: boolean) => void;
  confirmPhoneNumber: (
    phoneNumber: string,
    countryCode: string
  ) => Promise<TypeApiResponse<'sendSmsCode'>>;
  checkSMSCode: (
    data: TypeApiRequest<'verifySmsCode'>
  ) => Promise<TypeApiResponse<'verifySmsCode'>>;
};

export const ChangePhoneNumberWizard: FC<Props> = ({
  setChangePhoneNumberModal,
  phoneNumberCode,
  phoneNumber,
  confirmPhoneNumber,
  checkSMSCode,
}) => {
  const [phoneInput, setPhoneInput] = useState(phoneNumber || '');
  const [countryCode, setCountryCode] = useState(phoneNumberCode);
  const [operIdState, setOperIdState] = useState('');
  const [phoneInputError, setPhoneInputError] = useState('');
  const [code, setCode] = useState('');
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (operIdState) {
      setSeconds(seconds - 1);
    }
  }, [operIdState]);

  useEffect(() => {
    if (operIdState) {
      if (seconds > 0 && seconds < 60) {
        setTimeout(() => {
          setSeconds(seconds - 1);
        }, 1000);
      }
    }
  }, [seconds]);

  const handleConfirmPhoneNumber = () => {
    if (phoneInput.length) {
      setSeconds(60);
      confirmPhoneNumber(phoneInput, countryCode)
        .then(({ operId }) => {
          setOperIdState(operId);
        })
        .catch(response => {
          console.log(response);
          if (response.response.data.error && response.response.data.error.title) {
            setPhoneInputError(getError(response.response.data.error.title));
          }
        });
    } else {
      setPhoneInputError('Поле обязательно для заполнения');
    }
  };

  const handleCheckSMSCode = () => {
    checkSMSCode({
      operId: operIdState,
      code,
    })
      .then(response => {
        setCheckSuccess(true);
      })
      .catch(response => {
        if (response.response.data.error && response.response.data.error.title) {
          setPhoneInputError(getError(response.response.data.error.title));
        }
      });
  };

  const handleChangePhone = (val: string, _code: string) => {
    setPhoneInput(val);
    setCountryCode(_code);
  };

  if (checkSuccess) {
    return (
      <>
        <Image src={phoneImg} alt="" />
        <ModalTitle>Телефон успешно изменен</ModalTitle>
        <ModalDescription>Телефон был изменен на {phoneInput}</ModalDescription>
        <ModalButtonWrapper>
          <Button
            data-test-id={'profile-edit-phone-ok'}
            type="button"
            color="primary"
            onClick={() => {
              setChangePhoneNumberModal(false);
            }}
          >
            Хорошо
          </Button>
        </ModalButtonWrapper>
      </>
    );
  }

  return (
    <>
      <ModalTitle>Изменение номера телефона</ModalTitle>
      <ModalDescription>
        На указанный Вами номер придет смс-сообщение с кодом подтверждения
      </ModalDescription>
      <InputWrapper>
        <PhoneInput
          data-test-id={'profile-edit-phone-input-modal'}
          label="Телефон"
          error={phoneInputError}
          defaultCountryCode={phoneNumberCode as '+7' | '+380' | undefined}
          defaultValue={phoneNumber || undefined}
          onChange={handleChangePhone}
        />
      </InputWrapper>
      {operIdState && (
        <SmsCode
          error=""
          data-test-id={'profile-edit-sms-input-modal'}
          label="Код подтверждения"
          placeholder="000000"
          value={code}
          onChange={setCode}
          onSendCodeAgain={handleConfirmPhoneNumber}
          seconds={seconds}
        />
      )}
      <ModalAction>
        <ModalButtonWrapper>
          {operIdState ? (
            <Button
              type="button"
              color="primary"
              onClick={handleCheckSMSCode}
              data-test-id={'profile-edit-phone-continue'}
            >
              Продолжить
            </Button>
          ) : (
            <Button
              type="button"
              color="primary"
              onClick={handleConfirmPhoneNumber}
              data-test-id={'profile-edit-phone-send-code'}
            >
              Отправить код
            </Button>
          )}
        </ModalButtonWrapper>
        <ModalButtonWrapper>
          <Button
            data-test-id={'profile-edit-phone-cancel'}
            type="button"
            color="default"
            onClick={() => {
              setChangePhoneNumberModal(false);
            }}
          >
            Отмена
          </Button>
        </ModalButtonWrapper>
      </ModalAction>
    </>
  );
};
