import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, memo, useCallback, useRef, ChangeEvent, useState, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { FieldsImportController } from '../../../../controllers/import';
import { FieldsRoute } from '../../../../fields.route';
import { FieldsImportStore } from '../../../../stores/import';
import { ALLOWED_FIELDS_IMPORT_FILE_LIST } from '../../../../utils/constants/import';
import {
  getFileContentType,
  getFileExtension,
  isAllowedFileType,
} from '../../../../utils/helpers/import';

import {
  FieldsImportFileImportModal,
  FieldsImportLoader,
  FieldsImportSomethingWentWrongModal,
  FieldsImportSuccessModal,
  FieldsImportWrongFormatModal,
} from './components';
import Styled from './FieldsImportModalContainer.styles';

enum ERenderModalName {
  FileImport = 'fileImport',
  WrongFormat = 'wrongFormat',
  Loader = 'loader',
  Success = 'success',
  SomethingWentWrong = 'SomethingWentWrong',
}

enum EFileExtension {
  Kml = 'kml',
  GeoJSON = 'geojson',
  Shape = 'shp',
}

const AVAILABLE_FILE_EXTENSIONS: string[] = [
  EFileExtension.Kml,
  EFileExtension.GeoJSON,
  EFileExtension.Shape,
];

const FieldsImportModalContainer: FC = observer(() => {
  const organizationsStore = useStore(OrganizationsStore);
  const { selectedOrganizationId } = organizationsStore;

  const fieldsImportStore = useStore(FieldsImportStore);
  const { listOfImportedField } = fieldsImportStore;

  const fieldsImportController = useStore(FieldsImportController);
  const {
    importFieldsFromKml,
    importFieldsFromGeojson,
    importFieldsFromShape,
    fetchImportedList,
  } = fieldsImportController;

  const [renderModalName, setRenderModalName] = useState<ERenderModalName | null>(null);

  const { closeModal, getModalPayload } = useModal();
  const modalPayload = getModalPayload();

  useEffect(() => {
    if (modalPayload?.isSomethingWentWrong) {
      setRenderModalName(ERenderModalName.SomethingWentWrong);
    } else {
      setRenderModalName(ERenderModalName.FileImport);
    }
  }, modalPayload?.isSomethingWentWrong);

  const history = useHistory();

  const fileUploaderRef = useRef<HTMLInputElement>(null);

  const handleFileUploaderClick = useCallback((): void => {
    fileUploaderRef?.current?.click();
  }, [fileUploaderRef]);

  const continueClick = useCallback((): void => {
    const routePath = generatePath(FieldsRoute.Import, {
      orgId: selectedOrganizationId,
    });

    history.push(routePath);

    closeModal();
  }, [selectedOrganizationId]);

  const handleImportFieldsFromKml = useCallback(async (formData: FormData): Promise<void> => {
    setRenderModalName(ERenderModalName.Loader);

    const importKmlRes = await importFieldsFromKml(formData);

    if (!importKmlRes) {
      setRenderModalName(ERenderModalName.SomethingWentWrong);

      return;
    }

    const { token, errorType } = importKmlRes;

    if (errorType) {
      setRenderModalName(ERenderModalName.SomethingWentWrong);

      return;
    }

    const isSuccess = await fetchImportedList(token);

    if (isSuccess) {
      setRenderModalName(ERenderModalName.Success);
    } else {
      setRenderModalName(ERenderModalName.SomethingWentWrong);
    }
  }, []);

  const handleImportFieldsFromGeojson = useCallback(async (formData: FormData): Promise<void> => {
    setRenderModalName(ERenderModalName.Loader);

    const importGeojsonRes = await importFieldsFromGeojson(formData);

    if (!importGeojsonRes) {
      setRenderModalName(ERenderModalName.SomethingWentWrong);

      return;
    }

    const { token, errorType } = importGeojsonRes;

    if (errorType) {
      setRenderModalName(ERenderModalName.SomethingWentWrong);

      return;
    }

    const isSuccess = await fetchImportedList(token);

    if (isSuccess) {
      setRenderModalName(ERenderModalName.Success);
    } else {
      setRenderModalName(ERenderModalName.SomethingWentWrong);
    }
  }, []);

  const handleImportFieldsFromShape = useCallback(async (formData: FormData): Promise<void> => {
    setRenderModalName(ERenderModalName.Loader);

    const importShapeRes = await importFieldsFromShape(formData);

    if (!importShapeRes) {
      setRenderModalName(ERenderModalName.SomethingWentWrong);

      return;
    }

    const { token, errorType } = importShapeRes;

    if (errorType) {
      setRenderModalName(ERenderModalName.SomethingWentWrong);

      return;
    }

    const isSuccess = await fetchImportedList(token);

    if (isSuccess) {
      setRenderModalName(ERenderModalName.Success);
    } else {
      setRenderModalName(ERenderModalName.SomethingWentWrong);
    }
  }, []);

  const handleFileUploaderChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      const [file] = event.target.files;

      if (!file) {
        return;
      }

      if (file.size > 1e7) {
        return;
      }

      const fileContentType = await getFileContentType(file);

      const isAllowedThisFile = isAllowedFileType(fileContentType, ALLOWED_FIELDS_IMPORT_FILE_LIST);

      const fileExtension = getFileExtension(file);

      const isAllowedThisFileByExtension = AVAILABLE_FILE_EXTENSIONS.includes(fileExtension);

      if (!isAllowedThisFileByExtension) {
        setRenderModalName(ERenderModalName.WrongFormat);

        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      if (!fileContentType) {
        return;
      }

      if (fileExtension === EFileExtension.Kml) {
        await handleImportFieldsFromKml(formData);
      }

      if (fileExtension === EFileExtension.GeoJSON) {
        await handleImportFieldsFromGeojson(formData);
      }

      if (fileExtension === EFileExtension.Shape) {
        const shapeFormData = new FormData();
        shapeFormData.append('files', file);
        await handleImportFieldsFromShape(shapeFormData);
      }
    },
    []
  );

  return (
    <>
      {renderModalName === ERenderModalName.FileImport ? (
        <FieldsImportFileImportModal
          onFileUploaderClick={handleFileUploaderClick}
          closeModal={closeModal}
        />
      ) : null}

      {renderModalName === ERenderModalName.WrongFormat ? (
        <FieldsImportWrongFormatModal
          onFileUploaderClick={handleFileUploaderClick}
          closeModal={closeModal}
        />
      ) : null}

      {renderModalName === ERenderModalName.Loader ? <FieldsImportLoader /> : null}

      {renderModalName === ERenderModalName.Success ? (
        <FieldsImportSuccessModal
          addedFieldsCount={listOfImportedField?.length}
          continueClick={continueClick}
        />
      ) : null}

      {renderModalName === ERenderModalName.SomethingWentWrong ? (
        <FieldsImportSomethingWentWrongModal
          onFileUploaderClick={handleFileUploaderClick}
          closeModal={closeModal}
        />
      ) : null}

      <Styled.Input
        type="file"
        name="file"
        ref={fileUploaderRef}
        onChange={handleFileUploaderChange}
      />
    </>
  );
});

FieldsImportModalContainer.displayName = 'FieldsImportModalContainer';

export default memo(FieldsImportModalContainer);
