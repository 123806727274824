import React, { StrictMode, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { generatePath, useHistory } from 'react-router';

import { OperationsList } from '../../components/OperationsList';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { TasksStore } from '../../stores/tasks.store';
import {
  Footer,
  Header,
  Label,
  Wrapper,
  OperationsListWrapper,
  TasksListWrapper,
} from '../../style';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsController } from '../../controllers/operations.controller';
import { OperationsStore } from '../../stores/operations.store';
import { FieldsStore } from '../../../fields/stores/fields.store';
import { CultureList } from '../../components/CultureList';
// import { FieldsStore } from '../../stores/fields.store';
// import { UiStore } from '../../../../stores/ui.store';
// import { FieldItem } from '../../components/FieldItem/FieldItem';
// import MarkerSvg from '../../../../../shared/static/map.mark.svg';
// import { FieldsRoute } from '../../fields.route';
// import MapStore, { MapMode } from '../../../../../map/stores/map.store';
// import { SeasonsStore } from '../../../../stores/seasons.store';
// import { RenderPolygonOption } from '../../../../../map/consts/enum.render.option';
// import { CreateSeasonModal } from '../../../seasons/modals/CreateSeasonModal/CreateSeasonModal';
// import { ProfileStore } from '../../../profile/stores/ProfileStore';
// import { PopupPages } from '../../../../constants/popup.pages';
// import {
//   AccessRuleVisibility,
//   ControlAccessRulesWrapper,
// } from '../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
// import { FieldsListingController } from '../../controllers/fields.listing.controller';
// import { OrganizationsStore } from '../../../../stores/organizations.store';
import { TasksController } from '../../controllers/tasks.controller';
import { TasksList } from '../../components/TasksList/TasksList';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { ModalContainer } from '../../../../../modals/containers';
import { tasksModalUniqueKey } from '../../modals/modalsConfig';
import { EModalSize } from '../../../../../modals/components/Modal/styles';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import { DashboardRoutes } from '../../../../dashboard.routes';

export const OperationsListing = observer(() => {
  const store = useStore(OperationsStore);
  const seasonStore = useStore(SeasonsStore);
  const fieldsStore = useStore(FieldsStore);
  const organizationsStore = useStore(OrganizationsStore);
  const seasonCultureStore = useStore(SeasonCultureStore);
  const history = useHistory();

  const tasksController = useStore(TasksController);
  const tasksStore = useStore(TasksStore);

  const { accessRules, getAccessRuleValue, loading: accessRulesLoading } = useStore(
    CheckAccessStore
  );

  const [showSpinner, setShowSpinner] = useState(true);

  let prevTasksListLength = 0;

  fieldsStore.fieldsLoaded = false;

  const loadAllData = async () => {
    if (seasonStore.selectedSeason) {
      store.clearOperationsStore();
      seasonCultureStore.clearSeasonCultureStore();
      tasksStore.pageNumber = 0;
      tasksStore.totalPages = 0;
      tasksController.clear();
      await store.fetchCulturesList();
      if (store.OperationCulturesInFields.length || store.fieldsWithoutCulturesCount) {
        seasonCultureStore.setSelectedCultureId(
          store.fieldsWithoutCulturesCount
            ? 'emptyCulture'
            : store.OperationCulturesInFields[0]?.culture?.id
        );

        await store.getOperationsType();
        store.resetPageNumberSettings();
        await store.getOperations();
        tasksController.resetPageSettings();
        await tasksController.fetchTasks({
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId: seasonCultureStore.selectedCultureId,
          noCulture: seasonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
        });
      }
    }
  };

  useEffect(() => {
    tasksStore.fromTasksPage = false;
    tasksStore.setFullScreenMode(null);
  }, []);

  const isOrgMy = useMemo(() => organizationsStore.selectedOrganizationId === 'my', [
    organizationsStore.selectedOrganizationId,
  ]);

  useEffect(() => {
    if (isOrgMy || !accessRules.length) return;
    const isAllowOperations = getAccessRuleValue(`${SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS}`);

    if (!isAllowOperations) {
      const url = generatePath(DashboardRoutes.Fields, {
        orgId: organizationsStore.selectedOrganizationId,
      });
      history.push(url);
    }
  }, [organizationsStore.selectedOrganizationId, accessRules]);

  useEffect(() => {
    (async () => {
      await loadAllData();
    })();
  }, [seasonStore.selectedSeason]);

  useEffect(() => {
    setShowSpinner(tasksStore.totalPages > 0);
    prevTasksListLength = 0;
  }, [
    seasonCultureStore.selectedCultureId,
    store.selectedOperationId,
    store.selectedOperationFieldId,
  ]);

  const loadAdditionalTasks = async () => {
    tasksStore.pageNumber += 1;
    await tasksController.fetchTasks(
      {
        organizationId: organizationsStore.selectedOrganizationId,
        seassonCultureId: seasonCultureStore.selectedCultureId,
        operationId: store.selectedOperationId,
        fieldId: store.selectedOperationFieldId,
      },
      true
    );
  };

  console.log('listining operations');

  return (
    (isOrgMy || (accessRules.length && !accessRulesLoading)) && (
      <Wrapper data-test-id={'operations-container-wrapper'}>
        <Header data-test-id={'operations-container-header'}>
          <Label data-test-id={'operations-container-header-label'}>Операции по культурам</Label>
          <CultureList
            cultures={Array.from(store.OperationCulturesInFields)}
            fieldsWithoutCulturesCount={store.fieldsWithoutCulturesCount}
          />
          <div />
        </Header>
        <Footer data-test-id={'operations-container-footer'}>
          {seasonStore.selectedSeason && fieldsStore.idToFields.size ? (
            <OperationsListWrapper id="OperationsListWrapper">
              <OperationsList />
            </OperationsListWrapper>
          ) : null}
          <TasksListWrapper>
            <TasksList />
            {/* prevTasksListLength !== tasksStore.tasksMap.size ? <Spinner /> : null */}
            {/* todo Если выбрать " Культура не указана" то слайдер не исчезает */}
            {/* {showSpinner ? <Spinner /> : null} */}
          </TasksListWrapper>
        </Footer>
        <ModalContainer uniqueKey={tasksModalUniqueKey} $size={EModalSize.Medium} />
      </Wrapper>
    )
  );
});
