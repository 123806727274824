import { Select } from '@farmlink/farmik-ui';
import { FC, memo, useCallback, useMemo } from 'react';

import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { TaskEditController } from '../../../../operations/controllers/task.edit.controller';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';
import { ProfileStore } from '../../../../profile/stores/ProfileStore';

interface IProps {
  selectedOrganizationId: OrganizationsStore['selectedOrganizationId'];
  task: TasksEditStore['task'];
  usersList: TasksEditStore['usersList'];
  user: ProfileStore['user'];
  disabled: boolean;
  setTask: TaskEditController['setTask'];
  blocked?: boolean;
}

const AssigneeSelect: FC<IProps> = props => {
  const { selectedOrganizationId, task, usersList, user, disabled, setTask, blocked } = props;

  const getUsersList = useMemo(() => {
    return selectedOrganizationId === 'my'
      ? []
      : usersList.map(userItem => ({
          label: userItem.userName,
          value: (userItem as any).userId,
        }));
  }, [usersList]);

  const handleChange = useCallback(
    (assigneeId: string): void =>
      setTask({
        ...task,
        assigneeId,
      }),
    [task]
  );

  return (
    <Select
      label="Исполнитель"
      options={getUsersList}
      onChange={handleChange}
      defaultValue={
        (() => {
          return selectedOrganizationId === 'my'
            ? { label: `Я`, value: user?.id }
            : getUsersList.find(userItem => userItem.value === (task?.assigneeId || task?.assignee?.id));
        })()
      }
      disabled={disabled && !blocked}
      blocked={blocked}
      dataTestId={'assignee-select'}
    />
  );
};

export default memo(AssigneeSelect);
