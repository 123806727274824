import { FC } from 'react';
import { observer } from 'mobx-react';

import { ReactComponent as BurgerSvg } from '../Burger/svg/burger.svg';
import { ReactComponent as ArrowRightSvg } from '../Burger/svg/arrow-right.svg';
import { useStore } from '../../../shared/utils/IoC';
import { UiStore } from '../../stores/ui.store';

import { ArrowLeft, Wrapper, BurgerBlock, ArrowRight } from './style';

export const Burger: FC = observer(() => {
  const ui = useStore(UiStore);
  return (
    <Wrapper data-test-id={'burger'} onClick={() => ui.setIsSidebarShort(!ui.isSidebarShort)}>
      <ArrowLeft>
        {ui.isSidebarShort ? (
          <ArrowRightSvg />
        ) : (
          <ArrowRightSvg style={{ transform: 'scale(-1, -1)' }} />
        )}
      </ArrowLeft>
      <BurgerBlock>
        <BurgerSvg />
      </BurgerBlock>
    </Wrapper>
  );
});
