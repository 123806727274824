import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { usePortals } from 'react-portal-hook';

import { Operation } from '../../../../../../api/models/operations/operation.model';
import {
  Arrow,
  Content as ToolContent,
  IconWrapper,
  Item,
  Label as ToolLabel,
  Wrapper as ToolWrapper,
} from '../../../../../shared/components/ToolTip/style';
import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { UiStore } from '../../../../stores/ui.store';
import { FieldsStore } from '../../../fields/stores/fields.store';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';
import TasksFullscreen from '../../../tasks/containers/fullscreen/tasks';
import AssigneeSelect from '../../../tasks/components/common/formFields/AssigneeSelect';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import Comment from '../../../tasks/components/common/formFields/Comment';
import CultureSelect from '../../../tasks/components/common/formFields/CultureSelect';
import FieldSelect from '../../../tasks/components/common/formFields/FieldSelect';
import OperationSelect from '../../../tasks/components/common/formFields/OperationSelect';
import PlanEndDate from '../../../tasks/components/common/formFields/PlanEndDate';
import PlanStartDate from '../../../tasks/components/common/formFields/PlanStartDate';
import PointsOnTheMapWidget, {
  EFieldPointsMapMode,
} from '../../../tasks/components/Widgets/PointsOnTheMap';
import { ETaskActionType } from '../../../tasks/containers/fullscreen/tasks/ControlButtons';
import { PointsMapStore } from '../../../tasks/stores/pointsMap.store';
import { TaskEditController } from '../../controllers/task.edit.controller';
import { TasksController } from '../../controllers/tasks.controller';
import { PreparedTask } from '../../controllers/tasks.listing.controller';
import { ChecklistInstancesController } from '../../../operationsAndTasks/controllers/checklist.instances.controller';
import { ChecklistFileUploaderController } from '../../../operationsAndTasks/controllers/checklist.fileUploader.controller';
import { ModalType, TaskModal } from '../../modals/TaskModal';
import { OperationsStore } from '../../stores/operations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { TasksEditStore } from '../../stores/task.edit.store';
import { FullScreenMode, Task, TaskPrioretees, TasksStore } from '../../stores/tasks.store';
import { NoPointsText } from '../../../tasks/containers/fullscreen/tasks/styles';
import { TaskViewController } from '../../controllers/task.view.controller';
import { ETaskFormViewMode, TaskViewStore } from '../../stores/task.view.store';
import ChecklistFullscreen from '../../../tasks/containers/fullscreen/checklist';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';

import { ReactComponent as CloseSvg } from './close.svg';
import { ReactComponent as DeleteSvg } from './delete.svg';
import { ReactComponent as EditSvg } from './edit.svg';
import { ReactComponent as FullscreenSvg } from './fullscreen.svg';
import {
  CancelTaskButtonWrapper,
  CommentWrapper,
  Content,
  CreateSaveBottonWrapper,
  CultureSelectWrapper,
  FieldSelectWrapper,
  Footer,
  Header,
  NoPointsHeader,
  Overlay,
  PlanDatesWrapper,
  PlanDateWrapper,
  PointsOnTheMapWidgetWrapper,
  StyledAssegneeSelectWrapper,
  StyledFieldAssigneeBlock,
  StyledOperationLabelSelectWrapper,
  StyledOperationSelectWrapper,
  StyledWrapper,
  TaskEditForm,
  Title,
  TopBlock,
} from './style';

export const prioritiesSelectOptions = [
  {
    label: 'Низкий',
    value: TaskPrioretees.Low,
  },
  {
    label: 'Средний',
    value: TaskPrioretees.Medium,
  },
  {
    label: 'Высокий',
    value: TaskPrioretees.High,
  },
];

enum ELeaveFormType {
  ON_CLOSE_DIALOG = 'on-close-dialog',
  ON_CANCEL = 'on-cancel',
}

enum FormFields {
  cultureName = 'cultureName',
  operationId = 'operationId',
  field = 'field',
  assigneeId = 'assigneeId',
  planStartDate = 'planStartDate',
  planEndDate = 'planEndDate',
  comment = 'comment',
  instancesList = 'instancesList',
}

export const TasksEdit = observer(
  ({
    onCloseDialog,
    onCancel,
    taskId,
  }: {
    onCloseDialog: any;
    onCancel?: any;
    taskId?: string;
  }) => {
    const portalManager = usePortals();

    const operationsStore = useStore(OperationsStore);
    const organizationsStore = useStore(OrganizationsStore);
    const seasonsStore = useStore(SeasonsStore);
    const taskStore = useStore(TasksStore);
    const tasksController = useStore(TasksController);
    const taskEditStore = useStore(TasksEditStore);
    const taskEditController = useStore(TaskEditController);
    const taskViewStore = useStore(TaskViewStore);
    const taskViewController = useStore(TaskViewController);
    const { task } = taskEditStore;
    const seasonCulture = useStore(SeasonCultureStore);
    const profileStore = useStore(ProfileStore);
    const fieldsStore = useStore(FieldsStore);
    const checklistInstancesStore = useStore(ChecklistInstancesStore);
    const checklistInstancesController = useStore(ChecklistInstancesController);
    const pointsMapStore = useStore(PointsMapStore);
    const ui = useStore(UiStore);

    const { clearFileUploader } = useStore(ChecklistFileUploaderController);

    const { accessRules, getAccessRuleValue } = useStore(CheckAccessStore);

    const [operation, setOperation] = useState<Operation | null>(null);

    const [showFullScreen, setShowFullScreen] = useState(false);
    // const [touchedFields, setTouchedFields] = useState(new Set());
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modalType, setModalType] = useState<ModalType>(null);
    const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
    const [taskMemento, setTaskMemento] = useState<Partial<Task>>({});

    const [showLeaveCommit, setShowLeaveCommit] = useState<ELeaveFormType | null>(null);

    let isMouseDownOnOverlay = false;

    useEffect(() => {
      checklistInstancesController.setDeleteImmediately(taskStore.isViewMode);
      (async () => {
        if (taskId) {
          console.log(taskEditStore.task);
          const operationId = taskEditStore.task.operationInfo?.id;
          await taskEditController.getFieldsOfOperation({
            organizationId: organizationsStore.selectedOrganizationId,
            operationId,
          });
          checklistInstancesStore.setField(
            fieldsStore.idToFields.get(taskEditStore.task?.fieldId || taskEditStore.task?.field?.id)
          );
          if (organizationsStore.selectedOrganizationId !== 'my') {
            await taskEditController.getOrganizationUsersList(
              organizationsStore.selectedOrganizationId
            );
          } else {
            taskEditStore.usersList = [];
          }
          console.log('before taskEditController.setTask');
          const taskOperation = taskEditStore.operations.find(
            operationItem => operationItem.id === operationId
          );
          setOperation(taskOperation);
          const momentStartDate = moment(task.planStartDate);
          const momentEndDate = moment(task.planEndDate);
          const presettingsTask: Partial<Task> = {
            ...task,
            operationId: task.operationInfo?.id,
            fieldId: task.field?.id,
            planStartDate: momentStartDate.isValid() ? momentStartDate : null,
            planEndDate: momentEndDate.isValid() ? momentEndDate : null,
            assigneeId:
              organizationsStore.selectedOrganizationId === 'my'
                ? profileStore?.user?.id
                : task.assigneeId,
          };

          taskEditController.setTask(presettingsTask);
          setTaskMemento(presettingsTask);
        } else {
          const needToSetCurrentUserAssignee =
            getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS) &&
            !getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);
          let presettingsTask: Partial<Task> = {
            comment: '',
            priority: TaskPrioretees.Medium,
            planStartDate: taskEditStore.presetDateFromTaskList
              ? taskEditStore.presetDateFromTaskList
              : null,
            planEndDate: taskEditStore.presetDateFromTaskList
              ? taskEditStore.presetDateFromTaskList
              : null,
            assigneeId:
              organizationsStore.selectedOrganizationId === 'my' || needToSetCurrentUserAssignee
                ? profileStore?.user?.id
                : undefined,
          };
          tasksController.disableViewMode();
          checklistInstancesStore.positionToInstance = new Map();
          checklistInstancesStore.setHasPositionToInstanceChanged(false);
          pointsMapStore.idToMarker = new Map();

          await taskEditController.getSeasonCultures(
            Number(seasonsStore.selectedSeason),
            organizationsStore.selectedOrganizationId
          );
          if (organizationsStore.selectedOrganizationId !== 'my') {
            await taskEditController.getOrganizationUsersList(
              organizationsStore.selectedOrganizationId
            );
          } else {
            taskEditStore.usersList = [];
            presettingsTask.assigneeId = profileStore.user?.id;
          }

          console.log(presettingsTask);
          if (seasonCulture.selectedCultureId && !taskStore.fromTasksPage) {
            taskEditController.setSelectedFormCultureId(seasonCulture.selectedCultureId);
            await taskEditController.getOperationsByCultureId(
              seasonCulture.selectedCultureId === 'emptyCulture'
                ? ''
                : seasonCulture.selectedCultureId
            );

            if (operationsStore.selectedOperationId) {
              await taskEditController.getFieldsOfOperation({
                organizationId: organizationsStore.selectedOrganizationId,
                operationId: operationsStore.selectedOperationId,
              });
              const operationForCalculatePlanDate = taskEditStore.operations.find(
                operationItem => operationItem.id === operationsStore.selectedOperationId
              );
              setOperation(operationForCalculatePlanDate);

              if (operationsStore.selectedOperationFieldId) {
                checklistInstancesStore.setField(
                  fieldsStore.idToFields.get(operationsStore.selectedOperationFieldId)
                );
              }

              presettingsTask = {
                ...presettingsTask,
                operationId: operationsStore.selectedOperationId,
                fieldId: operationsStore.selectedOperationFieldId
                  ? operationsStore.selectedOperationFieldId
                  : null,
                planStartDate:
                  presettingsTask.planStartDate ??
                  (operationForCalculatePlanDate
                    ? taskEditController.getStartDate(operationForCalculatePlanDate)
                    : null),
                planEndDate:
                  presettingsTask.planStartDate ??
                  (operationForCalculatePlanDate
                    ? taskEditController.getStartDate(operationForCalculatePlanDate)
                    : null),
              };
            }
          }
          taskEditController.setTask(presettingsTask);
          setTaskMemento(presettingsTask);
          checklistInstancesController.clearInstances();
        }
      })();

      return () => {
        taskEditController.clearStore();
        tasksController.hideFullScreenCreationPage();
      };
    }, []);

    useEffect(() => {
      if (checklistInstancesStore.hasPositionToInstanceChanged) {
        handleTouchField(task, FormFields.instancesList);
      }
    }, [checklistInstancesStore.hasPositionToInstanceChanged]);

    const handleCloseDialog = (event?) => {
      if (event) {
        event.stopPropagation();
      }

      if (Boolean(taskEditStore.touchedFields.size)) {
        taskEditController.getModalWarningBeforeChangeInstance(() => {
          onCloseDialog();
          checklistInstancesController.clearInstances();
        });
      } else {
        onCloseDialog();
        checklistInstancesController.clearInstances();
      }
    };

    const handleModalClose = () => {
      handleCancel();
    };

    const handleReturn = () => {
      setIsOpenModal(false);
    };

    const handleCancel = (event?) => {
      if (event) {
        event.stopPropagation();
      }

      onCancel();
    };

    const handleSaveClick = async () => {
      const updatedTask = {
        id: task.id,
        assigneeId:
          organizationsStore.selectedOrganizationId === ''
            ? undefined
            : task.assigneeId
            ? task.assigneeId
            : (task as Task).assignee?.id,
      };

      taskEditStore.touchedFields.forEach((item: FormFields) => {
        updatedTask[item] = task[item];
      });

      try {
        await tasksController.updateTask(updatedTask);
        await checklistInstancesController.saveInstances(null, true);

        if (taskStore.fullScreenMode) {
          tasksController.enableViewMode();
          pointsMapStore.setMapMode(EFieldPointsMapMode.LISTING_ONLY);
          checklistInstancesController.setDeleteImmediately(true);
        } else {
          taskEditController.getModalTaskCompletedSuccessfully(() => {
            tasksController.enableViewMode();
            taskStore.setFullScreenMode(FullScreenMode.TaskFullScreen);
          });
        }

        // Обнуляем усоловия при  которых отображается модалка из SIdebar
        taskEditController.clearTouchedFields();
        checklistInstancesStore.setHasPositionToInstanceChanged(false);

        returnToFullScreenViewMode();
      } catch (error) {
        console.error(error);
        taskEditController.getModalTaskCreationError();
      }

      // Ломает ререндер списка задач
      // Вызываю при закрытии TaskModal
      /* if (!taskStore.fromTasksPage) {
        tasksController.resetPageSettings();
        await tasksController.fetchTasks({
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId: seasonCulture.selectedCultureId,
          operationId: operationsStore.selectedOperationId,
          fieldId: operationsStore.selectedOperationFieldId,
          noCulture: seasonCulture.selectedCultureId === 'emptyCulture' ? true : undefined,
        });
      } */
    };

    const handleCreateClick = async () => {
      try {
        const createdTaskId = await taskEditController.createTask();
        await checklistInstancesController.saveInstances(createdTaskId);

        if (!taskStore.fromTasksPage) {
          tasksController.resetPageSettings();
          await tasksController.fetchTasks({
            organizationId: organizationsStore.selectedOrganizationId,
            seassonCultureId: seasonCulture.selectedCultureId,
            operationId: operationsStore.selectedOperationId,
            fieldId: operationsStore.selectedOperationFieldId,
            noCulture: seasonCulture.selectedCultureId === 'emptyCulture' ? true : undefined,
          });
        }

        checklistInstancesController.clearInstances();
        taskEditController.getModalTaskCompletedSuccessfully(() => {
          onCloseDialog();
          tasksController.hideFullScreenCreationPage();
        });
        // Обнуляем усоловия при  которых отображается модалка из SIdebar
        checklistInstancesStore.setHasPositionToInstanceChanged(false);
        taskEditController.clearTouchedFields();
      } catch (error) {
        console.error(error);
        taskEditController.getModalTaskCreationError();
      }
    };

    const handleOverlayMouseDown = event => {
      console.log('handleOverlayMouseDown');
      if (event.target.id === 'task-edit-overlay') {
        event.stopPropagation();
        isMouseDownOnOverlay = true;
      }
    };

    const handleOverlayMouseUp = event => {
      event.stopPropagation();
      if (isMouseDownOnOverlay) {
        isMouseDownOnOverlay = false;
        handleCloseDialog(event);
      }
    };

    const handleTouchField = (
      taskData: Partial<Task> | PreparedTask,
      fieldName?: FormFields | FormFields[]
    ) => {
      if (fieldName) {
        if (Array.isArray(fieldName)) {
          fieldName.forEach(field => taskEditController.addFiledToTouchedFields(field));
        } else {
          taskEditController.addFiledToTouchedFields(fieldName);
        }
      }
      taskEditController.setTask(taskData);
    };

    const isOrgMy = organizationsStore.selectedOrganizationId === 'my';

    const allowEdit =
      isOrgMy ||
      getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS) ||
      (getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS) &&
        (task.assignee?.id || task.assigneeId) === profileStore.user.id);

    const allowChangeAssignee = isOrgMy || getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);

    const CommentElement = (
      <Comment
        setTask={taskData => handleTouchField(taskData, FormFields.comment)}
        task={task}
        blocked={taskStore.isViewMode}
      />
      // <Comment setTask={(task) => handleTouchField(task, FormFields.comment)} task={task} blocked={taskStore.isViewMode} />
    );

    const CultureElement = (
      <CultureSelect
        cultureId={taskEditStore.cultureId}
        cultureList={taskEditStore.cultureList}
        fieldsWithoutCulturesCount={operationsStore.fieldsWithoutCulturesCount}
        presetDateFromTaskList={taskEditStore.presetDateFromTaskList}
        task={taskEditStore.task}
        fromTasksPage={taskStore.fromTasksPage}
        disabled={taskEditController.isCultureSelectDisabled()}
        getOperationsByCultureId={taskEditController.getOperationsByCultureId}
        setOperation={setOperation}
        setSelectedFormCultureId={taskEditController.setSelectedFormCultureId}
        setTask={taskData => handleTouchField(taskData, FormFields.cultureName)}
        blocked={taskStore.isViewMode}
      />
    );

    const OperationElement = (
      <OperationSelect
        task={taskEditStore.task}
        operations={taskEditStore.operations}
        presetDateFromTaskList={taskEditStore.presetDateFromTaskList}
        selectedOrganizationId={organizationsStore.selectedOrganizationId}
        disabled={taskEditController.isOperationSelectDisabled()}
        getFieldsOfOperation={taskEditController.getFieldsOfOperation}
        getStartDate={taskEditController.getStartDate}
        setSelectedFormOperationId={taskEditController.setSelectedFormOperationId}
        setOperation={setOperation}
        setTask={taskData => handleTouchField(taskData, FormFields.operationId)}
        blocked={taskStore.isViewMode}
      />
    );

    const FieldElement = (
      <FieldSelect
        setTask={taskData => handleTouchField(taskData, FormFields.field)}
        operationFields={taskEditStore.operationFields}
        task={taskEditStore.task}
        disabled={taskEditController.isFiledSelectDisabled()}
        blocked={taskStore.isViewMode}
      />
    );

    const AssigneeElement = (
      <AssigneeSelect
        setTask={taskData => handleTouchField(taskData, FormFields.assigneeId)}
        selectedOrganizationId={organizationsStore.selectedOrganizationId}
        task={taskEditStore.task}
        user={profileStore.user}
        usersList={taskEditStore.usersList}
        disabled={taskEditController.isAssigneeSelectDisabled() || !allowChangeAssignee}
        blocked={taskStore.isViewMode}
      />
    );

    const PlanDateStartElement = (
      <PlanStartDate
        task={taskEditStore.task}
        operation={operation}
        setTask={taskData => {
          handleTouchField(taskData, [FormFields.planStartDate, FormFields.planEndDate]);
          if (!taskData.planEndDate) {
            handleTouchField(
              {
                ...taskData,
                planEndDate: taskData.planStartDate,
              },
              FormFields.planEndDate
            );
          }
        }}
        blocked={taskStore.isViewMode}
      />
    );

    const PlanDateEndElement = (
      <PlanEndDate
        task={taskEditStore.task}
        operation={operation}
        setTask={taskData => handleTouchField(taskData, FormFields.planEndDate)}
        blocked={taskStore.isViewMode}
      />
    );

    const handleMenuClick = event => {
      event.stopPropagation();
      if (!isOpenContextMenu) {
        portalManager.open(portal => renderContextMenu(portal.close), {
          appendTo: ui.tipContainerRef,
        });

        const bounds = event.target.getBoundingClientRect();
        ui.openTip(bounds.x - 145, bounds.y + 32);
        setIsOpenContextMenu(true);
      } else {
        setIsOpenContextMenu(false);
      }
    };

    const renderContextMenu = closeMenu => {
      const handleRef = el => {
        if (!el) {
          return;
        }
        el.focus();
      };

      ui.setContextMenuCloseFunc(() => {
        closeMenu();
        ui.closeTip();
        setIsOpenContextMenu(false);
      });

      const closeContextMenu = () => {
        closeMenu();
        ui.closeTip();
        setIsOpenContextMenu(false);
      };

      return (
        <ToolWrapper
          ref={handleRef}
          onBlur={event => {
            setTimeout(() => {
              event.stopPropagation();
              closeContextMenu();
            }, 100);
          }}
          tabIndex={-1}
        >
          <ToolContent>
            <Item
              onClick={() => {
                console.log('item');
              }}
            >
              <IconWrapper>
                <EditSvg />
              </IconWrapper>
              <ToolLabel>Редактировать</ToolLabel>
            </Item>

            <Item
              onClick={e => {
                e.stopPropagation();
                closeContextMenu();
              }}
            >
              <IconWrapper>
                <DeleteSvg />
              </IconWrapper>
              <ToolLabel>Удалить</ToolLabel>
            </Item>
          </ToolContent>
          <Arrow
            style={{ top: '-12px', left: 'unset', right: '10px', transform: 'rotate(90deg)' }}
          />
        </ToolWrapper>
      );
    };

    const returnToFullScreenViewMode = () => {
      tasksController.enableViewMode();
      taskStore.setFullScreenMode(FullScreenMode.TaskFullScreen);
      taskViewController.setTaskFormLastViewMode(ETaskFormViewMode.SideForm);
    };

    return (
      <>
        {isOpenModal && (
          <TaskModal
            handleFinish={handleModalClose}
            handleReturn={handleReturn}
            modalType={modalType}
            taskId={taskId}
            isFullScreen={Boolean(taskStore.fullScreenMode)}
            onCloseDialog={onCloseDialog}
          />
        )}
        {!taskStore.fullScreenMode && (
          <Overlay
            onMouseDown={handleOverlayMouseDown}
            onMouseUp={handleOverlayMouseUp}
            id="task-edit-overlay"
          >
            <TaskEditForm data-test-id={'task-edit-form'}>
              <TopBlock>
                <Header>
                  <Title>{taskId ? 'Редактирование задачи' : 'Новая задача'}</Title>
                  {taskStore.fullScreenMode !== FullScreenMode.TaskFullScreen && (
                    <StyledWrapper>
                      {/* {taskId && <OptionsSvg onClick={handleMenuClick} />} */}
                      <FullscreenSvg onClick={() => tasksController.showFullScreenCreationPage()} />
                      <CloseSvg onClick={handleCloseDialog} />
                    </StyledWrapper>
                  )}
                </Header>
                <Content>
                  <CultureSelectWrapper>{CultureElement}</CultureSelectWrapper>
                  <StyledOperationLabelSelectWrapper>
                    <StyledOperationSelectWrapper>{OperationElement}</StyledOperationSelectWrapper>
                  </StyledOperationLabelSelectWrapper>
                  <StyledFieldAssigneeBlock>
                    <FieldSelectWrapper>{FieldElement}</FieldSelectWrapper>
                    <StyledAssegneeSelectWrapper>{AssigneeElement}</StyledAssegneeSelectWrapper>
                  </StyledFieldAssigneeBlock>
                  <PlanDatesWrapper>
                    <PlanDateWrapper>{PlanDateStartElement}</PlanDateWrapper>
                    <PlanDateWrapper>{PlanDateEndElement}</PlanDateWrapper>
                  </PlanDatesWrapper>
                  <CommentWrapper>{CommentElement}</CommentWrapper>
                  <PointsOnTheMapWidgetWrapper>
                    <PointsOnTheMapWidget
                      mode={EFieldPointsMapMode.LISTING_ONLY}
                      field={checklistInstancesStore.field}
                      taskId={task.id}
                      onAddFieldClick={() =>
                        taskStore.setFullScreenMode(FullScreenMode.TaskFullScreen)
                      }
                      noPointsTextBlock={
                        <NoPointsText>
                          Перейдите в полноэкранный шаблон создания задачи, чтобы построить удобный
                          маршрут по полю
                        </NoPointsText>
                      }
                      checklistsAvailable={task.checklistsAvailable}
                    />
                  </PointsOnTheMapWidgetWrapper>
                </Content>
              </TopBlock>
              <Footer>
                <CreateSaveBottonWrapper
                  disabled={
                    !(task.operationInfo?.id || task.operationId) ||
                    !(task.fieldId || task.field?.id) ||
                    !task.priority ||
                    !(task.assigneeId || task.assignee?.id) ||
                    !task.planStartDate ||
                    !task.planEndDate
                  }
                >
                  {taskId ? (
                    <Button
                      color="primary"
                      type="button"
                      // alignment='center'
                      onClick={handleSaveClick}
                      dataTestId={'save-task-button'}
                    >
                      {'Сохранить'}
                    </Button>
                  ) : (
                    <Button
                      color={'primary'}
                      type={'button'}
                      alignment={'center'}
                      onClick={handleCreateClick}
                      dataTestId={'create-task-button'}
                    >
                      {'Создать задачу'}
                    </Button>
                  )}
                </CreateSaveBottonWrapper>
                <CancelTaskButtonWrapper>
                  <Button
                    color="default"
                    type="button"
                    // alignment='center'
                    onClick={handleCloseDialog}
                    dataTestId={'cancel-button'}
                  >
                    {'Отменить'}
                  </Button>
                </CancelTaskButtonWrapper>
              </Footer>
            </TaskEditForm>
          </Overlay>
        )}
        {taskStore.fullScreenMode === FullScreenMode.TaskFullScreen && (
          <TasksFullscreen
            backButtonHandler={() => {
              if (taskStore.isViewMode) {
                tasksController.hideFullScreenCreationPage();
                onCancel();
              } else if (!taskId) {
                taskStore.setFullScreenMode(null);
              } else if (Boolean(taskEditStore.touchedFields.size)) {
                taskEditController.getModalWarningBeforeChangeInstance(() => {
                  taskEditController.setTask(taskMemento);
                  checklistInstancesStore.positionToInstance.forEach(value => {
                    if (!value.id) {
                      pointsMapStore.clearMarker(Number(value?.name));
                    }
                  });
                  checklistInstancesStore.clearTemporaryPositionToInstance();

                  if (taskViewStore.taskFormLastViewMode === ETaskFormViewMode.SideForm) {
                    onCancel();
                  } else {
                    returnToFullScreenViewMode();
                  }
                });
              } else if (taskViewStore.taskFormLastViewMode === ETaskFormViewMode.SideForm) {
                tasksController.enableViewMode();
                onCancel();
              } else {
                returnToFullScreenViewMode();
              }
            }}
            onAddButtonClick={async () => {
              if (taskStore.isViewMode) {
                const createdInst = await checklistInstancesController.createMachineryInst();

                if (createdInst) {
                  checklistInstancesStore.setSelectedInstId(createdInst.id);

                  checklistInstancesController.enableChecklistEditMode();

                  taskStore.setFullScreenMode(FullScreenMode.ChecklistFullScreen);
                }
              } else if (Boolean(taskEditStore.touchedFields.size)) {
                taskEditController.getModalWarningBeforeChangeInstance(async () => {
                  taskEditController.setTask(taskMemento);
                  checklistInstancesStore.positionToInstance.forEach(value => {
                    if (!value.id) {
                      pointsMapStore.clearMarker(Number(value?.name));
                    }
                  });
                  checklistInstancesStore.clearTemporaryPositionToInstance();

                  const createdInst = await checklistInstancesController.createMachineryInst();

                  if (createdInst) {
                    checklistInstancesStore.setSelectedInstId(createdInst.id);

                    checklistInstancesController.enableChecklistEditMode();

                    taskStore.setFullScreenMode(FullScreenMode.ChecklistFullScreen);
                  }
                });
              }
            }}
            backButtonText={
              taskStore.isViewMode
                ? operation?.name
                : taskId
                ? 'Редактирование задачи'
                : 'Новая задача'
            }
            CultureElement={CultureElement}
            OperationElement={OperationElement}
            FieldElement={FieldElement}
            AssigneeElement={AssigneeElement}
            PlanDateStartElement={PlanDateStartElement}
            PlanDateEndElement={PlanDateEndElement}
            CommentElement={CommentElement}
            actionType={taskId ? ETaskActionType.UPDATE : ETaskActionType.CREATE}
            action={taskId ? handleSaveClick : handleCreateClick}
            handleCloseDialog={handleCloseDialog}
            actionButton={
              taskStore.isViewMode &&
              allowEdit && {
                label: 'Редактировать',
                onClick: () => {
                  taskStore.setIsViewMode(false);
                  checklistInstancesController.setDeleteImmediately(false);
                  taskViewController.setTaskFormLastViewMode(ETaskFormViewMode.FullscreenForm);
                  pointsMapStore.setMapMode(EFieldPointsMapMode.STANDART);
                },
              }
            }
            onClose={onCloseDialog}
            // addFieldToTouchedFieldsSet={addFieldToTouchedFieldsSet}
          />
        )}
        {taskStore.fullScreenMode === FullScreenMode.ChecklistFullScreen && (
          <ChecklistFullscreen
            className={'checklist-fullscreen'}
            backButtonHandler={() => {
              if (checklistInstancesStore.idOfUnsavedAttr) {
                checklistInstancesController.warnBeforeLeavingThePage(() => {
                  if (taskStore.fullScreenMode === FullScreenMode.ChecklistFullScreen) {
                    onCloseDialog();
                  }
                });
              } else {
                taskStore.setFullScreenMode(null);
                if (taskStore.isViewMode || checklistInstancesStore.isChecklistViewMode) {
                  onCloseDialog();
                }
              }
            }}
            backButtonText={taskViewStore.taskForView.operationInfo?.name}
            actionButton={
              checklistInstancesStore.isChecklistViewMode && {
                label: 'Редактировать',
                onClick: () => checklistInstancesController.enableChecklistEditMode(),
              }
            }
          />
        )}
        {/* {showLeaveCommit && (
          <DialogModal
            status={'warning'}
            title={
              'Вы уверены, что хотите покинуть экран? Все несохраненные данные будут потеряны'
            }
            accept={{
              name: 'Да, продолжить',
              handler: () => {
                if (showLeaveCommit === ELeaveFormType.ON_CANCEL) {
                  onCancel();
                } else if (showLeaveCommit === ELeaveFormType.ON_CLOSE_DIALOG) {
                  onCloseDialog();
                }
            },
            }}
            cancel={{ name: 'Нет, отменить', handler: () => setShowLeaveCommit(null) }}
          />
        )} */}
      </>
    );
  }
);
