import { Colors } from './colors';

export interface ICustomColor {
  color: Colors;
  backgroundColor: Colors;
}

export const CUSTOM_COLORS_LIST: ICustomColor[] = [
  { color: Colors.orange, backgroundColor: Colors.lightOrange },
  { color: Colors.purple, backgroundColor: Colors.lightPurple },
  { color: Colors.blue, backgroundColor: Colors.lightBlue },
  { color: Colors.red, backgroundColor: Colors.lightRed },
  { color: Colors.turquoise, backgroundColor: Colors.lightTurquoise },
  { color: Colors.limeGreen, backgroundColor: Colors.lightLimeGreen },
  { color: Colors.bluePurple, backgroundColor: Colors.lightBluePurple },
  { color: Colors.pink, backgroundColor: Colors.lightPink },
  { color: Colors.darkCyan, backgroundColor: Colors.lightDarkCyan },
  { color: Colors.dullGray, backgroundColor: Colors.lightDullGray },
];
