import { FC, MouseEvent } from 'react';

import Styled from './DropdownBody.styles';
import { TDropdownBodyProps } from './DropdownBody.types';
import { DropdownBodyOption } from './components/DropdownBodyOption';

const DropdownBody: FC<TDropdownBodyProps> = ({
  type,
  handlers: { onOptionClick },
  optionList,
  values,
}) => {
  const handleCreateNewValueClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    onOptionClick({ label: values.searchValue?.trim(), value: values.searchValue?.trim() });
  };

  // Определяет, когда показывать кнопку по добавлению нового опшена
  const isCreateNewValueButton =
    type?.search?.options?.isAllowNewValueToBeAdded && !optionList.length && values.searchValue;

  // Определяет, когда показывать сообщение о том, что ничего не было найдено
  const isNothingFoundLabel = type?.search && !optionList.length;

  return (
    <Styled.Wrapper>
      <Styled.OptionList>
        {optionList.map(option => (
          <DropdownBodyOption
            key={option.value}
            option={option}
            searchValue={values.searchValue}
            isSearch={Boolean(type?.search)}
            onOptionClick={onOptionClick}
          />
        ))}

        {isNothingFoundLabel ? (
          <Styled.NothingFoundLabel>
            К сожалению, мы ничего не смогли найти
          </Styled.NothingFoundLabel>
        ) : null}

        {isCreateNewValueButton ? (
          <Styled.CreateNewValueWrapper onClick={handleCreateNewValueClick}>
            <Styled.CreateNewValueButton>Создать</Styled.CreateNewValueButton>
            <Styled.CreateNewValueLabel>{values.searchValue}</Styled.CreateNewValueLabel>
          </Styled.CreateNewValueWrapper>
        ) : null}
      </Styled.OptionList>
    </Styled.Wrapper>
  );
};

export default DropdownBody;
