import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../shared/utils/IoC';
import { PreparedTask } from '../controllers/tasks.listing.controller';

export enum TaskStatuses {
  New = 'NEW',
  InWork = 'IN_WORK',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Impossible = 'IMPOSSIBLE',
}

export const StatusLinks = {
  [TaskStatuses.New]: [TaskStatuses.InWork, TaskStatuses.Canceled],
  [TaskStatuses.InWork]: [TaskStatuses.Completed, TaskStatuses.Canceled],
  [TaskStatuses.Completed]: [TaskStatuses.InWork],
};

export enum TaskPrioretees {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export enum TaskFormMode {
  None = 'None',
  View = 'View',
  Edit = 'Edit',
  Create = 'Create',
}

export const taskLabels = {
  [TaskStatuses.New]: 'Новая',
  [TaskStatuses.InWork]: 'В работе',
  [TaskStatuses.Completed]: 'Завершена',
  [TaskStatuses.Canceled]: 'Отменена',
  [TaskStatuses.Impossible]: 'Невыполнима',
};

export const taskPrioreteeLabels = {
  [TaskPrioretees.Low]: 'Низкий',
  [TaskPrioretees.Medium]: 'Средний',
  [TaskPrioretees.High]: 'Высокий',
};

export interface Task {
  id: string;
  operationId: string;
  status: TaskStatuses;
  availableStatuses: TaskStatuses[];
  isExpired: boolean;
  cancellationReason?: string;
  priority: TaskPrioretees;
  assignee?: {
    id: string;
    fullName: string;
  };
  operationInfo: {
    cultureId: string;
    endDate: string;
    id: string;
    name: string;
    operationTypeId: string;
    seasonYear: number;
    startDate: string;
  };
  createdBy: string;
  assigneeId: string;
  planStartDate: moment.Moment | string | null;
  planEndDate: moment.Moment | string | null;
  bindingDate: moment.Moment | string | null;
  executedDate: moment.Moment | string | null;
  comment: string;
  field: any;
  fieldId: string;
  deletable?: boolean;
  intensityRequired?: boolean;
  checklistsAvailable?: boolean;
  checklistsMachineryAvailable?: boolean;
}

export enum FullScreenMode {
  TaskFullScreen = 'task-full-screen',
  ChecklistFullScreen = 'checklist-full-screen',
}

@provide.singleton()
export class TasksStore {
  tasksMap: Map<string, Task> = new Map([]);
  selectedTaskId = '';
  selectedTask: Partial<Task> | null = {};
  pageNumber = 0;
  totalPages = 0;
  loading = false;
  tasksLoadedByFilter = false;
  tasksLoaded = false;
  fromTasksPage = false;
  private _fullScreenMode: FullScreenMode | null = null;
  isViewMode = false;
  isTaskEditForm = false;
  temporaryTask: Partial<Task> | PreparedTask = null;

  prevSettings = {
    selectedSeason: '',
    selectedOperationId: '',
    selectedCultureId: '',
    selectedOperationFieldId: '',
  };

  constructor() {
    makeAutoObservable(this);

    // @ts-ignore;
    window.tasks = this;
  }

  get fullScreenMode() {
    return this._fullScreenMode;
  }

  setFullScreenMode = (value: FullScreenMode | null) => {
    this._fullScreenMode = value;
  };

  setIsTaskEditForm = (value: boolean) => {
    this.isTaskEditForm = value;
  };

  setIsViewMode = (value: boolean) => (this.isViewMode = value);

  setTemporaryTask = (task: Partial<Task> | PreparedTask): void => {
    this.temporaryTask = task;
  };

  clearTemporaryTask = (): void => {
    this.temporaryTask = null;
  };
}
