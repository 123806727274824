import 'leaflet.gridlayer.googlemutant';
import {
  LatLngBoundsExpression,
  Polygon,
  Marker,
  gridLayer,
  Icon,
  marker,
  circleMarker,
  circle,
  DivIcon,
} from 'leaflet';
import { makeAutoObservable } from 'mobx';
import {
  polygon as turfPolygon,
  point as turfPoint,
  booleanPointInPolygon as turfBooleanPointInPolygon,
} from '@turf/turf';

import { GeoPoint } from '../../../../map/stores/map.store';
import { lazyInject, provide, useStore } from '../../../../shared/utils/IoC';
import { ChecklistInstancesStore } from '../../operationsAndTasks/stores/checklist.instances.store';
import { EFieldPointsMapMode } from '../components/Widgets/PointsOnTheMap';
import { ECheckListInstanceType } from '../../../../../api/models/checklist/instance/checklist.instance.model';
import { TaskEditController } from '../../operations/controllers/task.edit.controller';
import { ChecklistInstancesController } from '../../operationsAndTasks/controllers/checklist.instances.controller';

export interface MarkerOptions {
  lat: number;
  lng: number;
  number: number;
  color?: string;
  selected?: boolean;
}
@provide.singleton()
export class PointsMapStore {
  @lazyInject(ChecklistInstancesStore)
  protected checklistInstancesStore: ChecklistInstancesStore;

  @lazyInject(TaskEditController)
  TaskEditController: TaskEditController;

  // needed to track unfinished contours
  pointsOfUnfinishedContour = 0;

  idToMarker: Map<number, any> = new Map();

  selectedInstance: number | null = null;

  fieldPolygon;

  polyCoords;

  mapMode: EFieldPointsMapMode;

  constructor() {
    makeAutoObservable(this);
  }

  setMapMode = (mode: EFieldPointsMapMode) => {
    this.mapMode = mode;
  };

  setSelectedInstanceNumber = (instanceNumber: number | null) => {
    this.selectedInstance = instanceNumber;

    this.centerMapToPoint();
  };

  centerMapToPoint = (isPadding?: boolean) => {
    const options = {
      padding: isPadding && [150, 150],
    };

    if (this.instance && this.fieldPolygon) {
      this.instance.fitBounds(this.fieldPolygon.getBounds(), options);
    }
  };

  initInstance = () => {
    // L.SVG.include(fillLeafletShapeWithImageExtension);

    // @ts-ignore
    /* if (L.DomUtil.get('pointsMapId') != null && this.instance) {
      this.instance.off();
      this.instance.remove();
    } */

    // @ts-ignore
    const map = L.map('pointsMapId', {
      // @ts-ignore
      center: [55.751244, 37.618423],
      zoom: 19,
      zoomControl: true,
      minZoom: 3,
      maxZoom: 20,
    });
    if (this.mapMode === EFieldPointsMapMode.STANDART) {
      console.log('handleMapClick add event listener');
      map.on('click', this.handleMapClick);
    }
    this.setInstance(map);
    this.idToMarker = new Map();
  };

  setAllowSetMarker = (isAllow: boolean) => {
    if (isAllow) {
      this.instance.on('click', this.handleMapClick);
    } else {
      this.instance.off('click', this.handleMapClick);
    }
  };

  removeInstance = () => {
    console.log('removeInstance');
    this.instance.off();
    this.instance.remove();
    this.setInstance(null);
  };

  setInstance = (v: any) => {
    if (v) {
      // @ts-ignore
      window.leafletMap = v;
      this.instance = v;
      // @ts-ignore
      gridLayer.googleMutant({ type: 'hybrid' }).addTo(v);
    }
  };

  isGraphicVisible = true;

  instance: any = null;

  setCoordinates = (lat: number, lng: number) => {
    this.coordinates = {
      lat,
      lng,
    };
  };

  coordinates: GeoPoint = {
    lat: 0,
    lng: 0,
  };

  polygon: L.Polygon;

  // Add polygon by coords
  setPolygon = (geometry: Array<Array<number>> | Array<Array<Array<number>>>) => {
    if (this.instance) {
      const points = geometry[0][0][0]
        ? geometry.map(feature => feature.map(dots => [dots[1], dots[0]]))
        : [geometry.map(dots => [dots[1], dots[0]])];

      console.log('points:');
      console.log(points);
      console.log(this.instance);
      this.polyCoords = points;
      const polygon = new Polygon(points, {
        color: '#FFFFFF',
        fill: false,
      }).addTo(this.instance);
      if (this.fieldPolygon) {
        this.fieldPolygon.remove();
      }
      this.fieldPolygon = polygon;
      console.log('setPolygon:');
      console.log(polygon);
    }
  };

  deletePolygon = () => {
    if (!this.fieldPolygon) {
      return;
    }
    this.checklistInstancesStore.field = null;
    this.fieldPolygon.remove();
    this.fieldPolygon = null;
  };

  deleteMarker = (num: number) => {
    if (this.idToMarker.get(num)) {
      this.idToMarker.get(num).remove();
      this.idToMarker.delete(num);
    }
  };

  handleMapClick = event => {
    console.log('handleMapClick');
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    if (!event.originalEvent.isTrusted) {
      return;
    }
    // console.log(this.fieldPolygon);
    console.log(this.polyCoords);
    console.log(event.latlng);
    const turfPoly = turfPolygon(this.polyCoords);
    const turfPnt = turfPoint([event.latlng.lat, event.latlng.lng]);
    console.log(turfBooleanPointInPolygon(turfPnt, turfPoly));
    console.log(event.latlng);
    if (turfBooleanPointInPolygon(turfPnt, turfPoly)) {
      const markerKeys = Array.from(this.checklistInstancesStore.positionToInstance.keys());
      console.log(markerKeys);
      console.log(Array.from(this.checklistInstancesStore.positionToInstance.keys()));
      const newNumber = markerKeys.length ? Math.max(...markerKeys) + 1 : 1;
      this.setMarker({
        number: newNumber,
        color: 'white',
        lat: event.latlng.lat,
        lng: event.latlng.lng,
      });
      this.checklistInstancesStore.setInstance(newNumber, {
        taskId: this.checklistInstancesStore.taskId,
        onlyFrontend: true,
        name: String(newNumber),
        type: ECheckListInstanceType.PlannedPoint,
        isActive: true,
        planCoords: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [event.latlng.lng, event.latlng.lat],
          },
        },
      });
      this.TaskEditController.addFiledToTouchedFields('pointOnMap');
    }
  };

  setMarker = (markerInfo: MarkerOptions, selectMode?: boolean, isSelected?: boolean) => {
    console.log('setMarker');
    if (this.instance) {
      const className = selectMode
        ? isSelected
          ? 'selected'
          : 'not-selected'
        : 'plan-coord-general';
      console.log('className:', className);
      const geomanIcon = new DivIcon({
        html: String(markerInfo.number),
        className,
      });
      console.log(this.instance);
      if (this.idToMarker.get(markerInfo.number)) {
        this.idToMarker.get(markerInfo.number).remove();
      }
      const newMarker = marker([markerInfo.lat, markerInfo.lng], { pmIgnore: true })
        .setIcon(geomanIcon)
        .addTo(this.instance);

      this.idToMarker.set(markerInfo.number, newMarker);
    }
  };

  clearMarker = index => {
    this.idToMarker.get(index).remove();
  };

  clearAllMarkers = () => {
    Array.from(this.idToMarker.keys()).forEach(markerIndex => this.clearMarker(markerIndex));
    this.idToMarker.clear();
  };
}
