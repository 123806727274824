import styled, { keyframes } from 'styled-components';

import { TaskStatuses } from '../../stores/tasks.store';

export const TaskListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

export const TasksDateBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: white;
  padding: 24px 24px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 24px;
  }
  & * {
    // border: 1px solid black;
  }
`;

const getTaskItemFieldWidth = (fieldClassName, fromTaskListPage) => {
  const fieldWidthOperations = {
    'operation-name': 0.3,
    'field-name': 0.25,
    'assignee-name': 0.23,
    'culture-name': 0,
    'prioriteee': 0.08,
    'status-block': 0.14,
  };
  const fieldWidthTasks= {
    'operation-name': 0.22,
    'field-name': 0.16,
    'assignee-name': 0.22,
    'culture-name': 0.18,
    'prioriteee': 0.08,
    'status-block': 0.14,
  };
  const marginSum = fromTaskListPage ? 96 : 80;
  const widthPercent = fromTaskListPage ? fieldWidthTasks[fieldClassName] : fieldWidthOperations[fieldClassName];

  return `calc((100% - ${marginSum}px - 36px) * ${widthPercent})`;
}

export const TasksBlockList = styled.div<{fromTaskListPage?: boolean}>`
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  padding: 12px 0 12px 0;
  &:first-child {
    padding-top: 12px;
  }
  & .operation-name {
    width: ${({fromTaskListPage}) => getTaskItemFieldWidth('operation-name', fromTaskListPage)};
  }
  & .field-name {
    width: ${({fromTaskListPage}) => getTaskItemFieldWidth('field-name', fromTaskListPage)};
  }
  & .assignee-name {
    width: ${({fromTaskListPage}) => getTaskItemFieldWidth('assignee-name', fromTaskListPage)};
  }
  & .culture-name {
    width: ${({fromTaskListPage}) => getTaskItemFieldWidth('culture-name', fromTaskListPage)};
  }
  & .prioritee {
    width: ${({fromTaskListPage}) => getTaskItemFieldWidth('prioritee', fromTaskListPage)};
  }
  & .status-block {
    width: ${({fromTaskListPage}) => getTaskItemFieldWidth('status-block', fromTaskListPage)};
  }
`;

export const TaskRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e9e6f0;
  height: 60px;
  min-height: 60px;
  cursor: grab;
  user-select: none;
  /* &:hover {
    background: rgba(0, 0, 0, 0.01);
  } */
  &:active, &:focus {
    background-color: transparent;
  }
  & > * {
    webkit-tap-highlight-color: transparent;
  }
  webkit-tap-highlight-color: transparent;
  :focus {
    outline: none !important;
  }
`;

export const TaskTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TaskTypeSvg = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 16px;
`;

export const TaskLabel = styled.div`
  line-height: 16px;
  size: 12px;
  text-align: left;
  margin-right: 8px;
`;

export const OperationName = styled.div`
  line-height: 16px;
  size: 12px;
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

export const FieldName = styled(TaskLabel)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

export const AssigneeName = styled(TaskLabel)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

export const CultureBlock = styled(TaskLabel)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

export const PrioriteeBlock = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  margin-right: 16px;
`;

export const PrioriteeSvg = styled.img`
  margin-right: 6px;
`;

export const PrioriteeLabel = styled(TaskLabel)``;

export const Prioritee = styled(TaskLabel)``;

export const StatusBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 130px;
`;

export const Status = styled(TaskLabel)`
  border-bottom: 1px solid grey;
`;

export const StatusMenuItem = styled(TaskLabel)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: content-box;
  padding: 16px;
  border-bottom: 1px solid #e9e6f0;
  &:last-child {
    border-bottom: none;
  }
`;

export const StatusColorsMatrix = {
  [TaskStatuses.InWork]: '#FFD765',
  [TaskStatuses.New]: '#3FB65F',
  [TaskStatuses.Completed]: '#151F32',
  [TaskStatuses.Canceled]: '#F29993',
  [TaskStatuses.Impossible]: '#F29993',
};

export const TaskStatusMarker = styled.div<{ status: TaskStatuses }>`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border: ${({ status }) => `2px solid ${StatusColorsMatrix[status]}`};
  border-radius: 50%;
  align-self: center;
  margin-left: 7px;
`;

export const TaskDateHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TaskGroupDate = styled.div`
  line-height: 20px;
  size: 16px;
  font-weight: 600;
`;

export const CreateNewTask = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  // pointer-events: none;
  // opacity: 0.5;
  cursor: pointer;
`;

export const TaskStatusMenuWrapper = styled.div<{show: boolean}>`
  display: ${({show}) => show ? 'flex' : 'none'};
  width: 0;
  height: 0;
  z-index: 1001;
  position: relative;
`;

export const TaskStatusMenu = styled.div`
  right: 12px;
  position: absolute;
  top: -40px;
  right: -10px;
  width: 170px;
  /* margin-left: -180px;
  margin-top: -90px; */
  padding: 16px;
  background-color: white;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  box-sizing: content-box;
  cursor: pointer;
  z-index: 1002;
`;

export const StatusMenuOverlay = styled.div<{show: boolean}>`
  display: ${({show}) => show ? 'flex' : 'none'};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: transparent;
`;

export const BottomBlockWrapperOfTasksList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 42px 94px 42px;
`;

export const CreateTasksSVG = styled.img`
  width: 64px;
  height: 64px;
`;

export const CreateTasksTitle = styled.div`
  align-text: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export const CreateTasksText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
  text-align: center;
  margin-bottom: 40px;
`;

export const CreateNewTaskButtonWrapper = styled.div`
    width: 240px;
    & > button {
        justify-content: center;
    }
    // pointer-events: none;
    // opacity: 0.5;
`;

export const CreateNewTaskLabel = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3fb65f;
  margin-left: 4px;
`;

export const WarningBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    // margin: 0 24px;
`;

export const WarningIcon = styled.img`
    height: 100px;
    width: 100px;
    margin-bottom: 24px;
`;

export const WarningTitle1 = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
`;

export const WarningTitle2 = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #9AA0AD;
`;

export const ActionButtonWrapper = styled.div`
  width: 240px;
  & > button {
      justify-content: center;
  }
  margin-top: 40px;
`;

export const NoTasksPlaceHolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 16px;
`;

export const NoOperationsPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 16px;
`;

export const NoOperationsTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export const NoOperationsText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #9AA0AD;
  `;

const spinner = keyframes`
  to {transform: rotate(360deg);} 
`;

export const Spinner = styled.div`
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation:  ${spinner} .6s linear infinite;
    // margin: 24px 0;
    align-self: center;
`;
