import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';
import { FontSizes } from '../../../../../../../../../shared/constans/sizes';
import { ReactComponent as InfoIcon } from '../icons/info.svg';

const defaultScheme = `
border-color: ${Colors.borderGray};
color: ${Colors.generalDark};

&:hover {
  border-color: ${Colors.secondaryDarkGray}
}
`;

const disabledScheme = `
border-color: ${Colors.borderGray};
color: ${Colors.secondaryDarkGray};
`;

const hasErrorScheme = `
  border-color: ${Colors.red};
  color: ${Colors.red};
`;

const renderColor = (error: boolean, disabled: boolean) => {
  if (disabled) return disabledScheme;

  return error ? hasErrorScheme : defaultScheme;
};

type TStyledInputFieldProps = {
  $error?: string;
  $focus?: boolean;
  $isBlocked?: boolean;
  $isDisabled?: boolean;
  $isResizable?: boolean;
  $height?: string;
  $hideScrollbar?: boolean;
};

const InputField = styled.input<TStyledInputFieldProps>`
  // * Настройки временного характера. Как будет интегрирована фильная тема, просьба: обновить
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  // *

  background: ${({ $isDisabled, $isBlocked }) =>
    $isDisabled || $isBlocked ? '#ffffff' : Colors.secondaryGray};
  color: ${props => (props.disabled ? Colors.secondaryDarkGray : Colors.generalBlack)};
  border: 1px solid;
  border-radius: 14px;
  ${FontSizes.medium}
  width: 100%;
  height: ${({ $height = '40px' }) => $height};
  padding: 12px 16px;
  box-sizing: border-box;
  outline: none;

  ${({ $isResizable = true }) =>
    !$isResizable &&
    css`
      resize: none;
    `}

  ${({ $error, $isDisabled }) => renderColor(Boolean($error), $isDisabled)};

  ${({ $isBlocked }) => $isBlocked && `color: ${Colors.generalBlack};`}

  ${({ $hideScrollbar }) =>
    $hideScrollbar &&
    css`
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    `}

  ::placeholder {
    color: ${Colors.secondaryDarkGray};
  }

  :disabled {
    border-color: ${Colors.borderGray};
    color: ${Colors.generalBlack};
  }
`;
const ErrorMessage = styled.div`
  color: ${Colors.red};
  font-size: 12px;
  padding: 4px 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.p<{ required?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.secondaryDarkGray};
  margin: 0 0 4px;

  &:after {
    color: red;
    visibility: ${({ required }) => (required ? 'visible' : 'hidden')};
    content: '*';
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  margin: 0 4px 4px 4px;
  cursor: pointer;
`;

type TStyledIconProps = {
  $align: 'start' | 'end';
  $isLabel: boolean;
};

export const IconWrapper = styled.div<TStyledIconProps>`
  position: absolute;
  height: 40px;
  display: flex;
  align-items: center;

  ${({ $isLabel }) => ($isLabel ? `top: 20px;` : `top: 0;`)}
  ${({ $align }) => ($align === 'start' ? `left: 16px;` : `right: 16px;`)}
`;

const TopBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default {
  Wrapper,
  TopBlock,
  LabelWrapper,
  Label,
  Icon,
  ErrorMessage,
  InputField,
  IconWrapper,
};
