import { FC } from 'react';

import {
  ECheckListInstanceType,
  IInstance,
} from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';

import PointSvg from './point.svg';
import SelectedPointSvg from './selectedPoint.svg';
import techniqueDefaultSvg from './assets/images/technique-default-32x32.svg';
import techniqueSelectedSvg from './assets/images/technique-selected-32x32.svg';
import { CardTitle, InstanceCoordType, PointImg, TextBlock, Wrapper } from './style';

export interface InstanceCardProps {
  number: number;
  instance: Partial<IInstance>;
  isSelected: boolean;
  onCardClick: (num) => void;
  disabled?: boolean;
}

export const InstanceCard: FC<InstanceCardProps> = ({
  number,
  instance,
  isSelected,
  onCardClick,
  disabled,
}) => {
  const isTechnique = instance.type === ECheckListInstanceType.Machinery;

  return (
    <Wrapper
      disabled={disabled}
      selected={isSelected}
      onClick={() => !disabled && onCardClick(instance.id)}
    >
      <TextBlock>
        <CardTitle>
          {isTechnique
            ? `${instance?.machineryName || '—'}`
            : `Точка №${instance?.type === 'ACTUAL_POINT' ? instance?.name?.slice(-3) : number}`}
        </CardTitle>

        <InstanceCoordType>
          {isTechnique
            ? 'Техника'
            : instance.type === ECheckListInstanceType.ActualPoint
            ? 'Фактическая'
            : 'Плановая'}
        </InstanceCoordType>
      </TextBlock>

      {isTechnique ? (
        <PointImg src={isSelected ? techniqueSelectedSvg : techniqueDefaultSvg} />
      ) : (
        <PointImg src={isSelected ? SelectedPointSvg : PointSvg} />
      )}
    </Wrapper>
  );
};
