import { observer } from 'mobx-react';
import { forwardRef, memo } from 'react';

import {
  EChecklistAttributeType,
  IChecklistAttributeWithValue,
} from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesStore } from '../../../../../stores/checklist.instances.store';
import { THandleChangeChecklistAttributeValue } from '../../../../../utils/checklist.instances';
import {
  BooleanAttribute,
  ChecklistLinkAttribute,
  DateAttribute,
  DoubleAttribute,
  IntegerAttribute,
  LongStringAttribute,
  SelectAttribute,
  StringAttribute,
  UserDictionaryAttribute,
} from '../../components/attributes';
import { DictionaryAttribute } from '../../components/attributes/DictionaryAttribute';

export type TChecklistAttributeProps = {
  attributeWithValue: IChecklistAttributeWithValue;
  isBlocked?: boolean;
  focusTarget?: string;
  onChange: THandleChangeChecklistAttributeValue;
};

const ChecklistAttributeContainer = forwardRef<HTMLInputElement, TChecklistAttributeProps>(
  (props, ref) => {
    const { attributeWithValue } = props;

    const { focusTargetId } = useStore(ChecklistInstancesStore);

    switch (attributeWithValue.checklistAttribute.attribute.type) {
      case EChecklistAttributeType.Int:
        return <IntegerAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      case EChecklistAttributeType.Double:
        return <DoubleAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      case EChecklistAttributeType.String:
        return <StringAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      case EChecklistAttributeType.LongString:
        return <LongStringAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      case EChecklistAttributeType.Boolean:
        return <BooleanAttribute {...props} focusTarget={focusTargetId} />;
      case EChecklistAttributeType.Enum:
        return <SelectAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      case EChecklistAttributeType.UserDictionaryLink:
        return <UserDictionaryAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      case EChecklistAttributeType.DictionaryLink:
        return <DictionaryAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      case EChecklistAttributeType.ChecklistInstanceLink:
        return (
          <ChecklistLinkAttribute
            attributeWithValue={attributeWithValue}
            focusTarget={focusTargetId}
            ref={ref}
          />
        );
      case EChecklistAttributeType.Date:
        return <DateAttribute {...props} focusTarget={focusTargetId} ref={ref} />;
      default:
        return <></>;
    }
  }
);

ChecklistAttributeContainer.displayName = 'ChecklistAttributeContainer';

export default memo(observer(ChecklistAttributeContainer));
