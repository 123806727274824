enum EChecklistAttrPlaceholder {
  Int = 'Укажите значение',
  Double = 'Укажите значение',
  String = 'Введите текст',
  LongString = 'Введите текст',
  Enum = 'Выберите значение',
  UserDictionaryLink = 'Выберите или введите значение',
  DictionaryLink = 'Выберите значение',
  Date = 'Выберите значение',
}

export default EChecklistAttrPlaceholder;
