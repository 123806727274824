import styled, { css } from 'styled-components';

export type IStyledContainerProps = {
  $isChecklistLinkAttribute?: boolean;
};

export const Wrapper = styled.section<IStyledContainerProps>`
  background-color: #ffffff;
  border-radius: 16px;
  max-width: 912px;
  max-height: 100%;
  margin: 0 auto;

  ${({ $isChecklistLinkAttribute }) =>
    !$isChecklistLinkAttribute &&
    css`
      padding-bottom: 4px;
    `}
`;

export const HeaderWrapper = styled.div`
  padding: 24px 24px 0 24px;
`;

export const Header = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e6f0;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export const Body = styled.div<IStyledContainerProps>`
  ${({ $isChecklistLinkAttribute }) =>
    !$isChecklistLinkAttribute &&
    css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 16px;
      margin-top: 16px;

      & > div {
        margin-bottom: 20px;
      }
    `}
`;
