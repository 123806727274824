import styled from 'styled-components';

import { Colors } from '../../modules/shared/constans/colors';

export const Wrapper = styled.div`
  text-align: center;
  cursor: pointer;
`;

export enum ColorsState {
  Accent,
  Secondary,
  Disabled,
  Critical,
}

export enum ButtonType {
  Default,
  Outlined,
}

export type ButtonStyleProps = {
  colorStyle: ColorsState;
  variant: ButtonType;
};

const renderOutline = (color: ButtonStyleProps['colorStyle']) => `
  border: 1px solid ${getColor(color)};
  color: ${getColor(color)};
`;

const getColor = (color: ButtonStyleProps['colorStyle']) => {
  const variant = {
    [ColorsState.Accent]: Colors.green,
    [ColorsState.Secondary]: Colors.darkGray,
    [ColorsState.Disabled]: Colors.grayBorder,
    [ColorsState.Critical]: Colors.red,
  };
  return variant[color] || variant[ColorsState.Accent];
};

const renderDefault = (color: ButtonStyleProps['colorStyle']) => `
  background: ${getColor(color)};
  border: none;
  color: ${Colors.white};
`;

export const ButtonInner = styled.button<ButtonStyleProps>`
  padding: 10px 40px;
  border-radius: 12px;
  display: inline-block;
  outline: none;
  background: none;
  ${({ variant, colorStyle }) =>
    variant === ButtonType.Default ? renderDefault(colorStyle) : renderOutline(colorStyle)}
`;
