import { EModalType, IModal } from '../../../../modals/components/Modal/Modal';
import { IModalConfig } from '../../../../modals/config';

export const tasksModalUniqueKey = 'tasks';

export enum ETasksModalName {
  TaskCompletedSuccessfully = 'TASK_CREATE_SUCCESSFULLY',
  WarningBeforeChangeInstance = 'WARNING_BEFORE_CHANGE_INSTANCE',
  TaskCreationError = 'TASK_CREATION_ERROR',
}

const taskCompletedSuccessfully: IModal = {
  name: ETasksModalName.TaskCompletedSuccessfully,
  title: 'Задача успешно создана',
  type: EModalType.Success,
  successButton: {
    title: 'Продолжить',
  },
};

const warningBeforeChangeInstance: IModal = {
  name: ETasksModalName.WarningBeforeChangeInstance,
  title: 'Вы уверены, что хотите покинуть экран? Все несохраненные данные будут потеряны',
  type: EModalType.Warning,
  denyButton: {
    title: 'Нет, отменить',
  },
  successButton: {
    title: 'Да, продолжить',
  },
};

const TaskCreationError: IModal = {
  name: ETasksModalName.TaskCreationError,
  title: 'Кажется, что-то пошло не так. Попробуйте ещё раз',
  type: EModalType.Warning,
  successButton: {
    title: 'Продолжить',
  },
};

export const taskModalsConfig: IModalConfig = {
  [tasksModalUniqueKey]: [
    taskCompletedSuccessfully,
    warningBeforeChangeInstance,
    TaskCreationError,
  ],
};
