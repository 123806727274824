import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { useStore } from '../../../../../shared/utils/IoC';
import { TasksController } from '../../controllers/tasks.controller';
import { PreparedTask } from '../../controllers/tasks.listing.controller';
import { TaskViewStore } from '../../stores/task.view.store';
import { Task, TaskStatuses } from '../../stores/tasks.store';

import { StatusActionsBlock, Wrapper } from './style';

export interface TaskStatusesButtonsBlockProps {
  task: Partial<Task> | PreparedTask;
  handleCloseDialog: () => void;
}

export const TaskStatusesButtonsBlock: FC<TaskStatusesButtonsBlockProps> = observer(
  ({ task, handleCloseDialog }) => {
    const tasksController = useStore(TasksController);
    const { taskForView } = useStore(TaskViewStore);
    const { openModalByModalId } = useModal();

    const handleUpdateStatus = async (newStatus: TaskStatuses) => {
      if (newStatus === TaskStatuses.Canceled) {
        return openModalByModalId('cancellationModal', {
          id: task.id,
          status: newStatus,
        });
      } else {
        await tasksController.setStatus(task.id, newStatus);
        handleCloseDialog();
      }
    };

    const taskStatus = taskForView.status;

    return (
      <Wrapper>
        {task.status === TaskStatuses.New ? (
          <StatusActionsBlock>
            <Button
              type={'button'}
              color={'primary'}
              onClick={handleUpdateStatus.bind(this, TaskStatuses.InWork)}
              dataTestId={'change-status-button-to-work'}
            >
              Взять в работу
            </Button>
            {taskStatus !== TaskStatuses.Canceled ? (
              <Button
                type={'button'}
                color={'secondary'}
                styleType={'outlined'}
                onClick={handleUpdateStatus.bind(this, TaskStatuses.Canceled)}
                dataTestId={'change-status-button-cancel'}
              >
                Отменить задачу
              </Button>
            ) : null}
          </StatusActionsBlock>
        ) : null}
        {task.status === TaskStatuses.InWork ? (
          <StatusActionsBlock>
            <Button
              type={'button'}
              color={'primary'}
              onClick={handleUpdateStatus.bind(this, TaskStatuses.Completed)}
              dataTestId={'change-status-button-complete'}
            >
              Завершить
            </Button>
            {taskStatus !== TaskStatuses.Canceled ? (
              <Button
                type={'button'}
                color={'secondary'}
                styleType={'outlined'}
                onClick={handleUpdateStatus.bind(this, TaskStatuses.Canceled)}
                dataTestId={'change-status-button-cancel'}
              >
                Отменить задачу
              </Button>
            ) : null}
          </StatusActionsBlock>
        ) : null}
        {task.status === TaskStatuses.Completed ? (
          <StatusActionsBlock>
            <Button
              type={'button'}
              color={'primary'}
              onClick={handleUpdateStatus.bind(this, TaskStatuses.InWork)}
              dataTestId={'change-status-button-to-work'}
            >
              Взять в работу
            </Button>
          </StatusActionsBlock>
        ) : null}
        {/* Вернуть после доработки со стороны Backend 
        {task.status === TaskStatuses.Canceled ? (
          <StatusActionsBlock>
            <Button
              type={'button'}
              color={'primary'}
              onClick={handleUpdateStatus.bind(this, TaskStatuses.InWork)}
              dataTestId={'change-status-button-to-work'}
            >
              Вернуть в работу
            </Button>
          </StatusActionsBlock>
        ) : null} */}
      </Wrapper>
    );
  }
);
