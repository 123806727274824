import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { OperationsRoute } from './operations.route';
import { OperationsListing } from './containers/listing/operations.listing';
import { TasksEdit } from './components/TaskEdit/TaskEdit';
// import { FieldsAdd } from './containers/add/fields.add';
// import { FieldsEdit } from './containers/edit/fields.edit';

export const OperationsRouter: FC = () => {
  return (
    <Switch>
      {/* <Route path={OperationsRoute.Add} component={() => <FieldsAdd />} />
      <Route path={`${OperationsRoute.Edit}/:id`} component={() => <FieldsEdit />} /> 
      <Route path={`${OperationsRoute.ViewEditTask}/:taskId?`} component={() => <TasksEdit />} /> */}
      <Route path={OperationsRoute.Listing} component={() => <OperationsListing />} />
      
    </Switch>
  );
};
