import { useCallback } from 'react';

import { TDropdownOnOptionClick, TUseDropdownBodyHandlers } from './useDropdownBodyHandlers.types';

const useDropdownBodyHandlers: TUseDropdownBodyHandlers = ({ state, setState, props }) => {
  const handleOptionClick = useCallback<TDropdownOnOptionClick>(
    (option, isNewData) => {
      const isShowWarning = props.config?.validation?.modal?.isShowWarning;

      const isMultiselect = props.config.field?.type?.multiselect;

      props.config.field.onChange(option?.value || '', {
        selectedValueList: isMultiselect
          ? [...state.values.selectValueList, option]
          : state.values.selectValueList,
        isNewData: isNewData || option?.label === option?.value,
        searchValue: state.values.searchValue,
        option,
        optionList: props.config.body.optionList,
      });

      if (isShowWarning) {
        setState({
          temporaryData: {
            onChangeData: {
              selectedValueList: isMultiselect
                ? [...state.values.selectValueList, option]
                : state.values.selectValueList,
              isNewData: isNewData || option?.label === option?.value,
              searchValue: state.values.searchValue,
              option,
              optionList: props.config.body.optionList,
            },
          },
        });

        return;
      }

      if (props.config.field?.type?.multiselect) {
        setState({
          values: {
            ...state.values,
            searchValue: null,
            selectValueList: [...state.values.selectValueList, option],
          },
          settings: { ...state.settings, isDropped: false, isSearchFieldFocused: false },
        });

        return;
      }

      setState({
        values: { ...state.values, searchValue: null, selectValue: option },
        settings: { ...state.settings, isDropped: false, isSearchFieldFocused: false },
      });
    },
    [state, setState, props]
  );

  const handlers: ReturnType<TUseDropdownBodyHandlers> = {
    onOptionClick: handleOptionClick,
  };

  return handlers;
};

export default useDropdownBodyHandlers;
