import styled from 'styled-components';

import { Colors } from '../../constans/colors';

export const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;

  & input {
    width: 100%;
    height: 40px;
    padding: 12px 15px 12px 68px;
    background: ${({ disabled }) => (disabled ? '#ffffff' : Colors.lightGray)};
    border: 0.5px solid ${Colors.lightnessGray};
    box-sizing: border-box;
    border-radius: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${({ disabled }) => (disabled ? Colors.darkGray : Colors.black)};
  }

  & input::placeholder {
    color: ${Colors.darkGray};
  }
`;

export const OrgTypeSelectWrapper = styled.div`
  position: absolute;
  left: 17px;
  top: 20px;
  z-index: 10;
`;

export const OrgTypeSelectHeader = styled.div<{ disabled: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47px;
  color: ${({ disabled }) => (disabled ? Colors.darkGray : Colors.black)};
  font-size: 14px;

  & svg {
    width: 8px;
    margin-top: 2px;
  }
`;

export const OrgTypeSelectBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 60px;
  background: ${Colors.lightGray};
  border: 0.5px solid ${Colors.lightnessGray};
  border-top: none;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 5px;
  margin-top: -10px;
  margin-left: -17px;
  text-align: center;
  color: ${Colors.darkGray};
  font-size: 14px;

  & span {
    margin-bottom: 10px;
    cursor: pointer;
  }
`;

export const Error = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #f29993;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0 0 4px;
`;
