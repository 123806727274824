import { ChangeEvent, KeyboardEvent, useCallback } from 'react';

import { ISelectOption } from '../../../../../../../types/selectOption';

import { TUseDropdownSearchFieldHandlers } from './useDropdownSearchFieldHandlers.types';

const useDropdownSearchFieldHandlers: TUseDropdownSearchFieldHandlers = ({
  state,
  setState,
  props,
  availableOptionList,
}) => {
  const handleSearchFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.value === '') {
        setState({
          values: { ...state.values, searchValue: event.target.value, selectValue: null },
          settings: { ...state.settings, isExactCompareOfSearchValue: false },
        });
      } else {
        setState({
          values: { ...state.values, searchValue: event.target.value },
          settings: { ...state.settings, isExactCompareOfSearchValue: false },
        });
      }
    },
    [state]
  );

  const handleSearchFieldFocus = useCallback((): void => {
    if (!props.config.field?.type?.search) {
      return;
    }

    setState({ settings: { ...state.settings, isSearchFieldFocused: true, isDropped: true } });
  }, [state, props.config.field?.type?.search]);

  const handleSearchFieldBlur = useCallback((): void => {
    if (!props.config.field?.type?.search) {
      return;
    }

    setState({ settings: { ...state.settings, isSearchFieldFocused: false } });
  }, [state, props.config.field?.type?.search]);

  const handleKeypressOnSearchField = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter' && state.values.searchValue) {
        const trimmedSearchValue = state.values.searchValue.trim();

        const isAllowNewValueToBeAdded =
          props.config.field?.type?.search?.options?.isAllowNewValueToBeAdded;

        const isMultiselect = props.config.field?.type?.multiselect;

        const isOptionTheSameAsSearchValue = availableOptionList?.[0]?.label === trimmedSearchValue;

        /**
         * Если значение поисковой строки полностью совпадает с лэйблом селекта,
         * то выбираем данное значение
         */
        if (isOptionTheSameAsSearchValue) {
          event.preventDefault();
          event.currentTarget.blur();

          props.config.field.onChange(trimmedSearchValue || '', {
            selectedValueList:
              isMultiselect && availableOptionList?.[0]
                ? [...state.values.selectValueList, availableOptionList?.[0]]
                : state.values.selectValueList,
            isNewData: true,
            searchValue: trimmedSearchValue,
            option: availableOptionList?.[0],
            optionList: props.config.body.optionList,
          });

          setState({
            values: {
              ...state.values,
              searchValue: null,
              selectValue: availableOptionList?.[0],
              selectValueList:
                isMultiselect && availableOptionList?.[0]
                  ? [...state.values.selectValueList, availableOptionList?.[0]]
                  : state.values.selectValueList,
            },
            settings: {
              ...state.settings,
              isDropped: false,
              isSearchFieldFocused: false,
              isExactCompareOfSearchValue: false,
            },
          });

          return;
        }

        /**
         * Если разрешено добавлять новое значение и список лэйблов пуст,
         * то добавляем новое значение
         */
        if (isAllowNewValueToBeAdded && !availableOptionList.length) {
          event.preventDefault();
          event.currentTarget.blur();

          const newSelectValue: ISelectOption = {
            label: trimmedSearchValue,
            value: trimmedSearchValue,
          };

          props.config.field.onChange(state.values.searchValue || '', {
            selectedValueList: isMultiselect
              ? [...state.values.selectValueList, newSelectValue]
              : state.values.selectValueList,
            isNewData: true,
            searchValue: trimmedSearchValue,
            option: newSelectValue,
            optionList: props.config.body.optionList,
          });

          setState({
            values: {
              ...state.values,
              searchValue: null,
              selectValue: newSelectValue,
              selectValueList: isMultiselect
                ? [...state.values.selectValueList, newSelectValue]
                : state.values.selectValueList,
            },
            settings: {
              ...state.settings,
              isDropped: false,
              isSearchFieldFocused: false,
              isExactCompareOfSearchValue: false,
            },
          });

          return;
        }

        setState({ settings: { ...state.settings, isExactCompareOfSearchValue: true } });
      }
    },
    [
      state,
      availableOptionList,
      props.config.field?.type?.search?.options?.isAllowNewValueToBeAdded,
      props.config.field?.type?.multiselect,
      props.config.field.onChange,
    ]
  );

  const handlers: ReturnType<TUseDropdownSearchFieldHandlers> = {
    onChange: handleSearchFieldChange,
    onFocus: handleSearchFieldFocus,
    onBlur: handleSearchFieldBlur,
    onKeyUp: handleKeypressOnSearchField,
  };

  return handlers;
};

export default useDropdownSearchFieldHandlers;
