import { FC, useCallback, useRef, useEffect, useState } from 'react';
import { Icon } from '@farmlink/farmik-ui';

import { IndiceModel } from '../../../../api/models/indices.model';

import { Scroll, StyledSwipeBtn } from './style';

type Props = {
  scrollPostions?: 'default' | 'right';
  selectedFieldId?: string;
  indices?: IndiceModel[];
};

export const SwiperSlider: FC<Props> = ({
  children,
  scrollPostions = 'default',
  selectedFieldId,
  indices,
}) => {
  const scroll = useRef<HTMLDivElement>();
  let scrollPos = 0;
  let currElPosition = scrollPostions === 'default' ? 0 : scroll?.current?.childElementCount - 1;
  let elementsLeft = 0;
  const [hasScroll, setHasScroll] = useState<boolean>();
  const [elementsWidthArr, setElementWidthArr] = useState<number[]>(null);
  const [maxElementsLeft, setMaxElementsLeft] = useState(0);

  useEffect(() => {
    scrollToEnd();

    if (scroll.current?.children.length) {
      let widthLeft = scroll.current.clientWidth;
      let elems = 0;
      let arrOfElements = Array.from(scroll.current.children);
      const arrOfElementsWidth = [];

      if (scrollPostions !== 'default') arrOfElements = arrOfElements.reverse();

      arrOfElements.forEach(element => {
        widthLeft -= element.clientWidth;
        if (widthLeft > 0) elems += 1;
        arrOfElementsWidth.push(parseInt(getComputedStyle(element).width, 10));
      });

      setElementWidthArr(arrOfElementsWidth);
      setMaxElementsLeft(arrOfElementsWidth.length - elems);
    }
  }, [selectedFieldId, scroll.current?.children.length, indices]);

  const scrollToEnd = useCallback(() => {
    scrollPos = 0;
    scroll.current.scrollTo(scrollPos, 0);
  }, []);

  const scrollHandle = useCallback(
    (direction: 'left' | 'right') => {
      if (scrollPostions === 'default') {
        if (direction === 'left' && elementsLeft > 0) {
          scrollPos -= elementsWidthArr[currElPosition - 1];
          currElPosition -= 1;
          elementsLeft -= 1;
          scroll.current.scrollTo(scrollPos, 0);
        } else if (direction === 'right' && currElPosition <= maxElementsLeft) {
          scrollPos += elementsWidthArr[currElPosition];
          currElPosition += 1;
          elementsLeft += 1;
          scroll.current.scrollTo(scrollPos, 0);
        }
      } else if (direction === 'left' && Math.abs(elementsLeft) < maxElementsLeft) {
        scrollPos -= elementsWidthArr[currElPosition];
        currElPosition -= 1;
        elementsLeft -= 1;
        scroll.current.scrollTo(scrollPos, 0);
      } else if (direction === 'right' && currElPosition < elementsWidthArr.length - 1) {
        scrollPos += elementsWidthArr[currElPosition];
        currElPosition += 1;
        elementsLeft += 1;
        scroll.current.scrollTo(scrollPos, 0);
      }
    },
    [elementsWidthArr, elementsLeft, maxElementsLeft, elementsLeft]
  );

  const handleScroll = e => {
    if (e.deltaY > 0) {
      scrollHandle('right');
    } else {
      scrollHandle('left');
    }
  };

  useEffect(() => {
    if (scroll.current.scrollWidth > scroll.current.clientWidth) setHasScroll(true);
    else setHasScroll(false);
  });

  return (
    <>
      <StyledSwipeBtn onClick={() => scrollHandle('left')} $position="left" $hasScroll={hasScroll}>
        <Icon icon={'arrow_left'} size={'small'} />
      </StyledSwipeBtn>
      <Scroll isRight={scrollPostions === 'right'} hideScrollbar ref={scroll} onWheel={handleScroll}>
        {children}
      </Scroll>
      <StyledSwipeBtn
        onClick={() => scrollHandle('right')}
        $position="right"
        $hasScroll={hasScroll}
      >
        <Icon icon={'arrow_right'} size={'small'} />
      </StyledSwipeBtn>
    </>
  );
};
