import { Icon, Tooltip } from '@farmlink/farmik-ui';
import { ChangeEvent, forwardRef, memo } from 'react';

import { Colors } from '../../../../../../../../../shared/constans/colors';

import Styled from './styles';
import { TInputProps } from './types';

const MAXIMAL_LENGTH_DEFAULT = 100;

const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      onChange,
      label,
      isRequired,
      className,
      isDisabled,
      isBlocked,
      value,
      placeholder,
      type,
      error,
      isWithoutErrorText,
      maxLength,
      dataTestId,
      id,
      tooltip,
      onBlur,
      as = 'input',
      isResizable,
      height,
      hideScrollbar,
    },
    ref
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      onChange?.(event.target.value.toString());
    };

    return (
      <Styled.Wrapper>
        <Styled.TopBlock>
          {label && (
            <Styled.LabelWrapper>
              <Styled.Label required={isRequired}>{label}</Styled.Label>
              {tooltip && (
                <>
                  <Tooltip id={`tooltip-${id}`} getContent={() => tooltip} offset={{ left: 4 }}>
                    <Styled.Icon />
                  </Tooltip>
                </>
              )}
            </Styled.LabelWrapper>
          )}
        </Styled.TopBlock>
        <Styled.InputField
          className={className}
          disabled={isDisabled || isBlocked}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={handleChange}
          maxLength={maxLength ? maxLength : MAXIMAL_LENGTH_DEFAULT}
          data-test-id={dataTestId}
          id={id}
          ref={ref}
          onBlur={onBlur}
          as={as}
          autoComplete={'off'}
          $error={error}
          $isBlocked={isBlocked}
          $isResizable={isResizable}
          $height={height}
          $hideScrollbar={hideScrollbar}
        />

        {isBlocked && (
          <Styled.IconWrapper $isLabel={Boolean(label)} $align={'end'}>
            <Icon fill={Colors.secondaryDarkGray} icon={'minus'} size={'xsmall'} />
          </Styled.IconWrapper>
        )}

        {error && !isWithoutErrorText && (
          <Styled.ErrorMessage data-test-id={`${dataTestId}_errorMessage`}>
            {error}
          </Styled.ErrorMessage>
        )}
      </Styled.Wrapper>
    );
  }
);

Input.displayName = 'Input';

export default memo(Input);
