import { useMemo } from 'react';
import { Button } from '@farmlink/farmik-ui';

import { ModalComponent as Modal } from '../../../../../../components/Modal/Modal';
import MarkSvg from '../../static/label.svg';
import WarningSvg from '../../static/warning.svg';

import { ButtonActionWrapper, LabelTitle, MarkImg, ModalElement, ModalOverlay } from './style';

export enum ModalType {
  success,
  failure,
  unsavedResult,
}

export const TaskModal = ({
  handleFinish,
  modalType,
  handleReturn,
  taskId,
  isFullScreen,
  onCloseDialog,
}: {
  handleFinish: (event: any) => void;
  handleReturn: (event: any) => void;
  modalType: ModalType;
  taskId?: string;
  isFullScreen: boolean;
  onCloseDialog: () => void;
}) => {
  const MODAL_TEXT = useMemo(
    () => [
      `Задача успешно ${taskId ? 'отредактирована' : 'создана'}`,
      'Кажется что-то пошло не так. Попробуйте ещё раз',
      // для предупреждения о потере данных использовал модалку из uiStore. Все остальные модалки тоже можно сделать централизованными из uiStore
    ],
    [taskId]
  );

  return (
    <>
      <ModalOverlay />
      <ModalElement>
        <Modal
          paddings={'culture-zone'}
          isVerticalCentered={true}
          data-test-id={'task-modal-success'}
          size={'medium'}
        >
          {modalType < 1 ? <MarkImg src={MarkSvg} /> : <MarkImg src={WarningSvg} />}
          <LabelTitle data-test-id={'task-modal-label'}>{MODAL_TEXT[modalType]}</LabelTitle>
          {modalType < 2 ? (
            <ButtonActionWrapper>
              <Button
                color={'primary'}
                type={'button'}
                onClick={handleFinish}
                data-test-id={'task-modal-success-continue'}
              >
                Продолжить
              </Button>
            </ButtonActionWrapper>
          ) : (
            <ButtonActionWrapper>
              <Button
                color={'default'}
                type={'button'}
                onClick={handleReturn}
                data-test-id={'task-modal-success-decline'}
              >
                Нет, отменить
              </Button>
              <Button
                color={'primary'}
                type={'button'}
                onClick={isFullScreen ? onCloseDialog : handleFinish}
                data-test-id={'task-modal-success-continue'}
              >
                Да, продолжить
              </Button>
            </ButtonActionWrapper>
          )}
        </Modal>
      </ModalElement>
    </>
  );
};
