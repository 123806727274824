import { makeAutoObservable, reaction } from 'mobx';
import area from '@turf/area';
import { polygon as turfPolygon } from '@turf/turf';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios, TypeApiResponse } from '../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { Field } from '../../../../../api/models/field.model';
import MapStore, { MapMode } from '../../../../map/stores/map.store';
import { editPolygonEventName } from '../../../../map/events/edit.polygon.event';
import { RenderPolygonOption } from '../../../../map/consts/enum.render.option';
import { FieldsErrors } from '../constants/fields.errors';
import { toFixedWithCeilBackEnd } from '../../../../shared/utils/toFixedWithCeil';
import { SeasonsStore } from '../../../stores/seasons.store';
import { UiStore } from '../../../stores/ui.store';
import { PopupPages } from '../../../constants/popup.pages';
import { CultureFillEnum } from '../../../constants/culture.fill.enum';
import { LabelFieldFillEnum } from '../../../constants/label.fill.enum';
import { ProfileStore } from '../../profile/stores/ProfileStore';
import { CheckAccessStore } from '../../../../authorization/stores/checkAccess.store';
import { ACCESS_ACTIONS_MODULES } from '../../../../shared/constants/access-rules-action';

export type PrevValuesType = {
  prevPopupState: PopupPages;
  prevMapMode: MapMode;
  prevSeason: string;
  prevOrganization: string;
};

@provide.singleton()
export class FieldsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  @lazyInject(ProfileStore)
  protected profile: ProfileStore;

  @lazyInject(CheckAccessStore)
  protected checkAccessStore: CheckAccessStore;

  @lazyInject(MapStore)
  map: MapStore;

  @lazyInject(UiStore)
  ui: UiStore;

  editableField: Field | null = null;

  initialEditableField: Field | null = null;

  constructor() {
    makeAutoObservable(this);

    // todo refactor избавиться от реакции
    reaction(
      () => this.organizations.selectedOrganizationId,
      () => {
        if (this.organizations.selectedOrganizationId !== 'my') {
          this.checkAccessStore.clearAccessRules();
          ACCESS_ACTIONS_MODULES.forEach(async muduleCode => {
            await this.checkAccessStore.getAccessRulesByModule(
              this.organizations.selectedOrganizationId,
              muduleCode
            );
          });
        }
      }
    );
  }

  setEditableField = (field: Field): void => {
    this.editableField = field;
  };

  setInitialEditableField = (field: Field): void => {
    this.initialEditableField = field;
  };

  clearEditableField = (): void => {
    this.editableField = null;
  };

  clearInitialEditableField = (): void => {
    this.initialEditableField = null;
  };

  // todo mapMode присутствует и в fieldStore и mapStore
  mapMode: MapMode = MapMode.Listing;

  setMapModeValue = (value: MapMode) => {
    this.mapMode = value;
  };

  startViewMode = () => {
    this.map.setViewMode();
  };

  showCulturesFillPanel: CultureFillEnum = CultureFillEnum.None;

  showLabelFieldFill: LabelFieldFillEnum = LabelFieldFillEnum.Name;

  clear = (): void => {
    this.isLoading = true;
    this.fieldsLoaded = false;
    this.clearIdToFields();
    this.fieldCultureZonesId.clear();
  };

  fieldCultureZonesId: Map<number, Field> = new Map<number, Field>();

  cultureZones: Set<number> = new Set();

  idToFields: Map<string, Field> = new Map<string, Field>();

  get fields() {
    return Array.from(this.idToFields.values());
  }

  getField = (id: string): Field => {
    return this.idToFields.get(id);
  };

  setField = (field: Field): void => {
    this.idToFields.set(field.id, field);
  };

  deleteField = (id: string): void => {
    this.idToFields.delete(id);
  };

  selectedFieldId = '';

  noneFieldFill = false;

  isLoading = false;
  fieldsLoaded = false;

  firstLoadingFields = false;

  prevSeason = '';
  prevOrganization = '';
  prevMapMode: MapMode;
  prevPopupState: PopupPages = PopupPages.None;
  prevFieldId = '';

  // CREATING FIELDS SECTION
  idToCreatableField: Map<number, CreationFieldType> = new Map();

  get creationFields() {
    return Array.from(this.idToCreatableField.values());
  }

  get editableFieldWithGeoJson() {
    const layer = this.map.getEditableLayer(this.editableField.polyId);
    // @ts-ignore
    const geoJson = layer.toGeoJSON();

    return {
      name: this.editableField.name,
      geoJson,
    };
  }

  get isSaveButtonDisabled() {
    return this.idToCreatableField.size === 0 || this.map.incorrectLayersId.size > 0;
  }

  blurEditableField = () => {
    this.editableField = null;
  };

  // FIELD WEATHER

  showFullWeather = false;

  weatherById: any;

  weatherForecastsById: any;

  setNoneFieldFill = (value: boolean) => {
    this.noneFieldFill = value;
  };

  setShowLabelFieldFill = (fill: LabelFieldFillEnum) => {
    this.showLabelFieldFill = fill;
  };

  /**
   * Select first poly in fields list without select
   * TODO: possible need select center point from geolocation or moscow by default
   */
  centerMapWithoutSelect = () => {
    const fieldId = Array.from(this.idToFields.keys()).shift();
    if (fieldId) {
      const field = this.idToFields.get(fieldId);
      this.map.centerMapToPoint(field.polyId);
    }
  };

  clearCreatableFields = () => {
    let layers = Array.from(this.map.idToEditableLayer.values());
    layers.forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    layers = Array.from(this.map.idToPolygon.values());
    layers.forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    this.map.idToEditableLayer.clear();
    this.idToCreatableField.clear();
    layers = Array.from(this.map.idToPolygon.values());
    layers.forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    this.map.idToPolygon.clear();
    this.map.currentLayer = null;
    this.map.isShapeFinished = false;
    this.map.unbindCreatingEvents();
  };

  setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setFirstLoadingFields = (value: boolean) => {
    this.firstLoadingFields = value;
  };

  setPreviousValues = (prevValue: PrevValuesType) => {
    this.prevPopupState = prevValue.prevPopupState;
    this.prevMapMode = prevValue.prevMapMode;
    this.prevSeason = prevValue.prevSeason;
    this.prevOrganization = prevValue.prevOrganization;
  };

  startFieldCreating = () => {
    this.map.changeMode();
  };

  handleEditPolygon = (data: any) => {
    const points = data.detail.geometry.coordinates;
    const polyId = data.detail.id;
    console.log('handle points ', data);
    const polygon = turfPolygon(points);
    // conver square meters to square gectars
    const fieldArea = area(polygon) / 10000;
    const field = this.idToCreatableField.get(polyId);
    // const name = `Поле ${toFixedWithCeilBackEnd(fieldArea)} га`;
    const { name } = this.idToCreatableField.get(polyId);

    const layer = this.map.getEditableLayer(polyId);
    if (name) layer.getTooltip().setContent(name);
    field.areaInHectare = fieldArea;
    // field.name = name;
  };

  handlePolygonCreation = (data: any) => {
    const points = [data.detail.geometry];
    const polyId = data.detail.id;
    console.log('points', points);
    const polygon = turfPolygon(points);
    const fieldArea = area(polygon) / 10000;
    console.log(fieldArea);
    const name = `Поле ${toFixedWithCeilBackEnd(fieldArea)} га`;
    console.log('name', name);
    const layer = this.map.getEditableLayer(polyId);
    layer.getTooltip().setContent(name);
    this.idToCreatableField.set(polyId, {
      id: polyId,
      name,
      areaInHectare: parseFloat(fieldArea.toFixed(7)),
      longLatGeometry: points,
      polyId,
    });
  };

  changeName = (id: number, v: string) => {
    const field = this.idToCreatableField.get(id);
    field.name = v;
    this.map.changeCreatableFieldName(id, v);
  };

  get creatableFieldsWithGeoJson() {
    const fields = Array.from(this.idToCreatableField.values());

    return fields.map(field => {
      const layer = this.map.getEditableLayer(field.polyId);
      // @ts-ignore
      const geoJson = layer.toGeoJSON();
      console.log('creatableFieldsWithGeoJson', geoJson);
      return {
        name: field.name,
        geoJson: {
          ...geoJson,
          geometry: {
            ...geoJson.geometry,
            coordinates: [
              geoJson.geometry.coordinates[0].map(position => [
                position[0].toFixed(19),
                position[1].toFixed(19),
              ]),
            ],
          },
        },
      };
    });
  }

  get isAreaToBig() {
    return (
      Array.from(this.idToCreatableField.values()).filter(field => field.areaInHectare > 1000)
        .length > 0
    );
  }

  saveCreatableFields = async () => {
    if (this.isAreaToBig) {
      throw new Error(FieldsErrors.AreaTobBig);
    }

    if (this.map.incorrectLayersId.size > 0) {
      throw new Error(FieldsErrors.Intersection);
    }

    let response: TypeApiResponse<'createFields'> | null = null;

    try {
      this.isLoading = true;

      response = await this.axios.api.createFields({
        organizationId: this.organizations.selectedOrganizationId,
        seasonYear: Number(this.seasons.selectedSeason),
        fields: this.creatableFieldsWithGeoJson,
      });
    } catch (e) {
      this.isLoading = false;
      console.log(e);

      throw new Error(e.response.data.error);
    }

    this.isLoading = false;
    this.clearCreatableFields();
  };

  fetchFieldById = async (id: string) => {
    let response: TypeApiResponse<'getFieldById'>;

    let attempts = 0;

    while (!this.seasons.selectedSeason) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => {
        setTimeout(() => resolve(0), 1000);
      });
      attempts++;
      if (attempts > 5) {
        throw new Error('season not passed');
      }
    }

    try {
      response = await this.axios.api.getFieldById(
        { fieldId: id, seasonYear: Number(this.seasons.selectedSeason) },
        { omit: ['fieldId'] }
      );
    } catch (e) {
      return;
    }
    this.editableField = response;
    const layerId = this.map.setPolygon(response.geometry.coordinates, {
      name: response.name,
      renderOption: RenderPolygonOption.View,
      editable: true,
    });
    const layer = this.map.getEditableLayer(layerId);
    // @ts-ignore
    layer.pm.enable({ allowSelfIntersection: false, preventMarkerRemoval: true });
    this.editableField.polyId = layerId;
    this.initialEditableField = response;
    this.initialEditableField.polyId = layerId;
    const points = response.geometry.coordinates;

    const polygon = turfPolygon(points);
    const fieldArea = area(polygon) / 10000;

    this.editableField.areaInHectare = fieldArea;
    this.map.changeLayerToEditable(layerId);
  };

  // FIELD WEATHER

  setFullWeatherMode = (isFull: boolean) => {
    this.showFullWeather = isFull;
  };

  fetchWeatherById = async () => {
    let response: TypeApiResponse<'getWeatherById'>;
    if (!this.selectedFieldId) return;
    try {
      this.isLoading = true;
      response = await this.axios.api.getWeatherById({
        id: this.selectedFieldId,
        seasonYear: Number(this.seasons.selectedSeason),
      });
      this.weatherById = response;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      return;
    }
    return response;
  };

  fetchWeatherForecastsById = async () => {
    let response: TypeApiResponse<'getWeatherForecastsById'>;
    if (!this.selectedFieldId) return;
    try {
      this.isLoading = true;
      response = await this.axios.api.getWeatherForecastsById({
        id: this.selectedFieldId,
        seasonYear: Number(this.seasons.selectedSeason),
      });
      this.weatherForecastsById = response;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      return;
    }
    return response;
  };

  updateCultureZones = async (editableFieldWithGeoJSON, seasonYear) => {
    const field = this.editableField;
    if (field && field.cultureZones && field.cultureZones.length && seasonYear) {
      await this.axios.api.updateCultureZones({
        zones: [
          {
            ...editableFieldWithGeoJSON,
            sowingDate: String(field.cultureZones[0].sowingDate),
            harvestDate: String(field.cultureZones[0].harvestDate),
            cultureId: field.cultureZones[0].culture.id,
          },
        ],
        seasonYear,
        fieldId: field.id,
      });
    }
  };

  handleEditPolygonInEditMode = data => {
    const points = data.detail.geometry.coordinates;

    const polygon = turfPolygon(points);
    // convert square meters to square gectars
    const fieldArea = area(polygon) / 10000;
    this.editableField.areaInHectare = fieldArea;
    this.editableField.geometry = data.detail.geometry;
  };

  deleteFieldById = async (id: string) => {
    try {
      await this.axios.api.deleteFieldById({ fieldId: id });
    } catch (e) {
      console.error(e);
    }
  };

  deleteFieldFromCurrentSeason = async (id: string, seasonYear: number) => {
    try {
      await this.axios.api.deleteFieldFromCurrentSeason({ fieldId: id, seasonYear });
    } catch (e) {
      throw e;
    }
    const field = this.editableField || this.idToFields.get(id);

    this.map.deletePolygon(field.polyId);
    this.idToFields.delete(id);
  };

  unbindEditEvents = () => {
    window.removeEventListener(editPolygonEventName, this.handleEditPolygonInEditMode);
  };

  resetEditMode = () => {
    [
      ...Array.from(this.map.idToPolygon.values()),
      ...Array.from(this.map.idToEditableLayer.values()),
    ].forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    this.map.idToPolygon.clear();
    this.map.idToEditableLayer.clear();
  };

  getSelectedField = (): Field => this.idToFields.get(this.selectedFieldId);

  clearIdToFields = (): void => {
    this.idToFields.clear();
  };
}

type CreationFieldType = {
  longLatGeometry: Array<Array<number>>;
  areaInHectare: number;
  name: string;
  id: number;
  polyId: number;
};
