import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

const ErrorMessage = styled.p`
  color: ${Colors.red};
  font-size: 12px;
  padding: 4px 0;
  margin: 0;
`;

const Styled = {
  ErrorMessage,
};

export default Styled;
