import { EAppSettingsType, lsAppSettingsKey } from '../../../..';
import { SeasonModel } from '../../../../api/models/season.model';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../shared/utils/IoC';
import { FieldsListingController } from '../../modules/fields/controllers/fields.listing.controller';
import { OrganizationsStore } from '../../stores/organizations.store';
import { SeasonsStore } from '../../stores/seasons.store';

@provide.singleton()
export class SeasonsController {
  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(FieldsListingController)
  protected fieldsListingController: FieldsListingController;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  @lazyInject(Axios)
  axios: Axios;

  setSelectedSeason = (v: string) => {
    this.seasonsStore.setSelectedSeason(v);
    this.fieldsListingController.updateListingAfterChangeSeasson();
  };

  fetchMySeasons = async () => {
    let response: TypeApiResponse<'getSeasons'> = [];

    let payload: TypeApiRequest<'getSeasons'> = {
      allowStub: false,
      sort: 'DESC',
    };

    if (this.organizations.selectedOrganizationId !== 'my') {
      payload = { ...payload, organizationId: this.organizations.selectedOrganizationId };
    }

    try {
      this.seasonsStore.setLoading(true);
      response = await this.axios.api.getSeasons(payload);
      console.log('*************fetch seasons response');
      console.log(response);
      this.seasonsStore.setLoading(false);
    } catch (e) {
      this.seasonsStore.setLoading(false);
      console.warn(e);
      return;
    }
    this.seasonsStore.setCurrentSeasons(response);

    if (response.length > 0) {
      const appSettingsStr = localStorage.getItem(lsAppSettingsKey);
      const appSettingsObj = appSettingsStr ? JSON.parse(appSettingsStr) : {};
      const selectedSeasonFromProfile = appSettingsObj[EAppSettingsType.Season];
      const haveSelectedSeasonFromProfileInFetched = response.some(
        item => String(item.year) === selectedSeasonFromProfile
      );
      this.setSelectedSeason(
        haveSelectedSeasonFromProfileInFetched
          ? selectedSeasonFromProfile
          : this.getComingSeason(response).year.toString()
      );
    } else {
      this.setSelectedSeason('');
    }
  };

  getComingSeason = (seasonList: SeasonModel[]): SeasonModel => {
    const currentYear = new Date().getFullYear();
    const currentSeason = seasonList.find(season => season.year === currentYear);

    const futureSeason = seasonList
      .filter(season => season.year > currentYear)
      .sort((firstSeason, secondSeason) => firstSeason.year - secondSeason.year)?.[0];

    const pastSeason = seasonList
      .filter(season => season.year < currentYear)
      .sort((firstSeason, secondSeason) => secondSeason.year - firstSeason.year)?.[0];

    return currentSeason || futureSeason || pastSeason;
  };

  clear = () => {
    this.seasonsStore.clear();
  };
}
