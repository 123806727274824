import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  operationId: string;
  operationTypeId: string;
  startDate: string;
  endDate: string;
  seasonYear: number;
  name: string;
  cultureId: string;
};

type TypeResponse = {
  operationTypeId: string;
  startDate: string;
  endDate: string;
  seasonYear: number;
  name: string;
  cultureId: string;
  id: string;
};

export const editOperation: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/operation/${request.operationId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
