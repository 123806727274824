import {
  Task,
  TaskStatuses,
} from '../../../modules/dashboard/modules/operations/stores/tasks.store';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {};

type TypeResponse = {};

export const getTask: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/task/${request.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
