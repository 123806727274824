import { forwardRef, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/order
import Datetime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';
import moment, { Moment } from 'moment';
import { Icon as FarmicIcon, Tooltip } from '@farmlink/farmik-ui';

import { Colors } from '../../constans/colors';

import {
  DateInput,
  Icon,
  Label,
  Wrapper,
  Placeholder,
  InformationIcon,
  LabelWrapper,
} from './style';
import { ReactComponent as CalendarSvg } from './icon.svg';

type CalendarProps = {
  label?: string;
  placeholder?: string;
  value: Moment;
  onChange: (v: Moment | string) => void;
  minDate?: Moment;
  maxDate?: Moment;
  dataTestId?: string;
  onOpen?: () => void;
  dateFormat?: string;
  blocked?: boolean;
  initialViewDate?: Moment;
  autoPlacement?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  id?: string;
  focusTarget?: string;
  targetId?: string;
  tooltip?: string;
};

const REQUIRED_SPACE = 280;

export const Calendar = forwardRef<HTMLInputElement, CalendarProps>(
  (
    {
      label,
      placeholder,
      value,
      onChange,
      maxDate,
      minDate,
      dataTestId,
      dateFormat,
      blocked = false,
      initialViewDate,
      autoPlacement,
      isRequired,
      isError,
      id,
      tooltip,
    },
    ref
  ) => {
    const calendarRef = useRef();
    const [isPlaceOnTop, setIsPlaceONnTop] = useState(false);

    useEffect(() => {
      // @ts-ignore
      if (calendarRef?.current) calendarRef.current.setViewDate(initialViewDate);
    }, [initialViewDate]);

    const renderInput = (updatedValue, props, openCalendar, closeCalendar) => {
      const handleClick = event => {
        if (autoPlacement) {
          setIsPlaceONnTop(
            Math.abs(window.innerHeight - event.target.getBoundingClientRect().bottom) <
              REQUIRED_SPACE
          );
        }

        if (!blocked) {
          openCalendar();
        }
      };

      return (
        <DateInput
          blocked={blocked}
          onClick={handleClick}
          data-test-id={dataTestId}
          className={'date-picker'}
          ref={ref}
          id={id}
          $isError={isError}
          data-current-date={moment(new Date(Number(value))).format('DD.MM.YYYY')}
        >
          {updatedValue ? (
            updatedValue.format(dateFormat || 'DD.MM.YYYY')
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )}
          <Icon $isError={isError}>
            {blocked ? (
              <div style={{ marginRight: '-13px' }}>
                <FarmicIcon fill={Colors.darkGray} icon={'minus'} size={'xsmall'} />
              </div>
            ) : (
              <CalendarSvg />
            )}{' '}
          </Icon>
        </DateInput>
      );
    };

    return (
      <Wrapper $topPlacement={isPlaceOnTop}>
        {label && (
          <LabelWrapper>
            <Label $isRequired={isRequired}>{label}</Label>
            {tooltip && (
              <>
                <Tooltip id={`tooltip-${id}`} getContent={() => tooltip} offset={{ left: 4 }}>
                  <InformationIcon />
                </Tooltip>
              </>
            )}
          </LabelWrapper>
        )}
        <Datetime
          ref={calendarRef}
          className={''}
          onChange={onChange}
          value={value}
          closeOnSelect={true}
          closeOnClickOutside={true}
          dateFormat={dateFormat || 'DD/MM/YYYY'}
          timeFormat={''}
          renderInput={renderInput.bind(this, value)}
          initialViewDate={initialViewDate}
          isValidDate={currentDate => {
            const momentDate = moment(currentDate);
            if (
              (minDate && momentDate.isBefore(minDate)) ||
              (maxDate && momentDate.isAfter(maxDate))
            ) {
              return false;
            }

            return true;
          }}
        />
      </Wrapper>
    );
  }
);
