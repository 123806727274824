import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
`;

export const ArrowLeft = styled.div`
  margin-right: 6px;
  height: 8px;
  margin-top: -17px;
`;

export const BurgerBlock = styled.div`
  svg {
    height: 14.5px;
    width: 16px;
  }
`;

export const ArrowRight = styled.div`
  margin-left: 6px;
  height: 8px;
  margin-top: -14px;
  svg {
    height: 8px;
    width: 4px;
    fill: ${Colors.darkGray};
  }
`;
