import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../shared/constans/colors';
import { ICustomColor } from '../../../../../../../../shared/constans/customColorsPrests';

import deleteSvg from './assets/images/delete-16x16.svg';

const PositionMark = styled.div<{ $colors: ICustomColor }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  color: ${({ $colors }) => $colors.color};
  background-color: ${({ $colors }) => $colors.backgroundColor};
  margin-right: 12px;
  text-align: center;
  align-items: center;
`;

const PositionText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;

const Name = styled.span`
  align-self: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex: 1 1 20%;
`;

const DescriptionWrapper = styled.div`
  flex: 1 1 40%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const ChecklistActionLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.green};
`;
const ChecklistStatus = styled.div<{ $isFilled: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ $isFilled }) => ($isFilled ? '#3FB65F' : Colors.black)};
  background: ${({ $isFilled }) => ($isFilled ? '#ECF8EF' : Colors.agateGray)};
  border-radius: 8px;
  padding: 2px 8px;
`;

const OperationWrapper = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`;

const DeleteButton = styled.div<{ $isDisabled?: boolean }>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  background: url(${deleteSvg});
`;

const Wrapper = styled.div`
  border-bottom: 0.5px solid #e9e6f0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
`;

const StyledChecklistInstance = {
  PositionMark,
  PositionText,
  Name,
  ChecklistActionLabel,
  ChecklistStatus,
  DescriptionWrapper,
  OperationWrapper,
  DeleteButton,
  Wrapper,
};

export default StyledChecklistInstance;
