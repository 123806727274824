import { makeAutoObservable } from 'mobx';

import {
  IChecklistAttributeFileValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeEnumValue,
  IGetChecklistAttributeUserDictionary,
  IPutChecklistAttributeValue,
} from '../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IGetChecklist } from '../../../../../api/models/checklist/checklist.model';
import {
  IDrawingNestedInstance,
  IGetChecklistInstance,
  IGetChecklistInstanceByTaskId,
  IInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import { Field } from '../../../../../api/models/field.model';
import { IGetIntensity } from '../../../../../api/models/intensity/intensity.model';
import { ISelectOption } from '../../../../../types/selectOption';
import { provide } from '../../../../shared/utils/IoC';
import {
  IChecklistDrawingStage,
  IGetChecklistStage,
} from '../../../../../api/models/checklist/stage/checklist.stage.model';
import { IGetDictionary } from '../../../../../api/models/dictionary/dictionary.model';

export enum EChecklistMode {
  View = 'VIEW',
  Edit = 'EDIT',
}

@provide.singleton()
export class ChecklistInstancesStore {
  /** TODO: описать комментариями принадлежность ниже значений
   * (или вынести в контроллер подключив соответствующий стор) */
  hasPositionToInstanceChanged = false;
  taskId: string;
  field: Field | null = null;
  deleteImmediately = false;

  // imgsArr: Array<any> = [];

  checklistAttributeId: string;

  // Показывает какой сейчас режим чек-листа (просмотр или редактирование)
  private _checklistMode: EChecklistMode | null = null;

  private _focusTargetId = '';

  private _isFocused = false;

  // Ниже располагаются выбранные значения чек-листа

  private _selectedInstance: IGetChecklistInstance | null = null;

  private _selectedInstId: string | null = null;

  private _selectedIntensity: IGetIntensity | null = null;

  private _selectedChecklist: IGetChecklist | null = null;

  /**
   * Показывает какая запись в этот момент редактируется или заполняется
   * Требуется для отслеживания незаполненных атрибутов/записей при сохранении
   */
  private _editableDrawingNestedInstance: IDrawingNestedInstance | null = null;

  /**
   * Показывает состояние редактируемой записи до всех изменений с ней
   */
  private _previousStateOfEditableDrawingNestedInstance: IDrawingNestedInstance | null = null;

  /**
   * Отслеживает результат выбора в модальном окне фенофаз. Необходим для того, чтобы
   * понимать, когда выбирать фенофазу, а когда нет.
   */
  private _intensityModalResult: boolean | null = null;

  // Требуется для контроля за несохраненными данными пользователя
  private _idOfUnsavedAttr: string | null = null;

  // Ниже располагаются коллекции данных

  private _idToChecklist = new Map<string, IGetChecklist>();

  private _idToExtendedInstance = new Map<string, IGetChecklistInstance>();

  private _checklistIdToStageList = new Map<string, IGetChecklistStage[]>();

  private _checklistIdToAttributeList = new Map<string, IGetChecklistAttribute[]>();

  /** TODO: сделать данную коллекцию приватной и отказаться от логики,
   *  что изменяет её напрямую (используйте соответствующий сеттер) */
  positionToInstance = new Map<number, Partial<IInstance>>();

  private _idToIntensity = new Map<string, IGetIntensity>();

  // [ИСПОЛЬЗУЕТСЯ ДЛЯ ОТРИСОВКИ] Хранит stages, что непосредственно отображаются на странице
  private _idToDrawingStage = new Map<string, IChecklistDrawingStage>();

  private _idToDrawingNestedInstance: Map<string, IDrawingNestedInstance> = new Map<
    string,
    IDrawingNestedInstance
  >();

  // Коллекция из записей, которые в данный момент редактируются
  private _attrIdToEditNestedInst: Map<string, IDrawingNestedInstance> = new Map<
    string,
    IDrawingNestedInstance
  >();

  private _attrIdToTemplateNestedInstance = new Map<string, IDrawingNestedInstance>();

  private _attributeIdToEnumList = new Map<string, IGetChecklistAttributeEnumValue[]>();

  private _attrIdToFileList = new Map<string, IChecklistAttributeFileValue[]>();

  private _attributeIdToUserDictionaryList = new Map<
    string,
    IGetChecklistAttributeUserDictionary[]
  >();

  private _attrIdToDictionaryList = new Map<string, IGetDictionary[]>();

  private _idToTechnique: Map<string, IGetChecklistInstanceByTaskId> = new Map<
    string,
    IGetChecklistInstanceByTaskId
  >();

  private _depAttrIdToValue: Map<string, IPutChecklistAttributeValue> = new Map<
    string,
    IPutChecklistAttributeValue
  >();

  constructor() {
    makeAutoObservable(this);
  }

  // Геттеры

  get checklistMode() {
    return this._checklistMode;
  }

  get isChecklistViewMode(): boolean {
    return this._checklistMode === EChecklistMode.View;
  }

  get isChecklistEditMode(): boolean {
    return this._checklistMode === EChecklistMode.Edit;
  }

  get focusTargetId() {
    return this._focusTargetId;
  }

  get isFocused() {
    return this._isFocused;
  }

  get selectedInstance() {
    return this._selectedInstance;
  }

  get selectedIntensity() {
    return this._selectedIntensity;
  }

  get selectedChecklist() {
    return this._selectedChecklist;
  }

  get editableDrawingNestedInstance() {
    return this._editableDrawingNestedInstance;
  }

  get previousStateOfEditableDrawingNestedInstance() {
    return this._previousStateOfEditableDrawingNestedInstance;
  }

  get intensityModalResult() {
    return this._intensityModalResult;
  }

  get checklistList(): IGetChecklist[] {
    return Array.from(this._idToChecklist.values());
  }

  getChecklist = (id: string): IGetChecklist => {
    return this._idToChecklist.get(id);
  };

  get extendedInstanceList(): IGetChecklistInstance[] {
    return Array.from(this._idToExtendedInstance.values());
  }

  getExtendedInstance = (id: string): IGetChecklistInstance => {
    return this._idToExtendedInstance.get(id);
  };

  get intensityList(): IGetIntensity[] {
    return Array.from(this._idToIntensity.values());
  }

  get instanceList(): Partial<IInstance>[] {
    return Array.from(this.positionToInstance.values());
  }

  getStageList = (checklistId: string): IGetChecklistStage[] => {
    return this._checklistIdToStageList.get(checklistId);
  };

  getAttributeList = (checklistId: string): IGetChecklistAttribute[] => {
    return this._checklistIdToAttributeList.get(checklistId);
  };

  get drawingStageList(): IChecklistDrawingStage[] {
    return Array.from(this._idToDrawingStage.values());
  }

  getDrawingStage = (id: string): IChecklistDrawingStage => this._idToDrawingStage.get(id);

  get drawingNestedInstanceList(): IDrawingNestedInstance[] {
    return Array.from(this._idToDrawingNestedInstance.values());
  }

  getDrawingNestedInstance = (instanceId: string): IDrawingNestedInstance => {
    return this._idToDrawingNestedInstance.get(instanceId);
  };

  get listOfEditNestedInst(): IDrawingNestedInstance[] {
    return Array.from(this._attrIdToEditNestedInst.values());
  }

  getEditNestedInst = (attrId: string): IDrawingNestedInstance => {
    return this._attrIdToEditNestedInst.get(attrId);
  };

  getTempNestedInst = (attrId: string): IDrawingNestedInstance => {
    return this._attrIdToTemplateNestedInstance.get(attrId);
  };

  get templateNestedInstanceList(): IDrawingNestedInstance[] {
    return Array.from(this._attrIdToTemplateNestedInstance.values());
  }

  get idOfUnsavedAttr() {
    return this._idOfUnsavedAttr;
  }

  getInstanceByPosition = (position: number): Partial<IInstance> =>
    this.positionToInstance.get(position);

  get enumList(): IGetChecklistAttributeEnumValue[] {
    const _enumList: IGetChecklistAttributeEnumValue[] = [];

    Array.from(this._attributeIdToEnumList.values()).forEach(valueList =>
      _enumList.push(...valueList)
    );

    return _enumList;
  }

  get userDictionaryList(): IGetChecklistAttributeUserDictionary[] {
    const _userDictionaryList: IGetChecklistAttributeUserDictionary[] = [];

    Array.from(this._attributeIdToUserDictionaryList.values()).forEach(valueList =>
      _userDictionaryList.push(...valueList)
    );

    return _userDictionaryList;
  }

  get dictionaryList(): IGetDictionary[] {
    const _dictionaryList: IGetDictionary[] = [];

    Array.from(this._attrIdToDictionaryList.values()).forEach(valueList =>
      _dictionaryList.push(...valueList)
    );

    return _dictionaryList;
  }

  getAttributeEnumList = (attributeId: string): IGetChecklistAttributeEnumValue[] => {
    return this._attributeIdToEnumList.get(attributeId);
  };

  getFileListByAttrId = (attrId: string): IChecklistAttributeFileValue[] => {
    return this._attrIdToFileList.get(attrId) || [];
  };

  getUserDictionaryList = (attributeId: string): ISelectOption[] => {
    return (
      this._attributeIdToUserDictionaryList
        .get(attributeId)
        ?.map<ISelectOption>(({ id, value }) => ({ label: value, value: id })) || []
    );
  };

  getDictionaryOptionListByAttrId = (attributeId: string): ISelectOption[] => {
    return this._attrIdToDictionaryList
      .get(attributeId)
      ?.map<ISelectOption>(({ id, name }) => ({ label: name, value: id }));
  };

  get techniqueList(): IGetChecklistInstanceByTaskId[] {
    return Array.from(this._idToTechnique.values());
  }

  getTechnique = (id: string): IGetChecklistInstanceByTaskId => {
    return this._idToTechnique.get(id);
  };

  get selectedInstId() {
    return this._selectedInstId;
  }

  get listOfDepAttrValue(): IPutChecklistAttributeValue[] {
    return Array.from(this._depAttrIdToValue.values());
  }

  getDepAttrValue = (attrId: string): IPutChecklistAttributeValue => {
    return this._depAttrIdToValue.get(attrId);
  };

  // Сеттеры

  setField = (field: Field | null): void => {
    this.field = field;
  };

  setChecklistMode = (mode: EChecklistMode): void => {
    this._checklistMode = mode;
  };

  setFocusTargetId = (id: string): void => {
    this._focusTargetId = id;
  };

  setIsFocused = (value: boolean): void => {
    this._isFocused = value;
  };

  setSelectedIntensity = (intensity: IGetIntensity): void => {
    this._selectedIntensity = intensity;
  };

  setSelectedInstance = (instance: IGetChecklistInstance): void => {
    this._selectedInstance = instance;
  };

  setSelectedChecklist = (checklist: IGetChecklist): void => {
    this._selectedChecklist = checklist;
  };

  setEditableDrawingNestedInstance = (drawingNestedInstance: IDrawingNestedInstance): void => {
    this._editableDrawingNestedInstance = drawingNestedInstance;
  };

  setPreviousStateOfEditableDrawingNestedInstance = (
    drawingNestedInstance: IDrawingNestedInstance
  ): void => {
    this._previousStateOfEditableDrawingNestedInstance = drawingNestedInstance;
  };

  setIntensityModalResult = (result: boolean): void => {
    this._intensityModalResult = result;
  };

  setChecklist = (checklist: IGetChecklist): void => {
    this._idToChecklist.set(checklist.id, checklist);
  };

  setExtendedInstance = (instance: IGetChecklistInstance): void => {
    this._idToExtendedInstance.set(instance.id, instance);
  };

  setInstance = (position: number, instance: Partial<IInstance>): void => {
    this.positionToInstance.set(position, instance);
    this.setHasPositionToInstanceChanged(true);
  };

  setIdToIntensity = (id: string, intensity: IGetIntensity): void => {
    this._idToIntensity.set(id, intensity);
  };

  setStageList = (checklistId: string, stageList: IGetChecklistStage[]): void => {
    this._checklistIdToStageList.set(checklistId, stageList);
  };

  setAttributeList = (checklistId: string, attributeList: IGetChecklistAttribute[]): void => {
    this._checklistIdToAttributeList.set(checklistId, attributeList);
  };

  setIdToDrawingStage = (idToDrawingStage: Map<string, IChecklistDrawingStage>): void => {
    this._idToDrawingStage = idToDrawingStage;
  };

  setDrawingStage = (id: string, drawingStage: IChecklistDrawingStage): void => {
    this._idToDrawingStage.set(id, drawingStage);
  };

  setDrawingNestedInstance = (drawingNestedInstance: IDrawingNestedInstance): void => {
    this._idToDrawingNestedInstance.set(drawingNestedInstance.id, drawingNestedInstance);
  };

  setDrawingNestedInstanceList = (drawingNestedInstanceList: IDrawingNestedInstance[]): void => {
    if (drawingNestedInstanceList?.length) {
      const idToDrawingNestedInstanceValueList: [string, IDrawingNestedInstance][] = Array.from(
        this._idToDrawingNestedInstance.entries()
      );

      drawingNestedInstanceList.forEach(drawingNestedInstance => {
        idToDrawingNestedInstanceValueList.push([drawingNestedInstance.id, drawingNestedInstance]);
      });

      this._idToDrawingNestedInstance = new Map<string, IDrawingNestedInstance>(
        idToDrawingNestedInstanceValueList
      );
    }
  };

  setEditNestedInst = (inst: IDrawingNestedInstance): void => {
    this._attrIdToEditNestedInst.set(inst.rootChecklistAttribute.id, inst);
  };

  setTemplateNestedInstance = (templateNestedInstance: IDrawingNestedInstance): void => {
    this._attrIdToTemplateNestedInstance.set(
      templateNestedInstance.rootChecklistAttribute.id,
      templateNestedInstance
    );
  };

  deleteTemplateNestedInstance = (attributeId: string): void => {
    this._attrIdToTemplateNestedInstance.delete(attributeId);
  };

  setIdOfUnsavedAttr = (attrId: string): void => {
    this._idOfUnsavedAttr = attrId;
  };

  setEnumList = (attributeId: string, enumList: IGetChecklistAttributeEnumValue[]): void => {
    this._attributeIdToEnumList.set(attributeId, enumList);
  };

  setHasPositionToInstanceChanged = (value: boolean) => {
    this.hasPositionToInstanceChanged = value;
  };

  setFileListByAttrId = (attrId: string, value: IChecklistAttributeFileValue[]): void => {
    this._attrIdToFileList.set(attrId, value);
  };

  setAttributeIdToUserDictionaryList = (
    attributeId: string,
    userDictionaryList: IGetChecklistAttributeUserDictionary[]
  ): void => {
    this._attributeIdToUserDictionaryList.set(attributeId, userDictionaryList);
  };

  setNewUserDictionaryValue = (
    attributeId: string,
    userDictionaryValue: IGetChecklistAttributeUserDictionary
  ): void => {
    const userDictionaryList = this._attributeIdToUserDictionaryList.get(attributeId);

    if (userDictionaryList) {
      userDictionaryList.push(userDictionaryValue);

      this._attributeIdToUserDictionaryList.set(attributeId, userDictionaryList);
    }
  };

  deleteDrawingNestedInstance = (instanceId: string): void => {
    this._idToDrawingNestedInstance.delete(instanceId);
  };

  setChecklistAttributeId = (value: string) => {
    this.checklistAttributeId = value;
  };

  setDictionaryList = (attributeId: string, dictionaryList: IGetDictionary[]): void => {
    this._attrIdToDictionaryList.set(attributeId, dictionaryList);
  };

  setTechnique = (technique: IGetChecklistInstanceByTaskId): void => {
    this._idToTechnique.set(technique.id, technique);
  };

  setSelectedInstId = (id: string): void => {
    this._selectedInstId = id;
  };

  setDepAttrValue = (value: IPutChecklistAttributeValue): void => {
    this._depAttrIdToValue.set(value.checkListAttributeId, value);
  };

  // Ниже методы для приведения значений в сторе к дефолтному состоянию

  clearChecklistMode = (): void => {
    this._checklistMode = null;
  };

  clearFocusTargetId = (): void => {
    this._focusTargetId = '';
  };

  clearIsFocused = (): void => {
    this._isFocused = false;
  };

  clearField = (): void => {
    this.field = null;
  };

  clearSelectedInstance = (): void => {
    this._selectedInstance = null;
  };

  clearSelectedIntensity = (): void => {
    this._selectedIntensity = null;
  };

  clearSelectedChecklist = (): void => {
    this._selectedChecklist = null;
  };

  clearEditableDrawingNestedInstance = (): void => {
    this._editableDrawingNestedInstance = null;
  };

  clearPreviousStateOfEditableDrawingNestedInstance = (): void => {
    this._previousStateOfEditableDrawingNestedInstance = null;
  };

  clearIntensityModalResult = (): void => {
    this._intensityModalResult = null;
  };

  clearIdToChecklist = (): void => {
    this._idToChecklist.clear();
  };

  clearIdToExtendedInstance = (): void => {
    this._idToExtendedInstance.clear();
  };

  clearIdToIntensity = (): void => {
    this._idToIntensity.clear();
  };

  clearChecklistIdToAttributeList = (): void => {
    this._checklistIdToAttributeList.clear();
  };

  clearIdOfUnsavedAttr = (): void => {
    this._idOfUnsavedAttr = null;
  };

  clearPositionToInstance = (): void => {
    this.positionToInstance.clear();
  };

  clearAttributeIdToEnumList = (): void => {
    this._attributeIdToEnumList.clear();
  };

  clearChecklistIdToStageList = (): void => {
    this._checklistIdToStageList.clear();
  };

  clearIdToDrawingStage = (): void => {
    this._idToDrawingStage.clear();
  };

  clearIdToDrawingNestedInstance = (): void => {
    this._idToDrawingNestedInstance.clear();
  };

  clearAttrIdToEditNestedInst = (): void => {
    this._attrIdToEditNestedInst.clear();
  };

  delEditNestedInst = (attrId: string): void => {
    this._attrIdToEditNestedInst.delete(attrId);
  };

  clearAttrIdToTemplateNestedInstance = (): void => {
    this._attrIdToTemplateNestedInstance.clear();
  };

  clearAttrIdToFileList = () => {
    this._attrIdToFileList.clear();
  };

  clearAttributeIdToUserDictionaryList = (): void => {
    this._attributeIdToUserDictionaryList.clear();
  };

  clearAttrIdToDictionaryList = (): void => {
    this._attrIdToDictionaryList.clear();
  };

  clearIdToTechnique = (): void => {
    this._idToTechnique.clear();
  };

  delTechnique = (id: string): void => {
    this._idToTechnique.delete(id);
  };

  clearSelectedInstId = (): void => {
    this._selectedInstId = null;
  };

  clearDepAttrIdToValue = (): void => {
    this._depAttrIdToValue.clear();
  };

  clearStore = (): void => {
    this.clearChecklistMode();

    this.clearFocusTargetId();
    this.clearIsFocused();
    this.clearIdOfUnsavedAttr();

    this.clearSelectedChecklist();
    this.clearSelectedInstance();
    this.clearSelectedIntensity();

    this.clearSelectedInstId();

    this.clearEditableDrawingNestedInstance();
    this.clearPreviousStateOfEditableDrawingNestedInstance();

    this.clearIntensityModalResult();

    this.clearIdToChecklist();
    this.clearIdToExtendedInstance();
    this.clearChecklistIdToStageList();
    this.clearChecklistIdToAttributeList();
    this.clearIdToIntensity();
    this.clearAttrIdToEditNestedInst();

    this.clearAttributeIdToEnumList();
    this.clearAttributeIdToUserDictionaryList();
    this.clearAttrIdToDictionaryList();
    this.clearAttrIdToFileList();
    this.clearDepAttrIdToValue();

    this.clearIdToDrawingStage();
    this.clearIdToDrawingNestedInstance();
    this.clearAttrIdToTemplateNestedInstance();
  };

  clearTemporaryPositionToInstance = (): void => {
    const namesForRemove = [];
    this.positionToInstance.forEach(value => {
      if (value.onlyFrontend) {
        namesForRemove.push(value.name);
      }
    });
    /**
     * Использование имени как ключ для удаления.
     * TODO: переделать при изменении формата хранения инстанса
     */
    namesForRemove.forEach(name => this.positionToInstance.delete(Number(name)));
  };

  clearStrangeProps = (): void => {
    this.checklistAttributeId = '';
    this.clearAttrIdToFileList();
  };
}
