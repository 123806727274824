import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon, Select } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment from 'moment';
import { isNumber } from 'lodash';

import { useStore } from '../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { FieldSeasonsStore } from '../../../../stores/field.seasons.store';
import { UiStore } from '../../../../stores/ui.store';
import { PopupPages } from '../../../../constants/popup.pages';
import MapStore from '../../../../../map/stores/map.store';
import { ModalActionsFooter } from '../../../../../../components/Modal/style';
import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../AccessRulesWrapper/ControlAccessRulesWrapper';
import { FieldsStore } from '../../../../modules/fields/stores/fields.store';
import { SeasonsController } from '../../../../controllers/seasons/seasons.controller';
import { FieldsErrors } from '../../../../modules/fields/constants/fields.errors';
import { FieldsController } from '../../../../modules/fields/controllers/fields.controller';

import {
  AddCulture,
  ArrowButton,
  ArrowsWrapper,
  BackButton,
  BackButtonIcon,
  BackButtonText,
  EmptyCultureLink,
  EmptyCultureTitle,
  EmptyCultureWrapper,
  Header,
  SeasonCultureItem,
  SeasonCultureItemColor,
  SeasonCultureItemSubTitle,
  SeasonCultureItemTitle,
  SeasonItem,
  SeasonItemBody,
  SeasonItemHeader,
  SeasonItemHeaderSubTitle,
  SeasonItemHeaderTitle,
  SeasonsComponentWrapper,
  SeasonsWrapper,
  Title,
  SeasonsModalTitle,
  SeasonsModalBody,
  SeasonCultureItemColorWrapper,
  SeasonCultureItemDescriptionWrapper,
} from './style';
import { ReactComponent as AddCultureIcon } from './assets/add_culture.svg';
import { ReactComponent as BackSVG } from './assets/back.svg';

export const Seasons = observer(() => {
  const scroll = useRef<HTMLDivElement>();
  const seasonElementWidth = 312;

  const seasons = useStore(SeasonsStore);
  const sasonsController = useStore(SeasonsController);
  const fieldsAndSeasons = useStore(FieldSeasonsStore);
  const fieldsStore = useStore(FieldsStore);
  const ui = useStore(UiStore);
  const map = useStore(MapStore);
  const fieldsController = useStore(FieldsController);

  const [copyToSessonModalActive, setCopyToSessonModalActive] = useState<number>(null);
  const [isCopyToSessonAlertOpen, setIsCopyToSessonAlertOpen] = useState<boolean>(false);
  const [currentSeasonYear, setCurrentSeasonYear] = useState<string>('');
  const [hasScroll, setHasScroll] = useState<boolean>();
  const [isIntersectionModalOpen, setIsIntersectionModalOpen] = useState(false);
  const [scrollPos, setScrollPos] = useState<number>(0);

  const {
    clear,
    fields,
    fetchSeasons,
    isLoading,
    yearsToFieldIdToCopy,
    selectedSeasonYearToCopy,
    selectSeasonYearToCopy,
    copyFieldFromSeason,
  } = fieldsAndSeasons;

  const seasonsOnScreen = Math.round(
    (ui.pageWidth - (ui.isSidebarShort ? 62 : 196) - 284) / seasonElementWidth
  );

  const scrollHandle = useCallback(
    (direction: 'left' | 'right') => {
      const offset = scroll.current.scrollWidth - scroll.current.offsetWidth;

      if (direction === 'left') {
        setScrollPos(scrollPos - seasonElementWidth < 0 ? 0 : scrollPos - seasonElementWidth);
      } else if (scrollPos < offset) {
        setScrollPos(scrollPos + seasonElementWidth);
      }
    },
    [scrollPos]
  );

  // @ts-ignore
  useEffect(async () => {
    clear();
    if (ui.popupPageState === PopupPages.Seasons && fieldsStore.selectedFieldId) {
      map.clearAll();
      setCurrentSeasonYear(seasons.selectedSeason);
      await fetchSeasons();

      if (scroll.current.scrollWidth > scroll.current.clientWidth) setHasScroll(true);
      else setHasScroll(false);

      scrollToActive(true);
    }
    return () => {
      clear();
    };
  }, [fieldsStore.selectedFieldId]);

  useEffect(() => {
    if (copyToSessonModalActive) {
      fetchSeasons(false);
      scrollToActive();
    }
  }, [copyToSessonModalActive]);

  useEffect(() => {
    scroll.current.scrollTo(scrollPos, 0);
  }, [scrollPos]);

  const scrollToSeason = useCallback((seasonIndex: number, seasonsLength?: number) => {
    let selectedSeason = seasonIndex;
    if (seasonsLength && seasonIndex > seasonsLength - 2) {
      selectedSeason = seasonIndex - (seasonsOnScreen > 4 ? 3 : 2);
    }
    setScrollPos(selectedSeason * seasonElementWidth);
  }, []);

  const scrollToActive = (centered?: boolean) => {
    const seasonsElements = scroll.current.children;
    const activeSeason = scroll.current.getElementsByClassName('active');
    const activeSeasonsElements = Array.from(seasonsElements);
    let activeSeasonIndex = activeSeasonsElements.findIndex(item => item === activeSeason[0]);

    if (!activeSeason[0]) {
      return;
    }

    if (centered) {
      activeSeasonIndex -= seasonsOnScreen > 4 ? 2 : 1;
    }

    scrollToSeason(activeSeasonIndex, activeSeasonsElements.length);
  };

  useEffect(() => {
    if (!currentSeasonYear || isLoading) {
      return;
    }

    map.clearAll();
    setTimeout(() => {
      fieldsAndSeasons.renderCultureZoneByYear(currentSeasonYear);
    }, 50);
  }, [currentSeasonYear, isLoading]);

  const handleChangeToCultureZone = (v: number) => {
    sasonsController.setSelectedSeason(v.toString());
    fieldsController.refreshFieldsList(() => {
      map.clearAll();
      ui.setPageState(PopupPages.CultureZone);
    });
  };

  const handleBack = () => {
    ui.setPageState(PopupPages.Main);
    const field = [...fieldsStore.idToFields.values()].find(
      item => item.id === fieldsStore.selectedFieldId
    );
    fieldsController.closeFieldTooltip(field.polyId);
    map.highlightPolygon(field.polyId);
  };

  const handleCopyFieldFromSeason = async () => {
    try {
      await copyFieldFromSeason(copyToSessonModalActive);

      setCurrentSeasonYear(String(copyToSessonModalActive));
      setTimeout(() => scrollToActive(true), 500);
      setCopyToSessonModalActive(null);
      setIsCopyToSessonAlertOpen(true);
      fetchSeasons();
      selectSeasonYearToCopy(null);
    } catch (error) {
      if (
        error.message === FieldsErrors.Intersection ||
        error.message === FieldsErrors.CrossingGeometry ||
        error.error === FieldsErrors.CrossingGeometry
      ) {
        setCopyToSessonModalActive(null);
        setIsIntersectionModalOpen(true);
      }
    }
  };

  return (
    <SeasonsComponentWrapper
      isSidebarShort={ui.isSidebarShort}
      data-test-id={'seasons-list-wrapper'}
    >
      <Header>
        <BackButton onClick={handleBack} data-test-id="back-to-field-weather">
          <BackButtonIcon data-test-id="back-to-field-weather-icon">
            <BackSVG />
          </BackButtonIcon>
          <BackButtonText data-test-id="back-to-field-weather-word">Назад</BackButtonText>
        </BackButton>
        <Title data-test-id="seasons-history-crops">История посевов</Title>
        {hasScroll ? (
          <ArrowsWrapper>
            <ArrowButton onClick={() => scrollHandle('left')} data-test-id="scroll-left-seasons">
              <Icon icon={'arrow_left'} size={'small'} />
            </ArrowButton>
            <ArrowButton onClick={() => scrollHandle('right')} data-test-id="scroll-right-seasons">
              <Icon icon={'arrow_right'} size={'small'} />
            </ArrowButton>
          </ArrowsWrapper>
        ) : (
          <div />
        )}
      </Header>
      <SeasonsWrapper ref={scroll} data-test-id="seasons-wrapper">
        {fields.map(obj => (
          <SeasonItem
            className={currentSeasonYear === `${obj.season.year}` ? 'active' : ''}
            active={currentSeasonYear === `${obj.season.year}`}
            onClick={() => obj.fieldVersion && setCurrentSeasonYear(`${obj.season.year}`)}
            data-test-id="season-item"
          >
            <SeasonItemHeader data-test-id="seasons-item-header">
              <SeasonItemHeaderTitle data-test-id="seasons-item-header-years-range">
                {obj.season.year - 1}-{obj.season.year} гг
              </SeasonItemHeaderTitle>
              <SeasonItemHeaderSubTitle data-test-id="seasons-item-header-years-range-sub">
                {moment(obj.season.startDate).format('DD MMMM')} -{' '}
                {moment(obj.season.endDate).format('DD MMMM')}
              </SeasonItemHeaderSubTitle>
              <ControlAccessRulesWrapper
                mode={AccessRuleVisibility.Hide}
                actionName="field.editCultureZone"
              >
                {obj.fieldVersion && (
                  <ControlAccessRulesWrapper
                    mode={AccessRuleVisibility.Hide}
                    actionName="field.editCultureZone"
                  >
                    <AddCulture data-test-id="seasons-item-header-add-culture">
                      <AddCultureIcon
                        onClick={() => handleChangeToCultureZone(obj.season.year)}
                        data-test-id="seasons-item-header-add-culture-icon"
                      />
                    </AddCulture>
                  </ControlAccessRulesWrapper>
                )}
              </ControlAccessRulesWrapper>
            </SeasonItemHeader>
            <SeasonItemBody data-test-id="seasons-item-body">
              {obj.fieldVersion ? (
                !Boolean(obj.fieldVersion.cultureZones) ||
                obj.fieldVersion.cultureZones.length === 0 ? (
                  <EmptyCultureWrapper data-test-id="seasons-item-no-culture">
                    <EmptyCultureTitle data-test-id="seasons-item-no-culture-title">
                      В данном сезоне культуры
                      <br />
                      еще не выбраны
                    </EmptyCultureTitle>
                    <ControlAccessRulesWrapper
                      mode={AccessRuleVisibility.Hide}
                      actionName="field.editCultureZone"
                    >
                      <EmptyCultureLink
                        onClick={() => handleChangeToCultureZone(obj.season.year)}
                        data-test-id="specify-the-culture"
                      >
                        Указать культуры
                      </EmptyCultureLink>
                    </ControlAccessRulesWrapper>
                  </EmptyCultureWrapper>
                ) : (
                  obj.fieldVersion.cultureZones.map((zone, index) => {
                    return (
                      <SeasonCultureItem data-test-id="seasons-culture-item">
                        <SeasonCultureItemColorWrapper data-test-id="seasons-culture-item-color-wrapper">
                          <SeasonCultureItemColor
                            data-test-id="seasons-culture-item-color"
                            color={zone?.culture?.attrs?.assistanceColorLegend || '#fff'}
                          />
                        </SeasonCultureItemColorWrapper>
                        <SeasonCultureItemDescriptionWrapper data-test-id="seasons-culture-item-description">
                          <SeasonCultureItemTitle data-test-id="seasons-culture-item-description-title">
                            {zone?.culture?.name || ''}
                          </SeasonCultureItemTitle>
                          <SeasonCultureItemSubTitle data-test-id="seasons-culture-item-description-title-sub">
                            {zone?.variety || ''}
                          </SeasonCultureItemSubTitle>
                          <SeasonCultureItemTitle data-test-id="seasons-culture-item-harvest-date">
                            {moment(zone?.sowingDate).format('DD.MM.YYYY')} –{' '}
                            {moment(zone?.harvestDate).format('DD.MM.YYYY')}{' '}
                          </SeasonCultureItemTitle>
                        </SeasonCultureItemDescriptionWrapper>
                      </SeasonCultureItem>
                    );
                  })
                )
              ) : (
                <EmptyCultureWrapper data-test-id="seasons-empty-culture">
                  <EmptyCultureTitle gap={14} data-test-id={'no-field-in-season'}>
                    В этом сезоне нет поля
                  </EmptyCultureTitle>
                  <ControlAccessRulesWrapper
                    mode={AccessRuleVisibility.Hide}
                    actionName="field.editSeason"
                  >
                    <ControlAccessRulesWrapper
                      mode={AccessRuleVisibility.Hide}
                      actionName="field.createFieldOrSeason"
                    >
                      <EmptyCultureLink
                        onClick={() => setCopyToSessonModalActive(obj.season.year)}
                        data-test-id="copy-the-field"
                      >
                        Скопировать поле
                      </EmptyCultureLink>
                    </ControlAccessRulesWrapper>
                  </ControlAccessRulesWrapper>
                </EmptyCultureWrapper>
              )}
            </SeasonItemBody>
          </SeasonItem>
        ))}
      </SeasonsWrapper>

      {copyToSessonModalActive && (
        <ModalComponent
          onClose={() => setCopyToSessonModalActive(null)}
          paddings="seasons"
          data-test-id="seasons-modal"
        >
          <SeasonsModalTitle data-test-id="seasons-modal-title">Копирование поля</SeasonsModalTitle>
          <SeasonsModalBody data-test-id="seasons-modal-body">
            <Select
              onChange={value => selectSeasonYearToCopy(Number(value))}
              options={Array.from(yearsToFieldIdToCopy.keys()).map(item => ({
                value: item.toString(),
                label: yearsToFieldIdToCopy.get(item).season.label,
              }))}
              placeholder={'Сезон'}
              label={'Выберите сезон для добавления'}
              dataTestId={'season-stub-select'}
            />
          </SeasonsModalBody>
          <ModalActionsFooter data-test-id="seasons-modal-footer">
            <Button
              alignment="center"
              color="default"
              type="button"
              onClick={() => {
                selectSeasonYearToCopy(null);
                setCopyToSessonModalActive(null);
              }}
              data-test-id="seasons-modal-refuse-btn"
            >
              Отменить
            </Button>
            <Button
              alignment="center"
              color="primary"
              type="button"
              disabled={!isNumber(selectedSeasonYearToCopy)}
              onClick={handleCopyFieldFromSeason}
              data-test-id="seasons-modal-copy-field-btn"
            >
              Сохранить
            </Button>
          </ModalActionsFooter>
        </ModalComponent>
      )}

      {isCopyToSessonAlertOpen && (
        <DialogModal
          status={'success'}
          title={'Поле успешно скопировано'}
          accept={{
            name: 'Продолжить',
            handler: () => {
              setIsCopyToSessonAlertOpen(false);
            },
          }}
          data-test-id="seasons-modal-copy-field-success"
        />
      )}

      {isIntersectionModalOpen && (
        <DialogModal
          status={'warning'}
          title={'Границы полей не могут пересекаться. Измените границы для сохранения изменений!'}
          accept={{
            name: 'Вернуться к истории посевов',
            handler: () => setIsIntersectionModalOpen(false),
          }}
          onClose={() => setIsIntersectionModalOpen(false)}
          data-test-id={'fields-edit-modal-intersecting-areas'}
        />
      )}
    </SeasonsComponentWrapper>
  );
});
