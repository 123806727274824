import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { OrgType, TypeOrganization } from '../../../../../../../api/models/organization.model';
import { Axios, TypeApiResponse } from '../../../../../../shared/utils/axios2';
import { getOrgErrors } from '../orgErrors';

const defaultErrorsState = {
  name: '',
  orgTypeId: '',
  addressJur: '',
  INN: '',
  OGRN: '',
  KPP: '',
  OKVED: '',
  OKPO: '',
  formError: '',
};

@provide.singleton()
export class CreateOrganizationFormStore {
  @lazyInject(Axios)
  protected axios2: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  name = '';

  setName = (value: string, code: number) => {
    this.name = value;

    console.log(this.orgTypeId, this.INN);
    if (code !== this.orgTypeId) {
      this.INN = '';
    }
    this.orgTypeId = code;
  };

  INN: string | null = '';

  setINN = (value: string | null) => {
    const maxLength = this.orgTypeId === 4 ? 13 : 11;

    if (!value) {
      this.INN = null;
    } else if (value.length < maxLength && !Number.isNaN(Number(value))) {
      this.INN = value;
    }
  };

  OGRN: string | null = '';

  setOGRN = (value: string | null) => {
    if (!value) {
      this.OGRN = null;
    } else if (value.length < 14 && !Number.isNaN(Number(value))) {
      this.OGRN = value;
    }
  };

  KPP: string | null = '';

  setKPP = (value: string | null) => {
    if (!value) {
      this.KPP = null;
    } else if (value.length < 10 && !Number.isNaN(Number(value))) {
      this.KPP = value;
    }
  };

  OKPO: string | null = '';

  setOKPO = (value: string | null) => {
    if (!value) {
      this.OKPO = null;
    } else if (value.length < 9 && !Number.isNaN(Number(value))) {
      this.OKPO = value;
    }
  };

  OKVED: string | null = '';

  setOKVED = (value: string | null) => {
    if (!value) {
      this.OKVED = null;
    } else if (value.length < 7) {
      this.OKVED = value;
    }
  };

  addressFact = '';

  setAddressFact = (value: string) => {
    this.addressFact = value;
  };

  addressJur = '';

  setAddressJur = (value: string) => {
    this.addressJur = value;
  };

  orgTypes: OrgType[] = [];

  orgTypeId = 0;

  setOrgTypeId = (value: number) => {
    this.orgTypeId = value;
  };

  createOrganization = async () => {
    this.errors = defaultErrorsState;
    const isValid = this.validateFormData();
    console.log('invalid');
    if (isValid) {
      console.log('valied');
      try {
        const resp = await this.axios2.api.createOrganization({
          name: this.name.trim(),
          orgTypeId: this.orgTypeId || undefined,
          addressFact: {
            addressfull: this.addressFact || this.addressJur,
            index: '1231221',
          },
          addressJur: {
            addressfull: this.addressJur,
            index: '1231221',
          },
          INN: this.INN || undefined,
          OGRN: this.OGRN || undefined,
          KPP: this.KPP || undefined,
          OKPO: this.OKPO || undefined,
          OKVED: this.OKVED || undefined,
        });

        // @ts-ignore
        if (resp.error) {
          return Promise.reject(resp);
        }
        return resp;
      } catch (r) {
        return Promise.reject(r);
      }
    }

    return Promise.reject(new Error('validation error'));
  };

  updateOrganization = (organizationId: string, organization: TypeOrganization) => {
    this.errors = defaultErrorsState;
    const isValid = this.validateFormData();

    if (isValid) {
      return this.axios2.api.updateOrganization({
        name: this.name.trim(),
        orgTypeId: this.orgTypeId || undefined,
        addressFact: {
          addressfull: this.addressFact || this.addressJur,
        },
        addressJur: {
          addressfull: this.addressJur,
        },
        INN: this.INN || undefined,
        OGRN: this.OGRN || undefined,
        KPP: this.KPP || undefined,
        OKPO: this.OKPO || undefined,
        OKVED: this.OKVED || undefined,
        organizationId,
        // @ts-ignore
        logo: this.avatarHash || organization.logo?.id,
      });
    }

    return Promise.reject(new Error());
  };

  avatarHash = '';

  avatarFile: File;

  @action
  uploadFile = (file: File, orgId: string) => {
    this.avatarFile = file;
    this.axios2.api
      .uploadFile({
        ownerId: orgId,
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(this.onUploadFileSuccess)
      .catch(this.onUploadFileError);
  };

  @action.bound
  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    this.avatarHash = response.id;
    this.axios2.api
      .uploadFileFinish({ data: this.avatarFile, url: response.uploadUrl })
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  @action.bound
  onUploadFileError = () => {
    console.log('onUploadFileError');
  };

  @action.bound
  onUploadFileFinishSuccess = () => {
    console.log('File uploaded');
  };

  @action.bound
  onUploadFileFinishError = () => {
    console.log('onUploadFileFinishError');
  };

  getOrgTypes = () => {
    this.axios2.api.getDictOrgTypes({}).then(response => {
      runInAction(() => {
        this.orgTypes = response;
      });
    });
  };

  validateField = (field: string) => {
    // @ts-ignore
    if (!this[field]) {
      return true;
    }
    // @ts-ignore
    if (!this[field].trim()) {
      return true;
    }
  };

  validateFormData = (): boolean => {
    this.errors = defaultErrorsState;
    const errorsField = [
      'name',
      // 'addressJur',
      // 'INN',
      // 'OGRN',
      // 'KPP',
      // 'OKVED',
      // 'OKPO',
    ].filter(this.validateField);
    console.log('errorsField', errorsField);

    errorsField.forEach(field => {
      // @ts-ignore
      this.errors[field] = 'Поле обязательно для заполнения';
    });

    const maxLenth = this.orgTypeId === 4 ? 12 : 10;
    if (this.INN && this.INN.toString().length !== maxLenth) {
      this.errors.INN = `Длина ИНН должна быть ${maxLenth} символов`;
    }

    if (this.OGRN && this.OGRN.toString().length !== 13) {
      this.errors.OGRN = 'Длина ОГРН должна быть 13 символов';
    }

    if (this.KPP && this.KPP.toString().length !== 9) {
      this.errors.KPP = 'Длина КПП должна быть 9 символов';
    }

    if (this.OKVED && (this.OKVED.toString().length > 6 || this.OKVED.toString().length < 2)) {
      this.errors.OKVED = 'Длина ОКВЕД должна быть не менее 2 и не более 6 символов';
    }

    if (this.OKPO && this.OKPO.toString().length !== 8) {
      this.errors.OKPO = 'Длина КПП должна быть 8 символов';
    }

    if (this.orgTypeId === 0) {
      this.errors.orgTypeId = 'Поле обязательно для заполнения';
    }

    return !errorsField.length;
  };

  @observable
  errors = defaultErrorsState;

  resetFields = () => {
    this.errors = defaultErrorsState;
    this.name = '';
    this.orgTypeId = 0;
    this.addressJur = '';
    this.INN = '';
    this.OGRN = '';
    this.KPP = '';
    this.OKVED = '';
    this.OKPO = '';
  };

  @action
  setFormError = (field: string, title: string) => {
    console.log(field, ' + ', title);
    // @ts-ignore
    // if (!this.errors[field]) {
    //   return;
    // }

    this.errors[field] = getOrgErrors(title);
    console.log(this.errors);
  };
}
