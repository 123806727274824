import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../shared/constans/sizes';

export const TasksFilterWrapper = styled.div<{ shown: boolean }>`
  height: ${({ shown }) => (shown ? 'auto' : '0.5px')};
  border-top: ${({ shown }) => (shown ? `0.5px solid ${Colors.grayBorder}` : 'none')};
  overflow: ${({ shown }) => (shown ? 'visible' : 'hidden')};
  border-bottom: 0.5px solid ${Colors.grayBorder};
  padding: ${({ shown }) => (shown ? '16px 0' : '0')};
  margin-bottom: 16px;
  /* &>div>div>div>p {
    color: #f00;
  } */
  // todo это костыль, вынести в настройки Button в farmic
  .filter-tasks-button {
    height: 40px;
    border-radius: ${BorderRadius.small};
    background: none;
    color: ${Colors.green};
    border: 1px solid;
    justify-content: center;

    &:hover {
      color: ${Colors.greenHover};
    }

    &.disabled,
    &:hover.disabled {
      color: ${Colors.grayBorder};
      border-color: #eae7f1;
    }
  }
`;

export const TasksFilterFirstLine = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
`;
export const TasksFilterSecondLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-gap: 16px;
`;

export const TasksFilterControlsWrapper = styled.div`
  display: flex;
  .filter-hide {
    width: auto;
    height: 16px;
    background: none;
    color: ${Colors.darkGray};
    padding: 0;
    margin-right: 16px;
    border-bottom: 0.5px solid #9aa0ad;
    border-radius: 0;
    ${FontSizes.default};
  }
  .filter-reset {
    width: auto;
    height: 16px;
    background: none;
    color: ${Colors.red};
    padding: 0;
    ${FontSizes.default};
  }
  & button {
    border: none;
  }
`;
