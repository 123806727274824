import { Link } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { ReactComponent as AlertIcon } from '../../../shared/assets/images/allert.svg';
import { SwiperSlider } from '../../../shared/components/SwiperSlider/SwiperSlider';
import { useStore } from '../../../shared/utils/IoC';
import { IndicesStore } from '../../stores/indices.store';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { formatDateToNameString } from '../../../shared/utils/formatDateToNameString';
import { ReactComponent as CloundIcon } from '../../../shared/assets/images/cloud.svg';

import { IndicesController } from './controllers/indices.controller';
import {
  TimeLineWrapper,
  TimelineInfoMessage,
  TimelineItem,
  TimelineItemsWrapper,
  TimelineIconItem,
} from './style';

export const Timeline = observer(() => {
  const { indices, selectedIndex } = useStore(IndicesStore);
  const indicesController = useStore(IndicesController);
  const { selectedFieldId, showCulturesFillPanel, fields } = useStore(FieldsStore);
  const hasDays = Boolean(indices.length);

  useEffect(() => {
    if (selectedFieldId && fields.filter(field => field.id === selectedFieldId).length !== 0) {
      indicesController.fetchIndices(selectedFieldId);
    }
  }, [selectedFieldId, showCulturesFillPanel]);

  return (
    <TimeLineWrapper data-test-id="map-timeline-wrapper">
      {hasDays ? (
        <TimelineItemsWrapper>
          <SwiperSlider
            scrollPostions={'right'}
            selectedFieldId={selectedFieldId}
            indices={indices}
          >
            {indices.map((item, i) =>
              item.status === 'COMPLETED' ? (
                <TimelineItem
                  key={item.publicId}
                  active={selectedIndex ? selectedIndex.publicId === item.publicId : false}
                  onClick={() => indicesController.selectIndices(item)}
                  data-test-id={`map-timeline-item-${i}`}
                >
                  {formatDateToNameString(item.toDate)}
                </TimelineItem>
              ) : (
                <TimelineIconItem>
                  <CloundIcon key={item.publicId} />
                </TimelineIconItem>
              )
            )}
          </SwiperSlider>
        </TimelineItemsWrapper>
      ) : (
        <TimelineInfoMessage data-test-id="map-timeline-no-data">
          <AlertIcon />
          <span>Мы уже начали обрабатывать снимки, но нам нужно еще немного времени</span>
          <Link
            underlined
            color="default"
            label="Обновить"
            onClick={() => {
              indicesController.fetchIndices(selectedFieldId);
            }}
            data-test-id="map-timeline-no-data-reload"
          />
        </TimelineInfoMessage>
      )}
    </TimeLineWrapper>
  );
});
