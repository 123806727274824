import React, { FC, useEffect } from 'react';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';

import { useStore } from '../shared/utils/IoC';
import { Map } from '../map/Map';
import { CreateControls } from '../map/components/CreateControls/CreateControls';
import { MapUserControls } from '../map/components/MapUserControls/MapUserControls';

import { HoleTooltip } from './components/HoleTooltip/HoleTooltip';
import { LegendControls } from './components/LegendControls/LegendControls';
import { MainWrapper, MapsControlsWrapper, MapWrapper } from './components/DashboardLayout/style';
import { FullWeatherSlider } from './components/FullWeatherSlider';
import { PopupSlider } from './components/PopupSlider';
import { DashboardRoutes } from './dashboard.routes';
import { FieldsRouter } from './modules/fields/fields.router';
import { OperationsRouter } from './modules/operations/operations.router';
import { TasksRouter } from './modules/tasks/tasks.router';
import { ProfileRouter } from './modules/profile/ProfileRouter';
import { TopPanelControls } from './components/TopPanelControls';
import { OrganizationsController } from './controllers/organizations/organizations.controller';
import { OrganizationsStore } from './stores/organizations.store';

export const OrganizationRouter: FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const history = useHistory();
  const organizationsController = useStore(OrganizationsController);
  const organizationsStore = useStore(OrganizationsStore);

  useEffect(() => {
    setTimeout(() => {
      if (orgId !== organizationsStore.selectedOrganizationId) {
        if (organizationsStore.idToOrganization.has(orgId)) {
          const urlArr = location.pathname.split('/');
          urlArr[1] = orgId;
          history.push(generatePath(urlArr.join('/')));
          organizationsController.selectOrganization(orgId);
        } else {
          const urlArr = location.pathname.split('/');
          urlArr[1] = 'my';
          history.push(generatePath(urlArr.join('/')));
          organizationsController.selectOrganization('my');
        }
      }
    }, 500);
  }, [orgId]);

  return (
    <Switch>
      <Route path={'/:orgId/operations'} component={() => <OperationsRouter />} />
      <Route path={'/:orgId/tasks'} component={() => <TasksRouter />} />
      <Route path={'/:orgId/fields'}>
        <MainWrapper>
          <FieldsRouter orgId={orgId}/>
          <MapsControlsWrapper>
            <FullWeatherSlider />
            <MapWrapper>
              <HoleTooltip />
              <TopPanelControls />
              <Map />
              <CreateControls />
              <LegendControls />
              <MapUserControls />
            </MapWrapper>
            <PopupSlider />
          </MapsControlsWrapper>
        </MainWrapper>
      </Route>
      <Route path={DashboardRoutes.Profile} component={() => <ProfileRouter />} />
    </Switch>
  );
};
