import { partial } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { OperationField } from '../../../../../api/endpoints/operations/operation.fields.get';
import { CultureList } from '../../../../../api/models/operations/operation.culture.list.model';
import { Operation } from '../../../../../api/models/operations/operation.model';
import { OperationType } from '../../../../../api/models/operations/operation.type.model';
import { TypeUser } from '../../../../../api/models/user.model';
import { provide } from '../../../../shared/utils/IoC';
import { PreparedTask } from '../controllers/tasks.listing.controller';

import { Task } from './tasks.store';

@provide.singleton()
export class TasksEditStore {
  cultureId = '';
  cultureList: CultureList;
  operations: Array<Operation> = [];
  selectedOperationId = '';
  operationFields: Array<OperationField> = [];
  selectedFieldId = '';
  usersList: TypeUser[] = [];
  assigneeId = '';
  task: Partial<Task> | PreparedTask = {};
  touchedFields = new Set<string>();

  presetDateFromTaskList: moment.Moment | null;

  culturesLoading = false;
  operationsLoading = false;
  fieldsLoading = false;

  constructor() {
    makeAutoObservable(this);
  }
}
