import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import * as _ from 'lodash';
import { Modal, useModal, ModalProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { TasksController } from '../../controllers/tasks.controller';
import { PreparedTask, TasksListingController } from '../../controllers/tasks.listing.controller';
import { Task, TaskFormMode, TasksStore } from '../../stores/tasks.store';
import { OperationsStore } from '../../stores/operations.store';
import { TasksEdit } from '../TaskEdit/TaskEdit';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { TasksEditStore } from '../../stores/task.edit.store';
import { TasksView } from '../TaskView/TaskView';
import { TaskEditController } from '../../controllers/task.edit.controller';
import { ETaskFormViewMode, TaskViewStore } from '../../stores/task.view.store';
import { DashboardRoutes } from '../../../../dashboard.routes';
import { FieldsStore } from '../../../fields/stores/fields.store';
import { TasksFilterStore } from '../../../tasks/stores/tasks.filter.store';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import { TaskViewController } from '../../controllers/task.view.controller';
import {
  cancellationModalConfig,
  cancellationModalSuccessConfig,
} from '../../modals/CancellationModal';

import plusSvg from './svgPlus.svg';
import {
  CreateNewTask,
  CreateNewTaskLabel,
  Spinner,
  TaskDateHeader,
  TaskGroupDate,
  TaskListWrapper,
  TasksBlockList,
  TasksDateBlock,
} from './style';
import { TasksItem } from './TaskItem';
import { WarningBlock } from './WarningBlock';
import { NoOperationsPlaceholder } from './NoOperationsPlaceholder';
import { NoTasksPlaceHolder } from './NoTasksPlaceholder';
import { BottomBlockOfTasksList } from './BottomBlockOfTasksList';

type Props = {
  showCulture?: boolean;
};

export const TasksList: FC<Props> = observer(({ showCulture }) => {
  const history = useHistory();
  const fieldsStore = useStore(FieldsStore);
  const operationsStore = useStore(OperationsStore);
  const { registerModalList } = useModal();

  const tasks = useStore(TasksStore);
  const tasksController = useStore(TasksController);
  const tasksListingController = useStore(TasksListingController);
  const seasonStore = useStore(SeasonsStore);
  const seaosonCultureStore = useStore(SeasonCultureStore);
  const taskEditStore = useStore(TasksEditStore);
  const taskEditController = useStore(TaskEditController);
  const taskViewStore = useStore(TaskViewStore);
  const taskViewController = useStore(TaskViewController);
  const organizationsStore = useStore(OrganizationsStore);
  const tasksStore = useStore(TasksStore);
  const tasksFilterStore = useStore(TasksFilterStore);
  const profileStore = useStore(ProfileStore);

  const [loading, setLoading] = useState(true);

  const [taskFormMode, setTaskFormMode] = useState<TaskFormMode>(TaskFormMode.None);

  const [taskForViewId, setTaskForViewId] = useState<string | null>(null);

  let lastInterval;

  // @ts-ignore
  const createNewTaskFormByDate = (planDate: moment.Moment) => {
    taskEditStore.presetDateFromTaskList = planDate;
    setTaskFormMode(TaskFormMode.Create);
  };

  const fieldsListingRoute = useMemo(
    () =>
      generatePath(DashboardRoutes.Fields, {
        orgId: organizationsStore.selectedOrganizationId,
      }),
    []
  );

  useEffect(() => {
    setLoading(false);
    registerModalList(
      [cancellationModalConfig, cancellationModalSuccessConfig],
      'cancellationModal'
    );
    return () => {
      tasks.tasksMap.clear();
      operationsStore.clearOperationsStore();
      tasksController.clear();
      seaosonCultureStore.clearSeasonCultureStore();
    };
  }, []);

  const dateToTasks = useMemo(tasksListingController.getDateToTasks, [
    operationsStore.OperationsType,
    operationsStore.OperationsList,
    tasks.tasksMap.size,
    tasks.tasksMap,
  ]);

  const onGoToEdit = () => {
    taskEditController.setTask(taskViewStore.taskForView);
    taskEditStore.operations = taskViewStore.operations.slice();
    taskEditStore.cultureList = taskViewStore.cultureList;
    taskEditController.setSelectedFormCultureId(taskViewStore.cultureId);
    console.log(taskEditStore);
    setTaskFormMode(TaskFormMode.Edit);
  };

  const onGoToCreate = () => {
    taskEditController.clearStore();
    tasksController.disableViewMode();
    setTaskFormMode(TaskFormMode.Create);
  };

  const onTaskItemClick = id => {
    setTaskForViewId(id);
    setTaskFormMode(TaskFormMode.View);
    taskViewController.setTaskFormLastViewMode(ETaskFormViewMode.SideForm);
    tasks.setIsViewMode(true);
  };

  if (!tasks.fromTasksPage && !seasonStore.loading && !seasonStore.selectedSeason) {
    return (
      <WarningBlock
        title1={'Сначала добавьте сезон и поля'}
        title2={'После этого вы сможете запланировать операции и задачи на каждое поле'}
        buttonAction={() => {
          history.push(fieldsListingRoute);
        }}
      />
    );
  }

  const loadAdditionalTasks = async () => {
    console.log('@@@@@@@@@@@@@@@@@@@@@@handleVisit===', tasks.pageNumber);
    if (tasks.fromTasksPage) {
      await tasksController.fetchTasks(
        {
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId: tasksFilterStore.culture,
          fieldId: tasksFilterStore.field,
          assigneeId: tasksFilterStore.asignee,
          statusIn: tasksFilterStore.status.map(item => item.value),
          planDateFrom: tasksFilterStore.startDate,
          planDateTo: tasksFilterStore.endDate,
          operationTypeId: tasksFilterStore.operationType,
          priorityIn: tasksFilterStore.priority.map(item => item.value),
        },
        true
      );
    } else {
      await tasksController.fetchTasks(
        {
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId: seaosonCultureStore.selectedCultureId,
          operationId: operationsStore.selectedOperationId,
          fieldId: operationsStore.selectedOperationFieldId,
          noCulture: seaosonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
        },
        true
      );
    }
    console.log('tasksStore.tasksMap.size=', tasksStore.tasksMap.size);
  };

  const updateScrollRef = ref => {
    console.log('tasks updateScrollRef');
    console.log(ref);
    if (lastInterval) {
      clearInterval(lastInterval);
    }
    lastInterval = setInterval(async () => {
      if (
        ref &&
        !tasksStore.loading &&
        tasks.pageNumber < tasks.totalPages &&
        window.innerHeight - ref.getBoundingClientRect().top > 50
      ) {
        await loadAdditionalTasks();
      }
    }, 500);
  };

  if (!tasks.fromTasksPage && !fieldsStore.fields.length) {
    return (
      <WarningBlock
        title1={'Добавьте поля '}
        title2={'После этого вы сможете запланировать операции и задачи на каждое поле'}
        buttonAction={() => {
          history.push(fieldsListingRoute);
        }}
      />
    );
  }

  if (!tasks.fromTasksPage && !operationsStore.OperationsList.length && tasks.tasksLoaded) {
    return <NoOperationsPlaceholder />;
  }

  if (!tasks.fromTasksPage && !tasks.tasksMap.size && tasks.tasksLoaded) {
    return <NoTasksPlaceHolder />;
  }

  return loading && !tasksStore.tasksLoaded ? null : (
    <TaskListWrapper className="tasks-list">
      {Object.keys(dateToTasks).map((planDateKey, index) => (
        <TasksDateBlock key={index} data-test-id={'tasks-date-block'}>
          <TaskDateHeader>
            <TaskGroupDate data-test-id={'task-group-date'}>
              {moment(planDateKey).format('DD MMMM')}
            </TaskGroupDate>
            <CreateNewTask
              data-test-id={'create-new-task-by-date-button'}
              onClick={createNewTaskFormByDate.bind(
                this,
                moment((dateToTasks[planDateKey][0] as Task).bindingDate)
              )}
            >
              <CreateNewTaskLabel>Новая задача</CreateNewTaskLabel>
              <img src={plusSvg} />
            </CreateNewTask>
          </TaskDateHeader>
          <TasksBlockList fromTaskListPage={tasks.fromTasksPage} data-test-id={'tasks-block-list'}>
            {dateToTasks[planDateKey].map((task: PreparedTask, taskIndex) => (
              <TasksItem
                task={task}
                onClick={onTaskItemClick.bind(this, task.id)}
                showCulture={showCulture}
                key={taskIndex}
                profileUserName={`${profileStore.user?.lastName} ${profileStore.user?.firstName} ${profileStore.user?.secondName}`}
              />
            ))}
          </TasksBlockList>
        </TasksDateBlock>
      ))}
      {!tasks.fromTasksPage &&
      tasks.tasksMap.size &&
      tasks.tasksLoaded &&
      !tasks.tasksLoadedByFilter ? (
        <BottomBlockOfTasksList />
      ) : null}
      {tasks.pageNumber < tasks.totalPages ? (
        <Spinner ref={ref => updateScrollRef(ref)} data-test-id={'tasks-list-spinner'} />
      ) : null}
      {taskFormMode === TaskFormMode.Create ? (
        <TasksEdit
          onCloseDialog={() => setTaskFormMode(TaskFormMode.None)}
          onCancel={() => setTaskFormMode(TaskFormMode.None)}
          taskId={null}
        />
      ) : null}
      {taskFormMode === TaskFormMode.View ? (
        <TasksView
          onCloseDialog={() => setTaskFormMode(TaskFormMode.None)}
          onGoToEdit={onGoToEdit}
          onGoToCreate={onGoToCreate}
          taskId={taskForViewId}
          fromTaskListPage={tasks.fromTasksPage}
        />
      ) : null}
      {taskFormMode === TaskFormMode.Edit ? (
        <TasksEdit
          onCloseDialog={() => setTaskFormMode(TaskFormMode.None)}
          onCancel={() => {
            setTaskFormMode(TaskFormMode.View);
            tasksStore.setFullScreenMode(null);
            if (!tasks.fromTasksPage) {
              tasksController.resetPageSettings();
              tasksController.fetchTasks({
                organizationId: organizationsStore.selectedOrganizationId,
                seassonCultureId: seaosonCultureStore.selectedCultureId,
                operationId: operationsStore.selectedOperationId,
                fieldId: operationsStore.selectedOperationFieldId,
                noCulture:
                  seaosonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
              });
            }
          }}
          taskId={taskForViewId}
        />
      ) : null}
      {/* <div><InfiniteLoader onVisited={handleVisit} /></div> */}
      {/* <button onClick={handleVisit}>load more</button> */}
    </TaskListWrapper>
  );
});
