import { toBase64 } from '../../../../../../../shared/utils/getBase64';

const getFileContentType = async (file: File): Promise<string> => {
  if (file.type) {
    return file.type;
  } else {
    const based = (await toBase64(file)) as string;
    const parts = based.split(';base64,');
    const [, contentType] = parts[0].split(':');

    return contentType;
  }
};

export default getFileContentType;
