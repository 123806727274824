import { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Select } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment from 'moment';

import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { useStore } from '../../../../../shared/utils/IoC';
import { CreateSeasonStore } from '../../stores/create.season.store';
import { Calendar } from '../../../../../shared/components/Calendar/Calendar';
import { SeasonsStore } from '../../../../stores/seasons.store';
import ErrorImage from '../../../../../shared/assets/images/cancel.png';
import { EApiErrorTitle } from '../../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsController } from '../../../../controllers/seasons/seasons.controller';
import { CreateSeasonController } from '../../controllers/create.season.controller';

import MarkSvg from './../../static/label.svg';
import {
  Title,
  Line,
  SeasonStubSelectorWrapper,
  CalendarWrapper,
  CalendarRow,
  BottomLine,
  CopySeasonWrapper,
  ButtonsWrapper,
  CopySeasonSelectWrapper,
  MarkOkImg,
  LabelTitle,
  ButtonActionWrapper,
  ErrorImg,
} from './style';

type CreateSeasonModalProps = {
  closeModal: () => void;
};

export const CreateSeasonModal: FC<CreateSeasonModalProps> = observer(({ closeModal }) => {
  const store = useStore(CreateSeasonStore);
  const seasons = useStore(SeasonsStore);
  const seasonsController = useStore(SeasonsController);
  const organizations = useStore(OrganizationsStore);
  const createSeasonController = useStore(CreateSeasonController);

  const [isSecondStep, setIsSecondStep] = useState(false);

  const [isSeasonCreateError, setIsSeasonCreateError] = useState(false);

  const {
    stubYearsOptions,
    copyYearsOptions,
    selectStubYear,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateBorders,
    selectedStubYear,
    isButtonAvailable,
    setCopyYear,
    clear,
    clearCopyYear,
    isActiveCopyCheckbox,
  } = store;

  const { saveSeason } = createSeasonController;

  const [isCopyAvailable, setIsCopyAvailable] = useState<boolean>(false);

  useEffect(() => {
    store.fetchStubSeasons();

    store.fetchSeasonsForCopy({
      organizationId:
        (organizations.selectedOrganizationId !== 'my' && organizations.selectedOrganizationId) ||
        undefined,
      // startYear: 2010,
      // endYear: 2040,
    });

    return () => {
      clear();
    };
  }, []);

  const handleSave = async () => {
    try {
      await saveSeason();
    } catch (e) {
      console.warn(e);
      if (e.message === EApiErrorTitle.REF_EXISTS) {
        setIsSeasonCreateError(true);
      }
      return;
    }
    setIsSecondStep(true);
  };

  const handleFinish = async () => {
    await seasonsController.fetchMySeasons();
    seasonsController.setSelectedSeason(selectedStubYear);
    closeModal();
  };

  const handleIsCopyAvailable = (): void => {
    clearCopyYear();
    setIsCopyAvailable(!isCopyAvailable);
  };

  if (isSecondStep) {
    const yearsLabel = `${Number(selectedStubYear) - 1} - ${selectedStubYear}`;
    return (
      <ModalComponent
        paddings={'default'}
        isVerticalCentered={true}
        data-test-id={'season-add-modal-success'}
      >
        <MarkOkImg src={MarkSvg} />
        <LabelTitle data-test-id={'season-add-modal-label'}>
          Новый сезон «{yearsLabel} гг.»
          <br />
          успешно добавлен
        </LabelTitle>
        <ButtonActionWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={handleFinish}
            data-test-id={'season-add-modal-success-continue'}
          >
            Продолжить
          </Button>
        </ButtonActionWrapper>
      </ModalComponent>
    );
  }

  if (isSeasonCreateError) {
    const yearsLabel = `${Number(selectedStubYear) - 1} - ${selectedStubYear}`;
    return (
      <ModalComponent
        paddings={'default'}
        isVerticalCentered={true}
        data-test-id={'season-add-modal-error'}
      >
        <ErrorImg src={ErrorImage} data-test-id={'season-add-modal-already-created-img'} />
        <LabelTitle data-test-id={'season-add-modal-already-created'}>
          Сезон «{yearsLabel} гг.» уже создан
          <br />в организации «{organizations.currentOrganization.name}» !
        </LabelTitle>
        <ButtonActionWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={() => closeModal()}
            data-test-id={'season-add-modal-error-continue'}
          >
            Продолжить
          </Button>
        </ButtonActionWrapper>
      </ModalComponent>
    );
  }

  return (
    <ModalComponent paddings={'seasons'} data-test-id={'season-add-modal-new'}>
      <Title data-test-id={'season-add-modal-new-title'}>Добавление нового сезона</Title>
      <Line />
      <SeasonStubSelectorWrapper>
        <Select
          onChange={selectStubYear}
          options={stubYearsOptions}
          placeholder={'Сезон'}
          label={'Выберите сезон для добавления'}
          dataTestId={'season-stub-select'}
        />
      </SeasonStubSelectorWrapper>
      <CalendarRow>
        <CalendarWrapper>
          <Calendar
            label={'Начало сезона'}
            value={startDate}
            onChange={setStartDate}
            placeholder={'Укажите дату'}
            minDate={dateBorders.start.min}
            maxDate={endDate ? moment(endDate).subtract(1, 'd') : dateBorders.start.max}
            dataTestId={'season-start-date'}
          />
        </CalendarWrapper>
        <CalendarWrapper>
          <Calendar
            label={'Конец сезона'}
            value={endDate}
            onChange={setEndDate}
            placeholder={'Укажите дату'}
            minDate={
              startDate && startDate.year() < dateBorders.end.max.year()
                ? moment(dateBorders.end.min).add(1, 'y')
                : moment(startDate).add(1, 'd')
            }
            maxDate={dateBorders.end.max}
            dataTestId={'season-end-date'}
          />
        </CalendarWrapper>
      </CalendarRow>
      <BottomLine />
      <CopySeasonWrapper>
        <Checkbox
          label={'Копировать поля из другого сезона'}
          onChange={handleIsCopyAvailable}
          value={isCopyAvailable}
          disable={!Boolean(copyYearsOptions.length)}
          dataTestId={'copy-fields-other-season'}
        />
        {isCopyAvailable && (
          <CopySeasonSelectWrapper>
            <Select
              onChange={setCopyYear}
              options={copyYearsOptions.filter(({ fieldsCount }) => fieldsCount > 0)}
              placeholder={'Сезон'}
              label={'Выберите сезон для добавления'}
              dataTestId={'season-copy-stub-select'}
            />
          </CopySeasonSelectWrapper>
        )}
      </CopySeasonWrapper>
      <ButtonsWrapper>
        <Button
          color={'default'}
          type={'button'}
          onClick={() => closeModal()}
          dataTestId={'season-cancel-btn'}
        >
          Отменить
        </Button>
        <Button
          color={'primary'}
          type={'button'}
          disabled={isCopyAvailable ? !isActiveCopyCheckbox : !isButtonAvailable}
          onClick={handleSave}
          dataTestId={'season-save-btn'}
        >
          Сохранить
        </Button>
      </ButtonsWrapper>
    </ModalComponent>
  );
});
