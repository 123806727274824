import styled from 'styled-components';

export const Form = styled.form`
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Col = styled.div<{ $variant: string }>`
  display: grid;
  grid-template-columns: ${({ $variant }) => $variant};
  grid-column-gap: 16px;
  grid-row-gap: 20px;
`;

export const Row = styled.div``;
