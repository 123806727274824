import { forwardRef, memo, useEffect, useState } from 'react';

import { isNeedToAdjustPenultimateAttributeMargin } from '../../../../../utils/checklist.instances';
import ChecklistAttributeContainer from '../ChecklistAttributeContainer/ChecklistAttributeContainer';

import Styled from './ContainerForAttributeWithChildren.styles';
import { TContainerForAttributeWithChildrenProps } from './ContainerForAttributeWithChildren.types';

const ContainerForAttributeWithChildren = forwardRef<
  HTMLInputElement,
  TContainerForAttributeWithChildrenProps
>((props, ref) => {
  const [isAttributesAdjusted, setIsAttributesAdjusted] = useState<boolean>(false);

  // Выравнивает внешний отступ предпоследнего атрибута
  useEffect(() => {
    setIsAttributesAdjusted(
      isNeedToAdjustPenultimateAttributeMargin(props.attributeWithValue.children)
    );
  }, [props.attributeWithValue.children]);

  return (
    <Styled.Wrapper
      $isAttributesAdjusted={isAttributesAdjusted}
      $isActive={Boolean(props.attributeWithValue.attributeValue?.booleanValue)}
    >
      <ChecklistAttributeContainer {...props} ref={ref} />

      {props.attributeWithValue.children.map(childrenAttrWithValue => (
        <ChecklistAttributeContainer
          key={childrenAttrWithValue.checklistAttribute.id}
          attributeWithValue={childrenAttrWithValue}
          isBlocked={props.isBlocked}
          focusTarget={props.focusTarget}
          onChange={props.onChange}
          ref={ref}
        />
      ))}
    </Styled.Wrapper>
  );
});

export default memo(ContainerForAttributeWithChildren);
