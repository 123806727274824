import { makeAutoObservable } from 'mobx';

import { Axios, TypeApiResponse } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { ChecklistFileUploaderService } from '../services/fileUploader/checklist.fileUploader.service';
import {
  ChecklistFileUploaderStore,
  EFileLoadingStatus,
} from '../stores/checklist.fileUploader.store';
import { ModalsStore } from '../../../../modals/store/modals.store';
import {
  checklistModalUniqueKey,
  EChecklistModalName,
} from '../modules/fullscreen/checklist/modals/modalsConfig';
import { displayModalWindow } from '../../../../modals/config';

@provide.singleton()
export class ChecklistFileUploaderController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(ChecklistFileUploaderStore)
  protected checklistFileUploaderStore: ChecklistFileUploaderStore;

  @lazyInject(ChecklistFileUploaderService)
  protected checklistFileUploaderService: ChecklistFileUploaderService;

  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  constructor() {
    makeAutoObservable(this);
  }

  uploadFile = (file: File): Promise<TypeApiResponse<'uploadFile'>> => {
    const { setUploadFileBuffer, setFileLoadingStatus } = this.checklistFileUploaderStore;

    setUploadFileBuffer(file);
    setFileLoadingStatus(EFileLoadingStatus.pending);

    return this.checklistFileUploaderService
      .uploadFile(file)
      .then((resp: TypeApiResponse<'uploadFile'>) => {
        this.onUploadFileSuccess(resp);
        return resp;
      })
      .catch(error => {
        this.onUploadFileError();
        return error;
      });
  };

  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    const { addUrlToImgsUrlArray, uploadFileBuffer } = this.checklistFileUploaderStore;

    addUrlToImgsUrlArray(response.id);
    this.checklistFileUploaderService
      .finalFileUpload(response, uploadFileBuffer)
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  onUploadFileError = () => {
    const { setFileLoadingStatus } = this.checklistFileUploaderStore;
    setFileLoadingStatus(EFileLoadingStatus.error);
  };

  onUploadFileFinishSuccess = () => {
    const { setFileLoadingStatus } = this.checklistFileUploaderStore;
    setFileLoadingStatus(EFileLoadingStatus.fulfilled);
  };

  onUploadFileFinishError = () => {
    const { setFileLoadingStatus } = this.checklistFileUploaderStore;
    setFileLoadingStatus(EFileLoadingStatus.error);
  };

  warnBeforeDeleteFile = (onSuccess?: () => void) => {
    const { setModal } = this.modalsStore;

    const successHandler = () => {
      if (onSuccess) onSuccess();
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeDeleteFile,
        successHandler
      )
    );
  }

  clearFileUploader = () => {
    this.checklistFileUploaderStore.clearFileBuffer();
    this.checklistFileUploaderStore.clearFilesArray();
    this.checklistFileUploaderStore.clearImgUrls();
  };
}
