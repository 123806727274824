import React, { FC } from 'react';

import { OperationCulture } from '../../../../../../api/models/operations/operation.culture';

import { CultureItemBody, CultureItemName, CultureItemSort, CultureItemImg } from './style';

type CultureItemType = {
  id?: string;
  name: string;
  sort: string;
  img: string;
  selected: boolean;
  onClick: () => void;
};

export const CultureItem: FC<CultureItemType> = ({id, name, sort, img, selected, onClick }) => {
  return (
    <CultureItemBody id={id} active={selected} onClick={() => onClick()} data-test-id={'operation-culture-item'}>
      <CultureItemName data-test-id={'operation-culture-item-name'}>{name}</CultureItemName>
      <CultureItemSort data-test-id={'operation-culture-item-sort'}>{sort || 'Сорт не указан'}</CultureItemSort>
      <CultureItemImg src={img} data-test-id={'operation-culture-item-img'}></CultureItemImg>
    </CultureItemBody>
  );
};
