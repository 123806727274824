import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../../shared/constans/colors';
import { FontSizes } from '../../../../../../../../../../shared/constans/sizes';
import StyledAttributeContainer from '../../../../containers/ChecklistAttributeContainer/styles';
import StyledContainerForAttributeWithChildren from '../../../../containers/ContainerForAttributeWithChildren/ContainerForAttributeWithChildren.styles';

const Header = styled.div`
  margin: 0 8px;
  flex: 1 0 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  /* Ограничивает длину названия вложенного чек-листа */
  max-width: 864px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0 70%;
  white-space: nowrap;
`;

type TSubtitleProps = {
  $subtitleIncludeSvg: string;
};

const Subtitle = styled.p<TSubtitleProps>`
  ${FontSizes.medium}
  color: ${Colors.darkGray};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  margin-top: 6px;
  max-width: 85%;
  min-width: 40%;

  &::before {
    content: '';
    display: inline-block;
    background-image: url(${({ $subtitleIncludeSvg }) => $subtitleIncludeSvg});
    width: 16px;
    height: 16px;
    margin-right: 2px;
    transform: translateY(4px);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 24px;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;

type TEditButtonProps = {
  $editDefaultSvg: string;
  $editHoverSvg: string;
};

const EditButton = styled.div<TEditButtonProps>`
  width: 17px;
  height: 16px;
  cursor: pointer;

  background: url(${({ $editDefaultSvg }) => $editDefaultSvg});

  :hover {
    background: url(${({ $editHoverSvg }) => $editHoverSvg});
  }
`;

type TDeleteButtonProps = {
  $deleteDefaultSvg: string;
  $deleteHoverSvg: string;
};

const DeleteButton = styled.div<TDeleteButtonProps>`
  width: 24px;
  height: 24px;
  cursor: pointer;

  background: url(${({ $deleteDefaultSvg }) => $deleteDefaultSvg});

  :hover {
    background: url(${({ $deleteHoverSvg }) => $deleteHoverSvg});
  }
`;

type TCollapseButtonProps = {
  $isCollapsed: boolean;
  $arrowDownDefaultSvg: string;
  $arrowDownHoverSvg: string;
  $arrowTopDefaultSvg: string;
  $arrowTopHoverSvg: string;
};

const CollapseButton = styled.div<TCollapseButtonProps>`
  width: 24px;
  height: 24px;
  cursor: pointer;

  ${({
    $isCollapsed,
    $arrowTopDefaultSvg,
    $arrowTopHoverSvg,
    $arrowDownDefaultSvg,
    $arrowDownHoverSvg,
  }) =>
    $isCollapsed
      ? css`
          background: url(${$arrowTopDefaultSvg});

          :hover {
            background: url(${$arrowTopHoverSvg});
          }
        `
      : css`
          background: url(${$arrowDownDefaultSvg});

          :hover {
            background: url(${$arrowDownHoverSvg});
          }
        `}
`;

const TextAsDeleteButton = styled.span<{ $isDisabled: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.red};
  margin-left: 24px;
  cursor: pointer;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      visibility: hidden;
      margin: 0;
    `}
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 70%;
`;

const SaveButton = styled.button`
  margin: 4px 8px 0 8px;
  max-height: 40px;
  flex: 1 0 40%;
  max-width: calc(50% - 16px);
  background-color: white;
  border: none;
  color: ${Colors.green};
  box-shadow: 0 0 0 1px ${Colors.green};
  border-radius: 12px;
  padding: 12px 16px;
  cursor: pointer;
`;

const Wrapper = styled.div<{ $isHiddenAttributes: boolean; $isAttributesAdjusted: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 70%;

  ${({ $isHiddenAttributes }) =>
    $isHiddenAttributes &&
    css`
      & ${StyledAttributeContainer.Wrapper} {
        display: none;
      }

      & ${StyledContainerForAttributeWithChildren.Wrapper} {
        display: none;
      }

      & ${Header} {
        margin-bottom: 0 !important;
      }
    `}

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ $isAttributesAdjusted }) =>
    $isAttributesAdjusted &&
    css`
      & ${StyledAttributeContainer.Wrapper}:nth-last-child(2) {
        margin-bottom: 0 !important;
      }
    `}
`;

const StyledNestedInstance = {
  Wrapper,
  Header,
  Title,
  Subtitle,
  ButtonsWrapper,
  EditButton,
  DeleteButton,
  CollapseButton,
  TextAsDeleteButton,
  SaveButtonWrapper,
  SaveButton,
};

export default StyledNestedInstance;
