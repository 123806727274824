import { ChangeEvent, FC, memo, useCallback } from 'react';

import {
  CommentWrapper,
  TextareaCommentLabel,
} from '../../../../operations/components/TaskEdit/style';
import { TaskEditController } from '../../../../operations/controllers/task.edit.controller';
import { TasksEditStore } from '../../../../operations/stores/task.edit.store';

interface IProps {
  task: TasksEditStore['task'];
  setTask: TaskEditController['setTask'];
  blocked?: boolean;
}

const Comment: FC<IProps> = props => {
  const { task, setTask, blocked } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>): void =>
      setTask({
        ...task,
        comment: event.target.value,
      }),
    [task]
  );

  return (
    <CommentWrapper blocked={blocked}>
      <TextareaCommentLabel>Комментарий к задаче</TextareaCommentLabel>
      <textarea 
        value={task?.comment} 
        onChange={handleChange} 
        rows={4} 
        placeholder={'Отсутствует'}
        data-test-id={'comment-textarea'}
      />
    </CommentWrapper>
  );
};

export default memo(Comment);
