import { useMemo } from 'react';

import { EChecklistAttributeType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { getChecklistAttrPlaceholderByAttrType } from '../../../utils/attribute/helpers';

const useGetChecklistAttrPlaceholder = (
  attrType: EChecklistAttributeType,
  placeholder?: string
): string => {
  return useMemo<string>(() => {
    if (placeholder) {
      return placeholder;
    } else {
      return getChecklistAttrPlaceholderByAttrType(attrType);
    }
  }, [attrType, placeholder]);
};

export default useGetChecklistAttrPlaceholder;
