import { observer } from 'mobx-react';
import { FC, memo, useCallback, useMemo, useState } from 'react';

import { DialogModal } from '../../../../../../../../../components/DialogModal/DialogModal';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { FullScreenMode, TasksStore } from '../../../../../../operations/stores/tasks.store';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../../../stores/checklist.instances.store';
import { CheckAccessStore } from '../../../../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../../shared/constants/access-rules-action';

import Styled from './ChecklistInstance.styles';
import { TChecklistInstanceProps } from './ChecklistInstance.types';

const ChecklistInstance: FC<TChecklistInstanceProps> = observer(
  ({ colors, inst, num, isOrgMy, manageTasks, workWithTasks }) => {
    const checklistsController = useStore(ChecklistInstancesController);
    const checklistInstancesStore = useStore(ChecklistInstancesStore);
    const { getAccessRuleValue } = useStore(CheckAccessStore);
    const { delTechnique, enableChecklistViewMode, enableChecklistEditMode } = checklistsController;

    const taskStore = useStore(TasksStore);
    const profileStore = useStore(ProfileStore);

    const checklistStore = useStore(ChecklistInstancesStore);
    const { setSelectedInstId } = checklistStore;

    const [isShowedDeleteModal, setIsShowedDeleteModal] = useState<boolean>(false);

    const handleChecklistActionClick = useCallback((): void => {
      setSelectedInstId(inst.id);

      if (isOrgMy || manageTasks) {
        if (inst?.checkListId) {
          enableChecklistViewMode();
        } else {
          enableChecklistEditMode();
        }
      } else if (workWithTasks) {
        if (inst?.checkListId) {
          enableChecklistViewMode();
        } else {
          enableChecklistEditMode();
        }
      }

      taskStore.setFullScreenMode(FullScreenMode.ChecklistFullScreen);
    }, [inst]);

    const checkAccessRulesForEditChecklist = () => {
      if (!checklistInstancesStore.taskId) return false; // проверяем не оздание ли это новой задачи

      if (isOrgMy || manageTasks) return true;

      const isUserAssignedToTask =
        taskStore.tasksMap.get(checklistInstancesStore.taskId).assignee.id === profileStore.user.id;

      if (workWithTasks && isUserAssignedToTask) return true;

      if (workWithTasks && !isUserAssignedToTask) return false;

      return false;
    };

    const getCheckListActionLabel = () => {
      if (isOrgMy || manageTasks) {
        return inst.checkListId ? 'Просмотреть чек-лист' : 'Заполнить чек-лист';
      }

      const isUserAssignedToTask =
        taskStore.tasksMap.get(checklistInstancesStore.taskId).assignee.id === profileStore.user.id;

      if (workWithTasks && isUserAssignedToTask) {
        return inst.checkListId ? 'Просмотреть чек-лист' : 'Заполнить чек-лист';
      }

      if (workWithTasks && !isUserAssignedToTask) {
        return inst.checkListId ? 'Просмотреть чек-лист' : false;
      }
    };

    const machineryInstLogo = useMemo<string>(() => {
      if (inst?.machineryName && inst.machineryName.length >= 2) {
        return `${inst.machineryName[0].toUpperCase()}${inst.machineryName[1].toUpperCase()}`;
      } else {
        return `${num}`;
      }
    }, [inst]);

    return (
      <Styled.Wrapper>
        <Styled.DescriptionWrapper>
          <Styled.PositionMark $colors={colors}>
            <Styled.PositionText>{machineryInstLogo}</Styled.PositionText>
          </Styled.PositionMark>
          <Styled.Name>{inst?.machineryName}</Styled.Name>
        </Styled.DescriptionWrapper>

        <Styled.OperationWrapper>
          {taskStore.isViewMode ? (
            <>
              {getCheckListActionLabel() && (
                <Styled.ChecklistActionLabel onClick={handleChecklistActionClick}>
                  {getCheckListActionLabel()}
                </Styled.ChecklistActionLabel>
              )}
              <Styled.ChecklistStatus $isFilled={Boolean(inst?.checkListId)}>
                {inst?.checkListId ? 'Заполнено' : 'Не заполнено'}
              </Styled.ChecklistStatus>
            </>
          ) : null}
          <Styled.DeleteButton
            $isDisabled={
              checkAccessRulesForEditChecklist()
                ? false
                : inst.createdBy && profileStore.user?.id !== inst.createdBy
            }
            onClick={event => {
              event.stopPropagation();
              setIsShowedDeleteModal(true);
            }}
          />
        </Styled.OperationWrapper>

        {isShowedDeleteModal && (
          <DialogModal
            title={'Вы действительно хотите удалить чеклист?'}
            status={'warning'}
            accept={{
              name: 'Да, продолжить',
              handler: event => {
                event.preventDefault();
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                delTechnique(inst.id);
                setIsShowedDeleteModal(false);
              },
              color: 'primary',
            }}
            cancel={{
              name: 'Нет, отменить',
              handler: event => {
                event.preventDefault();
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                setIsShowedDeleteModal(false);
              },
              color: 'default',
            }}
          />
        )}
      </Styled.Wrapper>
    );
  }
);

ChecklistInstance.displayName = 'ChecklistInstance';

export default memo(ChecklistInstance);
