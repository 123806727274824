export enum ApplicationRoutes {
  Agreement = '/agreement',
  Authorization = '/authorization',
  // todo пути надо перенести в конфиг
  ExternalAuthorization = `/?systemId=assistant`,
  ExternalAuthorizationDev = 'http://localhost:3100/?systemId=localhost3000',
  ExternalRegistrationStep2 = '/sign_up/?step=2',
  ExternalRegistrationStep2Dev = 'http://localhost:3100/sign_up/?step=2',
  ExternalAgroidProfile = '/agroid',
  ExternalAgroidProfileDev = 'http://localhost:3100/agroid',
  Dashboard = '/',
  AdminView = '/admin-view',
}
