import { FC } from 'react';

import { ButtonInner, ButtonStyleProps, ColorsState, Wrapper } from './style';

type CustomButtonProps = {
  children: any;
  onClick: () => void;
  disabled?: boolean;
  dataTestId?: string;
} & ButtonStyleProps;

export const CustomButton: FC<CustomButtonProps> = ({
  children,
  variant,
  onClick,
  colorStyle,
  disabled,
  dataTestId,
}) => {
  return (
    <Wrapper>
      <ButtonInner
        colorStyle={colorStyle}
        variant={variant}
        onClick={onClick}
        type={'button'}
        disabled={colorStyle === ColorsState.Disabled}
        data-test-id={dataTestId}
      >
        {children}
      </ButtonInner>
    </Wrapper>
  );
};
