import { IGetIntensity } from '../../intensity/intensity.model';
import {
  IChecklistAttributeWithValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeValue,
  IPutChecklistAttributeValue,
} from '../attribute/checklist.attribute.model';

export interface IGetChecklistInstanceByTaskId {
  name: string;
  type: ECheckListInstanceType;
  machineryName?: string;
  isActive: boolean;
  intensity?: IGetIntensity;
  id: string;
  checkListId?: string;
  planCoords: ICheckListInstancePlanCoordinates;
  actualCoords?: ICheckListInstanceActualCoordinates;
  creationDate: string;
  createdBy: string;
}

export interface IGetChecklistInstance extends IGetChecklistInstanceByTaskId {
  attributeValues: IGetChecklistAttributeValue[];
  values?: IPutChecklistAttributeValue[];
  temporaryId?: string;
}

export interface IPutChecklistInstance {
  taskId: string;
  planCoords?: ICheckListInstancePlanCoordinates;
  actualCoords?: ICheckListInstanceActualCoordinates;
  name: string;
  type: ECheckListInstanceType;
  machineryName?: string;
  isActive: boolean;
  intensity?: string;
  id?: string;
}

export interface IPutNestedChecklistInstance {
  checkListId: string;
  values: IPutChecklistAttributeValue[];
  temporaryId?: string;
}

export interface IDrawingNestedInstance {
  id: string;
  rootChecklistAttribute: IGetChecklistAttribute;
  nestedInstance: IPutNestedChecklistInstance;
  attributeWithValueList: IChecklistAttributeWithValue[];
  isTemplate?: boolean;
  isRootAttributeHidden?: boolean;
  isCollapsed: boolean;
}

export interface IInstance {
  name: string;
  type: ECheckListInstanceType;
  machineryName: string;
  isActive: boolean;
  intensity: IGetIntensity;
  id: string;
  checkListId: string;
  planCoords?: {
    type: string;
    coordinates?: number[];
    geometry: {
      type: string;
      coordinates: number[];
    };
  };
  actualCoords?: {
    type: string;
    coordinates?: number[];
    geometry: {
      type: string;
      coordinates: number[];
    };
  };
  creationDate: string;
  createdBy: string;
  onlyFrontend?: boolean;
  taskId?: string;
  temporaryId?: string;
  virtualPosition?: number;
}

interface ICheckListInstancePlanCoordinates {
  type: string;
  coordinates?: number[];
  geometry: {
    type: string;
    coordinates: number[];
  };
}

interface ICheckListInstanceActualCoordinates {
  type: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
}

export enum ECheckListInstanceType {
  FromWeb = 'FROM_WEB',
  Machinery = 'MACHINERY',
  PlannedPoint = 'PLANNED_POINT',
  ActualPoint = 'ACTUAL_POINT',
  Nested = 'NESTED ',
}
