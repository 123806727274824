import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Select, MultiSelect } from '@farmlink/farmik-ui';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import { CalendarRange } from '../../../../../shared/components/CalendarRange/CalendarRange';
import { TasksController } from '../../controllers/tasks.controller';
import { OperationsStore } from '../../../operations/stores/operations.store';
import { TasksController as OperationsTasksController } from '../../../operations/controllers/tasks.controller';
import { ReactMultySelect } from '../../../../../shared/components/ReactMultySelect';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { defaultStatuses, TasksFilterStore } from '../../stores/tasks.filter.store';
import { taskLabels, TasksStore } from '../../../operations/stores/tasks.store';
import { FieldsStore } from '../../../fields/stores/fields.store';

import {
  TasksFilterWrapper,
  TasksFilterFirstLine,
  TasksFilterSecondLine,
  TasksFilterControlsWrapper,
} from './style';

const NullOption = {
  label: 'Не выбрано',
  value: null,
};

export const TasksFilter: FC<{ tasksListBlockRef?: any }> = observer(
  ({ tasksListBlockRef }: { tasksListBlockRef?: any }) => {
    const [isShown, setIsShown] = useState<boolean>(true);
    const [defaultCulture, setDefaultCulture] = useState(NullOption);
    const [defaultAsignee, setDefaultAsignee] = useState(NullOption);
    const [defaultOperation, setDefaultOperation] = useState(NullOption);
    const [defaultField, setDefaultField] = useState(NullOption);

    const controller = useStore(TasksController);
    const operationsStore = useStore(OperationsStore);
    const operationsTasksController = useStore(OperationsTasksController);
    const seasonStore = useStore(SeasonsStore);
    const organizationStore = useStore(OrganizationsStore);
    const tasksFilterStore = useStore(TasksFilterStore);
    const tasksStore = useStore(TasksStore);
    const fieldsStore = useStore(FieldsStore);

    const {
      culture,
      setCulture,
      asignee,
      setAsignee,
      status,
      setStatus,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      operationType,
      setOperationType,
      priority,
      setPriority,
      field,
      setField,
    } = tasksFilterStore;

  useEffect(() => {
    tasksStore.tasksLoadedByFilter = false;

      return () => {
        handleReset(true);
      };
    }, []);

  useEffect(() => {
    (async () => {
      handleReset();
      if (seasonStore.selectedSeason) {
        await operationsStore.fetchCulturesList();
        await operationsStore.getOperationsTypeForFilter('', true);
      }
    })();
  }, [seasonStore.selectedSeason]);

    useEffect(() => {
      if (seasonStore.selectedSeassonData) {
        tasksFilterStore.initDates(
          seasonStore.selectedSeassonData.startDate,
          seasonStore.selectedSeassonData.endDate
        );
      }
    }, [seasonStore.selectedSeassonData]);

  const handleTaskSearch = async () => {
    operationsTasksController.resetPageSettings();
    console.log('**************handleTaskSearch');
    await operationsTasksController.fetchTasks({
      seassonCultureId: culture === 'emptyCulture' ? undefined : culture,
      assigneeId: asignee,
      statusIn: status.map(item => item.value),
      planDateFrom: startDate,
      planDateTo: endDate,
      operationTypeId: operationType,
      priorityIn: priority.map(item => item.value),
      fieldId: field,
      organizationId: organizationStore.selectedOrganizationId,
      noCulture: culture === 'emptyCulture' ? true : undefined,
    });
    tasksStore.tasksLoadedByFilter = true;
    tasksListBlockRef?.current?.scrollTo(0, 0);
  };

  const handleDefaultTasksRequest = async () => {
    operationsTasksController.resetPageSettings();
    await operationsTasksController.fetchTasks({
      seassonCultureId: '',
      assigneeId: '',
      statusIn: defaultStatuses.map(item => item.value),
      planDateFrom: moment(seasonStore.selectedSeassonData?.startDate),
      planDateTo: moment(seasonStore.selectedSeassonData?.endDate),
      operationTypeId: '',
      priorityIn: [],
      fieldId: '',
      organizationId: organizationStore.selectedOrganizationId,
    });
    tasksListBlockRef?.current?.scrollTo(0, 0);
  };

    const handleReset = (notRefetchTasks?: boolean) => {
      operationsStore.resetOperationsTypeForFilter();

      setCulture('');
      setAsignee('');
      setStatus(defaultStatuses);
      setStartDate(moment(seasonStore.selectedSeassonData?.startDate));
      setEndDate(moment(seasonStore.selectedSeassonData?.endDate));
      setOperationType('');
      setPriority([]);
      setField('');

      // todo перенести state в store
      setDefaultCulture({ ...NullOption });
      setDefaultAsignee({ ...NullOption });
      setDefaultOperation({ ...NullOption });
      setDefaultField({ ...NullOption });
      if (!notRefetchTasks && seasonStore.selectedSeason) {
        handleDefaultTasksRequest();
      }
    };

    const getCultureSelectSelectOptions = useMemo(() => {
      return operationsStore.fieldsWithoutCulturesCount
        ? [NullOption, { label: 'Без культуры', value: 'emptyCulture' }, ...controller.culturesList]
        : [NullOption, ...controller.culturesList];
    }, [
      seasonStore.selectedSeason,
      operationsStore.OperationCulturesInFields,
      operationsStore.fieldsWithoutCulturesCount,
    ]);

    return (
      <>
        <TasksFilterWrapper shown={isShown} data-test-id={'tasks-filter-wrapper'}>
          <TasksFilterFirstLine>
            <Select
              onChange={cultureId => {
                setCulture(cultureId);
                setOperationType('');
                setDefaultOperation({
                  label: 'Не выбрано',
                  value: null,
                });
                operationsStore.getOperationsTypeForFilter(cultureId);
                // operationsStore.getOperations();
              }}
              options={getCultureSelectSelectOptions}
              placeholder={'Культура'}
              dataTestId={'tasks-select-culture'}
              defaultValue={defaultCulture}
            />
            <Select
              onChange={asigneeId => setAsignee(asigneeId)}
              options={[{ label: 'Не выбрано', value: null }, ...controller.organizationUsersList]}
              placeholder={'Исполнитель'}
              dataTestId={'tasks-select-performer'}
              disabled={organizationStore.selectedOrganizationId === 'my'}
              defaultValue={defaultAsignee}
            />
            <ReactMultySelect
              options={controller.taskStatuses.filter(
                taskStatus => taskStatus.label !== taskLabels.IMPOSSIBLE
              )}
              defaultValue={defaultStatuses}
              value={status}
              onChange={setStatus}
              placeholder="Статус"
              isSearchable={false}
              hideSelectedOptions={false}
              name="Статус"
              dataTestId={'tasks-select-status'}
            />
            <CalendarRange
              label={''}
              onChange={(dateObj: { start: Date; end: Date }) => {
                setStartDate(moment(dateObj.start));
                setEndDate(moment(dateObj.end));
              }}
              placeholder={'Укажите дату'}
              minDate={moment(seasonStore.selectedSeassonData?.startDate)}
              maxDate={moment(seasonStore.selectedSeassonData?.endDate)}
              dataTestId={'tasks-start-date'}
              dateFormat={'DD.MM.YYYY'}
              startDate={startDate}
              endDate={endDate}
            />
          </TasksFilterFirstLine>
          <TasksFilterSecondLine>
            <Select
              onChange={operationTypeId => {
                setOperationType(operationTypeId);
                operationsStore.selectOperationTypeId(operationTypeId);
              }}
              options={[
                { label: 'Не выбрано', value: null },
                ...controller.operationsTypesForFilter,
              ]}
              disabled={!controller.operationsTypesForFilter.length}
              placeholder={'Тип операции'}
              dataTestId={'tasks-select-operation-type'}
              defaultValue={defaultOperation}
            />
            {/* <ReactMultySelect
              value={priority}
              options={controller.taskPrioretees}
              onChange={pr => setPriority(pr)}
              placeholder="Приоритет"
              isSearchable={false}
              hideSelectedOptions={false}
              name="Приоритет"
              dataTestId={'tasks-select-priority'}
            /> */}
            <Select
              onChange={fieldId => {
                setField(fieldId);
              }}
              options={[{ label: 'Не выбрано', value: null }, ...controller.fieldsList]}
              placeholder={'Название поля'}
              dataTestId={'tasks-select-field-name'}
              defaultValue={defaultField}
            />
            <Button
              color={'primary'}
              type={'button'}
              className={`filter-tasks-button`}
              onClick={() => {
                handleTaskSearch();
              }}
              dataTestId={'tasks-filter-find'}
              disabled={!fieldsStore.fields.length || !seasonStore.selectedSeason}
            >
              Найти
            </Button>
          </TasksFilterSecondLine>
        </TasksFilterWrapper>
        <TasksFilterControlsWrapper>
          <Button
            color={'default'}
            type={'button'}
            className={'filter-hide'}
            onClick={() => {
              setIsShown(prev => !prev);
            }}
            dataTestId={'tasks-filter-hide'}
          >
            {isShown ? 'Скрыть фильтр' : 'Показать фильтр'}
          </Button>
          <Button
            color={'secondary'}
            type={'button'}
            className={'filter-reset'}
            onClick={() => handleReset(false)}
            dataTestId={'tasks-filter-reset'}
          >
            Сбросить фильтры
          </Button>
        </TasksFilterControlsWrapper>
      </>
    );
  }
);
