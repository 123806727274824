import { useMemo } from 'react';

import { IChecklistAttributeWithValue } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

const useGetErrorListOfChecklistAttr = (
  attrSchema: IChecklistAttributeWithValue['schema'],
  attrWithFileSchema?: IChecklistAttributeWithValue['schema']
): string[] => {
  return useMemo<string[]>(() => {
    const isShownErrorInParentAttr: boolean = attrSchema.isShownError;

    const isShownErrorInFileAttr: boolean = attrWithFileSchema?.isShownError;

    const isShownErrorInBothAttrs = Boolean(isShownErrorInParentAttr && isShownErrorInFileAttr);

    if (isShownErrorInBothAttrs) {
      return [attrWithFileSchema.error, attrSchema.error];
    }

    if (isShownErrorInParentAttr) {
      return [attrSchema.error];
    }

    if (isShownErrorInFileAttr) {
      return [attrWithFileSchema?.error];
    }

    return [];
  }, [attrSchema, attrWithFileSchema]);
};

export default useGetErrorListOfChecklistAttr;
